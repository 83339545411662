import React from "react";

function HistoryCallRequestesIcon() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8184 12.9567C13.5749 12.9567 13.351 13.0467 13.1879 13.2095L10.9331 15.464C8.12046 14.6463 6.99787 12.3671 6.62945 11.3564L9.06664 8.91918C9.35615 8.62967 9.39636 8.22532 9.18601 7.71742C8.70149 6.54783 5.55058 2.12128 3.77106 2.12128C3.4529 2.12128 3.15812 2.2409 2.94049 2.45853L0.568129 4.83089C-0.932688 6.33196 0.572653 13.2098 4.69713 17.334C7.97345 20.6103 12.4201 22.1023 15.1652 22.1023C16.1478 22.1021 16.8469 21.9015 17.243 21.5052L19.6153 19.1331C19.7857 18.9627 20.1469 18.4817 19.8219 17.6974C19.167 16.1169 14.9593 12.9567 13.8184 12.9567ZM15.1652 20.5425C13.1346 20.5945 8.96335 19.3945 5.80013 16.231C1.8302 12.2611 1.20419 6.66293 1.68243 5.92232L3.87535 3.7294C4.21688 3.90507 6.63875 5.84291 7.65579 8.12429L5.32666 10.4532C5.03565 10.7444 4.93085 11.1699 5.05349 11.5637C5.37391 12.5911 6.73525 16.0224 10.8007 17.0437C10.8879 17.0656 11.2251 17.3717 11.8504 16.7522L13.9933 14.6096C15.0636 15.126 17.9044 17.3008 18.3445 18.1975C18.3447 18.1975 16.3141 20.5131 15.1652 20.5425Z"
        fill="#186985"
      />
      <path
        d="M19.931 0H11.6647C10.5466 0 9.68591 0.89667 9.68591 2.01449V5.44586C9.68591 5.87661 10.0091 6.22567 10.4398 6.22567C10.8706 6.22567 11.1938 5.87661 11.1938 5.44586V2.01449C11.1938 1.75665 11.4066 1.50785 11.6647 1.50785H19.931C20.1891 1.50785 20.4922 1.75665 20.4922 2.01449V15.4279C20.4922 15.8586 20.8154 16.2077 21.2461 16.2077C21.6769 16.2077 22 15.8586 22 15.4279V2.01449C22 0.89667 21.0491 0 19.931 0Z"
        fill="#186985"
      />
      <path
        d="M13.6922 4.77485H17.7473C18.178 4.77485 18.5271 4.32601 18.5271 3.89527C18.5271 3.46452 18.178 3.01569 17.7473 3.01569H13.6922C13.2614 3.01569 12.9124 3.46452 12.9124 3.89527C12.9124 4.32601 13.2614 4.77485 13.6922 4.77485Z"
        fill="#186985"
      />
      <path
        d="M13.6922 8.04185H17.7473C18.178 8.04185 18.5271 7.71867 18.5271 7.28792C18.5271 6.85718 18.178 6.534 17.7473 6.534H13.6922C13.2614 6.534 12.9124 6.85718 12.9124 7.28792C12.9124 7.71867 13.2614 8.04185 13.6922 8.04185Z"
        fill="#186985"
      />
      <path
        d="M13.6922 11.5602H17.7473C18.178 11.5602 18.5271 11.237 18.5271 10.8063C18.5271 10.3756 18.178 10.0524 17.7473 10.0524H13.6922C13.2614 10.0524 12.9124 10.3756 12.9124 10.8063C12.9124 11.237 13.2614 11.5602 13.6922 11.5602Z"
        fill="#186985"
      />
    </svg>
  );
}

export default HistoryCallRequestesIcon;
