import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import { Box, Divider, Grid, IconButton, Popover } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateCarSale } from "../store/actions/car";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import GPS from "../icons/gps.svg";
import Tooltip from "@material-ui/core/Tooltip";
// import ReportSaleDialog from "./ReportSaleDialog";
import Reserved from "../icons/Reserved.svg";
import ReserveredAr from "../icons/ReserveredAr.svg";
import ReportSaleComponent from "./ReportSale/ReportSaleComponent";
import SoldFlag from "./icons/SoldFlag";
import {
  numberWithCommas,
  timerSinceDisplay,
  encrypt,
  distanceWithCommas,
} from "../helpers/functions";
import MoreIcon from "../icons/More.svg";
import { MoreVert } from "@material-ui/icons";
import Close from "./icons/Close.js";
import smallLogo from "../icons/markabteSmallLogo.svg";
import OwnerInfo from "./ReportSale/OwnerInfo";
import SellerCarGenerate from "./SellerCarGenerate";
import { getCarStatus } from "../store/actions/dealership";
import CarStatus from "./ReportSale/CarStatus";
// import EditAppointmestsTimes from "./EditTimeList/EditAppointmestsTimes";

const useStyles = makeStyles((theme) => ({
  firstGridItemHome: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 16.73px", /////
      minWidth: "269.27px",
      maxWidth: "269.27px",
    },
    [theme.breakpoints.up("md")]: {
      // margin: "0 10.885px", /////
      // margin: "0 7.015px", /////
      // minWidth: "285.97px",
      // maxWidth: "285.97px",
      // margin: "0 9.9px", /////
      // margin: "0 7.9px", /////
      margin: "0 8.34px", ///// (840 - (263.32 *3))/6
      minWidth: "263.32px",
      maxWidth: "263.32px",
    },
    [theme.breakpoints.up("lg")]: {
      // margin: "0 12.235px", /////
      // margin: "0 8.015px", /////
      // minWidth: "321.48px",
      // maxWidth: "321.48px",
      // margin: "0 11.2px", /////
      // margin: "0 9.1px", /////
      margin: "0 9.38px", ///// (945 - (296.24 *3))/6
      minWidth: "296.24px",
      maxWidth: "296.24px",
    },
    [theme.breakpoints.up("xl")]: {
      // margin: "0 14.5px", /////
      // margin: "0 9.5px", /////
      // minWidth: "381px",
      // maxWidth: "381px",
      // margin: "0 13.2px", /////
      margin: "0 11.166px", ///// (1120 - (351 *3))/6
      minWidth: "351px",
      maxWidth: "351px",
    },
  },
  firstPaper: {
    margin: "7.5px 0",
    padding: "0.5em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 4px 4px rgba(15, 71, 90, 0.4)",
    borderRadius: "0",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0",
    },
  },
  imagePaper: {
    // maxHeight: 235,
    minHeight: "200px",
    margin: "-8px",
    position: "relative",
    borderRadius: "0",
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      // minHeight: "343px",
    },
    [theme.breakpoints.up("lg")]: {
      // minHeight: 235,
    },
  },
  imagePaperHome: {
    // minHeight: 235,
    minHeight: "200px",

    margin: "-8px",
    position: "relative",
    borderRadius: "0",
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      // minHeight: "204.89px",
    },
    [theme.breakpoints.up("lg")]: {
      // minHeight: "230.33px",
    },
    [theme.breakpoints.up("xl")]: {
      // minHeight: "272.97px",
    },
  },
  Image: {
    // backgroundColor: "rgba(32,32,32,0.7)",
    // filter: "blur(12px)",
    // height: "235px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      // height: "343px",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "235px",
    },
    // [theme.breakpoints.up("xl")]: {
    // },
  },
  ImageHome: {
    objectFit: "cover",
  },
  overlayDiv: {
    backgroundColor: "rgba(0,0,0,0.59)",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    padding: "4px 6px",
    [theme.breakpoints.down("sm")]: {
      bottom: "-6px",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "-8px",
    },
    [theme.breakpoints.up("md")]: {
      bottom: "-4px",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-8px",
    },
  },
  overlaySecondTypography: {
    marginLeft: "auto",
  },
  listedSinceTypography: {
    // fontSize: "8px",
    color: "white",
    fontWeight: 500,
    margin: "0 1px",
    fontSize: "12px",
  },
  locationIcon: {
    marginRight: "4px",
    // width:'8px',
    width: "12px",
  },
  locationTypography: {
    // fontSize: "8px",
    color: "white",
    fontWeight: 500,
    fontSize: "12px",
  },
  dialogContentText: {
    padding: "0",
  },
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 45px",
    width: "298px",
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  milageTypography: {
    fontSize: "12px",
    color: "#07303E",
    padding: "0 12px 0 5px",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  firstButton: {
    padding: "0 4px 0 0",
    [theme.breakpoints.up("md")]: {
      padding: "0",
      alignContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 4px 0 0",
    },
  },
  firstButtonButton: {
    margin: "16px 0 8px",
    padding: "8px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "clamp(12px,1vw,14px)",
    [theme.breakpoints.up("md")]: {
      margin: "0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "16px 0 8px",
    },
  },
  secondButton: {
    padding: "0 0 0 4px",
    [theme.breakpoints.up("md")]: {
      flexGrow: "0",
      flexBasis: "100%",
      maxWidth: "100%",
      padding: "0",
      alignContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      flexGrow: "0",
      flexBasis: "50%",
      maxWidth: "50%",
      padding: "0 0 0 4px",
    },
  },
  secondButtonButton: {
    margin: "16px 0 8px",
    padding: "7px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "clamp(12px,1vw,14px)",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "16px 0 8px",
    },
  },
  Button: {
    margin: "17px 0 7px",
    padding: "8px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      margin: "auto 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "17px 0 1px",
    },
  },
  buttonGridContainer: {
    borderTop: "0.5px solid #c4c4c4",
    // padding: "0 16px",
    width: "calc(100% + 16px)",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      borderTop: "none",
      ////new
      margin: "-25px -8px 0",
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%",
      padding: "0 0 10px 20px",
      ////////
      alignContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      borderTop: "0.5px solid #c4c4c4",
      ////new
      margin: "0",
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
      padding: 0,
      ////////
    },
  },
  cardHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  mainTextDiv: {
    padding: "4px 8px 8px",
    zIndex: 2,
    position: "relative",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      padding: "4px 8px 16px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "4px 8px 8px",
    },
  },
  lastRowTypography: {
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "0px",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  likeIconButton: {
    transform: "translate(-100%,-76.47%)",
    left: "100%",
    position: "absolute",
    top: "100%",
    padding: 0,
  },
  soldIconHover: {
    "&:hover": {
      cursor: "auto",
    },
  },
  reserved: {
    position: "absolute",
    top: "10px",
    zIndex: "10",
  },
  popoverTypo: {
    fontSize: "12px",
    padding: "4px 0",
    margin: "3px 0",
    whiteSpace: "nowrap",
  },
  titleIDRowDiv: {
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  inspectedDiv: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
    WebkitUserSelect: "none",
    backgroundColor: "rgba(24, 105, 133, 0.05)",
    padding: "3px 8px",
    borderRadius: "50em",
  },
  inspectedTypography: {
    color: "#626465",
    fontSize: "11px",
    marginLeft: "4px",
  },
  typoChip: {
    whiteSpace: "nowrap",
    fontSize: "14px",
    marginBottom: "5px",
  },
}));

const CarCard = (props) => {
  const {
    updateCarSale,
    car_id,
    make,
    model,
    trim,
    year,
    milage,
    fuelType,
    image,
    price,
    score,
    sold,
    reserved,
    reservedTo,
    reservationId,
    dealershipLocation,
    dealershipArea,
    createdAt,
    onClick2,
    uom,
    driveType,
    countrySpecs,
    role,
    engineSize,
    batterySize,
    dealership_details,
    listing_request_id,
    getCarStatus,
    unlocked,
    published_for_buyers,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [ownerInfoPopup, setOwnerInfoPopup] = React.useState(false);
  const [carInfoPopup, setCarInfoPopup] = React.useState(false);
  const [sellerCarPopup, setSellerCarPopup] = useState(false);
  const [openTimes, setOpenTimes] = useState(false);
  // var difference = new Date() - new Date(createdAt);
  // var days = 1000 * 60 * 60 * 24; //milli_sec sec min hour
  // var listedSince = Math.floor(difference / days); //to limit the difference to days and not hours,...
  var listedSinceObj = timerSinceDisplay(new Date(createdAt));
  var listedSince = listedSinceObj.time;
  var listedSinceUnit = listedSinceObj.unit;
  // const icons = [Odometer, Transmission, Fuel, Rating, Score, Location]; //to use in map function and render out icons
  const data = [fuelType, engineSize, driveType, countrySpecs]; //to use in map function and render out data
  const getScoreColor = (x) => {
    if (isNaN(x)) {
      return "#07303E";
    }
    if (x <= 20) {
      return "#D40000";
    } else if (x <= 30) {
      return "#EA3800";
    } else if (x <= 40) {
      return "#EA5400";
    } else if (x <= 50) {
      return "#FF7A00";
    } else if (x <= 60) {
      return "#FFA800";
    } else if (x <= 70) {
      return "#FFD600";
    } else if (x <= 80) {
      return "#C5E500";
    } else if (x <= 90) {
      return "#5BCE00";
    } else if (x <= 100) {
      return "#00A900";
    }
  };
  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    document.getElementById("root").style.filter = "blur(16px)"; // adds blur to root element
  };

  /////////////////// here

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const opener = Boolean(anchorEl);
  const id = opener ? "simple-popover" : undefined;

  //////////////////////

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        style={{ minWidth: "290px" }}
        // className={classes.firstGridItemHome}
      >
        <Paper className={classes.firstPaper}>
          {reserved && localStorage.getItem("language") === "ar" ? (
            <img
              src={ReserveredAr}
              style={{ left: "-7px" }}
              className={classes.reserved}
            />
          ) : reserved ? (
            <img
              src={Reserved}
              style={{ left: "-7px" }}
              className={classes.reserved}
            />
          ) : null}
          <Grid container spacing={2} alignContent={"space-between"}>
            <Grid
              item
              xs={12}
              md={12}
              onClick={() => {
                // props.history.push(`/car/${encrypt(car_id)}`);
                window.open(`/car/${encrypt(car_id)}`, "_blank");
              }}
              className={classes.cardHover}
            >
              <Paper
                style={{ height: "102%" }}
                className={clsx({
                  [classes.imagePaper]: true,
                })}
              >
                <img
                  loading="lazy"
                  src={image}
                  alt={"car_thumbnail"}
                  width={"100%"}
                  // height={"82%"}
                  height={this?.container?.offsetWidth + "px"}
                  className={classes.Image}
                />
                {sold && (
                  <div
                    style={{
                      backgroundColor: "rgba(32,32,32,0.7)",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  >
                    <SoldFlag
                      size={168}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "50%",
                        height: "50%",
                      }}
                    />
                  </div>
                )}
                <div
                  // style={{
                  //   backgroundColor: "rgba(0,0,0,0.59)",
                  //   position: "absolute",
                  //   bottom: 0,
                  //   right: 0,
                  //   left: 0,
                  //   display: "flex",
                  //   flexDirection: "row",
                  //   padding: "4px 6px",
                  // }}
                  className={classes.overlayDiv}
                >
                  <img
                    src={GPS}
                    alt="gps_icon"
                    className={classes.locationIcon}
                  />
                  <Typography
                    // style={{ fontSize: "8px", color: "white", fontWeight: 500 }}
                    className={classes.locationTypography}
                  >
                    {t(dealershipLocation)}, {dealershipArea}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "8px",
                      color: "white",
                      fontWeight: 500,
                      display: "flex",
                    }}
                    className={classes.overlaySecondTypography}
                  >
                    <Typography className={classes.listedSinceTypography}>
                      {`${listedSince} ${t(listedSinceUnit)} ${t("ago")}`}
                    </Typography>{" "}
                  </Typography>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div
                // className={classes.cardHover}
                onClick={() => {
                  // props.history.push(!sold ? `/car/${encrypt(car_id)}` : "");
                  window.open(
                    !sold ? (`/car/${encrypt(car_id)}`, "_blank") : ""
                  );
                }}
                className={clsx({
                  [classes.mainTextDiv]: true,
                  [classes.cardHover]: !sold,
                })}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className={classes.titleIDRowDiv}
                >
                  <Typography
                    children={
                      localStorage.getItem("language") === "ar"
                        ? `${numberWithCommas(price)} ${t("JD")}`
                        : `${t("JD")} ${numberWithCommas(price)}`
                    }
                    style={
                      localStorage.getItem("language") === "en"
                        ? {
                            fontWeight: "600",
                            color: sold ? "#626465" : "#f15a29",
                            marginBottom: "5px",
                          }
                        : {
                            fontWeight: "700",
                            color: sold ? "#626465" : "#f15a29",
                            marginBottom: "5px",
                          }
                    }
                  />
                </div>
                <Grid container>
                  <Grid item xs={12} md={6} lg={12}>
                    <Tooltip
                      disableFocusListener
                      title={`${year}  ${make}  ${model}  ${trim}`}
                    >
                      <div style={{ display: "flex", overflow: "hidden" }}>
                        <Typography
                          children={`${year}`}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: sold ? "#626465" : "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: sold ? "#626465" : "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}
                        <Typography
                          children={`${make}`}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: sold ? "#626465" : "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: sold ? "#626465" : "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}
                        <Typography
                          children={`${model}`}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: sold ? "#626465" : "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: sold ? "#626465" : "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}
                        <Typography
                          children={`${trim}`}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  color: sold ? "#626465" : "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  color: sold ? "#626465" : "#07303E",
                                }
                          }
                        />
                      </div>
                    </Tooltip>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {data.map((item, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // overflow: "hidden",////new
                          }}
                        >
                          <Typography ///fix at user
                            children={
                              item != "N/A"
                                ? item
                                : index == 1
                                ? batterySize
                                : "N/A"
                            }
                            style={{
                              fontWeight: "600",
                              fontSize: "12px",
                              color: "#626465",
                              // marginBottom: "10px",
                              marginBottom: "5px",
                              direction: index == 1 && "ltr",
                              whiteSpace: "nowrap", ////new
                            }}
                          />
                          {index === data.length - 1 ? null : (
                            <Typography
                              children="|"
                              style={{
                                fontWeight: "600",
                                fontSize: "12px",
                                color: sold ? "#626465" : "#C4C4C4",
                                padding: "0 5px",
                                marginBottom: "5px",
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // marginBottom: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600", // i think 500
                                  color: sold ? "#626465" : "#07303E",
                                }
                              : {
                                  fontWeight: "600",
                                  color: sold ? "#626465" : "#07303E",
                                }
                          }
                          className={classes.lastRowTypography}
                        >
                          {t("Odometer")}:{" "}
                        </Typography>
                        <Typography
                          children={distanceWithCommas(milage)}
                          className={classes.milageTypography}
                          style={
                            localStorage.getItem("language") === "en"
                              ? { color: sold ? "#626465" : "#07303E" }
                              : {
                                  color: sold ? "#626465" : "#07303E",
                                  fontWeight: 600,
                                }
                          }
                        />
                      </div>
                      <div className={classes.inspectedDiv}>
                        <img src={smallLogo} width="12px" />
                        <Typography className={classes.inspectedTypography}>
                          {t("Inspected")}
                        </Typography>
                      </div>
                    </div>
                  </Grid>

                  {/* ////////////////////////////////////// */}
                  <Grid
                    container
                    className={classes.buttonGridContainer} ///???
                  >
                    {" "}
                    {/*adding this padding causes long button text to wrap due to less space fix*/}
                    <Grid
                      item
                      xs={role === "dealer owner" && !reserved ? 6 : 12}
                      md={12}
                      lg={role === "dealer owner" && !reserved ? 6 : 12}
                      style={{ display: "grid" }}
                      className={classes.firstButton}
                    >
                      <Button
                        children={t("Report Sale")}
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleClickOpen}
                        className={classes.firstButtonButton}
                      />
                    </Grid>
                    {role === "dealer owner" && !reserved && (
                      <Grid
                        item
                        xs={6}
                        style={{ display: "grid" }}
                        className={classes.secondButton}
                      >
                        <Button
                          aria-describedby={id}
                          // variant="contained"
                          onClick={(e) => handleClick(e)}
                          style={{ postion: "relative" }}
                          children={
                            <div style={{ display: "flex" }}>
                              <span style={{ margin: "0 10px" }}>
                                {t("More")}
                              </span>{" "}
                              <img src={MoreIcon} />
                            </div>
                          }
                          variant={"outlined"}
                          color={"primary"}
                          className={classes.secondButtonButton}
                        ></Button>
                        <Popover
                          id={id}
                          open={opener}
                          anchorEl={anchorEl}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onClose={(e) => handleClose(e)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "150px",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div></div>
                              <Typography
                                style={{ fontSize: "12px", padding: "4px 0" }}
                                color="textSecondary"
                              >
                                {t("take action")}
                              </Typography>
                              <div onClick={(e) => handleClose(e)}>
                                <Close />
                              </div>
                            </div>
                            <Grid item xs>
                              <Divider component="div" />
                            </Grid>
                            <div
                              style={{
                                padding: "4px 0",
                                display: "inline-grid",
                              }}
                            >
                              <div>
                                <Button
                                  variant="text"
                                  className={classes.popoverTypo}
                                  // disabled={true}
                                  onClick={(event) => {
                                    onClick2();
                                  }}
                                >
                                  {t("Edit Price")}
                                </Button>
                              </div>
                              {props?.user?.runner_account && (
                                <div>
                                  <Button
                                    variant="text"
                                    className={classes.popoverTypo}
                                    href={`tel:${dealership_details.phone_number}`}
                                  >
                                    {t("Call Owner")}
                                  </Button>
                                </div>
                              )}
                              {props?.user?.runner_account && (
                                <div>
                                  <Button
                                    variant="text"
                                    className={classes.popoverTypo}
                                    onClick={() => {
                                      window.open(
                                        `https://wa.me/${dealership_details.phone_number}/`
                                      );
                                    }}
                                  >
                                    {t("Text Owner")}
                                  </Button>
                                </div>
                              )}
                              {props?.user?.runner_account && (
                                <div>
                                  <Button
                                    variant="text"
                                    className={classes.popoverTypo}
                                    onClick={() => {
                                      setOwnerInfoPopup(true);
                                    }}
                                  >
                                    {t("Owner Info")}
                                  </Button>
                                </div>
                              )}

                              {props?.user?.runner_account && (
                                <div>
                                  <Button
                                    variant="text"
                                    className={classes.popoverTypo}
                                    onClick={() => {
                                      window.open(
                                        `https://wa.me/${
                                          dealership_details.phone_number
                                        }/?text=مرحباً سيد/ة ${
                                          dealership_details.owner_name
                                        }, يمكنك مشاهدة تقرير الصيانة الخاص بسيارتك من الرابط الآتي: %0a https://dealers.markabte.com/car-maintenance-report/${encrypt(
                                          car_id
                                        )}`
                                      );
                                    }}
                                  >
                                    {t("Send Maintenance Report")}
                                  </Button>
                                </div>
                              )}
                              {props?.user?.runner_account && (
                                <div>
                                  <Button
                                    variant="text"
                                    className={classes.popoverTypo}
                                    onClick={() => {
                                      setSellerCarPopup(true);
                                    }}
                                  >
                                    {t("Send Inspection Report")}
                                  </Button>
                                </div>
                              )}
                              {props?.user?.runner_account && (
                                <div>
                                  <Button
                                    variant="text"
                                    className={classes.popoverTypo}
                                    onClick={() => {
                                      // props.history.push(
                                      //   `/car-details/${encrypt(car_id)}`
                                      // );
                                      window.open(
                                        `/car/${encrypt(car_id)}`,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    {t("Inspection Report")}
                                  </Button>
                                </div>
                              )}
                              {props?.user?.runner_account && (
                                <div>
                                  <Button
                                    variant="text"
                                    className={classes.popoverTypo}
                                    onClick={() => {
                                      setCarInfoPopup(true);
                                      getCarStatus({
                                        carId: car_id,
                                      });
                                    }}
                                  >
                                    {t("Car status")}
                                  </Button>
                                </div>
                              )}
                              {/* <div>
                                <Button
                                  variant="text"
                                  className={classes.popoverTypo}
                                  onClick={() => setOpenTimes(true)}
                                >
                                  {t("Remove temporary")}
                                </Button>
                              </div> */}
                            </div>
                          </div>
                        </Popover>
                      </Grid>
                    )}
                  </Grid>
                  {/* ////////////////////////////////////// */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <ReportSaleComponent
        setOpen={setOpen}
        open={open}
        carId={car_id}
        initialTab={!reserved ? 1 : 2}
        firstFlow={!reserved}
        reservedTo={reservedTo}
        reservationId={reservationId}
      />
      <OwnerInfo
        setOpen={setOwnerInfoPopup}
        open={ownerInfoPopup}
        carId={car_id}
        dealership_details={dealership_details}
        listing_request_id={listing_request_id}
      />
      <SellerCarGenerate
        setOpen={setSellerCarPopup}
        open={sellerCarPopup}
        carId={car_id}
      />
      <CarStatus
        unlocked={unlocked}
        published_for_buyers={published_for_buyers}
        setOpen={setCarInfoPopup}
        open={carInfoPopup}
        carId={car_id}
      />
    </Grid>
  );
};

CarCard.defaultProps = {
  make: "Make",
  model: "Model",
  year: "Year",
  dealershipLocation: "Location",
  milage: "Milage",
  fuelType: "Fuel Type",
  engineSize: "Engine Size",
  onClick: () => {},
};

CarCard.propTypes = {
  make: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.string,
  dealershipLocation: PropTypes.string,
  milage: PropTypes.string,
  fuelType: PropTypes.string,
  engineSize: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  car: state.car.car,
  role: state.auth.user.role,
});

const mapDispatchToProps = (dispatch) => ({
  updateCarSale(payload) {
    dispatch(updateCarSale(payload));
  },
  getCarStatus(payload) {
    dispatch(getCarStatus(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarCard)
);
