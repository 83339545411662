// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";
import {
  FETCH_CAR_REQUEST,
  FETCH_CAR_REQUEST_SUCCEED,
  FETCH_CAR_REQUEST_FAILURE,
  LIST_CARS_REQUEST,
  LIST_CARS_REQUEST_FAILURE,
  LIST_CARS_REQUEST_SUCCEED,
  ADD_CAR,
  REMOVE_CAR,
  RESERVE_CAR_SUCCESS,
  RESERVE_CAR_PURCHASE_STATUS_SUCCESS,
  AUTO_RESERVE_CAR,
  AUTO_UNRESERVE_CAR,
  SET_OWNER_INFO_SUCCEED,
} from "../constants";

const initialState = {
  car: {},
  fetchingCar: true,
  cars: [],
  fetching: true,
};

export default createReducer(initialState, {
  [FETCH_CAR_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      car: payload,
      fetchingCar: false,
    });
  },
  [FETCH_CAR_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingCar: false,
    });
  },
  [LIST_CARS_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetching: true,
    });
  },
  [LIST_CARS_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      cars: payload,
      fetching: false,
    });
  },
  [LIST_CARS_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetching: false,
    });
  },
  [FETCH_CAR_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetchingCar: true,
    });
  },
  [FETCH_CAR_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      car: payload,
      fetchingCar: false,
    });
  },
  [FETCH_CAR_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingCar: false,
    });
  },
  [ADD_CAR]: (state, payload) => {
    const modifiedCars = state.cars;
    modifiedCars.push(payload.instance);
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [REMOVE_CAR]: (state, payload) => {
    const removed = payload.instance;
    const modifiedCars = state.cars;
    modifiedCars.forEach((element, index) => {
      if (element.id === removed) {
        modifiedCars.splice(index, 1);
      }
    });
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [RESERVE_CAR_SUCCESS]: (state, payload) => {
    const reservedCar = payload;
    const modifiedCars = state.cars;
    modifiedCars.forEach((element, index) => {
      if (element.id === reservedCar.id) {
        modifiedCars.splice(index, 1, reservedCar);
      }
    });

    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [RESERVE_CAR_PURCHASE_STATUS_SUCCESS]: (state, payload) => {
    const changedStatusCar = payload;
    const modifiedCars = state.cars;
    modifiedCars.forEach((element, index) => {
      if (element.id === changedStatusCar.id) {
        modifiedCars.splice(index, 1, changedStatusCar);
      }
    });

    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [AUTO_RESERVE_CAR]: (state, payload) => {
    const modifiedCars = state.cars,
      carIndex = modifiedCars.findIndex((car) => car.id == payload.instance);
    modifiedCars[carIndex].reservation = payload.reservation;
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [AUTO_UNRESERVE_CAR]: (state, payload) => {
    const modifiedCars = state.cars,
      carIndex = modifiedCars.findIndex((car) => car.id == payload.instance);
    modifiedCars[carIndex].reservation = payload.reservation;
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [SET_OWNER_INFO_SUCCEED]: (state, payload) => {
    console.log("vvvvvvvvvvvvv", payload);
    const modifiedCars = state.cars,
      carIndex = modifiedCars.findIndex((car) => car.id == payload.carId);
    modifiedCars[carIndex].dealership_details = payload.data;
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
});
