import {
  GET_CALLS_INFO,
  // GET_APPOINTMENT_INTERVAL,
  GET_DEALER_CREDITS,
  MOVE_TO_HISTORY,
  SET_DEALER_CREDITS,
  SET_OWNER_INFO,
  GET_MESSAGES_INFO,
  RECEIVED_OR_HISTORY,
  SET_CAR_STATUS,
  GET_CAR_STATUS,
} from "../constants";

// export function getApptInterval(payload) {
//   //payload:{ dealershipId: "?dealerspip__id=4" }
//   return {
//     type: GET_APPOINTMENT_INTERVAL,
//     payload: payload,
//   };
// }

export function getDealerCredits(payload) {
  return {
    type: GET_DEALER_CREDITS,
    payload: payload,
  };
}

export function setDealerCredits(payload) {
  return {
    type: SET_DEALER_CREDITS,
    payload: payload,
  };
}

export function setOwnerInfo(payload) {
  return {
    type: SET_OWNER_INFO,
    payload: payload,
  };
}

export function getCallsInfo(payload) {
  return {
    type: GET_CALLS_INFO,
    payload: payload,
  };
}

export function moveToHistory(payload) {
  return {
    type: MOVE_TO_HISTORY,
    payload: payload,
  };
}

export function getMessagesInfo(payload) {
  return {
    type: GET_MESSAGES_INFO,
    payload: payload,
  };
}

export function receivedOrHistory(payload) {
  return {
    type: RECEIVED_OR_HISTORY,
    payload: payload,
  };
}

export function setCarStatus(payload) {
  return {
    type: SET_CAR_STATUS,
    payload: payload,
  };
}

export function getCarStatus(payload) {
  return {
    type: GET_CAR_STATUS,
    payload: payload,
  };
}
