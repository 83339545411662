import { put, takeLatest } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  CREATE_LISTING_REQUESTS,
  CREATE_LISTING_REQUESTS_SUCCEED,
  CREATE_LISTING_REQUESTS_FAILURE,
  TOGGLE_NOTIFICATION_BAR,
  GET_DEALER_CREDITS,
} from "../constants";

import API_URLS from "../../api";

export function* createRequests(action) {
  try {
    let data = {
      url: API_URLS(`${action.payload.id}`).LISTINGS.REQUEST,
      method: "POST",
      body: {
        number_of_listings: action.payload.number_of_listings,
        request_data: action.payload.request_data,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: CREATE_LISTING_REQUESTS_SUCCEED, payload: response });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message:
          localStorage.getItem("language") === "en"
            ? `You successfully requested ${action.payload.number_of_listings} entries`
            : `لقد طلبت ${action.payload.number_of_listings} إدخالات بنجاح`,
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({ type: CREATE_LISTING_REQUESTS_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "There was an error sending your request please try again",
        variant: "error",
        open: true,
      },
    });
    async function getErrorResponseData() {
      let errorResponseData = await e?.response
        .json()
        .then((responseObject) => {
          return responseObject;
        });
      return errorResponseData.dealershipId;
    }

    const dealershipId = yield getErrorResponseData();

    yield put({
      type: GET_DEALER_CREDITS,
      payload: { dealershipId: dealershipId },
    });
  }
}

export function* createRequestsWatcher() {
  yield takeLatest(CREATE_LISTING_REQUESTS, createRequests);
}
