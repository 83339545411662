import { all } from "redux-saga/effects";
import {
  fetchTokenWatcher,
  fetchRefreshTokenWatcher,
  confirmResetWatcher,
  activateAccountWatcher,
  sendOTPWatcher,
  validateOTPWatcher,
  checkUniquePhoneWatcher,
} from "./sagas-definitions/auth";
import {
  getCarPriceFormWatcher,
  updateCarPriceFormWatcher,
  getCarListWatcher,
  getCarDetailsWatcher,
  getCarGroupsWatcher,
  updateCarSaleWatcher,
  getCarWatcher,
  getCarVisualizationWatcher,
  getCarFaultsWatcher,
  reserveCarWatcher,
  reservedCarPurchaseStatusWatcher,
  uploadLicenseImagesWatcher,
  getCarGroupsScoringWatcher,
  generateSellerCarWatcher,
} from "./sagas-definitions/car";
import { createRequestsWatcher } from "./sagas-definitions/request";
import {
  fetchFieldsWatcher,
  fetchFieldsOptionsWatcher,
  fetchOptionsWatcher,
} from "./sagas-definitions/forms";
import {
  updatePageTimeStampWatcher,
  getPageTimeStampsWatcher,
  getUnseenNotificationsWatcher,
} from "./sagas-definitions/websockets";

import { requestConnectionWatcher } from "./sagas-definitions/websockets";
// import {
//   dealerConfirmationWatcher,
//   getAppointmentsWatcher,
//   getDeactivatedAppointmentWatcher,
//   getDisabledAppointmentWatcher,
//   getReportPurchaseAppointmentsWatcher,
//   postDisabledAppointmentsWatcher,
// } from "./sagas-definitions/appointments";
import {
  getCallsInfoWatcher,
  // getApptIntervalWatcher,
  getDealerCreditsWatcher,
  moveToHistoryWatcher,
  getMessagesInfoWatcher,
  receivedOrHistoryWatcher,
  setOwnerInfoWatcher,
  setCarStatusWatcher,
  getCarStatusWatcher,
} from "./sagas-definitions/dealership";

function* appSagas() {
  yield all([
    updateCarSaleWatcher(),
    getCarGroupsWatcher(),
    getCarDetailsWatcher(),
    getCarListWatcher(),
    fetchTokenWatcher(),
    fetchRefreshTokenWatcher(),
    activateAccountWatcher(),
    confirmResetWatcher(),
    createRequestsWatcher(),
    getCarPriceFormWatcher(),
    fetchFieldsWatcher(),
    updateCarPriceFormWatcher(),
    getCarWatcher(),
    getCarVisualizationWatcher(),
    getCarFaultsWatcher(),
    requestConnectionWatcher(),
    updatePageTimeStampWatcher(),
    getPageTimeStampsWatcher(),
    getUnseenNotificationsWatcher(),
    fetchFieldsOptionsWatcher(),
    // getAppointmentsWatcher(),
    // dealerConfirmationWatcher(),
    // getReportPurchaseAppointmentsWatcher(),
    reserveCarWatcher(),
    reservedCarPurchaseStatusWatcher(),
    uploadLicenseImagesWatcher(),
    // getApptIntervalWatcher(),
    getDealerCreditsWatcher(),
    // getDisabledAppointmentWatcher(),
    // postDisabledAppointmentsWatcher(),
    // getDeactivatedAppointmentWatcher(),
    sendOTPWatcher(),
    validateOTPWatcher(),
    getCarGroupsScoringWatcher(),
    fetchOptionsWatcher(),
    setOwnerInfoWatcher(),
    getCallsInfoWatcher(),
    moveToHistoryWatcher(),
    getMessagesInfoWatcher(),
    receivedOrHistoryWatcher(),
    generateSellerCarWatcher(),
    checkUniquePhoneWatcher(),
    setCarStatusWatcher(),
    getCarStatusWatcher(),
  ]);
}

export default appSagas;
