import {
  FETCH_FIELDS_REQUEST,
  UPDATE_ANSWERS,
  UPDATE_HIDDEN,
  UPDATE_POINTS,
  FETCH_FIELDS_OPTIONS_REQUEST,
  UPDATE_FOEMFIELDS,
  FETCH_OPTIONS,
} from "../constants";

export function getFields(payload) {
  return {
    type: FETCH_FIELDS_REQUEST,
    payload: payload,
  };
}
export function updateAnswers(payload) {
  return {
    type: UPDATE_ANSWERS,
    payload: payload,
  };
}

export function updateformFields(payload) {
  return {
    type: UPDATE_FOEMFIELDS,
    payload: payload,
  };
}
export function updateHidden(payload) {
  return {
    type: UPDATE_HIDDEN,
    payload: payload,
  };
}
export function updatePoints(payload) {
  return {
    type: UPDATE_POINTS,
    payload: payload,
  };
}

export function getOptionsFields(payload) {
  return {
    type: FETCH_FIELDS_OPTIONS_REQUEST,
    payload: payload,
  };
}

export function getOptions(payload) {
  return {
    type: FETCH_OPTIONS,
    payload: payload,
  };
}
