// imports the React Javascript Library
import React from "react";
//Card
import { useTranslation } from "react-i18next";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

import Grid from "@material-ui/core/Grid";

import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";

import Typography from "@material-ui/core/Typography";

// Search
import UploadimageIcon from "../icons/uploadimage.svg";
import RabishPainIcon from "../icons/rabishPain.svg";
//Tabs
import { withStyles } from "@material-ui/core/styles";

const imageGallery = [
  "https://raw.githubusercontent.com/dxyang/StyleTransfer/master/style_imgs/mosaic.jpg",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1280px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg",
  "https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dora-maar-picasso.jpg",
  "https://pbs.twimg.com/profile_images/925531519858257920/IyYLHp-u_400x400.jpg",
  "https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dog.jpg",
  "http://r.ddmcdn.com/s_f/o_1/cx_462/cy_245/cw_1349/ch_1349/w_720/APL/uploads/2015/06/caturday-shutterstock_149320799.jpg",
];

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,   /// check it later
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    "&:hover": {
      color: red[800],
    },
  },
  cardHeader: {
    textalign: "center",
    align: "center",
    backgroundColor: "white",
  },
  input: {
    display: "none",
  },
  title: {
    color: blue[800],
    fontWeight: "bold",
    fontFamily: "Montserrat",
    align: "center",
  },
  button: {
    color: blue[900],
    margin: 10,
  },
  secondaryButton: {
    color: "gray",
    margin: 10,
  },
  typography: {
    margin: theme.spacing.unit * 2,
    backgroundColor: "default",
  },
  cardContainer: {
    "&.cardContainer": {},
  },
  searchDivider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

const ImageUpload = (props) => {
  const { message, classes, state, setstate, status } = props;

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Card className={props.cardName}>
          {(!state.selectedFile && (
            <RenderInitialState
              message={message}
              state={state}
              setstate={setstate}
              classes={classes}
              state={state}
              status={status}
            />
          )) ||
            (state.selectedFile && (
              <RenderUploadedState
                message={message}
                state={state}
                setstate={setstate}
                classes={classes}
                state={state}
              />
            ))}
        </Card>
      </div>
    </React.Fragment>
  );
};

const RenderUploadedState = (props) => {
  const { classes, theme, getImg, oldState, message, setstate, state } = props;

  const imageResetHandler = (event) => {
    // console.log("Click!");
    setstate({
      mainState: "initial",
      selectedFile: null,
      imageUploaded: 0,
    });
  };

  return (
    <React.Fragment>
      <CardActionArea>
        <img
          width="333px"
          height="172px"
          className={classes.media}
          src={props.state.selectedFileImg}
        />
        <div
          onClick={(event) => {
            imageResetHandler(event);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            right: 0,
            bottom: 0,
            background: "#F0F1F2",
            padding: "5px 0px",
            width: "36px",
            borderRadius: "8px",
          }}
        >
          <img src={RabishPainIcon} />
        </div>
      </CardActionArea>
    </React.Fragment>
  );
};

const RenderInitialState = (props) => {
  const {
    classes,
    theme,
    getImg,
    oldState,
    message,
    value,
    state,
    setstate,
    status,
  } = props;
  const { t } = useTranslation();

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setstate({
        mainState: "uploaded",
        selectedFile: file,
        imageUploaded: 1,
        selectedFileImg: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
  };

  return (
    <React.Fragment>
      <label htmlFor={`contained-button-file-${status}`}>
        <CardContent
          className={classes.cardContainer}
          style={{
            border: "1px dashed #186985",
            padding: "0",
            position: "relative",
            cursor: "pointer",
          }}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              width: "333px",
              height: "172px",
              backgroundColor: "#F0F1F2",
            }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id={`contained-button-file-${status}`}
              multiple
              type="file"
              onChange={handleUploadClick}
            />
            <div style={{ display: "block" }}>
              <label
                htmlFor={`contained-button-file-${status}`}
                style={{
                  margin: "auto",
                  width: "64px",
                  display: "flex",
                  marginBottom: "16px",
                }}
              >
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  src={UploadimageIcon}
                />
              </label>
              <Typography
                style={{
                  color: "#186985",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {message || t("Upload Front Side Image")}
              </Typography>
            </div>
          </Grid>
        </CardContent>
      </label>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(ImageUpload);
