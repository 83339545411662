import { FormControl, Hidden, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const useStyle = makeStyles((theme) => ({
  formControlRoot: {
    borderRadius: "5px",
    height: "48px",
    margin: "0 0 20px",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      maxHeight: "40px",
      borderRadius: "5px",
    },
  },
  inputBaseRoot: {
    borderRadius: "5px",
    height: "48px",
    // boxShadow: "0px 4px 10px rgba(7, 48, 62, 0.1)",
    boxShadow: "none", //"0px 2.20309px 2.67182px rgb(130 148 154 / 40%)",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      maxHeight: "40px",
      backgroundColor: "white",
    },
    // ".MuiOutlinedInput-adornedStart": {

    // },
  },
  inputLableOutlined: {
    transform: "translate(20px, 14px) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(20px,10px) scale(1)`,
    },
  },
  error: {
    border: "1px solid red",
    margin: "0",
  },
  errorText: {
    fontSize: "0.75rem",
  },
  fullwidth: {
    width: "100%",
  },
  flexEnd: {
    display: "flex",
    justifyContent: theme.direction == "ltr" ? "flex-start" : "flex-end",
  },
  inputSpacing: {
    margin: "14px 0", /////
    height: "46px", /////
    borderRadius: "8.67px",
    boxShadow: "0px 2.20309px 2.67182px rgba(130, 148, 154, 0.4)",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      margin: "10px 0",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  inputBaseRoot: {
    borderRadius: "5px",
    height: "48px", /////
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      height: "37px",
    },
  },
}));

function MarkabtePhone(props) {
  const classes = useStyle();
  const { value, errors, disabled, label } = props;

  return (
    <div
      style={{
        margin: "0 0 20px",
        width: "100%",
      }}
    >
      <FormControl
        fullWidth
        style={{
          display: "contents",
          direction: "ltr",
        }}
        // .MuiOutlinedInput-notchedOutline
        classes={{ root: classes.formControlRoot }}
      >
        <Hidden smDown>
          <PhoneInput
            label={label ?? ""}
            disabled={disabled ?? false}
            id={props?.id ? props.id : "webLoginField"}
            className={errors && classes.error}
            smartCaret={false}
            required
            international
            addInternationalOption={false}
            countries={["JO"]}
            country="JO"
            defaultCountry="JO"
            value={value}
            onChange={(e) => props?.onChange(e)}
            handleBlur={(e) => props?.handleBlur && props?.handleBlur()}
            fullWidth
            numberInputProps={{ maxlength: 16 }}
          />
          {errors && (
            <div className={`${classes.fullwidth} ${classes.flexEnd}`}>
              <Typography color="secondary" className={classes.errorText}>
                {errors}
              </Typography>
            </div>
          )}
        </Hidden>
        <Hidden mdUp>
          <PhoneInput
            className={errors && classes.error}
            smartCaret={false}
            required
            international
            addInternationalOption={false}
            countries={["JO"]}
            country="JO"
            defaultCountry="JO"
            value={value}
            onChange={(e) => props?.onChange(e)}
            handleBlur={(e) => props?.handleBlur && props?.handleBlur()}
            fullWidth
          />
          {errors && (
            <div className={`${classes.fullwidth} ${classes.flexEnd}`}>
              <Typography color="secondary" className={classes.errorText}>
                {errors}
              </Typography>
            </div>
          )}
        </Hidden>
      </FormControl>
    </div>
  );
}

export default MarkabtePhone;
