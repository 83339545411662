import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        "Car Listings": "Car Listings",
        Earnings: "Earnings",
        "Listing Request": "Listing Request",
        Language: "Language (AR)",
        "Sign out": "Sign out",
        Logout: "Logout",
        Dispatcher: "Dispatcher",
        Officer: "Officer",
        "Car Details": "Car Details",
        "Edit Car": "Edit Car",
        "Add New Car": "Add New Car",
        "Edit Car Information": "Edit Car Information",
        "Submit to qc": "Submit to qc",
        "Submit Form": "Submit Form",
        "Submit & Exit": "Submit & Exit",
        Submit: "Submit",
        "Sort By": "Sort By",
        Sort: "Sort",
        "Date (Oldest First)": "Date (Oldest First)",
        "Date (Newest First)": "Date (Newest First)",
        "Price (High to Low)": "Price (High to Low)",
        "Price (Low to High)": "Price (Low to High)",
        "Score (High to Low)": "Score (High to Low)",
        "Score (Low to High)": "Score (Low to High)",
        "Odometer (Low to High)": "Odometer (Low to High)",
        "Odometer (High to Low)": "Odometer (High to Low)",
        "Year (Oldest First)": "Year (Oldest First)",
        "Year (Newest First)": "Year (Newest First)",
        Date: "Date",
        Price: "Price",
        Score: "Score",
        Milage: "Milage",
        Year: "Year",
        "(Oldest First)": "(Oldest First)",
        "(Newest First)": "(Newest First)",
        "(Low to High)": "(Low to High)",
        "(High to Low)": "(High to Low)",
        "Condition Score": "Condition Score",
        Results: "Results",
        Odometer: "Odometer",
        Location: "Location",
        "Oldest Listings First": "Oldest Listings First",
        "Newest Listings First": "Newest Listings First",
        "Highest Price First": "Highest Price First",
        "Lowest Price First": "Lowest Price First",
        "Highest Score First": "Highest Score First",
        "Lowest Score First": "Lowest Score First",
        "Number of listings": "Number of listings",
        "Vehicle Details": "Vehicle Details",
        "Vehicle Features": "Vehicle Features",
        "No Features available for this car!":
          "No Features available for this car!",
        "Markabte Inspection Score": "Markabte Inspection Score",
        "View Full Report": "View Full Report",
        Listing: "Listing",
        "out of": "out of",
        "Inspection Report": "Inspection Report",
        "Markabte Inspection Details": "Markabte Inspection Details:",
        "Inspection Date": "Inspection Date :",
        "Inspected By": "Inspected By :",
        "Overall Inspection Score": "Overall Inspection Score :",
        "Detailed Inspection Points": "Detailed Inspection Points :",
        "Show inspection points with faults only":
          "Show inspection points with faults only",
        "Markabte Officer Comments": "Markabte Officer Comments :",
        "No comments": "No comments!",
        "Back To Vehicle Detail Page": "Back To Vehicle Detail Page",
        "Incoming Requests": "Incoming Requests",
        "Claimed Requests": "Claimed Requests",
        submitted: "submitted",
        "hour ago": "hour ago",
        Claim: "Claim",
        continue: "Continue",
        GPS: "GPS",
        "List Vehicle": "List Vehicle",
        Listed: "Listed",
        "days ago": "days ago",
        Mile: "Mile",
        Km: "Km",
        JD: "JD",
        "english JD": "JD ",
        "arabic JD": "",
        "/ Month .": "/ Month .",
        " yrs . JD": " yrs . JD",
        "K down": "K down",
        "View Vehicle": "View Vehicle",
        publish: "publish",
        "View Exterior": "View Exterior",
        "View Interior": "View Interior",
        "Driver Front": "Driver Front",
        "Passenger Rear": "Passenger Rear",
        Front: "Front",
        Back: "Back",
        "dealer staff": "dealer staff",
        "dealer owner": "dealer owner",
        "Edit Price": "Edit Price",
        "Report Sale": "Report Sale",
        "Please select the type of user this vehicle was sold to!":
          "Please select the type of user this vehicle was sold to!",
        "Independent Customer": "Independent Customer",
        "Markabte User": "Markabte User",
        "Edit car price": "Edit car price",
        "Request Listing Entry": "Request Listing Entry",
        "Request Officers for Vehicle Listing":
          "Request Officers for Vehicle Listing",
        "Please specify the number of vehicles that need to be listed by our officers":
          "Please specify the number of vehicles that need to be listed by our officers!",
        "Number Of Vehicles": "Number Of Vehicles",
        Confirm: "Confirm",
        Close: "Close",
        "Sign In": "Sign In",
        Email: "Email",
        Password: "Password",
        "Hurray, No issues were found": "Hurray, No issues were found!",
        Exterior: "Exterior",
        Interior: "Interior",
        EXTERIOR: "EXTERIOR",
        INTERIOR: "INTERIOR",
        "Drive Front View": "Drive Front View",
        "Passenger Rear View": "Passenger Rear View",
        "3rd Row View": "3rd Row View",
        "Trunk View": "Trunk View",
        "Rear View": "Rear View",
        "Front View": "Front View",
        Major: "Major",
        Minor: "Minor",
        "Vehicle Details Page": "Vehicle Details Page",
        "Vehicle Information": "Vehicle Information",
        "Vehicle Condition": "Vehicle Condition",
        "Show full report": "Show full report",
        "Inspection Points": "Inspection Points",
        "Based on our FREE ": "Based on our FREE ",
        "+ point condition report": "+ point condition report",
        Incoming: "Incoming",
        Claimed: "Claimed",
        "List Car": "List Car",
        "Edit Content": "Edit Content",
        "Publish Vehicle": "Publish Vehicle",
        "Publish Vehicle?": "Publish Vehicle?",
        "Are you sure that you want to publish the vehicle listing?":
          "Are you sure that you want to publish the vehicle listing?",
        Cancel: "Cancel",
        of: "of",
        "Front Angle": "Front Angle",
        "Rear Angle": "Rear Angle",
        Driver: "Driver",
        "Driver View": "Driver View",
        Passenger: "Passenger",
        "Passenger View": "Passenger View",
        "3rd row": "3rd Row",
        Trunk: "Trunk",
        "Angle Issues List": "Angle Issues List",
        "View List": "View List",
        "forgot password": "Forgot Password?",
        "Send Reset Link": "Send Reset Link",
        "Minor Issues": "Minor Issues",
        "Major Issues": "Major Issues",
        "Please enter your email and we will send you a reset link.":
          "Please enter your email and we will send you a reset link.",
        "Forgot Your Password?": "Forgot Your Password?",
        "Forgot your password?": "Forgot your password?",
        Connect: "Connect",
        "with serious car buyers.": "with serious car buyers.",
        "Back to Sign In": "Back to Sign In",
        "Please enter your new password.": "Please enter your new password.",
        "New Password": "New Password",
        "Confirm Password": "Confirm Password",
        "Reset Your Password": "Reset Your Password",
        "Reset Password": "Reset Password",
        "Sign In to Your Account": "Sign In to Your Account",
        "Keep me signed in": "Keep me signed in",
        "What do you want to do next?": "What do you want to do next?",
        "Vehicle Condition Report": "Vehicle Condition Report",
        "Markabte Condition Report": "Markabte Condition Report",
        "Markabte Officer": "Markabte Officer",
        "Inspection On": "Inspection On",
        "Report Summary": "Report Summary",
        "Vehicle Visual Condition": "Vehicle Visual Condition",
        "Landscape Mode Is Recommended For The Best User Experience!":
          "Landscape Mode Is Recommended For The Best User Experience!",
        "View Visual Cosmetic Report": "View Visual Cosmetic Report",
        "Markabte Full Condition Report": "Markabte Full Condition Report",
        Disclaimer: "Disclaimer",
        "Report Car Sale": "Report Car Sale",
        "You are successfully connected to live changes":
          "You are successfully connected to live changes",
        "Please make sure you entered valid credentials":
          "Please make sure you entered valid credentials",
        "Your passwords do not match!": "Your passwords do not match!",
        "account activated! please login with your new password":
          "account activated! please login with your new password",
        error: "error",
        "Your password reset reqest has been sent to your email.":
          "Your password reset reqest has been sent to your email.",
        "Please make sure you entered a valid email address":
          "Please make sure you entered a valid email address",
        "Car sale status successfully updated":
          "Car sale status successfully updated",
        Status: "Status",
        Attended: "Attended",
        Unattended: "Unattended",
        Cancelled: "Cancelled",
        "Oops! an error has occured! please try again.":
          "Oops! an error has occured! please try again.",
        "Your changes have been saved": "Your changes have been saved",
        "There was an error sending your request please try again":
          "There was an error sending your request please try again",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior":
          "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior",
        "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.":
          "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.",
        "We measure temperature of cooling/heating systems & check air throw for all AC vents.":
          "We measure temperature of cooling/heating systems & check air throw for all AC vents.",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior":
          "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior",
        "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not":
          "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not",
        "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle":
          "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle",
        "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date":
          "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date",
        "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)":
          "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)",
        "We check the availability of standard equipment in the vehicle":
          "We check the availability of standard equipment in the vehicle",
        "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.":
          "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.",
        Excellent: "Excellent",
        "Minor Issue": "Minor Issue",
        "Major Issue": "Major Issue",
        "Origional Paint": "Origional Paint",
        Repainted: "Repainted",
        Working: "Working",
        "Not Working": "Not Working",
        "Additional Information": "Additional Information",
        "No codes": "No codes",
        "Minor issues": "Minor issues",
        "Major issues": "Major issues",
        Good: "Good",
        Monitor: "Monitor",
        Replace: "Replace",
        Available: "Available",
        "Not Available": "Not Available",
        "Welcome Back!": "Welcome Back!",
        firstArabicLoginText: "",
        secondArabicLoginText: "",
        DEDICATED: "DEDICATED",
        "officers at your fingertips,": "officers at your fingertips,",
        QUALIFIED: "QUALIFIED",
        "buyers to your dealership.": "buyers to your dealership.",
        "There where no Major issues spotted in this view!":
          "There where no Major issues spotted in this view!",
        "There where no Minor issues spotted in this view!":
          "There where no Minor issues spotted in this view!",
        "The maximum number of vehicles is 50":
          "The maximum number of vehicles is 50",
        "You Don’t Have Any Appointments to Confirm.":
          "You Don’t Have Any Appointments to Confirm.",
        "You Don’t Have Any Incoming Appointments.":
          "You Don’t Have Any Incoming Appointments.",
        "Earlier Today": "Earlier Today",
        Appointments: "Appointments",
        Incoming: "Incoming",
        Cancelled: "Cancelled",
        Time: "Time",
        "Markabte User": "Markabte User",
        "Car Title": "Car Title",
        "Car VIN": "Car VIN",
        "Time Left": "Time Left",
        "After Tomorrow": "After Tomorrow",
        "Go To Homepage": "Go To Homepage",
        "We couldn’t find the page you are looking for.":
          "We couldn’t find the page you are looking for.",
        Deposit: "Deposit",
        "Transfer of Ownership": "Transfer of Ownership",
        "A buyer who was send to your dealership through Markabte.":
          "A buyer who was send to your dealership through Markabte.",
        "Markabte Buyer": "Markabte Buyer",
        "Independent Buyer": "Independent Buyer",
        "A buyer who has no relation with Markabte.":
          "A buyer who has no relation with Markabte.",
        Next: "Next",
        Submit: "Submit",
        "Select buyer type": "Select buyer type",
        "Report Purchase": "Report Purchase",
        "Select payment type": "Select payment type",
        "Select purchase state": "Select purchase state",
        "Car is sold": "Car is sold",
        "Car ownership has been transfered to the new car owner.":
          "Car ownership has been transfered to the new car owner.",
        "Deal is cancelled": "Deal is cancelled",
        "The buyer is no longer interested in buying the car.":
          "The buyer is no longer interested in buying the car.",
        Filter: "Filter",
        "Type buyer’s name": "Type buyer’s name",
        "Please specify which buyer wants to buy the car below":
          "Please specify which buyer wants to buy the car below",
        "Filter on time": "Filter on time",
        Show: "Show",
        "Car State Changed Successfully": "Car State Changed Successfully",
        "Car is already reserved": "Car is already reserved",
        "Upload both sides of the car license below":
          "Upload both sides of the car license below",
        "Upload Front Side Image": "Upload Front Side Image",
        "Upload Back Side Image": "Upload Back Side Image",
        "Upload Car License Images": "Upload Car License Images",
        "No options": "No options",
        "What’s Next?": "What’s Next?",
        "Publish the vehicle if it’s ready!":
          "Publish the vehicle if it’s ready!",
        "All Results": "All Results",
        "Inspection Report Summary": "Inspection Report Summary",
        "Markabte Score": "Markabte Score",
        "Inspected On": "Inspected On",
        Jan: "Jan",
        Feb: "Feb",
        Mar: "Mar",
        Apr: "Apr",
        May: "May",
        Jun: "Jun",
        Jul: "July",
        Aug: "Aug",
        Sep: "Sep",
        Oct: "Oct",
        Nov: "Nov",
        Dec: "Dec",
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
        "(3rd Party)": "(3rd Party)",
        "(Markabte)": "(Markabte)",
        faults: "faults",
        Faults: "Faults",
        "Show more": "Show more",
        "Show less": "Show less",
        "Visual Inspection Report": "Visual Inspection Report",
        "Tap to view vehicle cosmetic issues.":
          "Tap to view vehicle cosmetic issues.",
        Note: "Note",
        "Full Inspection Report": "Full Inspection Report",
        "View Faults Only": "View Faults Only",
        "Click on the colored markers to see issue details.":
          "Click on the colored markers to see issue details.",
        "No Faults": "No Faults",
        System: "System",
        Description: "Description",
        "Search for more information": "Search for more information",
        "Good/ Good+Issues": "Good/ Good+Issues",
        Issues: "Issues",
        good: "good",
        "No Issues": "No Issues",
        "Report Image": "Report Image",
        Good: "Good",
        "Exterior Issues": "Exterior Issues",
        "Interior Issues": "Interior Issues",
        "Driver Angle": "Driver Angle",
        "Passenger Angle": "Passenger Angle",
        "This Angle has no Minor issue": "This Angle has no Minor issue",
        "This Angle has no Major issue": "This Angle has no Major issue",
        "You Don’t Have Cars in Your Account.":
          "You Don’t Have Cars in Your Account.",
        "Request officers to list cars under your account.":
          "Request officers to list cars under your account.",
        ///////////////////////////
        "Coming soon": "Coming soon",
        "We are currently working on it!": "We are currently working on it!",
        "Oops!": "Oops!",
        "Something went wrong, please try again later.":
          "Something went wrong, please try again later.",
        "Update Car Status!": "Update Car Status!",
        "Update your inventory and remove the car!":
          "Update your inventory and remove the car!",
        days: "days",
        hours: "hours",
        minutes: "minutes",
        seconds: "seconds",
        ago: "ago",
        zarqa: "zarqa",
        Zarqa: "Zarqa",
        amman: "amman",
        Amman: "Amman",
        Poor: "Poor",
        "View Image": "View Image",
        Moderate: "Moderate",
        "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.":
          "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.",
        "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new":
          "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new",
        "Faults | ": "Faults | ",
        "Save Time": "Save Time",
        "Select when the car is not available below":
          "Select when the car is not available below",
        "Edit Time": "Edit Time",
        Today: "Today",
        Tomorrow: "Tomorrow",
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Selected: "Selected",
        "Edit Price": "Edit Price",
        "Remove temporary": "Remove temporary",
        More: "More",
        "take action": "take action",
        "Phone Number": "Phone Number",
        Previous: "Previous",
        "Click to view issues": "Click to view issues",
        Next: "Next",
        "Visual Report": "Visual Report",
        "* We do not record any issue in the body that is less than 0.2 (cm).":
          "* We do not record any issue in the body that is less than 0.2 (cm).",
        "* In some cases, Re-painted panels cannot be detected.":
          "* In some cases, Re-painted panels cannot be detected.",
        "* We do not record any scratch that cannot be felt by hand.":
          "* We do not record any scratch that cannot be felt by hand.",
        "* We do not inspect whether the battery was replaced or repaired.":
          "* We do not inspect whether the battery was replaced or repaired.",
        "* We do not check if the battery was replaced or repaired.":
          "* We do not check if the battery was replaced or repaired.",
        "* We do not inspect the inside of the tire or the internal sidewall of the tire.":
          "* We do not inspect the inside of the tire or the internal sidewall of the tire.",
        "* We do not confirm the genuineness of the equipment.":
          "* We do not confirm the genuineness of the equipment.",
        "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).":
          "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).",
        "* We do not validate the results of an inspection report from any third party.":
          "* We do not validate the results of an inspection report from any third party.",
        /////////////////////////////
        "We test the battery under load by evaluating cell resistance & voltage values.":
          "We test the battery under load by evaluating cell resistance & voltage values.",
        "No Notes": "No Notes",
        Notes: "Notes",
        "Replace Battery": "Replace Battery",
        "Not Inspected due to influence of resistance values":
          "Not Inspected due to influence of resistance values",
        Charge: "Charge",
        "Send Request": "Send Request",
        "Account Balance": "Account Balance",
        Credits: "Credits",
        "Re-painted": "Re-painted",
        "Original Paint": "Original Paint",
        "Not measured": "Not measured",
        "Car Views": "Car Views",
        "Click on the views to check the car's condition from a specific view.":
          "Click on the views to check the car's condition from a specific view.",
        "You can view all the issues of the selected car view here.":
          "You can view all the issues of the selected car view here.",
        "issues list": "issues list",
        "Click on the hotspots to see an up-close image of the damage.":
          "Click on the hotspots to see an up-close image of the damage.",
        "Damage Areas": "Damage Areas",
        Next: "Next",
        "Click here for help": "Click here for help",
        Exit: "Exit",
        "Click here to view the issue’s list of a certain view.":
          "Click here to view the issue’s list of a certain view.",
        "The increase in car score after repair of inspection notes.":
          "The increase in car score after repair of inspection notes.",
        "Car Score": "Car Score",
        "Inspection Notes": "Inspection Notes",
        "Excellent Condition": "Excellent Condition",
        Point: "Point",
        "Maintenance Report Summary": "Maintenance Report Summary",
        "View Insepction Notes": "View Insepction Notes",
        "Inspection notes are available for 24hr from date of inspection.":
          "Inspection notes are available for 24hr from date of inspection.",
        "Inspection notes are available for":
          "Inspection notes are available for",
        "24hr from date of inspection.": "24hr from date of inspection.",
        "Car Inspection Notes": "Car Inspection Notes",
        "Faults Details": "Faults Details",
        "Inspection Unavailable, diagnostic tools could not connect to car's computer":
          "Inspection Unavailable, diagnostic tools could not connect to car's computer",
        "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results":
          "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results",
        "Voltage difference inconclusive, due to the effect of Abnormal resistance values":
          "Voltage difference inconclusive, due to the effect of Abnormal resistance values",
        Car: "Car",
        Make: "Make",
        Model: "Model",
        "Listing Request Details": "Listing Request Details",
        "Seller Information": "Seller Information",
        "Full Name": "Full Name",
        "Car Information": "Car Information",
        "Location Information": "Location Information",
        City: "City",
        Area: "Area",
        "GPS Location": "GPS Location",
        "Inspection Time Information": "Inspection Time Information",
        Day: "Day",
        "Time Slot": "Time Slot",
        Aqaba: "Aqaba",
        Jerash: "Jerash",
        Irbid: "Irbid",
        Madaba: "Madaba",
        Kerak: "Kerak",
        Mafraq: "Mafraq",
        Ajloun: "Ajloun",
        "As-Salt": "As-Salt",
        "Ma'an": "Ma'an",
        "At-Tafilah": "At-Tafilah",
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        PM: "PM",
        AM: "AM",
        "Submit Listing": "Submit Listing",
        " yr. ": " yr. ",
        " mos": " mos",
        "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity":
          "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity",
        "* We do not take images of light scratches on the car's Exterior":
          "* We do not take images of light scratches on the car's Exterior",
        "Owner Information": "Owner Information",
        "Appointment Availability": "Appointment Availability",
        "Car Related Information": "Car Related Information",
        "Additional Info": "Additional Info",
        "Available for Test Drive": "Available for Test Drive",
        "Price Range": "Price Range",
        "First Owner": "First Owner",
        "Edit Information": "Edit Information",
        "Owner Info": "Owner Info",
        "Save Changes": "Save Changes",
        "data saved successfuly": "data saved successfuly",
        "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.":
          "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.",
        "* Markabte safety system report is based on advanced computer diagnostics only, and does not include opening interior panels to check for airbag tampering.":
          "* Markabte safety system report is based on advanced computer diagnostics only, and does not include opening interior panels to check for airbag tampering.",
        "Call Back": "Call Back",
        "Call Back Requests": "Call Back Requests",
        "Requests History": "Requests History",
        Requests: "Requests",
        History: "History",
        "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.":
          "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.",
        "Call Owner": "Call Owner",
        "Text Owner": "Text Owner",
        "Maintenance Report": "Maintenance Report",
        "Looking to buy a car?": "Looking to buy a car?",
        "Sign Up": "Sign Up",
        "Send Inspection Report": "Send Inspection Report",
        "Send Maintenance Report": "Send Maintenance Report",
        "Send Inspection Report Link": "Send Inspection Report Link",
        "Send Link": "Send Link",
        "Please enter car owner's phone": "Please enter car owner's phone",
        "This phone number is not a registered user.":
          "This phone number is not a registered user.",
        "Inspected Point": "Inspected Point",
        Received: "Received",
        "Not received": "Not received",
        "Total Car Stats": "Total Car Stats",
        "Search views": "Search views",
        "Detailed views": "Detailed views",
        Calls: "Calls",
        Messages: "Messages",
        "Whatsapp Messages": "Whatsapp Messages",
      },
    },
    ar: {
      translations: {
        "Car Listings": "قائمة المركبات",
        Earnings: "الأرباح",
        "Listing Request": "طلبات الإدراج",
        Language: "اللغة (EN)",
        "Sign out": "تسجيل الخروج",
        Logout: "تسجيل الخروج",
        Dispatcher: "المرسل",
        Officer: "موظف",
        Next: "التالي",
        "Visual Report": "الحالة المرئية",
        Previous: "السابق",
        "Click to view issues": "أنقر لمعاينة الملاحظات",
        "Phone Number": "رقم الهاتف",
        "Car Details": "تفاصيل السيارة",
        "Add New Car": "إضافة سيارة جديدة",
        "Edit Car Information": "تحرير معلومات السيارة",
        "Submit to qc": " إرسال لمراقبة الجودة ",
        "Submit Form": "تقديم النموذج",
        "Submit & Exit": "إرسال وخروج",
        Submit: "تعديل",
        "Sort By": "صنف حسب",
        Sort: "صنف",
        "Date (Oldest First)": "التاريخ (الأقدم أولاً)",
        "Date (Newest First)": "التاريخ (الأحدث أولاً)",
        "Price (High to Low)": "السعر (من الاعلى الى الأقل)",
        "Price (Low to High)": "السعر (من الأقل الى الاعلى)",
        "Score (High to Low)": "النتيجة (من الأعلى إلى الأقل)",
        "Score (Low to High)": "النتيجة (من الأقل إلى الأعلى)",
        "Odometer (Low to High)": "عداد المسافة (من الأقل إلى الأعلى)",
        "Odometer (High to Low)": "عداد المسافة (من الأعلى إلى الأقل)",
        "Year (Oldest First)": "السنة (الأقدم أولاً)",
        "Year (Newest First)": "السنة (الأحدث أولاً)",
        Date: "التاريخ",
        Price: "السعر",
        Score: "النتيجة",
        Milage: "الأميال",
        Year: "السنة",
        "(Oldest First)": "(الأقدم أولاً)",
        "(Newest First)": "(الأحدث أولاً)",
        "(Low to High)": "(من الأعلى إلى الأقل)",
        "(High to Low)": "(من الأعلى إلى الأقل)",
        "Condition Score": "نتيجة الحالة",
        Results: "نتيجة",
        Odometer: "عداد المسافة",
        Location: "الموقع",
        "Oldest Listings First": "القوائم الأقدم أولاً",
        "Newest Listings First": "أحدث القوائم أولاً",
        "Highest Price First": "أعلى سعر أولاً",
        "Lowest Price First": "أقل سعر أولاً",
        "Highest Score First": "أعلى نتيجة أولاً",
        "Lowest Score First": "أقل نتيجة أولاً",
        "Number of listings": "عدد القوائم",
        "Vehicle Details": "تفاصيل السيارة",
        "Vehicle Features": "ميزات السيارة",
        "No Features available for this car!":
          "لا توجد ميزات متاحة لهذه السيارة!",
        "Markabte Inspection Score": "تقييم مركبتي",
        "View Full Report": "عرض التقرير الكامل",
        "Edit Car": "تعديل السيارة",
        Listing: "قائمة",
        "out of": "من",
        "Inspection Report": "تقرير الفحص",
        "Markabte Inspection Details": "تفاصيل فحص مركبتي",
        "Inspection Date": "تاريخ الفحص :",
        "Inspected By": "تم الفحص بواسطة :",
        "Overall Inspection Score": "نتيجة الفحص العامة",
        "Detailed Inspection Points": "نقاط الفحص التفصيلية",
        "Show inspection points with faults only":
          "إظهار نقاط الفحص ذات العيوب فقط",
        "Markabte Officer Comments": "ملاحظات موظف مركبتي",
        "No comments": "لا يوجد ملاحظات",
        "Back To Vehicle Detail Page": "العودة الى صفحة تفاصيل السيارة",
        "Incoming Requests": "قائمة الطلبات الواردة  ",
        "Claimed Requests": "قائمة طلباتي",
        submitted: " قدمت : منذ",
        "hour ago": "ساعة مضت",
        Claim: "حجز الطلب",
        continue: " ... متابعة الادخال",
        GPS: "الموقع",
        "List Vehicle": "بدء الادخال",
        Listed: "ادرجت : منذ",
        "days ago": "ايام",
        Km: "كم",
        Mile: "ميل",
        JD: "د.ا",
        "english JD": "",
        "arabic JD": " د.ا",
        "/ Month .": "/ شهر .",
        " yrs . JD": "سنوات . ",
        "K down": "ألف دينار دفعة أولى",
        "View Vehicle": "عرض السيارة",
        publish: "نشر",
        "View Exterior": "مظهر خارجى",
        "View Interior": "مظهر داخلي",
        "Driver Front": "من الامام",
        "Passenger Rear": "من الخلف",
        Front: "منظور السائق",
        Back: "منظور الراكب",
        Trunk: "الصندوق",
        "Edit Price": "تعديل السعر",
        "Remove temporary": "إزاﻟﺔ ﻣﺆﻗﺘﺔ",
        More: "المزيد",
        "take action": "ﻗﻢ ﺑﺎﻟﺈﺧﺘﻴﺎر",
        "Report Sale": "الإبلاغ عن بيع المركبة",
        "Please select the type of user this vehicle was sold to":
          "الرجاء تحديد نوع المستخدم الذي تم بيع هذه السيارة له!",
        "Markabte User": "مستخدم مركبتي",
        "Independent Customer": "مشتري مستقل",
        "Edit car price": "تعديل سعر السيارة",
        "Request Listing Entry": "طلب ادخال سيارة",
        "Request Officers for Vehicle Listing": "طلب موظف ادخال المركبات",
        "Please specify the number of vehicles that need to be listed by our officers":
          "الرجاء تحديد عدد المركبات التي يجب أن يتم ادخالها من قبل مندوبينا!",
        "Number Of Vehicles": "ادخل عدد السيارات",
        Confirm: "تأكيد",
        Close: "إغلاق",
        "Sign In": "تسجيل الدخول",
        Email: "البريد الإلكتروني",
        Password: "كلمه السر",
        "Hurray, No issues were found": "مرحى ، لم يتم العثور على مشكلات!",
        Exterior: "خارجي",
        Interior: "داخلي",
        EXTERIOR: "خارجي",
        INTERIOR: "داخلي",
        "Drive Front View": "منظر أمامي للسائق",
        "Passenger Rear View": "الرؤية الخلفية للراكب",
        "3rd Row View": "عرض الصف الثالث",
        "Rear View": "رؤية خلفية",
        "Front View": "منظر أمامي",
        Minor: "ثانوي",
        Major: "رئيسي",
        "Vehicle Details Page": "صفحة تفاصيل السيارة",
        "Vehicle Information": "معلومات السيارة",
        "Vehicle Condition": "حالة المركبة",
        "Show full report": "إظهار التقرير الكامل",
        "Inspection Points": "نقطة تفتيش",
        "Based on our FREE ": "بناءا على",
        "+ point condition report": "+ نقطة في فحصنا المجاني لحالة المركبة",
        Incoming: "الوارد  ",
        Claimed: "طلباتي",
        "List Car": "بدء الادخال",
        "Edit Content": "تعديل المحتوى",
        "Publish Vehicle": "نشر المركبة",
        "Publish Vehicle?": "نشر المركبة؟",
        "Are you sure that you want to publish the vehicle listing?":
          "هل أنت متأكد أنك تريد نشر المركبة؟",
        Cancel: "إلغاء",
        of: "من",
        "Front Angle": "الزاوية الأمامية",
        "Rear Angle": "الزاوية الخلفية",
        Driver: "السائق",
        "Driver View": "السائق",
        Passenger: "الراكب",
        "Passenger View": "الراكب",
        "3rd Row": "الصف الثالت",
        "Trunk View": "الصندوق",
        "Angle Issues List": "قائمة مشاكل الزاوية",
        "View List": "عرض القائمة",
        "forgot password": "هل نسيت كلمة المرور؟",
        "Send Reset Link": "إرسال رابط إعادة التعيين",
        "Minor Issues": "مشاكل صغيرة",
        "Major Issues": "مشاكل كبيرة",
        "Please enter your email and we will send you a reset link.":
          "أدخل بريدك الإلكتروني وسنرسل لك رابط إعادة تعيين.",
        "Forgot Your Password?": "هل نسيت كلمة المرور؟",
        "Forgot your password?": "هل نسيت كلمة المرور؟",
        Connect: "تواصل",
        "with serious car buyers.": "مع مشتري السيارات الجادين.",
        "Back to Sign In": "تسجيل الدخول",
        "Please enter your new password.":
          "الرجاء إدخال كلمة المرور الجديدة الخاصة بك.",
        "New Password": "كلمة المرور الجديدة",
        "Confirm Password": "تأكيد كلمة المرور",
        "Reset Your Password": "إعادة تعيين كلمة المرور",
        "Reset Password": "إعادة تعيين كلمة المرور",
        "Sign In to Your Account": "تسجيل الدخول إلى حسابك",
        "Keep me signed in": "ابقني مسجل",
        "What do you want to do next?": "ماذا تريد ان تفعل لاحقا؟",
        "Vehicle Condition Report": "تقرير حالة المركبة",
        "Markabte Condition Report": "تقرير حالة مركبتي",
        "Markabte Officer": "موظف مركبتي",
        "Inspection On": "تاريخ التفتيش",
        "Report Summary": "ملخص التقرير",
        "Vehicle Visual Condition": "الحالة البصرية للمركبة",
        "Landscape Mode Is Recommended For The Best User Experience!":
          "يوصى باستخدام الوضع الأفقي للحصول على أفضل تجربة للمستخدم!",
        "View Visual Cosmetic Report": "معاينة التقرير المرئي",
        "Markabte Full Condition Report": "تقرير مركبتي الكامل للحالة",
        Disclaimer: "إخلاء مسؤولية",
        "Report Car Sale": "الإبلاغ عن بيع سيارة",
        "You are successfully connected to live changes":
          "تم الاتصال بنجاح بالتغييرات الحية",
        "Please make sure you entered valid credentials":
          "الرجاء التأكد من إدخال بيانات اعتماد صالحة",
        "Your passwords do not match!": "كلمات السر الخاصة بك لا تتطابق!",
        "account activated! please login with your new password":
          "تم تفعيل الحساب! الرجاء تسجيل الدخول باستخدام كلمة المرور الجديدة",
        error: "خطأ",
        "Your password reset reqest has been sent to your email.":
          "تم إرسال طلب إعادة تعيين كلمة المرور إلى بريدك الإلكتروني.",
        "Please make sure you entered a valid email address":
          "يرجى التأكد من إدخال عنوان بريد إلكتروني صالح",
        "Car sale status successfully updated":
          "تم تحديث حالة بيع السيارة بنجاح",
        Status: "حالة الحضور",
        Attended: "حضر",
        Unattended: "لم يحضر",
        Cancelled: "تم الإلغاء",
        "Oops! an error has occured! please try again.":
          "عفوًا! حدث خطأ! يرجى المحاولة مرة أخرى.",
        "Your changes have been saved": "تم حفظ التغييرات",
        "There was an error sending your request please try again":
          "حدث خطأ أثناء إرسال طلبك ، يرجى المحاولة مرة أخرى",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior":
          "نقوم بإجراء فحص نظري لتحديد نوع و موقع و شدة الأضرار الخاصة بالهيكل الخارجي للمركبة",
        "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.":
          "نقوم بقياس و تحليل قراءات سماكة الطلاء الخاصة بالمركبة لتحديد اللوحات المدهونة من اللوحات ذات الطلاء الأصلي",
        "We measure temperature of cooling/heating systems & check air throw for all AC vents.":
          "نقوم بقياس درجة حرارة نظام التكييف / التدفئة ونتحقق من تحكم الهواء لجميع فتحات التكييف.",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior":
          "نقوم بإجراء فحص نظري لتحديد نوع و موقع و شدة الأضرار الخاصة بالمقصورة الداخلية للمركبة",
        "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not":
          "نختبر وظائف أجزاء التحكم المتعددة بالمركبة (أزرار، مفاتيح، أنظمة) لتحديد ما يعمل و ما لا يعمل",
        "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle":
          "نقوم بإجراء فحص خاص بكمبيوتر المركبة لتشخيص وتحديد رموز الأعطال الخاصة بالأنظمة الميكانيكية والإلكترونية للمركبة",
        "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date":
          "تقرير فحص شاصي المركبة مقدم من جهة فحص أخرى٬ يرجى ملاحظة تاريخ الفحص",
        "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)":
          "نحدد الحالة العامة للإطار بناءً على عمق الفرزة المتبقي٬ العمر٬ ونتائج الفحص النظري للإطار (تشققات أو فقاعات)",
        "We check the availability of standard equipment in the vehicle":
          "نقوم بالتحقق من توافر المعدات الأساسية في المركبة",
        "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.":
          "تحتاج الإطارات إلى تبديل إذا كان العمر >5 سنوات٬ عمق الفرزة المتبقي <1.6 مم أو عند تواجد فقاعات أو تشققات.",
        Excellent: "ممتاز",
        "Minor Issue": "ملاحظة بسيطة",
        "Major Issue": "ملاحظة كبيرة",
        "Origional Paint": "الطلاء الأصلي",
        Repainted: "مدهون",
        Working: "يعمل",
        "Not Working": "لا يعمل",
        "Additional Information": "معلومات إضافية",
        "No codes": "لا أعطال",
        "Minor issues": "أعطال بسيطة",
        "Major issues": "أعطال كبيرة",
        Good: "جيد",
        Monitor: "مراقبة",
        Replace: "تبديل",
        Available: "متوفر",
        "Not Available": "غير متوفر",
        "Welcome Back!": "مرحبا بعودتك!",
        firstArabicLoginText: "مندوبين",
        secondArabicLoginText: "زبائن ",
        DEDICATED: " متأهبون",
        "officers at your fingertips,": "في خدمتك",
        QUALIFIED: "مؤهلين",
        "buyers to your dealership.": "في معرضك",
        "There where no Major issues spotted in this view!":
          "لا يوجد مشاكل كبيرة من هذا المنظور",
        "There where no Minor issues spotted in this view!":
          "لا يوجد مشاكل صغيرة من هذا المنظور",
        "dealer owner": "مالك المعرض",
        "dealer staff": "موظف مبيعات",
        "The maximum number of vehicles is 50":
          "الحد الأقصى لعدد المركبات هو 50",
        "You Don’t Have Any Appointments to Confirm.":
          "ﻟﺎ ﻳﻮﺟﺪ ﻟﺪﻳﻚ ﻣﻮاﻋﻴﺪ ﻟﻠﺘﺄﻛﻴﺪ.",
        "You Don’t Have Any Incoming Appointments.":
          "ﻟﺎ ﻳﻮﺟﺪ ﻟﺪﻳﻚ ﻣﻮاﻋﻴﺪ ﻗﺎدﻣﺔ.",
        "Earlier Today": "ﻣﺴﺒﻘﺎً اﻟﻴﻮم",
        Appointments: "المواعيد",
        Incoming: "ﻗﺎدﻣﺔ",
        Time: "الوقت",
        "Markabte User": "ﻣﺴﺘﺨﻢ ﻣﺮﻛﺒﺘﻲ",
        "Car Title": "ﻋﻨﻮان اﻟﺴﻴﺎرة",
        "Car VIN": "VIN السيارة",
        "Time Left": "اﻟﻮﻗﺖ اﻟﻤﺘﺒﻘﻲ",
        "After Tomorrow": "بعد غد",
        "Go To Homepage": "الانتقال إلى الصفحة الرئيسية",
        "We couldn’t find the page you are looking for.":
          "لم نتمكن من العثور على الصفحة التي تبحث عنها.",
        Deposit: "عربون",
        "Transfer of Ownership": "ﻧﻘﻞ اﻟﻤﻠﻜﻴﺔ",
        "A buyer who was send to your dealership through Markabte.":
          "ﻣﺸﺘﺮي ﺗﻢ ارﺳﺎﻟﻪ اﻟﻰ ﻣﻌﺮﺿﻚ ﻋﻦ ﻃﺮﻳﻖ ﻣﻨﺼﺔ ﻣﺮﻛﺒﺘﻲ",
        "Markabte Buyer": "ﻣﺸﺘﺮي ﻣﺮﻛﺒﺘﻲ",
        "Independent Buyer": "ﻣﺸﺘﺮي ﻣﺴﺘﻘﻞ",
        "A buyer who has no relation with Markabte.":
          "ﻣﺸﺘﺮي ﻟﺎ ﻳﻮﺟﺪ ﻟﻪ ﻋﻠﺎﻗﺔ ﺑﻤﻨﺼﺔ ﻣﺮﻛﺒﺘﻲ.",
        Next: "التالي",
        Submit: "إرسال",
        "Select buyer type": "ﻗﻢ ﺑﺘﺤﺪﻳﺪ اﻟﻤﺸﺘﺮي",
        "Report Purchase": "اﻟﺈﺑﻠﺎغ ﻋﻦ ﺑﻴﻊ اﻟﻤﺮﻛﺒﺔ",
        "Select payment type": "ﻗﻢ ﺑﺘﺤﺪﻳﺪ ﻋﻤﻠﻴﺔ اﻟﺪﻓﻊ",
        "Select purchase state": "ﻗﻢ ﺑﺘﺤﺪﻳﺪ ﺣﺎﻟﺔ اﻟﺒﻴﻊ",
        "Car is sold": "ﺗﻢ ﺑﻴﻊ اﻟﻤﺮﻛﺒﺔ",
        "Car ownership has been transfered to the new car owner.":
          "ﺗﻢ ﻧﻘﻞ ﻣﻠﻜﻴﺔ اﻟﺴﻴﺎرة إﻟﻰ ﻣﺎﻟﻚ اﻟﺴﻴﺎرة اﻟﺠﺪﻳﺪ.",
        "Deal is cancelled": "ﺗﻢ اﻟﻐﺎء اﻟﺼﻔﻘﺔ",
        "The buyer is no longer interested in buying the car.":
          "ﻟﻢ ﻳﻌﺪ اﻟﻤﺸﺘﺮي ﻣﻬﺘﻢ ﺑﺸﺮاء اﻟﻤﺮﻛﺒﺔ.",
        Filter: "فلتر",
        "Type buyer’s name": "ادﺧﻞ اﺳﻢ اﻟﻤﺸﺘﺮي",
        "Please specify which buyer wants to buy the car below":
          "ﻳﺮﺟﻰ ﺗﺤﺪﻳﺪ ﻣﺸﺘﺮي اﻟﻤﺮﻛﺒﺔ",
        "Filter on time": "ﻓﻠﺘﺮ ﺣﺴﺐ اﻟﻮﻗﺖ",
        Show: "إظهار",
        "Car State Changed Successfully": "ﺗﻢ ﺗﻐﻴﻴﺮ ﺣﺎﻟﺔ اﻟﻤﺮﻛﺒﺔ ﺑﻨﺠﺎح",
        "Car is already reserved": "السيارة محجوزة",
        "Upload both sides of the car license below":
          "ﻗﻢ ﺑﺘﺤﻤﻴﻞ ﻛﻠﺎ ﺟﺎﻧﺒﻲ رﺧﺼﺔ اﻟﺴﻴﺎرة أدﻧﺎه",
        "Upload Front Side Image": "ﺗﺤﻤﻴﻞ اﻟﺼﻮرة اﻟﺄﻣﺎﻣﻴﺔ",
        "Upload Back Side Image": "ﺗﺤﻤﻴﻞ اﻟﺼﻮرة اﻟﺨﻠﻔﻴﺔ",
        "Upload Car License Images": "ﺗﺤﻤﻴﻞ ﺻﻮر رﺧﺼﺔ اﻟﺴﻴﺎرة",
        "No options": "لا يوجد خيارات",
        "What’s Next?": "ما التالي؟",
        "Publish the vehicle if it’s ready!": "انشر المركبة إذا كانت جاهزة!",
        "All Results": "جميع النتائج",
        "Inspection Report Summary": "تقرير مركبتي لحالة السيارة",
        "Markabte Score": "نتيجة مركبتي",
        "Inspected On": "تاريخ الفحص",
        Jan: "يناير",
        Feb: "فبراير",
        Mar: "مارس",
        Apr: "أبريل",
        May: "مايو",
        Jun: "يونيو",
        Jul: "يوليو",
        Aug: "أغسطس",
        Sep: "سبتمبر",
        Oct: "أكتوبر",
        Nov: "نوفمبر",
        Dec: "ديسمبر",
        January: "يناير",
        February: "فبراير",
        March: "مارس",
        April: "أبريل",
        May: "مايو",
        June: "يونيو",
        July: "يوليو",
        August: "أغسطس",
        September: "سبتمبر",
        October: "أكتوبر",
        November: "نوفمبر",
        December: "ديسمبر",
        "(3rd Party)": "(جهة فحص أخرى)",
        "(Markabte)": "(مركبتي)",
        faults: "أعطال",
        Faults: "أعطال",
        "Show more": "أظهر المزيد",
        "Show less": "عرض أقل",
        "Visual Inspection Report": "تقرير الفحص المفصل",
        "Tap to view vehicle cosmetic issues.": ".انقر لعرض مشاكل المركبة",
        Note: "ملاحظة",
        "Full Inspection Report": "التقرير الكامل لحالة المركبة",
        "View Faults Only": "عرض المشاكل فقط",
        "Click on the colored markers to see issue details.":
          "اضغط على العلامات الملوّنة لرؤية تفاصيل الملاحظة",
        "No Faults": "لا أعطال",
        System: "النظام",
        Description: "الوصف",
        "Search for more information": "ابحث عن مزيد من المعلومات",
        "Good/ Good+Issues": "جيد/ جيد+ملاحظات",
        Issues: "ملاحظات",
        good: "جيد",
        "No Issues": "لا توجد ملاحظات",
        "Report Image": "صورة التقرير",
        Good: "جيد",

        "Exterior Issues": "المشاكل الخارجية",
        "Interior Issues": "المشاكل الداخلية",
        "Driver Angle": "زاوية السائق",
        "Passenger Angle": "زاوية الراكب",
        "This Angle has no Minor issue":
          "لا يوجد اي ملاحظات صغيرة في هذه الزاوية",
        "This Angle has no Major issue":
          "لا يوجد اي ملاحظات كبيرة في هذه الزاوية",
        "You Don’t Have Cars in Your Account.":
          "لا يوجد لديك أي مركبات في حسابك.",
        "Request officers to list cars under your account.":
          "قم بطلب إدخال مركبات إلى حسابك.",
        ///////////////////////////
        "Coming soon": "قريباً",
        "We are currently working on it!": "ﻧﻘﻮم ﺑﺎﻟﻌﻤﻞ ﻋﻠﻴﻪ ﻓﻲ اﻟﻮﻗﺖ اﻟﺤﺎﻟﻲ!",
        "Oops!": "عذراً!",
        "Something went wrong, please try again later.":
          "حدث خطأ، يرجى المحاولة فى وقت لاحق.",
        "Update Car Status!": "قم بتحديث حالة المركبة!",
        "Update your inventory and remove the car!":
          "قم بتحديث قائمة المركبات الخاصة بك وازل المركبة!",
        days: "أيام",
        hours: "ساعات",
        minutes: "دقائق",
        seconds: "ثواني",
        ago: "",
        zarqa: "الزرقاء",
        Zarqa: "الزرقاء",
        amman: "عمان",
        Amman: "عمان",
        Moderate: "متوسط",
        Poor: "ضعيف",
        "View Image": "رؤﻳﺔ اﻟﺼﻮرة",
        "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.":
          "ﻣﺆﺷﺮ ﻳﻌﻜﺲ ﺣﺎﻟﺔ اﻟﺒﻄﺎرﻳﺔ اﻟﻌﺎﻣﺔ و ﻗﺪرﺗﻬﺎ ﻋﻠﻰ ﺗﻘﺪﻳﻢ اﻟﺄداء اﻟﻤﻄﻠﻮب ﻣﻘﺎرﻧﺔ ﺑﺒﻄﺎرﻳﺔ ﺟﺪﻳﺪة.",
        "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new":
          "ﻳﻘﺪر اﻟﻌﻤﺮ اﻟﻤﺘﺒﻘﻲ ﻟﻠﺒﻄﺎرﻳﺔ ﻣﻦ ﺧﻠﺎل ﻣﻘﺎرﻧﺔ ﺳﻌﺘﻬﺎ اﻟﺤﺎﻟﻴﺔ ﺑﺴﻌﺘﻬﺎ اﻟﻤﺜﺎﻟﻴﺔ ﻋﻨﺪﻣﺎ ﺗﻜﻮن ﺟﺪﻳﺪة",
        "Faults | ": "أعطال | ",
        "Save Time": "ﺣﻔﻆ الوﻗﺖ",
        "Select when the car is not available below":
          "ﻗﻢ ﺑﺘﺤﺪﻳﺪ وﻗﺖ ﻋﺪم ﺗﻮﻓﺮ اﻟﻤﺮﻛﺒﺔ ادﻧﺎه",
        "Edit Time": "ﺗﻌﺪﻳﻞ اﻟﻮﻗﺖ",
        Today: "اليوم",
        Tomorrow: "غداً",
        Sunday: "الأحد",
        Monday: "الأثنين",
        Tuesday: "الثلاثاء",
        Wednesday: "الأربعاء",
        Thursday: "الخميس",
        Friday: "الجمعة",
        Saturday: "السبت",
        Selected: "ﻣﺨﺘﺎر",
        "* We do not record any issue in the body that is less than 0.2 (cm).":
          "* لا نسجل أي ضرر على هيكل المركبة لا يتجاوز 0.2 (سم) بالمساحة.",
        "* In some cases, Re-painted panels cannot be detected.":
          "* في بعض الحالات لا يمكن اكتشاف الأجزاء المدهونة بالمركبة.",
        "* We do not record any scratch that cannot be felt by hand.":
          "* لا نسجل أي خدش لا يمكن الإحساس به باليد.",
        "* We do not inspect whether the battery was replaced or repaired.":
          "* لا نتحقق من تبديل البطارية أو إصلاحها.",
        "* We do not check if the battery was replaced or repaired.":
          "* لا نتحقق من تبديل البطارية أو إصلاحها.",
        "* We do not inspect the inside of the tire or the internal sidewall of the tire.":
          "* لا نفحص الإطار من الداخل أو الجزء الداخلي من الحائط الجانبي للإطار.",
        "* We do not confirm the genuineness of the equipment.":
          "* لا نحدد إذا كانت المعدات اصلية.",
        "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).":
          "* لا نسجل رموز الأعطال التي تم حفظها في كمبيوتر المركبة و لكنها غير فعالة (تم إصلاحها).",
        "* We do not validate the results of an inspection report from any third party.":
          "* لا نتحقق من صحة نتائج أي فحص من جهة أخرى.",
        /////////////////////////////
        "We test the battery under load by evaluating cell resistance & voltage values.":
          "ﻧﻘﻮم ﺑﺎﺧﺘﺒﺎر اﻟﺒﻄﺎرﻳﺔ ﺗﺤﺖ اﻟﺘﺤﻤﻞ ﻣﻦ ﺧﻠﺎل ﺗﻘﻴﻴﻢ ﻗﻴﻢ اﻟﻤﻘﺎوﻣﺔ واﻟﻔﻮﻟﺘﻴﺔ ﻟﻠﺨﻠﺎﻳﺎ.",
        "No Notes": "ﻟﺎ ﻣﻠﺎﺣﻈﺎت",
        Notes: "ملاحظات",
        "Replace Battery": "ﺗﺒﺪﻳﻞ اﻟﺒﻄﺎرﻳﺔ",
        "Not Inspected due to influence of resistance values":
          "ﻟﻢ ﻳﺘﻢ اﻟﻔﺤﺺ ﺑﺴﺒﺐ ﺗﺄﺛﺮ ﻗﻴﻢ اﻟﻤﻘﺎوﻣﺔ",
        Charge: "إشحن",
        "Send Request": "إرسال طلب",
        "Account Balance": "رصيد الحساب",
        Credits: "طلبات إدخال",
        "Re-painted": "أعيد طلاؤها",
        "Original Paint": "الطلاء الأصلي",
        "Not measured": "لم يتم قياسها",
        "Car Views": "ﺟﻬﺎت اﻟﻤﺮﻛﺒﺔ",
        "Click on the views to check the car's condition from a specific view.":
          "اضغط على الأزرار  لتفقد حالة حالة المركبة من جهة معينة",
        "You can view all the issues of the selected car view here.":
          "اﺿﻐﻂ ﻫﻨﺎ ﻟﻌﺮض ﻗﺎﺋﻤﺔ اﻟﺄﺿﺮارﻟﻠﺠﻬﺔ اﻟﻤﺨﺘﺎرة.",
        "issues list": "ﻗﺎﺋﻤﺔ اﻟﺄﺿﺮار",
        "Click on the hotspots to see an up-close image of the damage.":
          "اﺿﻐﻂ ﻋﻠﻰ اﻟﻌﻠﺎﻣﺎت اﻟﻤﻠﻮﻧﺔ ﻟﺮؤﻳﺔ ﺻﻮرة اﻟﻀﺮرﻋﻦ ﻗﺮب.",
        "Damage Areas": "ﻣﻨﺎﻃﻖ اﻟﻀﺮر",
        Next: "التالي",
        "Click here for help": "اﺿﻐﻂ ﻫﻨﺎ ﻟﻠﻤﺴﺎﻋﺪة",
        Exit: "الخروج",
        "Click here to view the issue’s list of a certain view.":
          ".اﺿﻐﻂ ﻫﻨﺎ ﻟﻌﺮض ﻗﺎﺋﻤﺔ اﻟﺄﺿﺮار ﻟﻠﺠﻬﺔ اﻟﻤﺨﺘﺎرة",
        "The increase in car score after repair of inspection notes.":
          "اﻟﺰﻳﺎدة ﻋﻠﻰ ﻧﺘﻴﺠﺔ ﻓﺤﺺ اﻟﻤﺮﻛﺒﺔ ﺑﻌﺪ ﺻﻴﺎﻧﺔ ﻣﻠﺎﺣﻈﺎت اﻟﻔﺤﺺ.",
        "Car Score": "ﻧﺘﻴﺠﺔ اﻟﻤﺮﻛﺒﺔ",
        "Inspection Notes": "ﻣﻠﺎﺣﻈﺎت اﻟﻔﺤﺺ",
        "Excellent Condition": "ﺣﺎﻟﺔ ﻣﻤﺘﺎزة",
        Point: "نقطة",
        "Maintenance Report Summary": "ﻣﻠﺨﺺ ﺗﻘﺮﻳﺮ اﻟﺼﻴﺎﻧﺔ",
        "View Insepction Notes": "إﻇﻬﺎر ﻣﻠﺎﺣﻈﺎت اﻟﻔﺤﺺ",
        "Inspection notes are available for 24hr from date of inspection.":
          "ﻣﻠﺎﺣﻈﺎت اﻟﻔﺤﺺ ﻣﺘﻮﻓﺮة ﻟﻤﺪة 24 ﺳﺎﻋﺔ ﻣﻦ ﺗﺎرﻳﺦ اﻟﻔﺤﺺ.",
        "Inspection notes are available for": "ﻣﻠﺎﺣﻈﺎت اﻟﻔﺤﺺ ﻣﺘﻮﻓﺮة ﻟﻤﺪة",
        "24hr from date of inspection.": "24 ﺳﺎﻋﺔ ﻣﻦ ﺗﺎرﻳﺦ اﻟﻔﺤﺺ.",
        "Car Inspection Notes": "ﻣﻠﺎﺣﻈﺎت اﻟﻔﺤﺺ",
        "Faults Details": "تفاصيل الأعطال",
        "Inspection Unavailable, diagnostic tools could not connect to car's computer":
          "الفحص غير متوفر ، أجهزة الفحص لم تتمكن من قراءة كمبيوتر المركبة",
        "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results":
          "دورة القيادة غير كافية ، يجب قيادة السيارة لمسافة 70 كم وإعادة فحصها للحصول على نتائج موثوقة",
        "Voltage difference inconclusive, due to the effect of Abnormal resistance values":
          "فرق الجهد غير حاسم ، بسبب تأثير قيم المقاومة غير الطبيعية",
        Car: "اﻟﻤﺮﻛﺒﺔ",
        Make: "الصنع",
        Model: "الموديل",
        "Listing Request Details": "معلومات طلب الادراج",
        "Seller Information": "معلومات البائع",
        "Full Name": "الاسم الكامل",
        "Car Information": "معلومات السيارة",
        "Location Information": "معلومات الموقع",
        City: "المدينة",
        Area: "المنطقة",
        "GPS Location": "موقع GPS",
        "Inspection Time Information": "معلومات وقت الفحص",
        Day: "اليوم",
        "Time Slot": "الوقت",
        Aqaba: "العقبة",
        Jerash: "جرش",
        Irbid: "اربد",
        Madaba: "مادبا",
        Kerak: "الكرك",
        Mafraq: "المفرق",
        Ajloun: "عجلون",
        "As-Salt": "السلط",
        "Ma'an": "معان",
        "At-Tafilah": "الطفيلة",
        Sunday: "الأحد",
        Monday: "الاثنين",
        Tuesday: "الثلاثاء",
        Wednesday: "الأربعاء",
        Thursday: "الخميس",
        Friday: "الجمعة",
        Saturday: "السبت",
        PM: "مساءً",
        AM: "صباحاً",
        "Submit Listing": "ارسال طلب",
        " yr. ": " سنة ",
        " mos": " شهر",
        "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity":
          "(* التقرير النظري يشمل الخدوش من الدرجة (العميقة) فقط و ليس الدرجة (الخفيفة",
        "* We do not take images of light scratches on the car's Exterior":
          "* لا نقوم بإرفاق صور للخدوش (الخفيفة) المدرجة بفحص الهيكل الخارجي ",
        "Owner Information": "بيانات المالك",
        "Owner Info": "بيانات المالك",
        "Appointment Availability": "استقبال المواعيد",
        "Car Related Information": "معلومات متعلقة بالسيارة",
        "Additional Info": "معلومات اضافية",
        "Available for Test Drive": "متوفر لتجربة القيادة",
        "Price Range": "نطاق السعر",
        "First Owner": "المالك الأول",
        "Edit Information": "تعديل البيانات",
        "Save Changes": "حفظ التغييرات",
        "data saved successfuly": "تم حفظ التغييرات بنجاح",
        "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.":
          "* يتم معاينة أنظمة السلامة و الأمان للمركبة بواسطة أحدث الأجهزة و أكثرها تطوراً إلا أن تقرير نظام السلامة و الأمان لا يشمل فحص البطانات و قد لا يظهر في التقرير بعض حالات التلاعب بالوسائد الهوائية.",
        "* Markabte safety system report is based on advanced computer diagnostics only, and does not include opening interior panels to check for airbag tampering.":
          "* يعتمد تقرير مركبتي لأنظمة السلامة و الأمان على الفحص بأجهزة كمبيوتر المتطورة فقط و لا يشمل الكشف عن البطانات الداخلية و التحقق من التلاعب بالأكياس الهوائية.",
        "Call Back": "معاودة الاتصال",
        "Call Back Requests": "طلبات إعادة الاتصال",
        "Requests History": "الطلبات السابقة",
        Requests: "طلبات",
        History: "الطلبات السابقة",
        "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.":
          "* يتم معاينة أنظمة السلامة و الأمان للمركبة بواسطة أحدث الأجهزة و أكثرها تطوراً إلا أن تقرير نظام السلامة و الأمان لا يشمل فحص البطانات و قد لا يظهر في التقرير بعض حالات التلاعب بالوسائد الهوائية.",
        "Call Owner": "الإتصال بالمالك",
        "Text Owner": "أرسل رسالة للمالك",
        "Maintenance Report": "تقرير الصيانة",
        "Looking to buy a car?": "تبحث لشراء سيارة؟",
        "Sign Up": "انشئ حساب",
        "Send Inspection Report": "إرسال تقرير الفحص",
        "Send Maintenance Report": "إرسال تقرير الصيانة",
        "Send Inspection Report Link": "إرسال رابط تقرير الفحص",
        "Send Link": "ارسل الرابط",
        "Please enter car owner's phone": "الرجاء ادخال هاتف مالك السيارة",
        "This phone number is not a registered user.": "هذا الرقم لا يملك حساب",
        "Inspected Point": "نقطة فحص",
        Received: "وصلت الرسالة",
        "Not received": "لم تصل الرسالة",
        "Total Car Stats": "إجمالي احصائيات السيارة",
        "Search views": "مشاهدات البحث",
        "Detailed views": "مشاهدات مفصّلة",
        Calls: "مكالمات",
        Messages: "رسائل",
        "Whatsapp Messages": "رسائل واتساب",
      },
    },
  },
  fallbackLng: localStorage.getItem("language") ?? "ar",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
