import React from "react";

const CallBackRequestsIcon = (props) => {
  const { size, className, style, fill } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5958 2.0802H14.8173H16.494C17.1071 2.0802 17.6331 2.65874 17.6331 3.40463V18.7943C17.6331 19.5401 17.1071 20.1187 16.494 20.1187H5.5958C4.98263 20.1187 4.45667 19.5401 4.45667 18.7943V3.40463C4.45667 2.65874 4.98263 2.0802 5.5958 2.0802ZM16.494 0.881647H14.8173H5.5958C4.35961 0.881647 3.38165 2.02967 3.38165 3.40463V18.7943C3.38165 20.1692 4.35961 21.3172 5.5958 21.3172H16.494C17.7301 21.3172 18.7081 20.1692 18.7081 18.7943V3.40463C18.7081 2.02967 17.7301 0.881647 16.494 0.881647Z"
        fill={fill}
        stroke={fill}
        stroke-width="0.236706"
      />
      <line
        x1="6.01587"
        y1="7.3516"
        x2="16.0757"
        y2="7.35159"
        stroke={fill}
        stroke-width="1.18353"
      />
      <line
        x1="6.01562"
        y1="10.271"
        x2="16.0755"
        y2="10.271"
        stroke={fill}
        stroke-width="1.18353"
      />
      <line
        x1="6.01562"
        y1="13.1509"
        x2="16.0755"
        y2="13.1509"
        stroke={fill}
        stroke-width="1.18353"
      />
      <line
        x1="6.01562"
        y1="15.9912"
        x2="16.0755"
        y2="15.9912"
        stroke={fill}
        stroke-width="1.18353"
      />
    </svg>
  );
};

export default CallBackRequestsIcon;
