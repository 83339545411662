import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { TextField, Typography, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../theme";
import { connect } from "react-redux";
import { getOptions } from "../store/actions/forms";
import AutocompleteComponent from "./AutocompleteComponent";
import { removePhoneZero } from "../helpers/functions";
import MarkabtePhone from "./MarkabtePhone";

const useStyles = makeStyles((theme) => ({
  detailsSubSetDiv: {
    marginBottom: "16px",
  },
  detailsSubSetTitle: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#07303E",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  textFieldsDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    position: "relative",
    marginBottom: "16px",
    minWidth: "100%",
    flexBasis: "100%",
  },
  halfText: {
    minWidth: "calc(50% - 5px)",
    flexBasis: "calc(50% - 5px)",
  },
  textFieldInput: {
    padding: 0,
    height: "19px",
  },
  textFieldInputnote: {
    padding: 0,
    height: "100px",
  },
  textFieldRoot: {
    padding: "11.5px",
    borderRadius: "5px",
    boxShadow: "0px 2.16329px 2.49462px rgba(130, 148, 154, 0.3)",
    paddingLeft: "15px",
  },
  textFieldLable: {
    transform: "translate(14px,13px) scale(1)",
  },
}));

const RunnerListingRequestDetails = (props) => {
  const { open, setOpen, runnerAccount, getOptions, setRequestData } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);

  const cityOptions = [
    { title: t("Amman"), id: "amman" },
    { title: t("Aqaba"), id: "aqaba" },
    { title: t("Jerash"), id: "jerash" },
    { title: t("Irbid"), id: "irbid" },
    { title: t("Madaba"), id: "madaba" },
    { title: t("Zarqa"), id: "zarqa" },
    { title: t("Kerak"), id: "kerak" },
    { title: t("Al-Mafraq"), id: "mafraq" },
    { title: t("Ajloun"), id: "ajloun" },
    { title: t("As-Salt"), id: "salt" },
    { title: t("Ma'an"), id: "ma'an" },
    { title: t("At-Tafilah"), id: "at-tafilah" },
  ];
  const dayOptions = [
    { id: 5, title: t("Saturday") },
    { id: 6, title: t("Sunday") },
    { id: 0, title: t("Monday") },
    { id: 1, title: t("Tuesday") },
    { id: 2, title: t("Wednesday") },
    { id: 3, title: t("Thursday") },
    { id: 4, title: t("Friday") },
  ];
  const timeSlotOptions = [
    { id: "08:00:00.000000", title: `8:00 ${t("AM")}` },
    { id: "08:30:00.000000", title: `8:30 ${t("AM")}` },
    { id: "09:00:00.000000", title: `9:00 ${t("AM")}` },
    { id: "09:30:00.000000", title: `9:30 ${t("AM")}` },
    { id: "10:00:00.000000", title: `10:00 ${t("AM")}` },
    { id: "10:30:00.000000", title: `10:30 ${t("AM")}` },
    { id: "11:00:00.000000", title: `11:00 ${t("AM")}` },
    { id: "11:30:00.000000", title: `11:30 ${t("AM")}` },
    { id: "12:00:00.000000", title: `12:00 ${t("PM")}` },
    { id: "12:30:00.000000", title: `12:30 ${t("PM")}` },
    { id: "13:00:00.000000", title: `1:00 ${t("PM")}` },
    { id: "13:30:00.000000", title: `1:30 ${t("PM")}` },
    { id: "14:00:00.000000", title: `2:00 ${t("PM")}` },
    { id: "14:30:00.000000", title: `2:30 ${t("PM")}` },
    { id: "15:00:00.000000", title: `3:00 ${t("PM")}` },
    { id: "15:30:00.000000", title: `3:30 ${t("PM")}` },
    { id: "16:00:00.000000", title: `4:00 ${t("PM")}` },
    { id: "16:30:00.000000", title: `4:30 ${t("PM")}` },
    { id: "17:00:00.000000", title: `5:00 ${t("PM")}` },
  ];

  const [form, setForm] = useState({
    city: null,
    area: null,
    gps_location: null,
    time: null,
    day: null,
    owner_name: null,
    phone_number: null,
    make: null,
    model: null,
    year: null,
    notes: null,
  });

  useEffect(() => {
    getOptions({
      query: "227",
      callback: (result) => {
        setMakeOptions(result[0].options);
      },
    });
    getOptions({
      query: "230",
      callback: (result) => {
        setYearOptions(result[0].options);
      },
    });
  }, []);

  useEffect(() => {
    setRequestData((prev) => {
      let tempForm = { ...form };
      let tempReqData = [...prev];
      Object.keys(tempForm).map((key) => {
        tempForm[key] = tempForm[key]?.id ?? tempForm[key] ?? null;
      });
      tempReqData[0] = tempForm;
      return tempReqData;
    });
    return () => {
      setRequestData((prev) => {
        let tempReqData = [...prev];
        tempReqData.splice(0, 1);
        return tempReqData;
      });
    };
  }, [form]);

  const handleChange = (event, value, field) => {
    let tempForm = { ...form };
    tempForm[field] = value;
    if (field == "make") {
      tempForm["model"] = null;
      getOptions({
        query: `228&make=${value?.id}`,
        callback: (result) => {
          setModelOptions(result[0].options);
        },
      });
    }
    setForm(tempForm);
  };

  useEffect(() => {
    if (form.phone_number?.[4] == "0") {
      let phoneNumber = removePhoneZero(form.phone_number);
      handleChange(null, phoneNumber, "phone_number");
    }
    if (form.phone_number?.length < 4 || form.phone_number == undefined) {
      handleChange(null, "+962", "phone_number");
    }
  }, [form.phone_number]);

  return (
    <>
      {runnerAccount && (
        <div className={classes.detailsSubSetDiv}>
          <Typography className={classes.detailsSubSetTitle}>
            {t("Seller Information")}
          </Typography>
          <div className={classes.textFieldsDiv}>
            <TextField
              className={classes.textField}
              onChange={(event) =>
                handleChange(event, event.target.value, "owner_name")
              }
              fullWidth
              label={`${t("Full Name")} *`}
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.textFieldInput,
                  root: classes.textFieldRoot,
                },
              }}
              InputLabelProps={{
                classes: { outlined: classes.textFieldLable },
              }}
            />
            <MarkabtePhone
              value={form.phone_number}
              onChange={(e) => {
                handleChange(e, e, "phone_number");
              }}
            />
          </div>
        </div>
      )}
      <div className={classes.detailsSubSetDiv}>
        <Typography className={classes.detailsSubSetTitle}>
          {t("Car Information")}
        </Typography>
        <div className={classes.textFieldsDiv}>
          <AutocompleteComponent
            options={makeOptions}
            label={`${t("Make")} *`}
            onChange={(event, value) => {
              handleChange(event, value, "make");
            }}
            disabled={makeOptions.length == 0}
            value={form.make}
            setSelectedValues={setForm}
            field={"make"}
            className={clsx({ [classes.textField]: 1, [classes.halfText]: 1 })}
          />
          <AutocompleteComponent
            options={modelOptions}
            label={`${t("Model")} *`}
            onChange={(event, value) => {
              handleChange(event, value, "model");
            }}
            disabled={modelOptions.length == 0}
            value={form.model}
            setSelectedValues={setForm}
            field={"model"}
            className={clsx({ [classes.textField]: 1, [classes.halfText]: 1 })}
          />
          <AutocompleteComponent
            options={yearOptions}
            label={`${t("Year")} *`}
            onChange={(event, value) => {
              handleChange(event, value, "year");
            }}
            disabled={yearOptions.length == 0}
            value={form.year}
            setSelectedValues={setForm}
            field={"year"}
            className={classes.textField}
          />
        </div>
      </div>
      {runnerAccount && (
        <>
          <div className={classes.detailsSubSetDiv}>
            <Typography className={classes.detailsSubSetTitle}>
              {t("Location Information")}
            </Typography>
            <div className={classes.textFieldsDiv}>
              <AutocompleteComponent
                options={cityOptions}
                label={`${t("City")} *`}
                onChange={(event, value) => {
                  handleChange(event, value, "city");
                }}
                disabled={makeOptions.length == 0}
                value={form.city}
                setSelectedValues={setForm}
                field={"city"}
                className={clsx({
                  [classes.textField]: 1,
                  [classes.halfText]: 1,
                })}
              />
              <TextField
                className={clsx({
                  [classes.textField]: 1,
                  [classes.halfText]: 1,
                })}
                onChange={(event) =>
                  handleChange(event, event.target.value, "area")
                }
                fullWidth
                label={`${t("Area")} *`}
                variant="outlined"
                InputProps={{
                  classes: {
                    input: classes.textFieldInput,
                    root: classes.textFieldRoot,
                  },
                }}
                InputLabelProps={{
                  classes: { outlined: classes.textFieldLable },
                }}
              />
              <TextField
                className={classes.textField}
                onChange={(event) =>
                  handleChange(event, event.target.value, "gps_location")
                }
                fullWidth
                label={`${t("GPS Location")} *`}
                variant="outlined"
                InputProps={{
                  classes: {
                    input: classes.textFieldInput,
                    root: classes.textFieldRoot,
                  },
                }}
                InputLabelProps={{
                  classes: { outlined: classes.textFieldLable },
                }}
              />
            </div>
          </div>

          <div className={classes.detailsSubSetDiv}>
            <Typography className={classes.detailsSubSetTitle}>
              {t("Inspection Time Information")}
            </Typography>
            <div className={classes.textFieldsDiv}>
              <AutocompleteComponent
                options={dayOptions}
                label={`${t("Day")} *`}
                onChange={(event, value) => {
                  handleChange(event, value, "day");
                }}
                disabled={dayOptions.length == 0}
                value={form.day}
                setSelectedValues={setForm}
                field={"day"}
                className={clsx({
                  [classes.textField]: 1,
                })}
              />
              <AutocompleteComponent
                options={timeSlotOptions}
                label={`${t("Time Slot")} *`}
                onChange={(event, value) => {
                  handleChange(event, value, "time");
                }}
                disabled={timeSlotOptions.length == 0}
                value={form.time}
                setSelectedValues={setForm}
                field={"time"}
                className={clsx({
                  [classes.textField]: 1,
                })}
              />
            </div>
          </div>
        </>
      )}
      <TextField
        multiline
        className={classes.textField}
        onChange={(event) => handleChange(event, event.target.value, "notes")}
        fullWidth
        label={`${t("Notes")} *`}
        variant="outlined"
        InputLabelProps={{
          classes: { outlined: classes.textFieldLable },
        }}
      />
    </>
  );
};

RunnerListingRequestDetails.defaultProps = {
  runnerAccount: true,
};

RunnerListingRequestDetails.propTypes = {
  runnerAccount: PropTypes.bool,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getOptions(payload) {
    dispatch(getOptions(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RunnerListingRequestDetails);
