import {
  FETCH_CAR_REQUEST,
  FETCH_CAR_FAULTS_REQUEST,
  FETCH_CAR_VISUALIZATION_REQUEST,
  LIST_CARS_REQUEST,
  FETCH_CAR_DETAILS_REQUEST,
  FETCH_CAR_GROUPS_REQUEST,
  UPDATE_CAR_SALES_REQUEST,
  UPDATE_CAR_FORM_REQUEST,
  FETCH_CAR_FORMS_REQUEST,
  RESERVE_CAR,
  RESERVE_CAR_PURCHASE_STATUS,
  UPLOAD_LICENSE_IMAGES,
  FETCH_CAR_GROUPS_SCORING_REQUEST,
  SELLER_CAR_GENERATE,
} from "../constants";

export function getCarsList(payload) {
  return {
    type: LIST_CARS_REQUEST,
    payload: payload,
  };
}

export function updateCarForm(payload) {
  return {
    type: UPDATE_CAR_FORM_REQUEST,
    payload: payload,
  };
}

export function getCarDetails(payload) {
  return {
    type: FETCH_CAR_DETAILS_REQUEST,
    payload: payload,
  };
}

export function getCarGroups(payload) {
  return {
    type: FETCH_CAR_GROUPS_REQUEST,
    payload: payload,
  };
}

export function getCarGroupsScoring(payload) {
  return {
    type: FETCH_CAR_GROUPS_SCORING_REQUEST,
    payload: payload,
  };
}

export function updateCarSale(payload) {
  return {
    type: UPDATE_CAR_SALES_REQUEST,
    payload: payload,
  };
}

export function getCarForms(payload) {
  return {
    type: FETCH_CAR_FORMS_REQUEST,
    payload: payload,
  };
}
export function getCarFaults(payload) {
  return {
    type: FETCH_CAR_FAULTS_REQUEST,
    payload: payload,
  };
}
export function getCarVisualization(payload) {
  return {
    type: FETCH_CAR_VISUALIZATION_REQUEST,
    payload: payload,
  };
}
export function getCar(payload) {
  return {
    type: FETCH_CAR_REQUEST,
    payload: payload,
  };
}
export function reserveCar(payload) {
  return {
    type: RESERVE_CAR,
    payload: payload,
  };
}
export function reservedCarPurchaseStatus(payload) {
  return {
    type: RESERVE_CAR_PURCHASE_STATUS,
    payload: payload,
  };
}
export function uploadLicenseImages(payload) {
  return {
    type: UPLOAD_LICENSE_IMAGES,
    payload: payload,
  };
}
export function generateSellerCar(payload) {
  return {
    type: SELLER_CAR_GENERATE,
    payload: payload,
  };
}
