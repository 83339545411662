// AUTH Constants
export const FETCH_TOKEN_REQUEST = "FETCH_TOKEN_REQUEST";
export const FETCH_TOKEN_REQUEST_SUCCEED = "FETCH_TOKEN_REQUEST_SUCCEED";
export const FETCH_TOKEN_REQUEST_FAILURE = "FETCH_TOKEN_REQUEST_FAILURE";

export const FETCH_REFRESH_TOKEN_REQUEST = "FETCH_REFRESH_TOKEN_REQUEST";
export const FETCH_REFRESH_TOKEN_REQUEST_SUCCEED =
  "FETCH_REFRESH_TOKEN_REQUEST_SUCCEED";
export const FETCH_REFRESH_TOKEN_REQUEST_FAILURE =
  "FETCH_REFRESH_TOKEN_REQUEST_FAILURE";

export const UPDATE_KEEP_ME_LOGGED_IN = "UPDATE_KEEP_ME_LOGGED_IN";

export const ACTIVATE_ACCOUNT_REQUEST = "ACTIVATE_ACCOUNT_REQUEST";
export const ACTIVATE_ACCOUNT_SUCCEED = "ACTIVATE_ACCOUNT_SUCCEED";
export const ACTIVATE_ACCOUNT_FAILURE = "ACTIVATE_ACCOUNT_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCEED =
  "FORGOT_PASSWORD_REQUEST_SUCCEED";
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  "FORGOT_PASSWORD_REQUEST_FAILURE";
export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM";

export const TOKEN_REMOVE_REQUEST = "TOKEN_REMOVE_REQUEST";

export const CHECK_UNIQUE_PHONE = "CHECK_UNIQUE_PHONE";
export const CHECK_UNIQUE_PHONE_SUCCESS = "CHECK_UNIQUE_PHONE_SUCESS";
export const CHECK_UNIQUE_PHONE_FAILURE = "CHECK_UNIQUE_PHONE_FAILURE";

// NOTIFICATIONS Constants
export const TOGGLE_NOTIFICATION_BAR = "TOGGLE_NOTIFICATION_BAR";

// BACKDROP Constants
export const TOGGLE_LOADING_BACKDROP = "TOGGLE_LOADING_BACKDROP";

// FORMS Constants
export const FETCH_FORMS_REQUEST = "FETCH_FORMS_REQUEST";
export const FETCH_FORMS_REQUEST_SUCCEED = "FETCH_FORMS_REQUEST_SUCCEED";
export const FETCH_FORMS_REQUEST_FAILURE = "FETCH_FORMS_REQUEST_FAILURE";

export const FETCH_FIELDS_REQUEST = "FETCH_FIELDS_REQUEST";
export const FETCH_FIELDS_REQUEST_SUCCEED = "FETCH_FIELDS_REQUEST_SUCCEED";
export const FETCH_FIELDS_REQUEST_FAILURE = "FETCH_FIELDS_REQUEST_FAILURE";

export const FETCH_FEATURES_REQUEST = "FETCH_FEATURES_REQUEST";
export const FETCH_FEATURES_REQUEST_SUCCEED = "FETCH_FEATURES_REQUEST_SUCCEED";
export const FETCH_FEATURES_REQUEST_FAILURE = "FETCH_FEATURES_REQUEST_FAILURE";

export const UPDATE_ANSWERS = "UPDATE_ANSWERS";
export const UPDATE_FOEMFIELDS = "UPDATE_FOEMFIELDS";
export const UPDATE_HIDDEN = "UPDATE_HIDDEN";
export const UPDATE_FEATURES = "UPDATE_FEATURES";
export const UPDATE_POINTS = "UPDATE_POINTS";

export const FETCH_FIELDS_OPTIONS_REQUEST = "FETCH_FIELDS_OPTIONS_REQUEST";
export const FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED =
  "FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED";
export const FETCH_FIELDS_OPTIONS_REQUEST_FAILURE =
  "FETCH_FIELDS_OPTIONS_REQUEST_FAILURE";

export const FETCH_OPTIONS = "FETCH_OPTIONS";
export const FETCH_OPTIONS_SUCCEED = "FETCH_OPTIONS_SUCCEED";
export const FETCH_OPTIONS_FAILURE = "FETCH_OPTIONS_FAILURE";

// CAR Constants

export const UPDATE_CAR_SALES_REQUEST = "UPDATE_CAR_SALES_REQUEST";
export const UPDATE_CAR_SALES_REQUEST_SUCCEED =
  "UPDATE_CAR_SALES_REQUEST_SUCCEED";
export const UPDATE_CAR_SALES_REQUEST_FAILURE =
  "UPDATE_CAR_SALES_REQUEST_FAILURE";

export const LIST_CARS_REQUEST = "LIST_CARS_REQUEST";
export const LIST_CARS_REQUEST_SUCCEED = "LIST_CARS_REQUEST_SUCCEED";
export const LIST_CARS_REQUEST_FAILURE = "LIST_CARS_REQUEST_FAILURE";

export const CREATE_EMPTY_CAR_REQUEST = "CREATE_EMPTY_CAR_REQUEST";
export const CREATE_EMPTY_CAR_REQUEST_SUCCEED =
  "CREATE_EMPTY_CAR_REQUEST_SUCCEED";
export const CREATE_EMPTY_CAR_REQUEST_FAILURE =
  "CREATE_EMPTY_CAR_REQUEST_FAILURE";

export const UPDATE_CAR_FORM_REQUEST = "UPDATE_CAR_FORM_REQUEST";
export const UPDATE_CAR_FORM_REQUEST_SUCCEED =
  "UPDATE_CAR_FORM_REQUEST_SUCCEED";
export const UPDATE_CAR_FORM_REQUEST_FAILURE =
  "UPDATE_CAR_FORM_REQUEST_FAILURE";

export const UPDATE_CAR_VISUALS_REQUEST = "UPDATE_CAR_VISUALS_REQUEST";
export const UPDATE_CAR_VISUALS_REQUEST_SUCCEED =
  "UPDATE_CAR_VISUALS_REQUEST_SUCCEED";
export const UPDATE_CAR_VISUALS_REQUEST_FAILURE =
  "UPDATE_CAR_VISUALS_REQUEST_FAILURE";

export const FETCH_CAR_REQUEST = "FETCH_CAR_REQUEST";
export const FETCH_CAR_REQUEST_SUCCEED = "FETCH_CAR_REQUEST_SUCCEED";
export const FETCH_CAR_REQUEST_FAILURE = "FETCH_CAR_REQUEST_FAILURE";

export const FETCH_CAR_FIELDS_REQUEST = "FETCH_CAR_FIELDS_REQUEST";
export const FETCH_CAR_FIELDS_REQUEST_SUCCEED =
  "FETCH_CAR_FIELDS_REQUEST_SUCCEED";
export const FETCH_CAR_FIELDS_REQUEST_FAILURE =
  "FETCH_CAR_FIELDS_REQUEST_FAILURE";

export const FETCH_CAR_FORMS_REQUEST = "FETCH_CAR_FORMS_REQUEST";
export const FETCH_CAR_FORMS_REQUEST_SUCCEED =
  "FETCH_CAR_FORMS_REQUEST_SUCCEED";
export const FETCH_CAR_FORMS_REQUEST_FAILURE =
  "FETCH_CAR_FORMS_REQUEST_FAILURE";

export const FETCH_CAR_FEATURES_REQUEST = "FETCH_CAR_FEATURES_REQUEST";
export const FETCH_CAR_FEATURES_REQUEST_SUCCEED =
  "FETCH_CAR_FEATURES_REQUEST_SUCCEED";
export const FETCH_CAR_FEATURES_REQUEST_FAILURE =
  "FETCH_CAR_FEATURES_REQUEST_FAILURE";

export const FETCH_CAR_FAULTS_REQUEST = "FETCH_CAR_FAULTS_REQUEST";
export const FETCH_CAR_FAULTS_REQUEST_SUCCEED =
  "FETCH_CAR_FAULTS_REQUEST_SUCCEED";
export const FETCH_CAR_FAULTS_REQUEST_FAILURE =
  "FETCH_CAR_FAULTS_REQUEST_FAILURE";

export const FETCH_CAR_VISUALIZATION_REQUEST =
  "FETCH_CAR_VISUALIZATION_REQUEST";
export const FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED =
  "FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED";
export const FETCH_CAR_VISUALIZATION_REQUEST_FAILURE =
  "FETCH_CAR_VISUALIZATION_REQUEST_FAILURE";

export const FETCH_CAR_DETAILS_REQUEST = "FETCH_CAR_DETAILS_REQUEST";
export const FETCH_CAR_DETAILS_REQUEST_SUCCEED =
  "FETCH_CAR_DETAILS_REQUEST_SUCCEED";
export const FETCH_CAR_DETAILS_REQUEST_FAILURE =
  "FETCH_CAR_DETAILS_REQUEST_FAILURE";

export const FETCH_CAR_GROUPS_REQUEST = "FETCH_CAR_GROUPS_REQUEST";
export const FETCH_CAR_GROUPS_REQUEST_SUCCEED =
  "FETCH_CAR_GROUPS_REQUEST_SUCCEED";
export const FETCH_CAR_GROUPS_REQUEST_FAILURE =
  "FETCH_CAR_GROUPS_REQUEST_FAILURE";

export const FETCH_CAR_GROUPS_SCORING_REQUEST =
  "FETCH_CAR_GROUPS_SCORING_REQUEST";
export const FETCH_CAR_GROUPS_SCORING_REQUEST_SCORING_SUCCEED =
  "FETCH_CAR_GROUPS_REQUEST_SCORING_SUCCEED";
export const FETCH_CAR_GROUPS_REQUEST_SCORING_FAILURE =
  "FETCH_CAR_GROUPS_REQUEST_SCORING_FAILURE";

export const SELLER_CAR_GENERATE = "SELLER_CAR_GENERATE";
export const SELLER_CAR_GENERATE_SUCCEED = "SELLER_CAR_GENERATE_SUCCEED";
export const SELLER_CAR_GENERATE_FAILURE = "SELLER_CAR_GENERATE_FAILURE";

// DEALERSHIP Listing req. Constants

export const CREATE_LISTING_REQUESTS = "CREATE_LISTING_REQUESTS";
export const CREATE_LISTING_REQUESTS_SUCCEED =
  "CREATE_LISTING_REQUESTS_SUCCEED";
export const CREATE_LISTING_REQUESTS_FAILURE =
  "CREATE_LISTING_REQUESTS_FAILURE";

//  websockets constants
// ws:// connection constants
export const CONNECTION_REQUEST = "CONNECTION_REQUEST";
export const CONNECTION_REQUEST_SUCCEED = "CONNECTION_REQUEST_SUCCEED";
export const CONNECTION_REQUEST_FAILURE = "CONNECTION_REQUEST_FAILURE";
// http:// constants
export const UPDATE_PAGE_TIME_STAMP = "UPDATE_PAGE_TIME_STAMP";
export const UPDATE_PAGE_TIME_STAMP_SUCCEED = "UPDATE_PAGE_TIME_STAMP_SUCCEED";
export const UPDATE_PAGE_TIME_STAMP_FAILURE = "UPDATE_PAGE_TIME_STAMP_FAILURE";

export const FETCH_PAGE_TIME_STAMP = "FETCH_PAGE_TIME_STAMP";
export const FETCH_PAGE_TIME_STAMP_SUCCEED = "FETCH_PAGE_TIME_STAMP_SUCCEED";
export const FETCH_PAGE_TIME_STAMP_FAILURE = "FETCH_PAGE_TIME_STAMP_FAILURE";

export const FETCH_UNSEEN_NOTIFICATIONS = "FETCH_UNSEEN_NOTIFICATIONS";
export const FETCH_UNSEEN_NOTIFICATIONS_SUCCEED =
  "FETCH_UNSEEN_NOTIFICATIONS_SUCCEED";
export const FETCH_UNSEEN_NOTIFICATIONS_FAILURE =
  "FETCH_UNSEEN_NOTIFICATIONS_FAILURE";

// redux store constants
export const ADD_CAR = "ADD_CAR";
export const REMOVE_CAR = "REMOVE_CAR";
export const AUTO_RESERVE_CAR = "AUTO_RESERVE_CAR";
export const AUTO_UNRESERVE_CAR = "AUTO_UNRESERVE_CAR";

export const CLEAR_NEW_CARS = "CLEAR_NEW_CARS";
export const CLEAR_REMOVED_CARS = "CLEAR_REMOVED_CARS";

// export const BOOKED_SEEN = "BOOKED_SEEN";

// get scheduled appointments
// export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
// export const GET_APPOINTMENTS_FAILURE = "GET_APPOINTMENTS_FAILURE";
// export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";

// get report purchase appointments dealership 3 months
// export const GET_REPORT_PURCHASE_APPOINTMENTS =
//   "GET_REPORT_PURCHASE_APPOINTMENTS";
// export const GET_REPORT_PURCHASE_APPOINTMENTS_FAILURE =
//   "GET_REPORT_PURCHASE_APPOINTMENTS_FAILURE";
// export const GET_REPORT_PURCHASE_APPOINTMENTS_SUCCESS =
//   "GET_REPORT_PURCHASE_APPOINTMENTS_SUCCESS";

// reserve a car
export const RESERVE_CAR = "RESERVE_CAR";
export const RESERVE_CAR_FAILURE = "RESERVE_CAR_FAILURE";
export const RESERVE_CAR_SUCCESS = "RESERVE_CAR_SUCCESS";

// reserved car Purchase status
export const RESERVE_CAR_PURCHASE_STATUS = "RESERVE_CAR_PURCHASE_STATUS";
export const RESERVE_CAR_PURCHASE_STATUS_FAILURE =
  "RESERVE_CAR_PURCHASE_STATUS_FAILURE";
export const RESERVE_CAR_PURCHASE_STATUS_SUCCESS =
  "RESERVE_CAR_PURCHASE_STATUS_SUCCESS";

// upload license images
export const UPLOAD_LICENSE_IMAGES = "UPLOAD_LICENSE_IMAGES";
export const UPLOAD_LICENSE_IMAGES_FAILURE = "UPLOAD_LICENSE_IMAGES_FAILURE";
export const UPLOAD_LICENSE_IMAGES_SUCCESS = "UPLOAD_LICENSE_IMAGES_SUCCESS";

export const DEALER_CONFIRMATION = "DEALER_CONFIRMATION";
export const DEALER_CONFIRMATION_FAILURE = "DEALER_CONFIRMATION_FAILURE";
export const DEALER_CONFIRMATION_SUCCESS = "DEALER_CONFIRMATION_SUCCESS";

// export const GET_APPOINTMENT_INTERVAL = "GET_APPOINTMENT_INTERVAL";
// export const GET_APPOINTMENT_INTERVAL_FAILURE =
//   "GET_APPOINTMENT_INTERVAL_FAILURE";
// export const GET_APPOINTMENT_INTERVAL_SUCCESS =
//   "GET_APPOINTMENT_INTERVAL_SUCCESS";

// export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT_INTERVAL";
// export const BOOK_APPOINTMENT_FAILURE = "BOOK_APPOINTMENT_FAILURE";
// export const BOOK_APPOINTMENT_SUCCESS = "BOOK_APPOINTMENT_SUCCESS";

export const GET_DISABLEDAPPTS = "GET_DISABLEDAPPTS";
export const GET_DISABLEDAPPTS_FAILURE = "GET_DISABLEDAPPTS_FAILURE";
export const GET_DISABLEDAPPTS_SUCCESS = "GET_DISABLEDAPPTS_SUCCESS";

// export const POST_DISABLED_APPOINTMENTS = "POST_DISABLED_APPOINTMENTS";
// export const POST_DISABLED_APPOINTMENTS_FAILURE =
//   "POST_DISABLED_APPOINTMENTS_FAILURE";
// export const POST_DISABLED_APPOINTMENTS_SUCCESS =
//   "POST_DISABLED_APPOINTMENTS_SUCCESS";

export const GET_DEACTIVATED = "GET_DEACTIVATED";
export const GET_DEACTIVATED_FAILURE = "GET_DEACTIVATED_FAILURE";
export const GET_DEACTIVATED_SUCCESS = "GET_DEACTIVATED_SUCCESS";

export const GET_DEALER_CREDITS = "GET_DEALER_CREDITS";
export const GET_DEALER_CREDITS_FAILURE = "GET_DEALER_CREDITS_FAILURE";
export const GET_DEALER_CREDITS_SUCCESS = "GET_DEALER_CREDITS_SUCCESS";

export const SET_DEALER_CREDITS = "SET_DEALER_CREDITS";

export const INITIATING_PAPRAZZI = "INITIATING_PAPRAZZI";
export const INITIATING_PAPRAZZI_SUCCEED = "INITIATING_PAPRAZZI_SUCCEED";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCEED = "SEND_OTP_SUCCEED";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_SUCCEED = "VALIDATE_OTP_SUCCEED";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

export const SET_OWNER_INFO = "SET_OWNER_INFO";
export const SET_OWNER_INFO_SUCCEED = "SET_OWNER_INFO_SUCCEED";
export const SET_OWNER_INFO_FAILURE = "SET_OWNER_INFO_FAILURE";

export const GET_CALLS_INFO = "GET_CALLS_INFO";
export const GET_CALLS_INFO_SUCCEED = "GET_CALLS_INFO_SUCCEED";
export const GET_CALLS_INFO_FAILURE = "GET_CALLS_INFO_FAILURE";

export const MOVE_TO_HISTORY = "MOVE_TO_HISTORY";
export const MOVE_TO_HISTORY_SUCCEED = "MOVE_TO_HISTORY_SUCCEED";
export const MOVE_TO_HISTORY_FAILURE = "MOVE_TO_HISTORY_FAILURE";

export const GET_MESSAGES_INFO = "GET_MESSAGES_INFO";
export const GET_MESSAGES_INFO_SUCCEED = "GET_MESSAGES_INFO_SUCCEED";
export const GET_MESSAGES_INFO_FAILURE = "GET_MESSAGES_INFO_FAILURE";

export const RECEIVED_OR_HISTORY = "RECEIVED_OR_HISTORY";
export const RECEIVED_OR_HISTORY_SUCCEED = "RECEIVED_OR_HISTORY_SUCCEED";
export const RECEIVED_OR_HISTORY_FAILURE = "RECEIVED_OR_HISTORY_FAILURE";

export const SET_CAR_STATUS = "SET_CAR_STATUS";
export const SET_CAR_STATUS_SUCCEED = "SET_CAR_STATUS_SUCCEED";
export const SET_CAR_STATUS_FAILURE = "SET_CAR_STATUS_FAILURE";

export const GET_CAR_STATUS = "GET_CAR_STATUS";
export const GET_CAR_STATUS_SUCCEED = "GET_CAR_STATUS_SUCCEED";
export const GET_CAR_STATUS_FAILURE = "GET_CAR_STATUS_FAILURE";
