//nothing changed here it just preter please make sure to remove comment after you see it 13:43 3-jul sprint 34
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { listingRequest } from "../../store/actions/request";
import { updateCarForm, getCarForms } from "../../store/actions/car";
import { getFields } from "../../store/actions/forms";
import { getDealerCredits } from "../../store/actions/dealership";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import RequestListingEntry from "../../icons/requestListingEntry.svg";
import NewEntry from "../../icons/NewEntry.svg";
import DisabledRequestListingEntry from "../../icons/DisabledRequestListingEntry.svg";
import DisabledNewEntry from "../../icons/DisabledNewEntry.svg";
import plus from "../../icons/plus.svg";
import plus2 from "../../icons/plus2.svg";
import minus from "../../icons/minus.svg";
import CarCard from "../../components/CarCard";
import { getCarsList } from "../../store/actions/car";
import {
  clearRemovedCars,
  updatePageTimeStamp,
  clearNewCars,
} from "../../store/actions/websockets";

import Sort from "../../components/sort";

import { useTranslation } from "react-i18next";
import CarCardSkeleton from "../../components/skeletons/CarCardSkeleton";
import vlpEmptyState from "../../images/vlpEmptyState.png";
import RequestListingsDialog from "../../components/RequestListingsDialog";
import { distanceToNumber } from "../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: "#f3f3f3",
    // [theme.breakpoints.down("sm")]: {
    //   padding: theme.spacing(1),
    // },
    // [theme.breakpoints.up("md")]: {
    //   padding: "24px 68px",
    // },
    margin: "auto",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "607px",
    },
    [theme.breakpoints.up("lg")]: {
      // width: "689px", //// try 750
      width: "750px", //// try 750
      padding: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70.414vw",
      padding: "25px",
    },
  },
  breadcrumbsPaper: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: 12,
    paddingBottom: 12,
  },
  mainDiv: {
    backgroundColor: "white",
  },
  sortDiv: {
    [theme.breakpoints.down("sm")]: {
      // padding: theme.spacing(1),
      padding: "8px 16px" /* remove if fails menue card results*/,
    },
    [theme.breakpoints.up("md")]: {
      padding: "8px 68px",
    },
  },
  sortContainer: {
    [theme.breakpoints.down("sm")]: {
      height: "47px",
    },
    [theme.breakpoints.up("md")]: {
      height: "66px",
    },
  },
  resultsGrid: {
    display: "flex",
    justifyContent: "flex-start",
  },
  sortGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  LinearProgressBar: {
    [theme.breakpoints.down("sm")]: {
      margin: "-8px",
      width: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      margin: "-24px -68px",
    },
  },
  requestListingEntryButton: {
    textTransform: "none",
    marginTop: "24px",
    backgroundColor: "white",
    height: "52px",
    border: "0.5px solid #186985",
    boxSizing: "border-box",
    boxShadow: "0px 2px 3px rgba(15, 71, 90, 0.4)",
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "30px", ////new
      fontSize: "1.25rem", ////new
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      padding: "7px 21px", ////new
      fontSize: "0.875rem", ////new
      marginTop: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  entryButtonIcon: {
    marginRight: "12px",
    [theme.breakpoints.up("md")]: {
      ////new
      width: "40px",
    },
  },
  requestListingEntryPaper: {
    display: "grid",
    // height: "calc(100% - 36px)",
    // height: "100%",
    margin: "16px 0", /////new
    height: "calc(100% - 32px)", /////new
    // margin: "1em 0",
    borderRadius: "0",
    border: " 2px solid #186985",
    boxShadow: "0px 4px 4px rgba(15, 71, 90, 0.4)",
    minHeight: "300px",

    cursor: "pointer",
    minWidth: "290px", ////new
  },
  labelPlacementStyle: {
    margin: "8px 13px",
  },
  formControlLable: {
    marginRight: "auto",
  },
  radioButton: {
    padding: 0,
  },
  sortLable: {
    marginRight: "auto",
    color: "#07303E",
  },
  cardsGrid: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      /* remove if fails menue card results*/ display: "flex",
      justifyContent: "center",
    },
  },
  gridContainerSpacing: {
    margin: "-16px",
    width: "calc(100% + 32px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 -16px", ////new
    },
    [theme.breakpoints.up("lg")]: {
      margin: "-16px", ////new
    },
  },
  carCardGridItem: {
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 8px",
    },
  },
  vlpEmptyStateImage: {
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      height: "188px",
    },
  },
  emptyStateDiv: {
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
  creditDiv: {
    height: "85px",
    backgroundColor: "#F3F5F9",
    border: "1px solid #ADB5BA",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      marginBottom: "0px",
    },
  },
  creditDivmobile: {
    height: "123px",
    width: "inherit",
    marginInline: "10px",
    backgroundColor: "#F3F5F9",
    border: "1px solid #ADB5BA",
    borderRadius: "3px",
    justifyContent: "space-between",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: "34px",
      marginBottom: "18px",
    },
  },
  field: {
    width: "230.71px",
    height: "73.88px",
    [theme.breakpoints.down("md")]: {
      width: "178px",
      height: "57px",
    },
  },
  accountBalanceDiv: {
    height: "fit-content",
    alignSelf: "center",
    marginRight: "25px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  webChargeButton: {
    height: "45px",
    width: "105px",
    alignSelf: "center",
    textTransform: "none",
    marginRight: "25px",
  },
  webDealerName: {
    alignSelf: "center",
    marginLeft: "28px",
    color: "#07303E",
    textSize: "18px",
    fontWeight: 600,
  },
  mobileDealerName: {
    textAlign: "center",
    marginTop: "20px",
    color: "#07303E",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "7px",
  },
  balanceDiv: {
    height: "fit-content",
    alignSelf: "center",
    paddingRight: "5px",
  },
  plusMargin: {
    marginRight: "8px",
  },
}));

const CarListings = (props) => {
  const classes = useStyles();
  const {
    history,
    user,
    cars,
    getCarsList,
    fetching,
    isAuthenticated,
    listingRequest,
    clearRemovedCars,
    state,
    updatePageTimeStamp,
    clearNewCars,
    dealershipName,
    dealershipNameAr,
    getDealerCredits,
    credits,
    runnerAccount,
  } = props;

  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [entries, setEntries] = React.useState(1);

  const [sortCriteria, setSortCriteria] = React.useState("oldest");
  const [updatedCars, setUpdatedCars] = useState(cars);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [radioDefaultValue, setRadioDefaultValue] = useState("oldest");
  const [minusDisabled, setMinusDisabled] = useState(false);
  const [plusDisabled, setPlusDisabled] = useState(false);
  const whatsappMessage = user?.dealership_name_ar;

  useEffect(() => {
    getDealerCredits({
      dealershipId: user?.dealership_id,
    });
  }, [openDialog]);

  useEffect(() => {
    if (credits == 0) {
      setOpenDialog(false);
      document.getElementById("root").style.filter = "none"; // removes blur from root element
    }
  }, [credits]);

  useEffect(() => {
    if (entries === 1) {
      setMinusDisabled(true);
      if (entries !== credits) {
        setPlusDisabled(false);
      } else {
        setPlusDisabled(true);
      }
    } else if (entries === credits) {
      setPlusDisabled(true);
    } else {
      setMinusDisabled(false);
      setPlusDisabled(false);
    }
  }, [entries, credits]);

  useEffect(() => {
    var bodyTag = document.getElementsByTagName("body");
    bodyTag[0].style.overflow = "auto";
  }, []);

  //This function is called in Sort Component and excuted here to change state of Listing and cause a re-render of the sortedCarList
  const handleChangeSort = (event) => {
    setRadioDefaultValue(event.target.value);
    setOpenDrawer(false);
    setSortCriteria(event.target.value);
    if (event.target.value === "oldest") {
      updatedCars.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
    } else if (event.target.value === "newest") {
      updatedCars.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    } else if (event.target.value === "highestPrice") {
      updatedCars.sort((a, b) => {
        return b.card.price - a.card.price;
      });
    } else if (event.target.value === "lowestPrice") {
      updatedCars.sort((a, b) => {
        return a.card.price - b.card.price;
      });
    } else if (event.target.value === "highestScore") {
      updatedCars.sort((a, b) => {
        return b.carScore - a.carScore;
      });
    } else if (event.target.value === "lowestScore") {
      updatedCars.sort((a, b) => {
        return a.carScore - b.carScore;
      });
    } else if (event.target.value === "lowestMilage") {
      updatedCars.sort((a, b) => {
        return (
          distanceToNumber(a.card.milage) - distanceToNumber(b.card.milage)
        );
      });
    } else if (event.target.value === "highestMilage") {
      updatedCars.sort((a, b) => {
        return (
          distanceToNumber(b.card.milage) - distanceToNumber(a.card.milage)
        );
      });
    } else if (event.target.value === "newestYear") {
      updatedCars.sort((a, b) => {
        return a.card.year - b.card.year;
      });
    } else if (event.target.value === "oldestYear") {
      updatedCars.sort((a, b) => {
        return b.card.year - a.card.year;
      });
    }
  };

  useEffect(() => {
    if (user) {
      getCarsList({
        filter: `?is_active=True&published_for_seller=true&listing_request__dealership=${user?.dealership_id}&is_sold=false`,
      });
      clearRemovedCars(); // reset removed cars
      updatePageTimeStamp({
        user_id: user?.user_id,
        page: "car_list_stamp",
      });
      clearNewCars();
    }
    // eslint-disable-next-line
  }, [getCarsList]);

  useEffect(() => {
    // this is probably wrong but for some reason react is not seeing changes in state.car.cars so i had to send state as a prop and check for changes in state.car
    setUpdatedCars(cars);
    // eslint-disable-next-line
  }, [state.car]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const renderMdDownDrawer = () => {
    return (
      <Drawer
        anchor="right"
        ModalProps={{
          BackdropProps: { classes: { root: classes.backdropRoot } },
        }}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <div className={classes.toolbar} style={{ backgroundColor: "white" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormControl>
              <FormLabel
                style={{ display: "flex", padding: "12px 13px 18px 12px" }} // or 8 13 8 12
              >
                <Typography classes={{ root: classes.sortLable }}>
                  {t("Sort By")}
                </Typography>{" "}
                <IconButton
                  style={{ padding: "0" }}
                  color="#07303E"
                  className={classes.button}
                  component="span"
                  onClick={handleCloseDrawer}
                >
                  <CloseIcon
                    onClick={handleCloseDrawer}
                    style={{ fill: "#07303E" }}
                  />
                </IconButton>
              </FormLabel>
              <hr
                style={{
                  height: "1px",
                  border: "0px none",
                  backgroundImage:
                    "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                  margin: "0px 0px 8px",
                }}
              />
              <RadioGroup
                aria-label="sort"
                defaultValue={radioDefaultValue}
                onChange={handleChangeSort}
              >
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"lowestPrice"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Price (Low to High)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"highestPrice"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Price (High to Low)")}
                  labelPlacement="start"
                />
                <hr
                  style={{
                    height: "1px",
                    border: "0px none",
                    backgroundImage:
                      "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    margin: "8px 0px 8px",
                  }}
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"lowestMilage"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Odometer (Low to High)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"highestMilage"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Odometer (High to Low)")}
                  labelPlacement="start"
                />
                <hr
                  style={{
                    height: "1px",
                    border: "0px none",
                    backgroundImage:
                      "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    margin: "8px 0px 8px",
                  }}
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"newestYear"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Year (Oldest First)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"oldestYear"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Year (Newest First)")}
                  labelPlacement="start"
                />
                <hr
                  style={{
                    height: "1px",
                    border: "0px none",
                    backgroundImage:
                      "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    margin: "8px 0px 8px",
                  }}
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"oldest"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Date (Oldest First)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"newest"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Date (Newest First)")}
                  labelPlacement="start"
                />
                <hr
                  style={{
                    height: "1px",
                    border: "0px none",
                    backgroundImage:
                      "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    margin: "8px 0px 8px",
                  }}
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"lowestScore"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Score (Low to High)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"highestScore"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Score (High to Low)")}
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Drawer>
    );
  };

  const handleChange = (event) => {
    setEntries(event.target.value);
  };

  const handleRequestListingEntry = () => {
    setOpenDialog(true);
    document.getElementById("root").style.filter = "blur(1px)"; // adds blur to root element
  };
  const [error, setError] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const handleConfirm = () => {
    // if (entries > 50) setError(true);
    // else {
    //   setError(false);
    // console.log("xxxxxxxxxxxxxxxd", entries);
    listingRequest({
      id: user.user_id,
      number_of_listings: entries,
      request_data: requestData,
      dealershipId: user.dealership_id, // credits mare refreshed on failure in catch using dealershipId
    });
    setOpenDialog(false);
    document.getElementById("root").style.filter = "none"; // removes blur from root element
    // }
  };
  const handleClose = () => {
    setError(false);
    setOpenDialog(false);
    document.getElementById("root").style.filter = "none"; // removes blur from root element
    setEntries(1);
  };
  return (
    <div>
      <Hidden mdUp>{renderMdDownDrawer()}</Hidden>
      {isAuthenticated ? (
        user.role === "dealer owner" || user.role === "dealer staff" ? (
          <div className={classes.mainDiv}>
            <Paper
              style={{
                boxShadow: "none",
                borderBottom: "2px solid #0c6885",
                borderRadius: 0,
              }}
              classes={{ root: classes.sortDiv }}
            >
              <Grid
                container
                justify={"space-between"}
                alignItems={"center"}
                spacing={2}
                className={classes.sortContainer}
              >
                <Grid item xs={4} className={classes.resultsGrid}>
                  <Typography>
                    {updatedCars?.length} {t("Results")}
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.sortGrid}>
                  {/* Sort child Component called with handleChange function and sortCriteria value as props  */}
                  <Sort
                    handleChange={handleChangeSort}
                    sortCriteria={sortCriteria}
                    handleOpenDrawer={handleOpenDrawer}
                  />
                </Grid>
              </Grid>
            </Paper>
            <div className={classes.container}>
              <Grid container /*spacing={3}*/>
                <Grid item md={12} className={classes.cardsGrid}>
                  <Hidden smDown>
                    <div className={classes.creditDiv}>
                      <Typography className={classes.webDealerName}>
                        {localStorage.getItem("language") == "en"
                          ? dealershipName
                          : dealershipNameAr}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div className={classes.balanceDiv}>
                          <Typography
                            style={{
                              fontSize: "40px",
                              color: "#186985",
                              lineHeight: "60px",
                              fontWeight: 700,
                            }}
                          >
                            {credits}
                          </Typography>
                        </div>
                        <div
                          // style={{
                          //   height: "fit-content",
                          //   alignSelf: "center",
                          //   marginRight: "25px",
                          //   fontWeight: 600,
                          // }}
                          className={classes.accountBalanceDiv}
                        >
                          <Typography
                            style={{ fontSize: "13px", color: "#6B8495" }}
                          >
                            {t("Account Balance")}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#186985",
                              fontWeight: 700,
                            }}
                          >
                            {t("Credits")}
                          </Typography>
                        </div>
                        {/* <a href="https://wa.me/+962787447192/?text=hello"> */}
                        {/* <Button
                          variant="contained"
                          color="primary"
                          // style={{
                          //   height: "45px",
                          //   width: "105px",
                          //   alignSelf: "center",
                          //   textTransform: "none",
                          //   marginRight: "25px",
                          // }}
                          className={classes.webChargeButton}
                          onClick={() =>
                            window.open(
                              `https://wa.me/+962795087430/?text=مرحباً, اريد شحن رصيد النقاط لمتجر ${user?.dealership_name_ar}.`
                            )
                          }
                        >
                          <img className={classes.plusMargin} src={plus} />
                          {t("Charge")}
                        </Button> */}
                        {/* </a> */}
                      </div>
                    </div>
                  </Hidden>
                  {/* //////////////////  Web ^  ///////////////////////// mobile  ///////////////////////////////////////// */}
                  {fetching ? (
                    // <LinearProgress className={classes.LinearProgressBar} />
                    <Grid container className={classes.gridContainerSpacing}>
                      {[1, 2, 3, 4, 5].map(() => (
                        <CarCardSkeleton />
                      ))}
                    </Grid>
                  ) : (
                    <Grid container className={classes.gridContainerSpacing}>
                      {updatedCars.length > 0 && (
                        <>
                          <Hidden mdUp>
                            <div className={classes.creditDivmobile}>
                              <Typography
                                // style={{
                                //   textAlign: "center",
                                //   marginTop: "20px",
                                //   color: "#07303E",
                                //   fontSize: "14px",
                                //   fontWeight: 600,
                                //   marginBottom: "7px",
                                //   }}
                                className={classes.mobileDealerName}
                              >
                                {localStorage.getItem("language") == "en"
                                  ? dealershipName
                                  : dealershipNameAr}
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  // style={{
                                  //   // height: "fit-content",
                                  //   // alignSelf: "center",
                                  //   marginRight: "4px",
                                  // }}
                                  className={classes.balanceDiv}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "40px",
                                      color: "#186985",
                                      lineHeight: "60px",
                                      fontWeight: 700,
                                      marginRight: "4px",
                                    }}
                                  >
                                    {credits}
                                  </Typography>
                                </div>
                                <div className={classes.accountBalanceDiv}>
                                  <Typography
                                    style={{
                                      fontSize: "13px",
                                      color: "#6B8495",
                                    }}
                                  >
                                    {t("Account Balance")}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "16px",
                                      color: "#186985",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {t("Credits")}
                                  </Typography>
                                </div>
                                {/* <Button
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    height: "45px",
                                    width: "105px",
                                    alignSelf: "center",
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    window.open(
                                      `https://wa.me/+962795087430/?text=مرحباً, اريد شحن رصيد النقاط لمتجر ${user?.dealership_name_ar}.`
                                    )
                                  }
                                >
                                  <img
                                    className={classes.plusMargin}
                                    src={plus}
                                  />
                                  {t("Charge")}
                                </Button> */}
                              </div>
                            </div>
                          </Hidden>
                          <Hidden lgUp>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "0 8px",
                                marginBottom: "10.5px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.requestListingEntryButton}
                                onClick={
                                  credits > 0 || runnerAccount
                                    ? handleRequestListingEntry
                                    : null
                                }
                                disabled={
                                  (credits <= 0 && !runnerAccount) &
                                  !runnerAccount
                                    ? true
                                    : false
                                }
                                style={
                                  credits <= 0 && !runnerAccount
                                    ? {
                                        color: "#B0B0B0",
                                        backgroundColor: "#DDDDDE",
                                      }
                                    : {
                                        backgroundColor: "#fff",
                                      }
                                }
                              >
                                <img
                                  className={classes.entryButtonIcon}
                                  src={
                                    credits <= 0 && !runnerAccount
                                      ? DisabledNewEntry
                                      : NewEntry
                                  }
                                />
                                {t("Request Listing Entry")}
                              </Button>
                            </Grid>
                          </Hidden>

                          <Hidden mdDown>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={12}
                              lg={6}
                              xl={4}
                              className={classes.carCardGridItem}
                            >
                              <Paper
                                className={classes.requestListingEntryPaper}
                                onClick={
                                  credits > 0 || runnerAccount
                                    ? handleRequestListingEntry
                                    : null
                                }
                                style={
                                  (credits <= 0) & !runnerAccount
                                    ? {
                                        backgroundColor: "#DDDDDE",
                                        border: " 2px solid #DDDDDE",
                                      }
                                    : {
                                        border: " 2px solid #186985",
                                        backgroundColor: "#fff",
                                      }
                                }
                              >
                                <div
                                  style={{
                                    margin: "auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      (credits <= 0) & !runnerAccount
                                        ? DisabledRequestListingEntry
                                        : RequestListingEntry
                                    }
                                    // style={{ margin: "auto auto 35.21px" }}
                                    alt={"Request Listing Entry"}
                                  />
                                  <Typography
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      // color: "#186985",
                                      color:
                                        (credits <= 0) & !runnerAccount
                                          ? "#B0B0B0"
                                          : "#186985",
                                      marginTop: "35px",
                                      // margin: "auto",
                                      // display: "flex",
                                      // justifyContent: "center",
                                    }}
                                  >
                                    {t("Request Listing Entry")}
                                  </Typography>
                                </div>
                              </Paper>
                            </Grid>
                          </Hidden>
                        </>
                      )}
                      {updatedCars.length > 0 ? (
                        updatedCars?.map((car, i) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={12}
                              lg={6}
                              xl={4}
                              className={classes.carCardGridItem}
                            >
                              {/* Added trim fuel_type score rating location to props and removed engin_size */}
                              <CarCard
                                model={car.card.model}
                                make={car.card.make}
                                trim={car.card.trim}
                                sold={car.is_sold}
                                reserved={car.reservation?.reserved}
                                reservedTo={car.reservation?.reserved_to}
                                reservationId={car.reservation?.id}
                                year={car.card.year}
                                image={car.card.feature_image}
                                milage={car.card.milage}
                                transmission={car.card.transmission}
                                fuelType={car.card.fuel_type}
                                driveType={car.card.drive_type}
                                engineSize={car.card.engine_size}
                                batterySize={car.card.battery_size}
                                countrySpecs={car.card.country_specs}
                                dealership_details={car.dealership_details}
                                listing_request_id={car.listing_request}
                                score={
                                  typeof car.carScore === "string"
                                    ? "N/A"
                                    : Math.floor(car.carScore)
                                }
                                rating={car.card.rating}
                                dealershipLocation={car.card.city}
                                dealershipArea={car.card.area}
                                createdAt={car.created_at}
                                enteredByFullName={car.entered_by_fullname}
                                price={car.card.price}
                                uom={car.card.unit_of_measurement}
                                onClick2={() => {
                                  history.push({
                                    pathname: "/car/" + car.id + "/edit-price",
                                    state: { carId: car.id },
                                  });
                                }}
                                car_id={car.id}
                                unlocked={car.global_unlocked}
                                published_for_buyers={car.published_for_buyers}
                              />
                            </Grid>
                          );
                        })
                      ) : (
                        <div className={classes.emptyStateDiv}>
                          <img
                            className={classes.vlpEmptyStateImage}
                            src={vlpEmptyState}
                          />
                          <Typography
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#07303E",
                              marginTop: "20px",
                            }}
                          >
                            {t("You Don’t Have Cars in Your Account.")}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#626465",
                              marginTop: "8px",
                            }}
                          >
                            {t(
                              "Request officers to list cars under your account."
                            )}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              marginTop: "20px",
                              textTransform: "none",
                              width: "210px",
                              height: "30px",
                            }}
                            // className={classes.requestListingEntryButton}
                            onClick={
                              credits > 0 || runnerAccount
                                ? handleRequestListingEntry
                                : null
                            }
                            disabled={
                              credits <= 0 && !runnerAccount ? true : false
                            }
                          >
                            {t("Request Listing Entry")}
                          </Button>
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <RequestListingsDialog
                setEntries={setEntries}
                entries={entries}
                openDialog={openDialog}
                handleClose={handleClose}
                credits={credits}
                minusDisabled={minusDisabled}
                handleChange={handleChange}
                plusDisabled={plusDisabled}
                handleConfirm={handleConfirm}
                setRequestData={setRequestData}
                requestData={requestData}
                runnerAccount={runnerAccount}
              />
            </div>
          </div>
        ) : null
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  cars: state.car.cars,
  fetching: state.car.fetching,
  removedCars: state.websockets.removedCars,
  dealershipName: state.auth?.user?.dealership_name,
  dealershipNameAr: state.auth?.user?.dealership_name_ar,
  credits: state.dealership.credits,
  runnerAccount: state.auth?.user?.runner_account,
});

const mapDispatchToProps = (dispatch) => ({
  listingRequest(payload) {
    dispatch(listingRequest(payload));
  },
  getCarsList(payload) {
    dispatch(getCarsList(payload));
  },
  updateCarForm(payload) {
    dispatch(updateCarForm(payload));
  },
  getCarForms(payload) {
    dispatch(getCarForms(payload));
  },
  getFields(payload) {
    dispatch(getFields(payload));
  },
  clearRemovedCars(payload) {
    dispatch(clearRemovedCars(payload));
  },
  updatePageTimeStamp(payload) {
    dispatch(updatePageTimeStamp(payload));
  },
  clearNewCars() {
    dispatch(clearNewCars());
  },
  getDealerCredits(payload) {
    dispatch(getDealerCredits(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarListings)
);
