import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

const TextInput = (props) => {
  const { tag, onChange, field, answers } = props;

  const handleChange = (event) => {
    if (tag) {
      onChange(event, {
        // score: event.target.value,
        text: answers[field?.id]?.text,
        field: field,
      });
      // onChange(event, { text: event.target.value, field: field });
    } else {
      onChange(event, { text: event.target.value, field: field });
    }
  };

  return (
    <FormControl fullWidth>
      <TextField {...props} fullWidth onChange={handleChange} />
    </FormControl>
  );
};

export default TextInput;
