import React from "react";

function Unlocked(props) {
  const { size } = props;
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3125 9.54687H7.545V5.37113C7.545 3.5295 9.09408 2.03125 10.9981 2.03125C12.9022 2.03125 14.4512 3.5295 14.4512 5.37113V7.3125H16.4825V5.37113C16.4825 2.40947 14.0222 0 10.9981 0C7.97405 0 5.51375 2.40947 5.51375 5.37113V9.54687H3.6875C2.00745 9.54687 0.640625 10.9137 0.640625 12.5937V22.9531C0.640625 24.6332 2.00745 26 3.6875 26H18.3125C19.9925 26 21.3594 24.6332 21.3594 22.9531V12.5937C21.3594 10.9137 19.9925 9.54687 18.3125 9.54687ZM19.3281 22.9531C19.3281 23.5131 18.8725 23.9687 18.3125 23.9687H3.6875C3.12748 23.9687 2.67187 23.5131 2.67187 22.9531V12.5937C2.67187 12.0337 3.12748 11.5781 3.6875 11.5781H18.3125C18.8725 11.5781 19.3281 12.0337 19.3281 12.5937V22.9531Z"
        fill="#37B44A"
      />
    </svg>
  );
}

export default Unlocked;
