import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { receivedOrHistory } from "../../store/actions/dealership";
import { getMessagesInfo } from "../../store/actions/dealership";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "72%",
      margin: "0 260px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "25px",
    },
  },

  mainDiv: {
    backgroundColor: "white",
  },

  cardsGrid: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "270px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  requestsCntContainer: {
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  requestsCnt: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "254px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  inline: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  item: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  gridDiv: {
    border: "1px solid #CBC6C6",
    margin: "15px",
    padding: "12px",
    maxWidth: "328px",
    background: "#F9FAFD",
  },
  typoPadding: {
    padding: "20px 60px 20px 68px",
  },
  buttonsDiv: {
    display: "flex",
    justifyContent: "space-around",
  },
}));
function WhatsappMessages(props) {
  const classes = useStyles();
  const { user, messages_info, receivedOrHistory, getMessagesInfo } = props;
  const { t } = useTranslation();
  useEffect(() => {
    getMessagesInfo({ dealership_id: user.dealership_id });
  }, [receivedOrHistory]);
  return (
    <div>
      <div className={classes.requestsCntContainer}>
        <div className={classes.requestsCnt}>
          <Typography className={classes.typoPadding}>
            {messages_info?.length} {t("Messages")}
          </Typography>
        </div>
      </div>
      <div className={classes.container}>
        <Grid container>
          {messages_info?.map((el, index) => (
            <Grid item sm={12} md={6} lg={4} className={classes.item}>
              <div className={classes.gridDiv}>
                <Typography>
                  {" "}
                  {"#" +
                    el.car +
                    " | " +
                    el?.car_info?.make +
                    "\r\n" +
                    el?.car_info?.model +
                    "\r\n" +
                    el?.car_info?.year}
                </Typography>
                <Typography>
                  {el.user_info?.name + " | " + el?.user_info?.phone}
                </Typography>
                <div className={classes.buttonsDiv}>
                  <Button
                    onClick={() => {
                      receivedOrHistory({
                        id: el.id,
                        dealership_id: el.dealership_id,
                        user_id: el.user_id,
                        car: el.car,
                        key: "received",
                        callback: (response) => {
                          if (response.status == "success") {
                            getMessagesInfo({
                              dealership_id: el.dealership_id,
                            });
                          }
                        },
                      });
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {t("Received")}
                  </Button>
                  <Button
                    onClick={() => {
                      receivedOrHistory({
                        id: el.id,
                        dealership_id: el.dealership_id,
                        user_id: el.user_id,
                        car: el.car,
                        key: "history",
                        callback: (response) => {
                          if (response.status == "success") {
                            getMessagesInfo({
                              dealership_id: el.dealership_id,
                            });
                          }
                        },
                      });
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    {t("Not received")}
                  </Button>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  messages_info: state.dealership.messages_info?.filter(
    (word) => word.history == false
  ),
});

const mapDispatchToProps = (dispatch) => ({
  receivedOrHistory(payload) {
    dispatch(receivedOrHistory(payload));
  },
  getMessagesInfo(payload) {
    dispatch(getMessagesInfo(payload));
  },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WhatsappMessages)
);
