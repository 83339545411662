import React from "react";

function Remove() {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5312 3.25H15.0625V2.4375C15.0625 1.09347 13.969 0 12.625 0H9.375C8.03097 0 6.9375 1.09347 6.9375 2.4375V3.25H2.46875C1.34872 3.25 0.4375 4.16122 0.4375 5.28125V8.125C0.4375 8.5737 0.801297 8.9375 1.25 8.9375H1.69403L2.39598 23.6784C2.45798 24.9803 3.52744 26 4.83069 26H17.1693C18.4726 26 19.5421 24.9803 19.604 23.6784L20.306 8.9375H20.75C21.1987 8.9375 21.5625 8.5737 21.5625 8.125V5.28125C21.5625 4.16122 20.6513 3.25 19.5312 3.25ZM8.5625 2.4375C8.5625 1.98951 8.92701 1.625 9.375 1.625H12.625C13.073 1.625 13.4375 1.98951 13.4375 2.4375V3.25H8.5625V2.4375ZM2.0625 5.28125C2.0625 5.05725 2.24475 4.875 2.46875 4.875H19.5312C19.7552 4.875 19.9375 5.05725 19.9375 5.28125V7.3125C19.6871 7.3125 3.10011 7.3125 2.0625 7.3125V5.28125ZM17.9808 23.6011C17.9602 24.0351 17.6037 24.375 17.1693 24.375H4.83069C4.39625 24.375 4.03977 24.0351 4.01915 23.6011L3.32086 8.9375H18.6791L17.9808 23.6011Z"
        fill="#186985"
      />
      <path
        d="M11 22.75C11.4487 22.75 11.8125 22.3862 11.8125 21.9375V11.375C11.8125 10.9263 11.4487 10.5625 11 10.5625C10.5513 10.5625 10.1875 10.9263 10.1875 11.375V21.9375C10.1875 22.3862 10.5512 22.75 11 22.75Z"
        fill="#186985"
      />
      <path
        d="M15.0625 22.75C15.5112 22.75 15.875 22.3862 15.875 21.9375V11.375C15.875 10.9263 15.5112 10.5625 15.0625 10.5625C14.6138 10.5625 14.25 10.9263 14.25 11.375V21.9375C14.25 22.3862 14.6137 22.75 15.0625 22.75Z"
        fill="#186985"
      />
      <path
        d="M6.9375 22.75C7.3862 22.75 7.75 22.3862 7.75 21.9375V11.375C7.75 10.9263 7.3862 10.5625 6.9375 10.5625C6.4888 10.5625 6.125 10.9263 6.125 11.375V21.9375C6.125 22.3862 6.48875 22.75 6.9375 22.75Z"
        fill="#186985"
      />
    </svg>
  );
}

export default Remove;
