import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  //   checkUniquePhone,
  sendOTP,
  validateOTP,
  //   signUpNewUser,
  getToken,
} from "../../store/actions/auth";
import SignUpTabs from "./SignUpTabs";
import PassWordVerify from "./Password";
// import AccountInfo from "../wrappers/Signeup/AccountInfo";
import { removePhoneZero, validate } from "../../helpers/functions";
import { connect } from "react-redux";
import OTPVerification from "./OTPVerification";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { withRouter } from "react-router";
import { Typography, Button, FormControl, Hidden } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "react-phone-number-input/style.css";
import MarkabtePhone from "../../components/MarkabtePhone";

const useStyles = makeStyles((theme) => ({
  typographyDivs: {
    display: "flex",
  },
  inputSpacing: {
    margin: "14px 0", /////
    height: "46px", /////
    borderRadius: "8.67px",
    boxShadow: "0px 2.20309px 2.67182px rgba(130, 148, 154, 0.4)",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      margin: "12px 0",
    },
  },
  termsFirstTypography: {
    flexWrap: "wrap",
    marginTop: "14px",

    fontSize: "14px",
    color: "rgba(80, 79, 79, 0.8)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      marginTop: "14px",
    },
  },
  termsSecondTypography: {
    display: "contents",
    fontSize: "14px",
    fontWeight: "600",
    color: "#186985",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  signUpButton: {
    textTransform: "none",
    margin: "8px 0 24px 0",
    fontSize: "18px",
    borderRadius: "8.67px",
    width: "28.542vw",
    fontWeight: localStorage.getItem("language") === "en" ? "500" : "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      margin: "8px auto 20px",
      left: "50%",
      borderRadius: "5px", //check
      marginLeft: "-38vw",
      width: "74vw",
    },
  },
  haveAccountFirstTypography: {
    color: "#07303E",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  haveAccountSecondTypography: {
    display: "contents",

    fontWeight: "600",
    color: "#186985",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      // fontWeight: "500", //check
      fontWeight: localStorage.getItem("language") === "en" ? "500" : "600", //check
    },
  },
  paperContainer: {
    height: "50%",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  bottomImg: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  circleDiv: {
    height: "130vh",
    borderRadius: "0% 100rem 100rem 0%", // problematic at high heights and low widths
    // borderRadius: "0% 50% 50% 0%",  // not as circular
    backgroundColor: "white",
    position: "relative",
    transform: "translate(0, -15vh)",
    maxWidth: "46.11%",
    flexBasis: "46.11%",
    padding: "15vh 0 15vh 7.013vw",
  },
  formControlRoot: {
    // borderRadius: "8.67px",
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      maxHeight: "40px",
    },
  },
  inputBaseRoot: {
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      maxHeight: "40px",
      backgroundColor: "white",
    },
  },
  inputLableOutlined: {
    transform: "translate(20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      // transform: "translate(16px, 2.4vh) scale(1)",
      transform: `translate(16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  inputLableOutlinedArabic: {
    transform: "translate(-20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      // transform: "translate(16px, 2.4vh) scale(1)",
      transform: `translate(-16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  logo: {
    marginLeft: "-17px",
  },
  sendLinkButton: {
    fontSize: "clamp(14px,1.46vw,18px)",
    fontWeight: "500",
    textTransform: "none",
    padding: "9px clamp(25px,3.13vw,45px)",
    // borderRadius: "8.67px",
    borderRadius: "5px",
    lineHeight: "1.5",
    boxShadow: "0px 2px 5px rgba(9, 38, 48, 0.4)",
    // marginTop: "6.82vh",
    width: "28.542vw",
    minWidth: "335.55px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(12px,3.89vw,14px)",
      padding: "8px 25px",
      marginTop: "0",
      width: "auto",
      minWidth: "auto",
    },
  },
  toSignInButton: {
    fontSize: "clamp(14px,1.46vw,18px)",
    fontWeight: "500",
    textTransform: "none",
    // borderRadius: "8.67px",
    borderRadius: "5px",
    lineHeight: "1.5",
    // marginTop: "6.82vh",
    marginTop: "4.74vh",
    width: "max-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(12px,3.89vw,14px)",
      padding: "0",
      marginTop: "0",
    },
  },
  mainContentDiv: {
    // paddingTop: "14.08vh",
    paddingTop: "5.7vh",
    justifyContent: "left",
  },
  toSignInButton: {
    fontSize: "clamp(14px,1.46vw,18px)",
    fontWeight: "500",
    textTransform: "none",
    // borderRadius: "8.67px",
    borderRadius: "5px",
    lineHeight: "1.5",
    // marginTop: "6.82vh",
    marginTop: "1.74vh",
    width: "max-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(12px,3.89vw,14px)",
      padding: "0",
      marginTop: "0",
      left: "8vw",
    },
  },
}));

const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  password: "",
  confirm: "",
};

function ResetSwitcher(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { sendOTP, validateOTP, getToken, history } = props;
  const [isunique, setIsunique] = useState({ phone: false });
  const [form, setForm] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [firstTabSelected, setFirstTabSelected] = useState(true);
  const prevPage = props?.location?.state?.prevPage ?? "/signin"; // check
  const [phone, setPhone] = useState("");

  const convertPhone = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    validate({ [name]: t(value) }, form, errors, setErrors);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    var vad = validate(undefined, form, errors, setErrors);
    // if ( isunique.phone) {
    sendOTP({
      phone: phone,
      callback: (response) => {
        console.log("aaaaaaaaa", response);
      },
    });
    return true;
    // }
    return false;
  };
  const handleVerified = (otp) => {
    window.location.replace("/request_reset/" + otp + "/" + phone + "/");
  };

  useEffect(() => {
    if (phone?.[4] == "0") {
      let phoneNumber = removePhoneZero(phone);
      setPhone(phoneNumber);
    }
    if (phone?.length < 4 || phone == undefined) {
      setPhone("+962");
    }
  }, [phone]);

  return (
    <SignUpTabs
      firstTabSelected={firstTabSelected}
      isunique={isunique}
      firstTab={{
        title: props.tabs == undefined ? t("Forgot Your Password?") : null,
        render: (
          <>
            <div style={{ height: "100%" }}>
              <Hidden smDown>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      // marginBottom: "8.3vh",
                      marginBottom: "4.74vh",
                    }}
                  >
                    {t(
                      "Please enter your phone and we will send you a reset link."
                    )}
                  </Typography>
                  <form>
                    <MarkabtePhone
                      value={form.phone_number} //value
                      onChange={(e) => {
                        setPhone(convertPhone("phone", e).target.value);
                      }}
                    />
                  </form>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid
                  item
                  style={{
                    margin: "0 9.722vw",
                    position: "relative",
                    zIndex: 10,
                  }}
                  xs={12}
                >
                  <Typography
                    variant={"h3"}
                    style={{
                      fontWeight: "700",
                      // margin: "5.54vh 0 0",
                      fontSize: "22px",
                      lineHeight: "1.5",
                    }}
                  >
                    {t("Forgot Your Password?")}
                  </Typography>
                  <Typography
                    style={{
                      marginBottom: "4.96vh",
                      fontSize: "14px",
                    }}
                  >
                    {t(
                      "Please enter your phone and we will send you a reset link."
                    )}
                  </Typography>
                  <form>
                    <MarkabtePhone
                      value={form.phone_number}
                      onChange={(e) => {
                        setPhone(convertPhone("phone", e).target.value);
                      }}
                    />
                  </form>
                </Grid>
              </Hidden>
            </div>
            <Button
              className={classes.signUpButton}
              //   fullWidth
              variant="contained"
              color="primary"
              onClick={(event) => {
                if (handleSubmit(event)) {
                  setFirstTabSelected(false);
                }
              }}
            >
              {t("Reset password")}
            </Button>
            <FormControl
              fullWidth
              style={{
                boxShadow:
                  "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4) inline",
                marginBottom: "3em",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                className={classes.toSignInButton}
                type="submit"
                children={t("Back to Sign In")}
                variant="text"
                color={"primary"}
                onClick={() => {
                  history.push("/login");
                }}
              />
            </FormControl>
            {/* </div> */}
          </>
        ),
      }}
      secondTab={{
        title: t("Verify Your Number"),
        render: (
          <OTPVerification
            phoneNumber={phone}
            editMobileNumber={setFirstTabSelected}
            handleOTPVerified={handleVerified}
            // setOtpStatus
            // errorText={errorText}
            // codeError={codeError}
          />
        ),
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  isAuthenticating: state.auth.isAuthenticating,
});

const mapDispatchToProps = (dispatch) => ({
  sendOTP(payload) {
    dispatch(sendOTP(payload));
  },
  validateOTP(payload) {
    dispatch(validateOTP(payload));
  },
  getToken(credentials) {
    dispatch(getToken(credentials));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetSwitcher)
);
