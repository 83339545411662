import { put, takeLatest } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  FETCH_CAR_REQUEST,
  FETCH_CAR_REQUEST_SUCCEED,
  FETCH_CAR_REQUEST_FAILURE,
  FETCH_CAR_FORMS_REQUEST,
  FETCH_CAR_FORMS_REQUEST_SUCCEED,
  FETCH_CAR_FORMS_REQUEST_FAILURE,
  FETCH_CAR_FAULTS_REQUEST,
  FETCH_CAR_FAULTS_REQUEST_SUCCEED,
  FETCH_CAR_FAULTS_REQUEST_FAILURE,
  FETCH_CAR_VISUALIZATION_REQUEST,
  FETCH_CAR_VISUALIZATION_REQUEST_FAILURE,
  FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
  LIST_CARS_REQUEST,
  LIST_CARS_REQUEST_SUCCEED,
  LIST_CARS_REQUEST_FAILURE,
  FETCH_CAR_DETAILS_REQUEST,
  FETCH_CAR_DETAILS_REQUEST_SUCCEED,
  FETCH_CAR_DETAILS_REQUEST_FAILURE,
  FETCH_CAR_GROUPS_REQUEST,
  FETCH_CAR_GROUPS_REQUEST_SUCCEED,
  FETCH_CAR_GROUPS_REQUEST_FAILURE,
  TOGGLE_NOTIFICATION_BAR,
  UPDATE_CAR_SALES_REQUEST,
  UPDATE_CAR_SALES_REQUEST_SUCCEED,
  UPDATE_CAR_SALES_REQUEST_FAILURE,
  UPDATE_CAR_FORM_REQUEST,
  UPDATE_CAR_FORM_REQUEST_SUCCEED,
  UPDATE_CAR_FORM_REQUEST_FAILURE,
  RESERVE_CAR,
  RESERVE_CAR_FAILURE,
  RESERVE_CAR_SUCCESS,
  RESERVE_CAR_PURCHASE_STATUS,
  RESERVE_CAR_PURCHASE_STATUS_FAILURE,
  RESERVE_CAR_PURCHASE_STATUS_SUCCESS,
  UPLOAD_LICENSE_IMAGES,
  UPLOAD_LICENSE_IMAGES_FAILURE,
  UPLOAD_LICENSE_IMAGES_SUCCESS,
  FETCH_CAR_GROUPS_REQUEST_SCORING_FAILURE,
  FETCH_CAR_GROUPS_SCORING_REQUEST,
  SELLER_CAR_GENERATE,
  SELLER_CAR_GENERATE_FAILURE,
  SELLER_CAR_GENERATE_SUCCEED,
} from "../constants";

import API_URLS from "../../api";
export function* getCar(action) {
  try {
    let data = {
      url: API_URLS(action.payload.id).CAR.DETAILS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarWatcher() {
  yield takeLatest(FETCH_CAR_REQUEST, getCar);
}

export function* getCarList(action) {
  try {
    let data = {
      url: API_URLS(action.payload.filter).CAR.LIST,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: LIST_CARS_REQUEST_SUCCEED, payload: response.results });
  } catch (e) {
    yield put({ type: LIST_CARS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarListWatcher() {
  yield takeLatest(LIST_CARS_REQUEST, getCarList);
}

export function* getCarDetail(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).CAR.PAGE,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_DETAILS_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_DETAILS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarDetailsWatcher() {
  yield takeLatest(FETCH_CAR_DETAILS_REQUEST, getCarDetail);
}

export function* getCarGroups(action) {
  try {
    const query = action.payload.carId + "/?" + action.payload.query;
    const endPoint = action.payload.endPoint;
    let data = {
      // url: API_URLS(query).CAR.CAR_GROUPED_FIELDS,
      url: API_URLS(query).CAR[endPoint],
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_GROUPS_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_GROUPS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarGroupsWatcher() {
  yield takeLatest(FETCH_CAR_GROUPS_REQUEST, getCarGroups);
}

export function* getCarGroupsScoring(action) {
  try {
    const query = action.payload.carId + "/?" + action.payload.query;
    let data = {
      url: API_URLS(query).CAR.CAR_GROUPED_SCORING_FIELDS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    // yield put({ type: FETCH_CAR_GROUPS_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: FETCH_CAR_GROUPS_REQUEST_SCORING_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getCarGroupsScoringWatcher() {
  yield takeLatest(FETCH_CAR_GROUPS_SCORING_REQUEST, getCarGroupsScoring);
}

export function* updateCarSale(action) {
  try {
    let data = {
      url: API_URLS(`${action.payload.car_id}`).CAR.CAR_SALE,
      method: "POST",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: UPDATE_CAR_SALES_REQUEST_SUCCEED,
      payload: response.results,
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car sale status successfully updated",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({ type: UPDATE_CAR_SALES_REQUEST_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* updateCarSaleWatcher() {
  yield takeLatest(UPDATE_CAR_SALES_REQUEST, updateCarSale);
}

export function* getCarPriceForm(action) {
  try {
    const { formId, carId } = action.payload;
    let data = {
      url: API_URLS(`?car_id=${carId}&form=${formId}`).CAR.CAR_FORMS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_FORMS_REQUEST_SUCCEED,
      payload: response.results,
    });
  } catch (e) {
    yield put({ type: FETCH_CAR_FORMS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarPriceFormWatcher() {
  yield takeLatest(FETCH_CAR_FORMS_REQUEST, getCarPriceForm);
}

export function* updateCarPriceForm(action) {
  try {
    let data = {
      url: API_URLS(action.payload.id).CAR.UPDATE_FORM,
      method: "POST",
      body: action.payload.body,
    };
    const response = yield requestAPI(data);
    yield put({ type: UPDATE_CAR_FORM_REQUEST_SUCCEED, payload: response });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Your changes have been saved",
        variant: "success",
        open: true,
      },
    });
    action.payload.callback();
  } catch (e) {
    yield put({ type: UPDATE_CAR_FORM_REQUEST_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* updateCarPriceFormWatcher() {
  yield takeLatest(UPDATE_CAR_FORM_REQUEST, updateCarPriceForm);
}
export function* getCarVisualization(action) {
  try {
    const { query } = action.payload;
    let data = {
      url: API_URLS(query).CAR.CAR_VISUALIZATION,
      method: "GET",
    };
    const response = yield requestAPI(data);
    let points = {};
    for (let i = 0; i < response.results.length; i++) {
      points[response.results[i].field.side] = response.results[i];
    }
    let first = action.payload.first;

    yield put({
      type: FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
      payload: { points: points, first: first },
    });
    action.payload.callback(points);
  } catch (e) {
    yield put({
      type: FETCH_CAR_VISUALIZATION_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getCarVisualizationWatcher() {
  yield takeLatest(FETCH_CAR_VISUALIZATION_REQUEST, getCarVisualization);
}

export function* getCarFaults(action) {
  try {
    const { carId, side, position } = action.payload;
    let data = {
      url: API_URLS(
        `car_id=${carId}&field__side=${side}&field__position=${position}`
      ).CAR.CAR_FAULTS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_FAULTS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({ type: FETCH_CAR_FAULTS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarFaultsWatcher() {
  yield takeLatest(FETCH_CAR_FAULTS_REQUEST, getCarFaults);
}

export function* reserveCar(payload) {
  try {
    let data = {
      url: API_URLS().CAR.RESERVE_CAR,
      method: "POST",
      body: payload.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: RESERVE_CAR_SUCCESS,
      payload: response.results,
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car State Changed Successfully",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({ type: RESERVE_CAR_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car is already reserved",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* reserveCarWatcher() {
  yield takeLatest(RESERVE_CAR, reserveCar);
}
//////////////////////////
export function* reservedCarPurchaseStatus(payload) {
  try {
    let data = {
      url: API_URLS(`${payload.payload.reservationId}`).CAR
        .RESERVED_CAR_BOUGHT_UPDATE,
      method: "PATCH",
      body: { bought: payload.payload.bought },
    };
    const response = yield requestAPI(data);

    yield put({
      type: RESERVE_CAR_PURCHASE_STATUS_SUCCESS,
      payload: response.results,
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car State Changed Successfully",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({
      type: RESERVE_CAR_PURCHASE_STATUS_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car is already reserved",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* reservedCarPurchaseStatusWatcher() {
  yield takeLatest(RESERVE_CAR_PURCHASE_STATUS, reservedCarPurchaseStatus);
}

export function* uploadLicenseImages(payload) {
  try {
    let data = {
      url: API_URLS().CAR.UPLOAD_LICENSE_IMAGES,
      method: "POST",
      body: payload.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: UPLOAD_LICENSE_IMAGES_SUCCESS,
      payload: response.results,
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car State Changed Successfully",
        variant: "success",
        open: true,
      },
    });
    payload.payload.callback();
  } catch (e) {
    yield put({
      type: UPLOAD_LICENSE_IMAGES_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car is already reserved",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* uploadLicenseImagesWatcher() {
  yield takeLatest(UPLOAD_LICENSE_IMAGES, uploadLicenseImages);
}

export function* generateSellerCar(payload) {
  try {
    let data = {
      url: API_URLS().CAR.SELLER_CAR_GENERATE,
      method: "POST",
      body: payload.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: SELLER_CAR_GENERATE_SUCCEED,
    });
    payload.payload.callback(response);
  } catch (e) {
    yield put({
      type: SELLER_CAR_GENERATE_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* generateSellerCarWatcher() {
  yield takeLatest(SELLER_CAR_GENERATE, generateSellerCar);
}
