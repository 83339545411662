import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { moveToHistory } from "../../store/actions/dealership";
import Locked from "../icons/Locked";
import Remove from "../icons/Remove";
import Unlocked from "../icons/Unlocked";
import CallReuestCard from "./CallReuestCard";
import HistoryCallRequestesIcon from "../icons/HistoryCallRequestesIcon";
const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: "607px",

      width: "72%",
      margin: "0 260px",
    },
    [theme.breakpoints.up("lg")]: {
      // width: "689px", //// try 750
      // width: "750px", //// try 750
      padding: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      // width: "85vw",
      padding: "25px",
    },
  },

  mainDiv: {
    backgroundColor: "white",
  },

  cardsGrid: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "270px",
    },
    [theme.breakpoints.down("sm")]: {
      /* remove if fails menue card results*/ display: "flex",
      justifyContent: "center",
    },
  },
  requestsCntContainer: {
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  requestsCnt: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "254px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  item: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  inline: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));
function CallBackRequests(props) {
  const classes = useStyles();
  const { isAuthenticated, user, calls_info, moveToHistory } = props;
  const [calls, setCalls] = useState();
  useEffect(() => {
    setCalls(calls_info?.filter((call) => !call.calledback));
  }, [calls_info]);

  const { t } = useTranslation();
  return (
    <div>
      <div className={classes.requestsCntContainer}>
        <div className={classes.requestsCnt}>
          <div className={classes.inline}>
            <Typography style={{ padding: "20px 60px 20px 68px" }}>
              {calls?.length} {t("Requests")}
            </Typography>
            <div
              className={classes.inline}
              style={{ width: "fit-content", padding: "20px 20px 20px  20px" }}
            >
              <span style={{ padding: "0px 10px" }}>
                <HistoryCallRequestesIcon />
              </span>
              <Button
                variant="text"
                color="primary"
                href="/requests-history"
                style={{ padding: "0" }}
              >
                {t("History")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <Hidden mdUp>{renderMdDownDrawer()}</Hidden> */}
      <div className={classes.container}>
        <Grid container>
          {calls?.map((el) => (
            <Grid item sm={12} md={6} lg={4} className={classes.item}>
              <CallReuestCard
                car={
                  el?.car_info?.make +
                  " " +
                  el?.car_info?.model +
                  " " +
                  el?.car_info?.year
                }
                id={el.car}
                phone={el.car_info?.phone}
                username={el.user_info?.name}
                icon={el?.is_unlocked > 0 ? <Unlocked /> : <Locked />}
                date={el?.date}
              >
                <div className={classes.inline} style={{ margin: "20px 0 0" }}>
                  <Button
                    onClick={() => {
                      moveToHistory({
                        dealership_id: el.dealership_id,
                        user_id: el.user_id,
                        car: el.car,
                      });
                    }}
                  >
                    <Remove />
                  </Button>
                  <Hidden mdUp>
                    <Button
                      href={`Tel:${el.user_info.phone}`}
                      fullWidth
                      color="primary"
                      variant="contained"
                    >
                      {t("Call Back")}
                    </Button>
                  </Hidden>
                  <Hidden smDown>
                    <Typography>{el.user_info.phone}</Typography>
                  </Hidden>
                </div>
              </CallReuestCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  calls_info: state.dealership.calls_info,
});

const mapDispatchToProps = (dispatch) => ({
  moveToHistory(payload) {
    dispatch(moveToHistory(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CallBackRequests)
);

// export default CallBackRequests;
