import { combineReducers } from "redux";
import auth from "./reducer-definitions/auth";
import notifications from "./reducer-definitions/notification";
import car from "./reducer-definitions/car";
import forms from "./reducer-definitions/forms";
import websockets from "./reducer-definitions/websockets";
// import appointment from "./reducer-definitions/appointment";
import dealership from "./reducer-definitions/dealership";

export default combineReducers({
  auth: auth,
  car: car,
  notifications: notifications,
  forms: forms,
  websockets: websockets,
  // appointment: appointment,
  dealership: dealership,
});
