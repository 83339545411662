import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";

import { getOptions } from "../store/actions/forms";
import AutocompleteComponent from "./AutocompleteComponent";

const useStyles = makeStyles((theme) => ({
  detailsSubSetDiv: {
    marginBottom: "16px",
  },
  detailsSubSetTitle: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#07303E",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  textFieldsDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  textField: {
    position: "relative",
    marginBottom: "16px",
    minWidth: "100%",
    flexBasis: "100%",
  },
  halfText: {
    minWidth: "calc(50% - 5px)",
    flexBasis: "calc(50% - 5px)",
  },
  hr: {
    width: "100%",
    margin: "15px 0",
    height: "1px",
    border: "none",
    color: "#C4C4C4",
    backgroundColor: "rgb(177, 177, 177)",
    [theme.breakpoints.up("xl")]: {
      margin: "10px 0 24px",
    },
  },
}));

const DealerListingRequestDetails = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { index, setRequestData, getOptions } = props;

  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);

  const [form, setForm] = useState({
    make: null,
    model: null,
    year: null,
  });

  useEffect(() => {
    getOptions({
      query: "227",
      callback: (result) => {
        setMakeOptions(result[0].options);
      },
    });
    getOptions({
      query: "230",
      callback: (result) => {
        setYearOptions(result[0].options);
      },
    });
  }, []);

  useEffect(() => {
    setRequestData((prev) => {
      let tempForm = { ...form };
      let tempReqData = [...prev];
      Object.keys(tempForm).map((key) => {
        tempForm[key] = tempForm[key]?.id ?? null;
      });
      tempReqData[index] = tempForm;
      return tempReqData;
    });
    return () => {
      setRequestData((prev) => {
        let tempReqData = [...prev];
        tempReqData.splice(index, 1);
        return tempReqData;
      });
    };
  }, [form]);

  const handleChange = (event, value, field) => {
    let tempForm = { ...form };
    tempForm[field] = value;
    if (field == "make") {
      tempForm["model"] = null;
      getOptions({
        query: `228&make=${value?.id}`,
        callback: (result) => {
          setModelOptions(result[0].options);
        },
      });
      setForm(tempForm);
    }
  };

  return (
    <div className={classes.detailsSubSetDiv}>
      <Typography className={classes.detailsSubSetTitle}>
        {`${t("Car")} ${index + 1}`}
      </Typography>
      <div className={classes.textFieldsDiv}>
        <AutocompleteComponent
          options={makeOptions}
          label={`${t("Make")} *`}
          onChange={(event, value) => {
            handleChange(event, value, "make");
          }}
          disabled={makeOptions.length == 0}
          value={form.make}
          setSelectedValues={setForm}
          field={"make"}
          className={clsx({ [classes.textField]: 1, [classes.halfText]: 1 })}
        />
        <AutocompleteComponent
          options={modelOptions}
          label={`${t("Model")} *`}
          onChange={(event, value) => {
            handleChange(event, value, "model");
          }}
          disabled={modelOptions.length == 0}
          value={form.model}
          setSelectedValues={setForm}
          field={"model"}
          className={clsx({ [classes.textField]: 1, [classes.halfText]: 1 })}
        />
        <AutocompleteComponent
          options={yearOptions}
          label={`${t("Year")} *`}
          onChange={(event, value) => {
            handleChange(event, value, "year");
          }}
          disabled={yearOptions.length == 0}
          value={form.year}
          setSelectedValues={setForm}
          field={"year"}
          className={classes.textField}
        />
      </div>
      <hr className={classes.hr} />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getOptions(payload) {
    dispatch(getOptions(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealerListingRequestDetails);
