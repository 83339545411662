import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import plus2 from "../icons/plus2.svg";
import minus from "../icons/minus.svg";
import DealerListingRequestDetails from "./DealerListingRequestDetails";
import RunnerListingRequestDetails from "./RunnerListingRequestDetails";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    height: "100%",
  },
  dialogPaper: {
    borderRadius: "5px",
    padding: "15px",
    width: "400px",
    margin: 0,
    maxHeight: "calc(100vh  - 165px)",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 19px",
      width: "100vw",
      maxWidth: "100%",
      height: "100%",
      height: "calc(100% - 64px)",
      // height: "100%",
      maxHeight: `100%`,
      justifyContent: "space-between",
    },
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  accountBalanceDiv: {
    height: "fit-content",
    alignSelf: "center",
    marginRight: "25px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  dialogContentText: {
    padding: "0",
    alignSelf: "center",
    width: "100%",
    overflowY: "unset",
    flex: "initial",
  },
  buttonsDiv: {
    display: "flex",
    justifyContent: "center",
  },
  dialogButtons2: {
    height: "57px",
    width: "57px",
    minWidth: "57px",
    borderRadius: "50%",
    marginRight: "15px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  dialogButtons22: {
    height: "57px",
    width: "57px",
    minWidth: "57px",
    borderRadius: "50%",
    marginLeft: "15px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  field: {
    width: "230.71px",
    height: "73.88px",
    [theme.breakpoints.down("md")]: {
      width: "178px",
      height: "57px",
      "-webkit-text-fill-color": "#186985",
    },
  },
  input: {
    padding: "27.44px 14px",
    [theme.breakpoints.down("md")]: {
      padding: "19px 14px",
    },
  },
  hr: {
    width: "100%",
    margin: "7.77px 0 18.66px",
    height: "1px",
    border: "none",
    color: "#C4C4C4",
    backgroundColor: "rgb(177, 177, 177)",
    [theme.breakpoints.up("xl")]: {
      margin: "10px 0 24px",
    },
  },
  dialogActions: {
    justifyContent: "space-between",
    padding: 0,
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    width: "50%",
  },
  detailsDiv: {
    maxHeight: "calc(100vh - 300px)",
    overflow: "scroll",
    flex: "1 1 auto",
  },
  test: {
    border: "1px solid black",
    height: "150px",
  },
  ///////////////////////////////
  titleDiv: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #D8D8D8",
    marginBottom: "25px",
  },
  title: {
    padding: "8px 0",
    fontWeight: 600,
    fontSize: "18px",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  iconButton: {
    padding: "8px",
    marginRight: "-8px",
    color: "#07303E",
  },
  runnerDialogActions: {
    // width: "calc(100% - 20px)",
    position: "sticky",
    bottom: "-15px",
    padding: "10px 19px",
    left: "-19px",
    boxShadow: "0px -2px 4px rgba(24, 105, 133, 0.25)",
    margin: "0 -15px",
    backgroundColor: "white",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      margin: "0 -19px",
      bottom: "-32px",
    },
  },
  submitButton: {
    textTransform: "none",
  },
}));

const RunnerDialogContent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { handleConfirm, handleClose, setRequestData, requestData } = props;
  return (
    <>
      <div className={classes.titleDiv}>
        <Typography className={classes.title}>
          {t("Listing Request Details")}
        </Typography>
        <IconButton className={classes.iconButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>
      <RunnerListingRequestDetails setRequestData={setRequestData} />
      <DialogActions className={classes.runnerDialogActions}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          disabled={requestData.some((element) => {
            if (
              element.area == null ||
              element.area == "" ||
              element.city == null ||
              element.day == null ||
              element.gps_location == null ||
              element.gps_location == "" ||
              element.make == null ||
              element.model == null ||
              element.owner_name == null ||
              element.owner_name == "" ||
              element.phone_number.length < 13 ||
              element.time == null ||
              element.year == null
            ) {
              return true;
            }

            return false;
          })}
          onClick={handleConfirm}
          className={classes.submitButton}
        >
          {t("Submit Listing")}
        </Button>
      </DialogActions>
    </>
  );
};

const DealerDialogContent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    plusDisabled,
    setEntries,
    entries,
    setRequestData,
    handleClose,
    handleConfirm,
    requestData,
    credits,
    minusDisabled,
    handleChange,
  } = props;
  return (
    <>
      <DialogTitle
        id="form-dialog-title"
        style={{
          display: "inline",
          padding: "0",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              height: "fit-content",
              alignSelf: "center",
              paddingRight: "5px",
            }}
          >
            <Typography
              style={{
                fontSize: "40px",
                color: "#186985",
                lineHeight: "60px",
                fontWeight: 700,
              }}
            >
              {credits}
            </Typography>
          </div>
          <div className={classes.accountBalanceDiv}>
            <Typography style={{ fontSize: "13px", color: "#6B8495" }}>
              {t("Account Balance")}
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                color: "#186985",
                fontWeight: 700,
              }}
            >
              {t("Credits")}
            </Typography>
          </div>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentText }}>
        <div className={classes.buttonsDiv}>
          <Button
            color="primary"
            variant="contained"
            className={classes.dialogButtons2}
            disabled={minusDisabled}
            onClick={() => {
              setEntries(entries - 1);
            }}
          >
            <img src={minus} />
          </Button>
          <TextField
            id="name"
            variant={"outlined"}
            onChange={handleChange}
            className={classes.field}
            inputProps={{
              classes: { input: classes.input },
              style: {
                color: "#0C6885",
                userSelect: "none",
                textAlign: "center",
                fontSize: "40px",
                padding: 0,
                height: "57px",
                fontWeight: "600",
              },
            }}
            value={entries}
            disabled
          />
          <Button
            color="primary"
            variant="contained"
            className={classes.dialogButtons22}
            disabled={plusDisabled}
            onClick={() => {
              setEntries(entries + 1);
            }}
          >
            <img src={plus2} />
          </Button>
        </div>
        <hr className={classes.hr} />
      </DialogContent>
      <div className={classes.detailsDiv}>
        {Array.from(Array(entries), (e, i) => {
          return (
            <DealerListingRequestDetails
              index={i}
              setRequestData={setRequestData}
            />
          );
        })}
      </div>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          className={classes.dialogButtons}
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          className={classes.dialogButtons}
          disabled={requestData.some((element) => {
            if (Object.values(element).includes(null)) {
              return true;
            }

            return false;
          })}
        >
          {t("Send Request")}
        </Button>
      </DialogActions>
    </>
  );
};

const RequestListingsDialog = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    setEntries,
    entries,
    openDialog,
    handleClose,
    credits,
    minusDisabled,
    handleChange,
    plusDisabled,
    handleConfirm,
    setRequestData,
    requestData,
    runnerAccount,
  } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullScreen
      classes={{
        paper: classes.dialogPaper,
        root: classes.dialogRoot,
        container: classes.dialogContainer,
      }}
    >
      {!runnerAccount ? (
        <DealerDialogContent
          plusDisabled={plusDisabled}
          setEntries={setEntries}
          entries={entries}
          setRequestData={setRequestData}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          requestData={requestData}
          credits={credits}
          minusDisabled={minusDisabled}
          handleChange={handleChange}
        />
      ) : (
        <RunnerDialogContent
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          setRequestData={setRequestData}
          requestData={requestData}
        />
      )}
    </Dialog>
  );
};

export default RequestListingsDialog;
