import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@material-ui/icons";
import {
  AppBar,
  Dialog,
  Hidden,
  IconButton,
  Paper,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  optionHr: {
    width: "calc(100% + 32px)",
    transform: "translate(-16px,0)",
    margin: "15px 0 0",
    height: "1px",
    border: "none",
    backgroundColor: "#BFD6DE",
    [theme.breakpoints.up("md")]: {
      margin: "7.375px 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "8.355px 0 0",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px 0 0",
    },
  },
  webListBox: {
    padding: 0,
    scrollbarColor: "#186985 #BFD6DE",
    "&::-webkit-scrollbar": {
      width: "11px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#BFD6DE",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#186985",
    },
  },
  listPaper: {
    margin: 0,
    borderRadius: "0 0 5px 5px",
  },
  listPopper: {
    margin: 0,
    boxShadow: "0 0 0 1px #676767",
    padding: "54px 0 0",
    borderRadius: "8px",
    borderRadius: " 0 0 8px 8px",
    top: "137px",
    padding: "0",
    boxShadow: "none",
    top: "44px",
    [theme.breakpoints.up("md")]: {
      top: "34px",
    },
    [theme.breakpoints.up("lg")]: {
      top: "38px",
    },
    [theme.breakpoints.up("xl")]: {
      top: "45px",
    },
  },
  webOption: {
    paddingTop: "15px",
    paddingBottom: "0",
    [theme.breakpoints.up("md")]: {
      paddingTop: "7.375px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "8.355px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "10px",
    },
  },
  webOptionTypography: {
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  },
  mobTextRoot: {
    [theme.breakpoints.down("sm")]: {
      position: "inherit",
      height: "42px",
      justifyContent: "end",
      width: "100%",
    },
  },
  inputLableOutlined: {
    color: "#07303E",
    transform: "translate(14px, 13.5px) scale(1)",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      transform: "translate(15px, 27px) scale(1)",
      position: "inherit",
      width: "fit-content",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
      transform: "translate(14px, 11.5px) scale(1)",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13.5px",
      transform: "translate(14px, 14.5px) scale(1)",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
      transform: "translate(14px, 13.5px) scale(1)",
    },
  },
  shrink: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(15px, 5px) scale(0.75)!important",
    },
  },
  mobTextFieldInput: {
    padding: "9.5px 4px 9.5px 6px",
    lineHeight: 0,
    fontSize: "14px",
    color: "#07303E",
  },
  autocompleteInput: {
    [theme.breakpoints.down("sm")]: {
      lineHeight: 0,
      paddingTop: theme.direction === "rtl" ? "3.375px!important" : "",
      paddingRight: theme.direction === "rtl" ? "4px!important" : "",
      paddingBottom: theme.direction === "rtl" ? "7.375px!important" : "",
      paddingLeft: theme.direction === "rtl" ? "6px!important" : "",
      fontSize: "14px",
      color: "#07303E",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "7.375px!important",
      paddingRight: "4px!important",
      paddingBottom: "7.375px!important",
      paddingLeft: "6px!important",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "11.5px!important",
      paddingRight: "4px!important",
      paddingBottom: "11.5px!important",
      paddingLeft: "6px!important",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "13px!important",
      paddingRight: "4px!important",
      paddingBottom: "13px!important",
      paddingLeft: "6px!important",
    },
  },
  outlinedInputRoot: {
    boxShadow: "0px 2.16329px 2.49462px rgba(130, 148, 154, 0.3)", //////
    borderRadius: "5px",
    backgroundColor: "white",
    padding: "3px 32px 3px 14px!important",
    [theme.breakpoints.down("sm")]: {
      height: "42px",
      paddingRight: "0!important",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "0!important", // had to seperate them because arabic turns "0 32px 0 9.09px!important" to "0 9.09px!important 0 32px" which is invalid value
      paddingRight: "32px!important",
      paddingBottom: "0!important",
      paddingLeft: "9.09px!important",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "0!important",
      paddingRight: "32px!important",
      paddingBottom: "0!important",
      paddingLeft: "10.12px!important",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "0!important",
      paddingRight: "32px!important",
      paddingBottom: "0!important",
      paddingLeft: "12px!important",
    },
  },
  outlinedInputRootFocused: {
    borderRadius: "5px 5px 0 0",
  },
  formControl: {},
  listOpenTypography: {
    fontSize: "14px",
    marginTop: "13px",
    padding: "0 16px",
  },
  optionHrListOpen: {
    width: "100%",
    margin: "13px 0 0",
    transform: "translateX(0)",
  },
  inputLableOutlinedlistOpen: {
    position: "relative",
    transform: "translate(22px, 25px) scale(1)",
  },
  shrinkListOpen: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(22px, 7px) scale(0.75)!important",
    },
  },
  formControlListOpen: {
    margin: "0px",
    width: "100%", //?
    width: "calc(100% - 32px)",
    padding: "16px",
  },
  mobListBox: {
    maxHeight: "calc(100vh - 150px)",
    minHeight: "calc(100vh - 150px)",
    padding: "0",
  },
  mobPaper: {
    margin: "5px 0",
    boxShadow: "unset",
  },
  mobPopper: {
    transform: "translate3d(0px, 0px, 0px) !important", /// wrong depends on scroll
    marginTop: "20px",
    width: "100vw !important",
  },
  mobOption: {
    padding: "0",
    minHeight: 0,
  },
  appBar: {
    backgroundColor: "white",
    color: "#07303E",
    boxShadow: "0px 2px 4px rgba(15, 71, 90, 0.2)",
  },
  title: {
    margin: "auto",
    transform: "translate(-18px,0)",
  },
}));

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});

const AutocompleteField = (props) => {
  const {
    options,
    label,
    onChange,
    setOpen,
    listOpen,
    value,
    setSelectedValues,
    field,
    className,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [listOpened, setListOpened] = useState(false);

  return (
    // stylesClsxConditions = {
    //  ListOpen: Mob inner autocomplete,
    //  !ListOpen: Mob/ Web outer autocomplete,
    // }
    <div className={className}>
      <Autocomplete
        closeIcon=""
        autoHighlight={true}
        // loading={true}
        disablePortal={true}
        options={options}
        PopperComponent={"Popper"}
        popupIcon={<ExpandMore />}
        open={listOpen}
        // open={field == "make" ? true : false} // for testing popper not accurate
        value={value}
        getOptionLabel={(option) => option.title}
        renderOption={(option, state) => (
          <div style={{ width: "100%" }}>
            <Typography
              className={clsx({
                [classes.listOpenTypography]: listOpen,
                [classes.webOptionTypography]: 1,
              })}
            >
              {option.title}
            </Typography>
            <hr
              className={clsx({
                [classes.optionHr]: 1,
                [classes.optionHrListOpen]: listOpen,
              })}
            />
          </div>
        )}
        onChange={(event, value) => {
          onChange(event, value);
          let fieldObj = {};
          fieldObj[`${field}`] = value;
          setSelectedValues((prev) => Object.assign({}, prev, fieldObj));
          setOpen(false);
        }}
        onOpen={() => {
          setListOpened(true);
        }}
        onClose={() => {
          setListOpened(false);
        }}
        disabled={options.length < 1}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            classes={{ root: clsx({ [classes.mobTextRoot]: 1 }) }} /////
            onClick={(event) => {
              setOpen(options.length < 1 ? false : true);
            }}
            {...params}
            label={label}
            variant="outlined"
            InputLabelProps={{
              classes: {
                outlined: clsx({
                  [classes.inputLableOutlined]: 1,
                  [classes.inputLableOutlinedlistOpen]: listOpen,
                }),
                shrink: clsx({
                  [classes.shrink]: 1,
                  [classes.shrinkListOpen]: listOpen,
                }),
              },
            }}
            InputProps={
              listOpen
                ? {
                    ...params.InputProps,
                    startAdornment: (
                      <SearchIcon
                        style={
                          localStorage.getItem("language") === "ar"
                            ? {
                                transform: "rotateY(180deg)",
                              }
                            : {}
                        }
                      />
                    ),
                  }
                : {
                    ...params.InputProps,
                  }
            }
          />
        )}
        classes={{
          input: clsx({
            [classes.autocompleteInput]: 1,
          }),
          inputRoot: clsx({
            [classes.outlinedInputRoot]: 1,
            [classes.outlinedInputRootFocused]: listOpened, //this is right
          }),
          root: clsx({
            [classes.formControl]: 1,
            [classes.formControlListOpen]: listOpen,
          }),
          listbox: clsx({
            [classes.mobListBox]: listOpen,
            [classes.webListBox]: !listOpen,
          }),
          paper: clsx({
            [classes.mobPaper]: listOpen,
            [classes.listPaper]: !listOpen,
          }),
          popper: clsx({
            [classes.mobPopper]: listOpen,
            [classes.listPopper]: !listOpen,
          }),
          option: clsx({
            [classes.mobOption]: listOpen,
            [classes.webOption]: !listOpen,
          }),
        }}
      />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogComponent = (props) => {
  const { mobAutocompleteProps, open, handleClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Hidden mdUp>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ paddingTop: "56px" }}
      >
        <Paper style={{ padding: "16px 0px", height: "100%" }}>
          {" "}
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon
                  fontSize={"10px"}
                  style={{ width: "18.5px", height: "18.5px" }}
                />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t(mobAutocompleteProps.label)}
              </Typography>
            </Toolbar>
          </AppBar>
          <AutocompleteField
            {...mobAutocompleteProps}
            listOpen={true}
            PopperComponent="Popper"
          />
        </Paper>
      </Dialog>
    </Hidden>
  );
};

const AutocompleteComponent = (props) => {
  const {
    id,
    options,
    label,
    onChange,
    // setOpen,
    // setMobAutocompleteProps,
    PopperComponent,
    listOpen,
    value,
    setSelectedValues,
    field,
    className,
  } = props;
  const classes = useStyles();
  const textFieldRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [mobAutocompleteProps, setMobAutocompleteProps] = useState({
    id: id,
    options: options,
    label: label,
    onChange: onChange,
    setOpen: setOpen,
    PopperComponent: PopperComponent,
    listOpen: listOpen,
    value: value,
    setSelectedValues: setSelectedValues,
    field: field,
    className: className,
  });

  // useEffect(() => {
  //   setMobAutocompleteProps((prev) => {
  //     prev.options = options;
  //     return prev;
  //   });
  // }, [options]);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.blur();
    }
  }, [open]);

  useEffect(() => {
    console.log("ddddddddddddddd333", mobAutocompleteProps);
  }, [mobAutocompleteProps]);
  useEffect(() => {
    setMobAutocompleteProps({
      id: id,
      options: options,
      label: label,
      onChange: onChange,
      setOpen: setOpen,
      PopperComponent: PopperComponent,
      listOpen: listOpen,
      value: value,
      setSelectedValues: setSelectedValues,
      field: field,
      className: className,
    });
  }, [
    id,
    options,
    label,
    onChange,
    setOpen,
    PopperComponent,
    listOpen,
    value,
    setSelectedValues,
    field,
    className,
  ]);
  console.log("dddddddddddddddd22", value);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Hidden smDown>
        <AutocompleteField {...mobAutocompleteProps} />
      </Hidden>
      <Hidden mdUp>
        <div className={className}>
          <TextField
            value={value?.title}
            label={label}
            variant="outlined"
            disabled={options.length < 1}
            onClick={() => {
              setOpen(true);
            }}
            InputLabelProps={{
              classes: {
                outlined: clsx({
                  [classes.inputLableOutlined]: 1,
                  [classes.inputLableOutlinedlistOpen]: listOpen,
                }),
                shrink: clsx({
                  [classes.shrink]: 1,
                  [classes.shrinkListOpen]: listOpen,
                }),
              },
              shrink: value?.title,
            }}
            classes={{
              root: clsx({
                [classes.mobTextRoot]: 1,
              }),
            }}
            InputProps={{
              classes: {
                input: clsx({
                  [classes.mobTextFieldInput]: 1,
                }),
                root: clsx({
                  [classes.outlinedInputRoot]: 1,
                }),
              },
            }}
            inputProps={{
              ref: textFieldRef,
            }}
          />
        </div>
      </Hidden>
      <DialogComponent
        open={open}
        handleClose={handleClose}
        mobAutocompleteProps={mobAutocompleteProps}
      />
    </>
  );
};
AutocompleteComponent.defaultProps = {
  options: [],
  label: "label",
  onChange: () => {},
  setOpen: () => {},
  // setMobAutocompleteProps:,
  value: 1,
  setSelectedValues: () => {},
  field: "field",
};

AutocompleteComponent.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  setOpen: PropTypes.func,
  // setMobAutocompleteProps:PropTypes.,
  value: PropTypes.number,
  setSelectedValues: PropTypes.func,
  field: PropTypes.string,
};

export default AutocompleteComponent;
