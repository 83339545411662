import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MarkabtePopup from "../MarkabtePopup";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";

import useTheme from "@material-ui/core/styles/useTheme";
import useGeneralStyle from "../GeneralStyles";
import { setCarStatus } from "../../store/actions/dealership";

const CarStatus = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useGeneralStyle();
  const {
    open,
    setOpen,
    setCarStatus,
    carId,
    unlocked: global_unlock,
    published_for_buyers,
  } = props;
  const [unlocked, setUnlocked] = useState(global_unlock); //flage does files editable. stars with false
  const [published, setPublished] = useState(published_for_buyers); //flage does files editable. stars with false

  const handleSubmit = (carId) => {
    setCarStatus({
      carId: carId,
      unlocked: unlocked,
      published: published,
    });
  };

  const toggle1 = (event) => {
    setPublished((prevCheck) => !prevCheck);
  };
  const toggle2 = (event) => {
    setUnlocked((prevCheck) => !prevCheck);
  };

  return (
    <MarkabtePopup
      height="max-content"
      title={t("Car Status")}
      setOpen={setOpen}
      open={open}
      withdivider={true}
      dir={localStorage.getItem("language") == "ar" ? "rtl" : "ltr"}
      onExited={() => {
        document.getElementById("root").style.filter = "none";
        setPublished(published_for_buyers);
        setUnlocked(global_unlock);
      }}
    >
      <div style={{ padding: "0 10px" }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={published} onChange={toggle1} />}
            label="Published for buyers"
          />
          <FormControlLabel
            control={<Checkbox checked={unlocked} onChange={toggle2} />}
            label="Global unlocked"
          />
        </FormGroup>
        <Button
          className={classes.innerCardButton}
          style={{
            justifyContent: "center",
            width: "100%",
            marginBottom: "20px",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit(carId);
            setOpen(false);
          }}
          fullWidth
        >
          {t("Save Changes")}
        </Button>
      </div>
    </MarkabtePopup>
  );
};

const mapStateToProps = (state) => ({
  unlockedState: state?.car?.carStatus?.global_unlocked,
  publishedForSellerState: state?.car?.carStatus?.published_for_seller,
});
const mapDispatchToProps = (dispatch) => ({
  setCarStatus(payload) {
    dispatch(setCarStatus(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarStatus)
);
