import React, { useEffect, useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { getCarGroups, getCar } from "../store/actions/car";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Visualisation from "./CosmeticConditionProto";
import BackArrowTail from "../components/icons/BackArrowTail";
import BackArrowIcon from "../components/icons/BackArrow.js";
import BackArrow from "../components/icons/BackArrow";
import Exterior from "../icons/Exterior.svg";
import Interior from "../icons/interior.svg";
import NotIncluded from "../icons/NotIncluded.svg";
import Tires from "../icons/tires.svg";
import Frame from "../icons/Frame.svg"; //will be added when i know the name for it in admin panel
import Dashboard from "../icons/OBD.svg"; //will be added when i know the name for it in admin panel
import Switches from "../icons/Controls.svg";
import BodyPaint from "../icons/BodyPaint.svg";
import Equipment from "../icons/equipment.svg";
import AcHeater from "../icons/AcHeater.svg";
import FaultyAnswer from "../icons/faultyPoint.svg";
import UnFaultyAnswer from "../icons/unFaultyPoint.svg";
import InformativeAnswer from "../icons/infoPoint.svg";
import Information from "../icons/Information.svg";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import Hidden from "@material-ui/core/Hidden";
import Tour from "./Tour";
import Navbord from "../containers/Dashboard";
// import HeartCircleIcon from "../components/icons/HeartCircleIcon";
// import ShareEllipseIcon from "../components/icons/ShareEllipseIcon";

import MobVisClickAr from "../components/icons/MobVisClickAr";
import ClickableAnswerDialog from "../components/ClickableAnswerDialog";
import FaultyDetails from "./computerscan/FaultDetails";
import {
  getCarFaults,
  getCarVisualization,
  updateCarSale,
} from "../store/actions/car";

import { useTranslation } from "react-i18next";
import { numberWithCommas, decrypt } from "../helpers/functions";
// import ReactGa from "react-ga";
import CardIterator from "./computerscan/CardIterator";
import MarkabtePopup from "../components/MarkabtePopup";
import FrameFaultsLis from "../components/inspection/FrameFaultsLis";
import Ev from "../icons/Ev.svg";
import MobVisClickEn from "../components/icons/visGifIcon.gif";
import HybridBattery from "../icons/HybridBattery.svg";
import FaultsStepper from "../components/FaultsStepper";
import InspectionReportMobile from "../components/inspectionReportMobile";
import BodyPaintResult from "../components/BodyPaintResult";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  navigationPaper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: "39px",
      backgroundColor: "#186985",
    },
  },
  fancyNavigationPaper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: "56px",
      backgroundColor: "#186985",
      justifyContent: "space-between",
    },
  },
  reportIcons: {
    height: "15px",
    width: "15px",
    margin: "0 5px 0 0",
  },
  noOfPoints: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#07303E",
    marginRight: "3px",
  },
  table: {
    width: "100%",
    fontSize: "1rem",
    backgroundColor: "#BFD6DE",
    borderCollapse: "collapse",
    display: "table",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.up("sm")]: { margin: "0 auto 20px" },
  },
  tablehead: {
    padding: "5px 10px",
    fontWeight: "700",
    fontSize: "1rem",
    backgroundColor: "#BFD6DE", // experimental
  },
  firstitem: {
    padding: "5px 0 5px 8px",
    fontWeight: "700",
    backgroundColor: "white",
    width: "55%",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: { padding: "0 0 0 8px" },
  },
  seconditem: {
    padding: "5px 13px",
    borderLeft: "1.33333px solid #C4C4C4",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: { padding: "2.5px 13px" },
  },
  thirditem: { marginLeft: "auto" },
  row: {
    borderBottom:
      "1px solid radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%)",
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "0 0 15px",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "clamp(20px,2vw,24px)",
      margin: "34px 0 26px 24px",
    },
  },
  headerVisualization: {
    whiteSpace: "nowrap",
    color: "#186985",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "16px 0 16px",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      margin: "0 25px 13px 0",
    },
  },
  headerVisualizationPaper: {
    borderRadius: 0,
    background: "#FFFFFF",
    boxSizing: "border-box",
    boxShadow: "none",

    [theme.breakpoints.down("sm")]: {
      // padding: "0px 0px 16px",
      padding: "0 10px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "34px 0px",
    },
  },
  visualizationImageStyle: {
    width: "100%",
    objectFit: "cover",
    height: "auto",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    position: "relative",
    filter: "blur(5px) brightness(50%)",
    borderRadius: "2px",
  },
  visualizationImageDivStyle: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(#00000000 13%, #000000cc)",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    borderRadius: "8.27px",
  },
  visualizationIconStyle: {
    width: "clamp(40px,4vw,50px)",
    transform: "translate(-50%,0)",
    position: "relative",
    left: "50%",
  },
  noteTypographyMedium: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#07303E",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      margin: "13px 0 24px 0",
    },
  },
  noteTypographyBold: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
  inspDetText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#9A9A9A",
    fontSize: "10px",
  },
  inspDetSubText: {
    color: "#07303E",
    fontSize: "10px",
    fontWeight: "500",
  },
  Link: {
    fontSize: "18px",
    color: "#707678",
    cursor: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "line-below",
    },
  },
  groupScore: {
    marginLeft: "auto",
    fontSize: "clamp(1rem,1.5vw,1.125rem)",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: { fontSize: "14px" },
  },
  groupIcon: {
    marginRight: "16px",
    height: "30px",
    width: "30px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "10px",
      height: "20px",
      width: "20px",
    },
  },
  exteriorGroupIcon: {
    marginRight: "16px",
    height: "32px",
    width: "32px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "10px",
      height: "20px",
      width: "20px",
    },
  },
  subGroupText: {
    fontSize: "1rem",
    fontWeight: "500",
    padding: "10px 16px",
    textAlign: "left",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "8px 0 10px 12px",
    },
  },
  subGroupScoreText: {
    fontSize: "1rem",
    fontWeight: "500",
    padding: "5px 10px",
    textAlign: "right",
  },
  navigationDiv: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
  },
  reportGrid: {
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
    },
  },
  reportPaper: {
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      all: "initial",
    },
    [theme.breakpoints.up("md")]: {
      boxShadow: "none",
      // margin: "50px 17px 0 4px", // page margin mismatch but matches design
    },
  },
  smPapers: {
    borderRadius: "0",
    boxShadow: "0px 2px 4px rgba(15, 71, 90, 0.4)",
    margin: "15px 0",
    padding: "15px",
    [theme.breakpoints.up("md")]: {
      all: "initial",
    },
  },
  firstGridItem: {},
  expansionPanelSummaryContent: {
    margin: "10px 0",
  },
  expansionPanelSummaryRoot: {
    padding: "0 12px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
    "& .MuiExpansionPanelSummary-expandIcon": {
      [theme.breakpoints.down("sm")]: {
        transform: "rotate(-90deg)",
      },
    },
  },
  expansionPanelDetails: {
    height: "min-content",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      width: "100%",
      // display: "inline-block",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 12px",
    },
  },

  column: {
    columnCount: "2",
    columnWidth: "349px", // decrease to get columns to break faster but long text will wrap more
    columnGap: "0px",
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
  },
  visualizationDiv: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      // height: "28vw",
      // height: "calc(56.5vw - 342.67px)", // calculated to keep the image at the same height to width ratio,
      // changing width of drawer ,or action bar grid item ,or first grid item's margin or padding will cause sizing problems
      height: "min(28vw,calc(56.5vw - 342.67px))",
      maxWidth: "734px", // for very big screens
      maxHeight: "419px", // for very big screens
      margin: "auto",
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  visualizationView: {
    position: "absolute",
    transformOrigin: "top left",
  },
  visualizationHr: {
    height: "1px",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 -20px 20px",
    },
  },
  fullReportHr: {
    margin: "0 -20px 9px",
    height: "1px",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  rowHr: {
    height: "2px",
    border: "none",
    margin: "6px 0",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.down("sm")]: {
      height: "1px",
    },
  },
  reportFilter: {
    color: "#07303E",
    fontSize: "18px",
    fontWeight: "500",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "56px 0px 28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      color: "#07303E",
      margin: "4px 0px 16px",
      marginRight: "12px",
    },
  },
  groupTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: { fontSize: "14px" },
  },
  groupDisclaimer: {
    textAlign: "center",
    width: "764px",
    margin: "auto",
    fontSize: "16px",
    paddingTop: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      // padding: "0 10px 10px 10px",
      width: "auto",
    },
  },
  disclaimerDiv: {
    margin: "20px 0px 38px 0px",
    background: "#F9FAFF",
    padding: "14px 11px 32px 11px",
  },
  actionBarRelative: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 90px)",
      position: "fixed",
      top: 0,
      transformOrigin: "top left",
      left: "45px",
      zIndex: 10,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
      height: "100%",
      position: "relative",
    },
  },
  actionBarPaper: {
    [theme.breakpoints.down("sm")]: {
      // padding: "8px 16px",
      margin: "0 -45px",
      filter: "drop-shadow(0px -2px 4px rgba(15, 71, 90, 0.4))",
      borderRadius: 0,
    },
    [theme.breakpoints.up("md")]: {
      padding: "43px 30px 0",
      position: "sticky",
      // top: "50px",// this is for not full paper looks better image problem
      top: "0",
      height: "81vh", //check on other screen sizes and try calc(87.35vh - 50px)
    },
  },
  discriptionGridContainer: {
    margin: "auto",
    width: "80%",
    maxWidth: "max-content",
    [theme.breakpoints.down("xs")]: {
      // width: "90%",
      width: "100%",
    },
  },
  discriptionText: {
    fontSize: "14px",
    margin: "0 9px 0 4px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  discriptionIcon: {
    width: "21px",
    height: "21px",
    margin: "0 0 0 9px",
    [theme.breakpoints.down("sm")]: {
      width: "17.5px",
      height: "17.5px",
      margin: "0 0 0 6px",
    },
  },
  tooltip: {
    backgroundColor: "white",
    color: "rgb(99,99,99)",
    border: "1px solid #000000",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    fontSize: "12px",
    padding: "15px",
    maxWidth: "327px",
    borderRadius: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      padding: "8px",
      maxWidth: "218px",
    },
  },
  tooltipArrow: {
    "&::before": {
      backgroundColor: "white",
      border: "1px solid #000000",
      width: "95%",
    },
  },
  infoIcon: {
    width: "18px",
    height: "18px",
    margin: "auto 4px",
    [theme.breakpoints.down("sm")]: {
      width: "15px",
      height: "15px",
    },
  },
  firstItemTypography: {
    fontSize: "14px",
    fontWeight: "500",
    paddingLeft: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  answerText: {
    fontSize: "14px",
    fontWeight: "400",
    fontColor: "#636363",
  },
  button: {
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    padding: "10px 48px",
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      padding: "8.94px 34.21px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
      padding: "11.5px 43px",
    },
  },
  featuredImageDiv: {
    //div added around image to hide image and image broken borders
    overflow: "hidden",
    width: "calc(56.76px - 2px)",
    height: "calc(43.54px - 2px)",
    borderRadius: "3.8874px",
    flexBasis: "calc(22% - 2px)",
    minWidth: "calc(22% - 2px)",
    padding: "0 10px 0 0",
    objectFit: "cover",
  },
  featuredImage: {
    borderRadius: "3.8874px",
    transform: "translate(-50%, -50%)",
    position: "relative",
    top: "50%",
    left: "50%",
    width: "auto",
    height: "100%",
  },
  navBarRelative: {
    position: "fixed",
    top: 0,
    // transformOrigin: "bottom left",
    zIndex: 10000,
    width: "100%",
    left: 0,
    display: "block",
    // alignContent: "center",
    // opacity: 1,
    // transition: "opacity 10ms ease-in",
    // transition: "transform 0s",
    transition: "opacity 1s,visibility .6s",
    // transform: "translateY(100%)",
  },
  navBarRelativeHidden: {
    opacity: "0",
    visibility: "hidden",
    transition: "opacity 0s,visibility .5s",
  },
  navbartitle: {
    fontSize: "13px",
    fontWeight: "600",
    color: "white",
    margin: "auto",
  },
  cardPriceTypo: {
    fontSize: "12px",
    fontWeight: "500",
    color: "white",
    margin: "auto",
  },
  visualizationHr: {
    border: "none",
    height: "1px",
    transform: "translate(-16px, 25px)",
    width: "calc(100% + 32px)",
    background:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
  },
  clickableAnswer: {
    textDecoration: "underline",
    color: "#00BDFF",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
  },
  tableInfoDiv: {
    backgroundColor: "#EAECF0",
    marginInline: "12px",
    borderRadius: "5px",
    marginBottom: "36px",
    [theme.breakpoints.down("sm")]: {
      marginInline: "12px",
      borderRadius: "5px",
      marginBottom: "26px",
      padding: "0 15px",
    },
  },
  groupNote: {
    margin: "0 0 34px 12px",
    color: "#636363",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "0",
    },
  },
  secondNote: {
    marginTop: "-24px",
  },
  visMobIconText: {
    position: "absolute",
    bottom: "17px",
    left: "50%",
    marginLeft: "-100px",
    width: "200px",
    textAlign: "center",
    fontFamily: "Poppins",
    color: "#fff",
  },
  visMobIcon: {
    position: "absolute",
    bottom: "27px",
    left: "50%",
    marginLeft: "-36.5px",
    width: "73px",
  },
  visMobShap: {
    position: "absolute",
    top: "0",
    left: localStorage.getItem("language") === "en" && 0,
    right: localStorage.getItem("language") === "ar" && 0,
    width: "101px",
    height: "28px",
    clipPath: "polygon(0 0, 100% 0%, 74% 100%, 0% 100%)",
    background: "#07303E",
    color: "#fff",
    width: "120px",
    height: "30px",
    textAlign: "center",
    fontFamily: "Poppins",
    lineHeight: "3",
    fontSize: "10px",
  },
  subGroupExpantionPanel: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
  },
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  dialogPaperWidthSm: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "0",
    },
  },
  inspectionNotesDialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inspectionNotesDialogContentText: {
    textAlign: "center",
    fontSize: "14px",
    padding: "0 0 22px",
    margin: 0,
    color: "black",
  },
  firstinspectionTypo: {
    textAlign: "left",
    color: "#07303E",
    fontSize: "18px",
    fontWeight: "500",
  },
  inspectionNotesSpan: {
    color: "#07303E",
    fontSize: "20px",
    fontWeight: "600",
  },
}));

function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

// switch component for inspection points with faults only this is the markup and styles only(logic is in main component)
// const IOSSwitch = withStyles((theme) => ({
//   root: {
//     width: 42,
//     height: 26,
//     padding: 0,
//     margin: "56px 16px 28px",
//     [theme.breakpoints.down("sm")]: {
//       margin: "0",
//     },
//   },
//   switchBase: {
//     padding: 1,
//     "&$checked": {
//       transform: "translateX(16px)",
//       color: theme.palette.common.white,
//       "& + $track": {
//         backgroundColor: "#0c6885",
//         opacity: 1,
//         border: "none",
//       },
//     },
//     "&$focusVisible $thumb": {
//       color: "#52d869",
//       border: "6px solid #fff",
//     },
//   },
//   thumb: {
//     width: 24,
//     height: 24,
//   },
//   track: {
//     borderRadius: 26 / 2,
//     border: "none",
//     backgroundColor: "#DBDBDB",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color", "border"]),
//     height: "auto",
//   },
//   checked: {},
//   focusVisible: {},
// }))(({ classes, ...props }) => {
//   return (
//     <Switch
//       focusVisibleClassName={classes.focusVisible}
//       disableRipple
//       classes={{
//         root: classes.root,
//         switchBase: classes.switchBase,
//         thumb: classes.thumb,
//         track: classes.track,
//         checked: classes.checked,
//       }}
//       {...props}
//     />
//   );
// });

// const nth = (day) => {
//   if (day > 3 && day < 21) return "th";
//   switch (day % 10) {
//     case 1:
//       return day + "st";
//     case 2:
//       return day + "nd";
//     case 3:
//       return day + "rd";
//     default:
//       return day + "th";
//   }
// };

const InspectionNotesDialog = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const {
    open,
    handleClose,
    handelPublishCar,
    inspectionNotes,
    setOpenInspection,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.dialogPaper,
        root: classes.dialogRoot,
        paperWidthSm: classes.dialogPaperWidthSm,
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography={true}
        className={classes.inspectionNotesDialogTitle}
      >
        <Typography style={{ fontSize: "24px", fontWeight: 500 }}>
          {t("Notes")}
        </Typography>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={() => setOpenInspection(false)}
        />
      </DialogTitle>
      {inspectionNotes?.map((item, index) => {
        return (
          <DialogContent classes={{ root: classes.dialogContentText }}>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.inspectionNotesDialogContentText}
            >
              <Typography className={classes.firstinspectionTypo}>
                <span className={classes.inspectionNotesSpan}>
                  {t("Note")} {index + 1}:{" "}
                </span>{" "}
                {t(item)}
              </Typography>
            </DialogContentText>
          </DialogContent>
        );
      })}
    </Dialog>
  );
};

const InspectionReport = (props) => {
  const { t, i18n } = useTranslation();
  let mmRegex = new RegExp(/[0-9]+.*[0-9]*\smm+/);
  const classes = useStyles();
  const carId = decrypt(props.match.params.id);
  const {
    history,
    date,
    enteredBy,
    getCarGroups,
    getCar,
    carList,
    getCarFaults,
    getCarVisualization,
    points,
    publishCar,
    hideAppBar,
    role,
    showAppBar,
    detailsLoadingCar,
    handleCallback,
    carInfo,
    carDetailsPopup,
    setCarDetailsPopup,
  } = props;
  const ref = useRef(null);
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" }); // from react responsive node module
  const visualizationImage = points?.dfv?.image;
  const [carData, setCarData] = useState({});
  const [openVisualization, setOpenVisualization] = useState(false); // a state to open and close the bigger image view on Big screens
  const [isSmallDevice, setIsSmallDevice] = useState(true); //a stste describing the size of the screen true:mobile or tablet, false:big tablet or pc
  const [visualisationPath, setVisualizationPath] = useState(""); // a state containing the path that the link on the visualization icon directs to small screen: to cosmetic condition page, big screen: do not redirect
  const [openTooltip, setOpenTooltip] = useState(false);
  const [tooltipButtonId, setTooltipButtonId] = useState(0);
  const backgroundRef = useRef(); // reference to the div node that contains the bigger image and stepper in the Big screen, used for exiting the "dialog" bach to the normal vehicle detail page
  const [imgLoaded, setImgLoaded] = useState(false);
  const [hideNav, setHideNav] = useState(true);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [imageDialogProps, setImageDialogProps] = useState({});
  const [showfaults, setshowfaults] = useState(false);
  const [faults, setfaults] = useState({});
  const [frameFault, setframeFault] = useState({});
  const [frameFaultopen, setframeFaultopen] = useState(false);
  const [faultOpen, setfaultOpen] = useState(false);
  const [openInspection, setOpenInspection] = useState(false);
  const [openInspectionDtc, setOpenInspectionDtc] = useState(false);
  const [inspectionNotes, setInspectionNotes] = useState({});
  const [interiorFaults, setinteriorFaults] = useState({
    images: [],
    score: 0,
  });
  const navRef = useRef();
  const faultscnt = (flts) => {
    // console.log(flts,'xxxxxxxxxxxxxxxxxxxxxxxx')
    var fields = Array.isArray(flts) ? flts : flts.fields ? flts.fields : flts;
    var fltscnt = 0;
    for (var i = 0; i < fields.length; i++) {
      fltscnt += fields[i].extended_answers.length;
    }
    return fltscnt;
  };

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (navRef.current) {
        if (window.scrollY <= 357) setHideNav(true);
        else if (window.scrollY > 357 || this.window.scrollY < 0)
          setHideNav(false);
      }
    });
  }, [navRef]);
  const showInteriorFaults = (data) => {
    setfaultOpen(true);
    setinteriorFaults({
      images: data.images,
      option_score: data.score,
      issue: data.issue,
      detailedFaultsWithImages: data.detailedFaultsWithImages,
    });
  };
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = (event) => {
    setOpenTooltip(true);
    setTooltipButtonId(event.currentTarget.id);
  };

  const infoSpecialCase = (fieldId) => {
    let specialCaseIds = [
      "59",
      "61",
      "264",
      "66",
      "68",
      "265",
      "73",
      "75",
      "266",
      "80",
      "82",
      "267",
    ]; // 3 fields for each tire change to backend later
    return specialCaseIds.includes(fieldId);
  };

  const fieldTypes = {
    for_details_report: 1,
  };

  const cars = {};
  for (var i = 0; i <= carList.length - 1; i++) {
    cars[carList[i].id] = carList[i];
  }

  const [loadingCar, setLoadingCar] = useState(true);
  const score = ["100", "70", "65", "25", "45", "33", "51"];
  const { groups } = carData;
  // const carInfo = carData;
  const [switchState, setSwitchState] = useState(false); //switchState will later be used to render faulty points only
  var pointCount = 0;
  var noComments = 1; //to render No comments! text incase of no comments
  const [noOfPoints, setNoOfPoints] = useState(0); // a state tha hold the counted number of points to cause a rerender
  // const [doneCounting, setDoneCounting] = useState(false);
  const [infoStyle, setInfoStyle] = useState("flex");
  const dateObject = new Date(date); //to convert the date text into an object to extract day month and year
  // let counterIsDone = false;
  let allFields = [];
  let allOptions = [];
  let optionsCount = 1;
  const tireAgeIds = ["59", "66", "73", "80"]; //Tire age ids
  // use this instead of [3, 3, 3] in skelStructure
  // function* Iterator(num) {
  //   let iteratorArray = [];
  //   for (let i = 0; i <= num; i++) {
  //     iteratorArray.push(i);
  //   }
  //   return iteratorArray;
  // }

  const skelStructure = [
    // defines how many tables and fields are in the skeleton
    // Iterator(5),
    [5, 5, 5, 5, 5],
    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
    [3, 3, 3],
    [4, 4, 4, 4],
    [6, 6, 6, 6, 6, 6, 7],
  ];
  const DetInspRepIcons = {
    //an object holding the group name as a key and the icon as the value
    6: Switches,
    7: Exterior,
    8: Equipment,
    9: Tires,
    19: Ev,
    10: Interior,
    15: Frame,
    16: Dashboard,
    17: BodyPaint,
    18: AcHeater,
    20: HybridBattery,
    21: HybridBattery,
  };
  const DetInspRepDiscription = {
    6: {
      text: t(
        "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Working") },
        { icon: FaultyAnswer, represents: t("Not Working") },
        { icon: Information, represents: t("Additional Information") },
      ],
    }, // Switches,
    7: {
      text: t(
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Excellent") },
        { icon: InformativeAnswer, represents: t("Minor Issue") },
        { icon: FaultyAnswer, represents: t("Major Issue") },
      ],
      note: t(
        "* We do not record any body issue that is smaller than 0.2 (cm)."
      ),
      secondNote: t(
        "* We do not take images of light scratches on the car's Exterior"
      ),
    }, // Exterior,
    19: {
      text: t(
        "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Good") },
        { icon: Information, represents: t("Moderate") },
        { icon: FaultyAnswer, represents: t("Poor") },
      ],
      note: t("* We do not check if the battery was replaced or repaired."),
    }, //ev
    20: {
      text: t(
        "We test the battery under load by evaluating cell resistance & voltage values."
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("No Notes") },
        // { icon: Information, represents: t("Moderate") },
        { icon: FaultyAnswer, represents: t("Replace Battery") },
      ],
      note: t("* We do not check if the battery was replaced or repaired."),
    }, //hybrid battery nickle
    21: {
      text: t(
        "We scan the vehicle's on-board computer to diagnose & identify fault codes within the hybrid Battery system."
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("No Faults") },
        { icon: FaultyAnswer, represents: t("Faults") },
      ],
      note: t("* We do not check if the battery was replaced or repaired."),
    }, //hybrid battery lithium

    8: {
      text: t("We check the availability of standard equipment in the vehicle"),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Available") },
        { icon: FaultyAnswer, represents: t("Not Available") },
      ],
      note: t("* We do not validate if the equipment is genuine."),
    }, // Equipment,
    9: {
      text: t(
        "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Good") },
        { icon: InformativeAnswer, represents: t("Monitor") },
        { icon: FaultyAnswer, represents: t("Replace") },
        { icon: Information, represents: t("Additional Information") },
      ],
      note: t(
        "* We do not inspect the inside of the tire or the internal sidewall of the tire."
      ),
    }, // Tires,
    10: {
      text: t(
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Excellent") },
        { icon: InformativeAnswer, represents: t("Minor Issue") },
        { icon: FaultyAnswer, represents: t("Major Issue") },
      ],
      note: t("* We do not record any scratch that can not be felt by hand."),
    }, // Interior,
    15: {
      text: t(
        "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Good/ Good+Issues") },
        { icon: FaultyAnswer, represents: t("Issues") },
      ],
      note: t(
        "* We do not validate the results of an inspection report from any third party."
      ),
    }, // Frame,
    16: {
      text: t(
        "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle"
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("No Faults") },
        { icon: FaultyAnswer, represents: t("Faults") },
      ],
      note: t(
        "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired)."
      ),
    }, // computer scan,
    17: {
      text: t(
        "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint."
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Origional Paint") },
        { icon: FaultyAnswer, represents: t("Repainted") },
      ],
      note: t("* In some cases, Re-painted panels can not be detected."),
    }, // BodyPaint,
    18: {
      text: t(
        "We measure temperature of cooling/heating systems & check air throw for all AC vents."
      ),
      iconDisc: [
        { icon: UnFaultyAnswer, represents: t("Working") },
        { icon: FaultyAnswer, represents: t("Not Working") },
        { icon: Information, represents: t("Additional Information") },
      ],
    },
  };
  let query = ""; // along with fieldTypes querys the api for report data instead of details data
  for (let key in fieldTypes) {
    if (fieldTypes.hasOwnProperty(key)) {
      query = query + key + "=" + fieldTypes[key] + "&";
    }
  }

  const tireAgeAnswer = (answer) => {
    let splitAnswer = answer.split(".");
    return splitAnswer[0] + t(" yr. ") + splitAnswer[1] + t(" mos");
  };

  useEffect(() => {
    let groupIndex = 0;
    const GetSeperateGroups = (initialGroups) => {
      // this function calls getCarGroups for each seperate group and then calls itself to get the next group
      let group = initialGroups[groupIndex];
      let details = {};
      group?.group_id &&
        getCarGroups({
          endPoint: "CAR_GROUPED_FIELDS",
          // gets the groups using the query defined below, here groups contain group,subgroup,field names and score answers
          carId: carId,
          query: `for_batched_report=1&group_id=${group.group_id}`,
          callback: (groups) => {
            initialGroups.splice(groupIndex, 1, groups.groups[0]); // here the group from getCarGroups(for_details_report) is replaced with the detailed group data from  getCarGroups (for_batched_report)
            details["groups"] = { groups: initialGroups }; // this structure {groups: initialGroups} is to keep the old data structure
            setCarData(details); // now car data is adding groups one by one
            setLoadingCar(false);
            groupIndex++;
            if (groupIndex <= initialGroups.length) {
              GetSeperateGroups(initialGroups); // calling GetSeperateGroups for each of the groups and giving it the previous initialGroups to build up on
            }
          },
        });
    };

    if (carId !== undefined) {
      setLoadingCar(true);
      // getCar({ id: carId }); // the car object in the redux store does not seem to update in the car detail page so i called it here to get entered by fullname and date of entry
      let details = {};
      getCarGroups({
        endPoint: "CAR_GROUPED_FIELDS",
        // gets the groups using the query defined above, here groups only contain group names and score
        carId: carId,
        query: query,
        callback: (groups) => {
          details["groups"] = groups;
          setCarData(details);
          // add a new state groupsData and render groups based on it and use car data to render subgroups so that setCarData({}) is not needed since the state will change entirely and the expantion panel is not closed
          GetSeperateGroups(groups.groups); // the second getCarGroups (for_batched_report) was taken out into a function to be able to re-call itself in the callback function at the end of each async saga so that multiple calls are not done at the same time where only the last call will take effect
        },
      });
      setLoadingCar(true);
      getCarFaults({
        carId: carId,
        side: "dfv",
        position: "ext",
        callback: (data) => {
          getCarVisualization({
            query: `field__car_id=${carId}`,
            first: true,
            callback: (data) => {
              setLoadingCar(false);
            },
          });
        },
      });
      // history.goBack(); // quick fix for history.push triggering twice important to fix
    }
  }, [query, getCarGroups, carId, getCar, detailsLoadingCar]);

  // const counterDone = (
  //   groupIndex,
  //   groupsLength,
  //   subgroupIndex,
  //   subGroupsLength
  // ) => {

  //   if (
  //     groupIndex === groupsLength - 1 &&
  //     subgroupIndex === subGroupsLength - 1
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  useEffect(() => {
    setNoOfPoints(pointCount); // to cause a rerender after counting points
    handleCallback(pointCount);
    // setDoneCounting(counterIsDone);
  });

  // const changeFaults = (destructuredFields, index) => {
  const changeFaults = (destructuredFields, key) => {
    // setfaults(Object.values(destructuredFields)[index]);
    setfaults(destructuredFields[key]);
  };

  const handleSwitchChange = (event) => {
    // swich change function the value of switchState will later be used to render faulty points only
    setSwitchState(event.target.checked);
    // ReactGa.event({
    //   category: "View Faults Only",
    //   action: "Clicked View Faults Only",
    //   value: event.target.checked ? 1 : 0,
    //   label: `${event.target.checked ? "faults only" : "all"}`,
    // });
  };

  const checkGroupFilter = (List) => {
    if (switchState) {
      return List.filter((element) => {
        return (
          element?.group_score?.fields?.group_score < 100 ||
          element?.group_score?.fields?.group_score === undefined
        );
      });
    } else {
      return List;
    }
  };

  const checkSubgroupFilter = (list) => {
    if (switchState) {
      return list.filter((element) => {
        return (
          element?.score?.fields?.subgroup_score < 100 ||
          element?.score?.fields?.subgroup_score === undefined
        );
      });
    } else {
      return list;
    }
  };

  const checkFieldFilter = (list) => {
    if (switchState) {
      return list.filter((element) => {
        return element?.field_score < 100 || element?.field_score === undefined;
      });
    } else {
      return list;
    }
  };

  useEffect(() => {
    // here the category of the size of the screen is set (isSmallDevice) and the path (imagePath) is set accordingly to the category
    const isTabletOrMobileWidth = window.matchMedia(
      "(max-device-width: 959px)"
    );
    const isTabletOrMobileHeight = window.matchMedia(
      "(max-device-width: 959px)"
    );
    if (isTabletOrMobileHeight.matches && isTabletOrMobileWidth.matches) {
      setIsSmallDevice(true);
      setVisualizationPath("/visualization");
    } else {
      setIsSmallDevice(false);
      setVisualizationPath("");
      if (history.location.pathname.includes("inspection-report")) {
        history.goBack();
      }
    }
  }, []);

  const handleImgClick = (event) => {
    // image's onClick function requests full screen and orientation lock if available in small screen and sets openDialog if big screen
    //not sure if necessary still doesnot fullscreen on safari and the rest did without extra vendor prefix
    //but condition is neccissary
    if (isSmallDevice) {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen().catch((e) => console.log(e));
      } else if (document.body.mozRequestFullscreen) {
        document.body.mozRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.msRequestFullscreen) {
        document.body.msRequestFullscreen().catch((e) => console.log(e));
      }
      if (window.screen.orientation) {
        window.screen.orientation
          .lock("landscape-primary")
          .catch((e) => console.log(e));
      }
      hideAppBar(); // hides app bar in small screen visualization so that the speed dial button's transparent black div get positioned right
    } else {
      setOpenVisualization(true);
      document.getElementById("root").style.filter = "blur(16px)"; // adds blur to root element
    }
  };

  useEffect(() => {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => console.log("Document Exited form Full screen mode"))
        .catch((err) => console.error(err));
      showAppBar(); // displays the app bar hidden upon entering fullscreen
    } else {
      showAppBar();
    }
  }, []);

  useEffect(() => {
    // to close big screen's dialog if anything but the image or the stepper was clicked (containing div) or if Esc was pressed
    const handleModalClose = (event) => {
      if (backgroundRef.current === event.target) {
        setOpenVisualization(false);
        document.getElementById("root").style.filter = "none"; // removes blur from root element
      }
    };
    document.addEventListener("mousedown", handleModalClose);
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        setOpenVisualization(false);
        document.getElementById("root").style.filter = "none"; // removes blur from root element
      }
    });

    return (_) => {
      // clean up
      document.removeEventListener("mousedown", handleModalClose);
      window.removeEventListener("keyup", (e) => {
        if (e.key === "Escape") {
          setOpenVisualization(false);
          document.getElementById("root").style.filter = "none"; // removes blur from root element
        }
      });
    };
  }, [backgroundRef]);

  const renderDialog = () => {
    const handleCloseBigScreenVisualization = () => {
      setOpenVisualization(false);
      // document.body.style.overflow = "auto"
      document.getElementById("root").style.filter = "none"; // removes blur from root element
    };
    return (
      <>
        <Hidden smDown>
          <Tour
            imgLoaded={imgLoaded}
            setImgLoaded={setImgLoaded}
            fromBigScreen={true}
            close={handleCloseBigScreenVisualization}
            carId={carId}
            // openVisualization={openVisualization}
            openVisualization={isSmallDevice ? openVisualization : true}
          >
            <Visualisation />
          </Tour>
        </Hidden>
        <Hidden mdUp>
          <Visualisation
            imgLoaded={imgLoaded}
            setImgLoaded={setImgLoaded}
            fromBigScreen={true}
            close={handleCloseBigScreenVisualization}
            carId={carId}
            // openVisualization={openVisualization}
            openVisualization={isSmallDevice ? openVisualization : true}
          />
        </Hidden>
      </>
    );
  };

  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAnswerClick = (image, title) => {
    setImageDialogProps({ image: image, title: title });
    setOpenAnswer(true);
  };

  const handleInspectionNotesDialog = (inspectionNotes) => {
    console.log("DDDDDDDDDDDDDDD", inspectionNotes);
    setInspectionNotes({ inspectionNotes });
    setOpenInspection(true);
  };

  return (
    <div className={classes.container}>
      <Grid container justify="center" style={{ paddingTop: 0 }}>
        <Grid item xs={12} className={classes.navigationDiv}>
          <Hidden mdUp>
            <>
              {!showfaults ||
                (frameFaultopen && (
                  <div
                    ref={navRef}
                    className={clsx({
                      [classes.navBarRelative]: 1,
                      [classes.navBarRelativeHidden]: hideNav,
                    })}
                  >
                    <Paper className={classes.fancyNavigationPaper} square>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            history.length <= 2
                              ? history.replace("/search-results/other=all")
                              : history.goBack();
                          }}
                        >
                          <BackArrowIcon
                            size="16.43"
                            fill="white"
                            style={
                              localStorage.getItem("language") === "ar"
                                ? { transform: "rotate(180deg)" }
                                : {}
                            }
                          />
                        </IconButton>
                        <div className={classes.featuredImageDiv}>
                          <img
                            // ref={featuredImageRef}
                            src={visualizationImage}
                            slt="featured_image"
                            className={classes.featuredImage}
                          />
                        </div>
                        <div>
                          <div style={{ display: "flex" }}>
                            <Typography className={classes.navbartitle}>
                              {`${carInfo?.card?.year}`}{" "}
                            </Typography>
                            {"\u00A0"}
                            <Typography className={classes.navbartitle}>
                              {`${carInfo?.card?.make}`}{" "}
                            </Typography>
                            {"\u00A0"}
                            <Typography className={classes.navbartitle}>
                              {`${carInfo?.card?.model}`}{" "}
                            </Typography>
                            {"\u00A0"}
                            <Typography className={classes.navbartitle}>
                              {`${carInfo?.card?.trim}`}{" "}
                            </Typography>
                          </div>
                          <Typography className={classes.cardPriceTypo}>
                            {t("english JD")}
                            {`${numberWithCommas(carInfo?.card?.price)}`}
                            {t("arabic JD")}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                      ></div>
                    </Paper>
                  </div>
                ))}
            </>
          </Hidden>
          <Hidden smDown>
            <Grid container className={classes.reportGrid}>
              <Grid item sm={12} className={classes.firstGridItem}>
                <Paper className={classes.reportPaper}>
                  <Paper className={classes.headerVisualizationPaper} id="coco">
                    <div>
                      {groups !== undefined ? (
                        // to not  crash before data is fetched from api
                        <div>
                          {checkGroupFilter(groups.groups).map((group, i) => {
                            const subGroups = group?.sub_groups;
                            if (group)
                              return group?.title !== "comments" ? ( // removes comment from groups expansion panel
                                <ExpansionPanel
                                  style={{
                                    background: "#F4F5F7",
                                    margin: "-1px",
                                    border: "1px solid #B5B5B5",
                                  }}
                                  expanded={expanded === `group_${i}`}
                                  onChange={handleChange(`group_${i}`)}
                                  key={`group_${i}`}
                                >
                                  <ExpansionPanelSummary
                                    classes={{
                                      content:
                                        classes.expansionPanelSummaryContent,
                                      root: classes.expansionPanelSummaryRoot,
                                    }}
                                    expandIcon={
                                      <ExpandMoreIcon
                                        style={{ fill: "#000000" }}
                                      />
                                    }
                                  >
                                    <img
                                      src={
                                        group != undefined
                                          ? DetInspRepIcons[group["group_id"]]
                                          : null
                                      }
                                      className={
                                        group != undefined
                                          ? group["group_id"] === 7
                                            ? classes.exteriorGroupIcon
                                            : classes.groupIcon
                                          : null
                                      }
                                      alt="DetInspRepIcons"
                                    />
                                    <Typography
                                      style={
                                        localStorage.getItem("language") ===
                                        "ar"
                                          ? { fontWeight: "600" }
                                          : { fontWeight: "400" }
                                      }
                                      className={classes.groupTitle}
                                    >
                                      {group?.title}
                                    </Typography>
                                  </ExpansionPanelSummary>

                                  <div className={classes.column}>
                                    <ExpansionPanelDetails
                                      className={classes.expansionPanelDetails}
                                    >
                                      {subGroups !== undefined ? ( // to not crash before end of slow fields fetch
                                        checkSubgroupFilter(subGroups).map(
                                          (subGroup, index) => {
                                            const fields = subGroup.fields;
                                            let destructuredFields = {};
                                            {
                                              var i = 0;
                                              checkFieldFilter(fields).map(
                                                (option) => {
                                                  let x = fields?.filter(
                                                    (el) => {
                                                      return (
                                                        el.selected_option ===
                                                          option.selected_option &&
                                                        option.selected_option !==
                                                          null &&
                                                        option.selected_option !==
                                                          3263 && // original paint
                                                        option.selected_option !==
                                                          3264 && // repainted paint
                                                        option.selected_option !==
                                                          3265 // in applicable paint
                                                      );
                                                    }
                                                  );
                                                  destructuredFields =
                                                    Object.assign(
                                                      destructuredFields,
                                                      {
                                                        [option.field_id]: {
                                                          field_tag:
                                                            option?.field_tag
                                                              ?.title,
                                                          field_id:
                                                            option?.field_tag
                                                              ?.id,
                                                          field:
                                                            option?.field_title,
                                                          field_tree_id:
                                                            option?.field_tree_id,
                                                          score:
                                                            option?.field_score,
                                                          img: {
                                                            [option?.field_title]:
                                                              [
                                                                ...(destructuredFields[
                                                                  option
                                                                    ?.field_id
                                                                ]?.img[
                                                                  option
                                                                    ?.field_title
                                                                ] ?? []),
                                                                ...(option?.images ??
                                                                  []),
                                                              ],
                                                          },
                                                          images: [
                                                            ...(destructuredFields[
                                                              option?.field_id
                                                            ]?.images ?? []),
                                                            {
                                                              [option?.field_title]:
                                                                option?.with_image ==
                                                                false
                                                                  ? localStorage.getItem(
                                                                      "language"
                                                                    ) == "en"
                                                                    ? [
                                                                        [
                                                                          "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png",
                                                                        ],
                                                                        option?.field_score,
                                                                      ]
                                                                    : [
                                                                        [
                                                                          "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png",
                                                                        ],
                                                                        option?.field_score,
                                                                      ]
                                                                  : [
                                                                      option?.images,
                                                                      option?.field_score,
                                                                    ],
                                                            },
                                                          ],
                                                          answers:
                                                            Object.assign(
                                                              destructuredFields[
                                                                option.field_id
                                                              ]
                                                                ? destructuredFields[
                                                                    option
                                                                      ?.field_id
                                                                  ].answers
                                                                : {},
                                                              {
                                                                [option.option_title ||
                                                                option.text]:
                                                                  x.length,
                                                              }
                                                            ),
                                                          clickableAnswers: {
                                                            title:
                                                              option?.field_tag
                                                                ?.id === 24
                                                                ? option.field_id ===
                                                                  335
                                                                  ? t(
                                                                      "Report Image"
                                                                    )
                                                                  : option.text
                                                                : null,
                                                            image:
                                                              option?.images,
                                                          },
                                                          fieldType:
                                                            option.field_type,
                                                          detailedFaultsWithImages:
                                                            [
                                                              ...(destructuredFields[
                                                                option?.field_id
                                                              ]
                                                                ?.detailedFaultsWithImages ??
                                                                []),
                                                              {
                                                                [option?.field_tree_id]:
                                                                  option?.with_image ==
                                                                  false
                                                                    ? localStorage.getItem(
                                                                        "language"
                                                                      ) == "en"
                                                                      ? [
                                                                          [
                                                                            "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png",
                                                                          ],
                                                                          option?.field_score,
                                                                          option?.option_title,
                                                                        ]
                                                                      : [
                                                                          [
                                                                            "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png",
                                                                          ],
                                                                          option?.field_score,
                                                                          option?.option_title,
                                                                        ]
                                                                    : [
                                                                        option?.images,
                                                                        option?.field_score,
                                                                        option?.option_title,
                                                                      ],
                                                              },
                                                            ],
                                                          extended_answers:
                                                            option?.extended_answers,
                                                        },
                                                      }
                                                    );
                                                }
                                              );
                                            }
                                            pointCount =
                                              pointCount +
                                              Object.keys(destructuredFields)
                                                .length;
                                            // counterIsDone = counterDone(
                                            //   i,
                                            //   groups.groups.length,
                                            //   index,
                                            //   subGroups.length
                                            // );
                                            return (
                                              <div>
                                                <Hidden mdUp>
                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      marginBottom: "50px",
                                                      width:
                                                        "calc(100vw - 32px)",
                                                    }}
                                                  >
                                                    {group.group_id == 17 ? (
                                                      <BodyPaintResult
                                                        fields={
                                                          group?.sub_groups[0]
                                                            .fields
                                                        }
                                                      >
                                                        {console.log(
                                                          "rrrrrrrrf",
                                                          ref?.current
                                                            ?.clientHeight
                                                        )}
                                                      </BodyPaintResult>
                                                    ) : null}
                                                  </div>
                                                </Hidden>
                                                <div>
                                                  {Math.floor(
                                                    subGroup?.score?.fields
                                                      ?.subgroup_score
                                                  ) != 100 && (
                                                    <div
                                                      style={{
                                                        display: "inline-block",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <Paper
                                                        ref={
                                                          group?.group_id == 17
                                                            ? ref
                                                            : null
                                                        }
                                                        className={
                                                          classes.table
                                                        }
                                                        style={
                                                          group?.group_id == 17
                                                            ? {
                                                                marginBottom: `${
                                                                  740 -
                                                                  ref?.current
                                                                    ?.clientHeight
                                                                }px`,
                                                              }
                                                            : {}
                                                        }
                                                      >
                                                        <header
                                                          style={{
                                                            display:
                                                              "table-row",
                                                          }}
                                                          className={
                                                            classes.tablehead
                                                          }
                                                        >
                                                          <div
                                                            style={{
                                                              display:
                                                                "table-cell",
                                                            }}
                                                          >
                                                            <Typography
                                                              className={
                                                                classes.subGroupText
                                                              }
                                                            >
                                                              {/* here */}
                                                              {subGroup.title}
                                                            </Typography>
                                                          </div>
                                                          {/*  */}
                                                          {/* <div
                                                            style={{
                                                              display:
                                                                "table-cell",
                                                            }}
                                                          >
                                                            <Typography
                                                              className={
                                                                classes.subGroupScoreText
                                                              }
                                                              style={
                                                                group?.group_id ==
                                                                  20 &&
                                                                group
                                                                  ?.sub_groups?.[0]
                                                                  ?.fields?.[0]
                                                                  ?.field_id ==
                                                                  916
                                                                  ? {
                                                                      color:
                                                                        "#929693",
                                                                    }
                                                                  : group?.group_id !=
                                                                      15 &&
                                                                    group?.group_id !=
                                                                      16 &&
                                                                    group?.group_id !=
                                                                      21 &&
                                                                    !(
                                                                      group?.group_id ==
                                                                        19 &&
                                                                      group
                                                                        ?.sub_groups?.[0]
                                                                        ?.fields?.[0]
                                                                        ?.field_id ==
                                                                        902
                                                                    )
                                                                  ? //here
                                                                    {
                                                                      color:
                                                                        "rgb(60, 182, 86)",
                                                                      // color: !subGroup.score
                                                                      //   ? "#929693"
                                                                      //   : subGroup.score
                                                                      //       .fields
                                                                      //       .subgroup_score <=
                                                                      //     50
                                                                      //   ? "rgb(235, 50, 35)"
                                                                      //   : subGroup.score
                                                                      //       .fields
                                                                      //       .subgroup_score <=
                                                                      //     70
                                                                      //   ? "rgb(236, 222, 0)"
                                                                      //   : "rgb(60, 182, 86)",
                                                                    }
                                                                  : {
                                                                      color:
                                                                        "#8A8A8A",
                                                                    }
                                                              }
                                                            >
                                                              {group?.group_id ==
                                                                20 &&
                                                              group
                                                                ?.sub_groups?.[0]
                                                                ?.fields?.[0]
                                                                ?.field_id ==
                                                                916
                                                                ? ""
                                                                : group?.group_id !=
                                                                  15
                                                                ? group?.group_id !=
                                                                    16 &&
                                                                  group?.group_id !=
                                                                    21
                                                                  ? !subGroup.score
                                                                    ? "N/A"
                                                                    : group?.group_id ==
                                                                        19 &&
                                                                      group
                                                                        ?.sub_groups?.[0]
                                                                        ?.fields?.[0]
                                                                        ?.field_id ==
                                                                        902
                                                                    ? t(
                                                                        "Not Available"
                                                                      )
                                                                    : subGroup?.fixed_score &&
                                                                      " + " +
                                                                        (Number(
                                                                          subGroup?.fixed_score?.toFixed(
                                                                            2
                                                                          )
                                                                        ) > 0.01
                                                                          ? Number(
                                                                              subGroup?.fixed_score?.toFixed(
                                                                                2
                                                                              )
                                                                            )
                                                                          : "< 0.01")
                                                                  : group
                                                                      ?.sub_groups?.[0]
                                                                      ?.fields
                                                                      ?.length ==
                                                                    1
                                                                  ? t(
                                                                      "Not Available"
                                                                    )
                                                                  : faultscnt(
                                                                      subGroup
                                                                    )
                                                                : ""}{" "}
                                                              {group?.group_id ==
                                                                20 &&
                                                              group
                                                                ?.sub_groups?.[0]
                                                                ?.fields?.[0]
                                                                ?.field_id ==
                                                                916
                                                                ? t(
                                                                    "Not Available"
                                                                  )
                                                                : group?.group_id !=
                                                                  15
                                                                ? group?.group_id !=
                                                                    16 &&
                                                                  group?.group_id !=
                                                                    21
                                                                  ? group?.group_id ==
                                                                      19 &&
                                                                    group
                                                                      ?.sub_groups?.[0]
                                                                      ?.fields?.[0]
                                                                      ?.field_id ==
                                                                      902
                                                                    ? null
                                                                    : "%"
                                                                  : group
                                                                      ?.sub_groups?.[0]
                                                                      ?.fields
                                                                      ?.length ==
                                                                    1
                                                                  ? null
                                                                  : t("Faults")
                                                                : ""}
                                                            </Typography>
                                                          </div> */}
                                                        </header>
                                                        {Object.keys(
                                                          destructuredFields
                                                        )
                                                          .sort((a, b) => {
                                                            return (
                                                              destructuredFields[
                                                                a
                                                              ].field_tree_id -
                                                              destructuredFields[
                                                                b
                                                              ].field_tree_id
                                                            );
                                                          })
                                                          .map(
                                                            (
                                                              key,
                                                              fieldIndex
                                                            ) => {
                                                              if (
                                                                destructuredFields[
                                                                  key
                                                                ].score != 100
                                                              )
                                                                return key ==
                                                                  950 &&
                                                                  Object.keys(
                                                                    destructuredFields[
                                                                      key
                                                                    ].answers
                                                                  )[0] ==
                                                                    "null" ? null : (
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "table-row-group",
                                                                    }}
                                                                  >
                                                                    {fieldIndex ===
                                                                    0 ? (
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "table-row",
                                                                        }}
                                                                      >
                                                                        <td
                                                                          colSpan="2"
                                                                          style={{
                                                                            padding: 0,
                                                                            backgroundColor:
                                                                              "#fff",
                                                                          }}
                                                                        >
                                                                          <hr
                                                                            style={{
                                                                              height:
                                                                                "2px",
                                                                              border:
                                                                                "none",
                                                                              margin:
                                                                                "6px 0",
                                                                            }}
                                                                          />
                                                                        </td>
                                                                      </div>
                                                                    ) : (
                                                                      <Hidden
                                                                        smDown
                                                                      >
                                                                        <div
                                                                          style={{
                                                                            display:
                                                                              "table-row",
                                                                            height:
                                                                              "8px",
                                                                          }}
                                                                        >
                                                                          <td
                                                                            colSpan="2"
                                                                            style={{
                                                                              padding: 0,
                                                                              backgroundColor:
                                                                                "#fff",
                                                                            }}
                                                                          ></td>
                                                                        </div>
                                                                      </Hidden>
                                                                    )}
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "table-row",
                                                                        backgroundColor:
                                                                          "#fff",
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "table-cell",
                                                                        }}
                                                                        className={
                                                                          classes.firstitem
                                                                        }
                                                                      >
                                                                        <div
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                          }}
                                                                        >
                                                                          <Typography
                                                                            style={
                                                                              localStorage.getItem(
                                                                                "language"
                                                                              ) ===
                                                                              "ar"
                                                                                ? {
                                                                                    fontWeight:
                                                                                      "600",
                                                                                  }
                                                                                : {
                                                                                    fontWeight:
                                                                                      "500",
                                                                                  }
                                                                            }
                                                                            className={
                                                                              classes.firstItemTypography
                                                                            }
                                                                          >
                                                                            {
                                                                              destructuredFields[
                                                                                key
                                                                              ]
                                                                                .field
                                                                            }
                                                                          </Typography>
                                                                          <Hidden
                                                                            smDown
                                                                          >
                                                                            {infoSpecialCase(
                                                                              key
                                                                            ) && (
                                                                              <Tooltip
                                                                                title={t(
                                                                                  "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking."
                                                                                )}
                                                                                arrow
                                                                                placement="top-start"
                                                                                classes={{
                                                                                  tooltip:
                                                                                    classes.tooltip,
                                                                                  arrow:
                                                                                    classes.tooltipArrow,
                                                                                }}
                                                                              >
                                                                                <img
                                                                                  src={
                                                                                    Information
                                                                                  }
                                                                                  alt="infoIcon"
                                                                                  className={
                                                                                    classes.infoIcon
                                                                                  }
                                                                                />
                                                                              </Tooltip>
                                                                            )}
                                                                          </Hidden>
                                                                          <Hidden
                                                                            mdUp
                                                                          >
                                                                            {infoSpecialCase(
                                                                              key
                                                                            ) && (
                                                                              <Tooltip
                                                                                title={t(
                                                                                  "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking."
                                                                                )}
                                                                                arrow
                                                                                placement="top-start"
                                                                                open={
                                                                                  openTooltip &&
                                                                                  tooltipButtonId ===
                                                                                    key
                                                                                }
                                                                                onClose={
                                                                                  handleTooltipClose
                                                                                }
                                                                                classes={{
                                                                                  tooltip:
                                                                                    classes.tooltip,
                                                                                  arrow:
                                                                                    classes.tooltipArrow,
                                                                                }}
                                                                                enterTouchDelay={
                                                                                  0
                                                                                }
                                                                                leaveTouchDelay={
                                                                                  6000
                                                                                }
                                                                              >
                                                                                <Button
                                                                                  id={
                                                                                    key
                                                                                  }
                                                                                  onClick={
                                                                                    handleTooltipOpen
                                                                                  }
                                                                                  style={{
                                                                                    padding:
                                                                                      "0",
                                                                                    minWidth:
                                                                                      "0px",
                                                                                  }}
                                                                                >
                                                                                  <img
                                                                                    src={
                                                                                      Information
                                                                                    }
                                                                                    alt="infoIcon"
                                                                                    className={
                                                                                      classes.infoIcon
                                                                                    }
                                                                                  />
                                                                                </Button>
                                                                              </Tooltip>
                                                                            )}
                                                                          </Hidden>
                                                                        </div>
                                                                      </div>
                                                                      {Object.keys(
                                                                        destructuredFields[
                                                                          key
                                                                        ]
                                                                          .answers
                                                                      ).map(
                                                                        (
                                                                          answer,
                                                                          index
                                                                        ) => {
                                                                          if (
                                                                            index ==
                                                                            0
                                                                          ) {
                                                                            return (
                                                                              <div>
                                                                                <div
                                                                                  className={
                                                                                    classes.seconditem
                                                                                  }
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                    }}
                                                                                  >
                                                                                    {/* // from here // */}
                                                                                    {destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .clickableAnswers
                                                                                      .title ===
                                                                                      null &&
                                                                                      (group?.group_id ==
                                                                                        15 &&
                                                                                      destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        .field_id ==
                                                                                        25 ? (
                                                                                        //
                                                                                        <Typography
                                                                                          className={
                                                                                            classes.answerText
                                                                                          }
                                                                                          onClick={() => {
                                                                                            setframeFaultopen(
                                                                                              true
                                                                                            );
                                                                                            setframeFault(
                                                                                              {
                                                                                                title:
                                                                                                  destructuredFields[
                                                                                                    key
                                                                                                  ]
                                                                                                    .field,
                                                                                                data: {
                                                                                                  issues:
                                                                                                    destructuredFields[
                                                                                                      key
                                                                                                    ].extended_answers
                                                                                                      .filter(
                                                                                                        (
                                                                                                          el
                                                                                                        ) =>
                                                                                                          el.score !==
                                                                                                          100
                                                                                                      )
                                                                                                      .map(
                                                                                                        (
                                                                                                          el
                                                                                                        ) =>
                                                                                                          el.text
                                                                                                      ),
                                                                                                },
                                                                                              }
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          {destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .score !==
                                                                                            0 &&
                                                                                            `${
                                                                                              //   t(
                                                                                              //   "good"
                                                                                              // )
                                                                                              t(
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ].extended_answers.filter(
                                                                                                  (
                                                                                                    el
                                                                                                  ) =>
                                                                                                    el.score ===
                                                                                                    100
                                                                                                )[0]
                                                                                                  ?.english_text
                                                                                              )
                                                                                            }`}
                                                                                          {destructuredFields[
                                                                                            key
                                                                                          ].extended_answers.filter(
                                                                                            (
                                                                                              el
                                                                                            ) =>
                                                                                              el.score !==
                                                                                              100.0
                                                                                          )
                                                                                            .length !==
                                                                                            0 &&
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .score !==
                                                                                              0 && (
                                                                                              <span>
                                                                                                {
                                                                                                  " + "
                                                                                                }
                                                                                              </span>
                                                                                            )}
                                                                                          {destructuredFields[
                                                                                            key
                                                                                          ].extended_answers.filter(
                                                                                            (
                                                                                              el
                                                                                            ) =>
                                                                                              el.score !==
                                                                                              100.0
                                                                                          )
                                                                                            .length !==
                                                                                            0 && (
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "#00BDFF",
                                                                                                textDecoration:
                                                                                                  "underline",
                                                                                                cursor:
                                                                                                  "pointer",
                                                                                              }}
                                                                                            >
                                                                                              {t(
                                                                                                "Issues"
                                                                                              )}{" "}
                                                                                              |{" "}
                                                                                              {
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ].extended_answers.filter(
                                                                                                  (
                                                                                                    el
                                                                                                  ) =>
                                                                                                    el.score !==
                                                                                                    100.0
                                                                                                )
                                                                                                  .length
                                                                                              }
                                                                                            </span>
                                                                                          )}
                                                                                        </Typography>
                                                                                      ) : (
                                                                                        <Typography
                                                                                          className={
                                                                                            classes.answerText
                                                                                          }
                                                                                          onClick={() => {
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              ?.field_tag ==
                                                                                              "Equipment" &&
                                                                                            Object.keys(
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers
                                                                                            )[0] !=
                                                                                              "Not Present" &&
                                                                                            Object.keys(
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers
                                                                                            )[0] !=
                                                                                              "No Keys" &&
                                                                                            Object.keys(
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers
                                                                                            )[0] !=
                                                                                              "لا مفاتيح" &&
                                                                                            Object.keys(
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers
                                                                                            )[0] !=
                                                                                              "لا يوجد"
                                                                                              ? handleAnswerClick(
                                                                                                  destructuredFields[
                                                                                                    key
                                                                                                  ]
                                                                                                    .clickableAnswers
                                                                                                    .image,
                                                                                                  destructuredFields[
                                                                                                    key
                                                                                                  ]
                                                                                                    .field
                                                                                                )
                                                                                              : key ==
                                                                                                  945 ||
                                                                                                key ==
                                                                                                  990
                                                                                              ? handleInspectionNotesDialog(
                                                                                                  Object.keys(
                                                                                                    destructuredFields[
                                                                                                      key
                                                                                                    ]
                                                                                                      .answers
                                                                                                  )
                                                                                                )
                                                                                              : key ==
                                                                                                  951 &&
                                                                                                Object.keys(
                                                                                                  destructuredFields[951]
                                                                                                    .answers
                                                                                                ) !=
                                                                                                  "No Notes" &&
                                                                                                Object.keys(
                                                                                                  destructuredFields[951]
                                                                                                    .answers
                                                                                                ) !=
                                                                                                  "لا ملاحظات"
                                                                                              ? handleInspectionNotesDialog(
                                                                                                  Object.keys(
                                                                                                    destructuredFields[951]
                                                                                                      .answers
                                                                                                  )
                                                                                                )
                                                                                              : key ==
                                                                                                  971 &&
                                                                                                Object.keys(
                                                                                                  destructuredFields[971]
                                                                                                    .answers
                                                                                                ) !=
                                                                                                  "No Notes" &&
                                                                                                Object.keys(
                                                                                                  destructuredFields[971]
                                                                                                    .answers
                                                                                                ) !=
                                                                                                  "لا ملاحظات"
                                                                                              ? handleInspectionNotesDialog(
                                                                                                  Object.keys(
                                                                                                    destructuredFields[971]
                                                                                                      .answers
                                                                                                  )
                                                                                                )
                                                                                              : destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .fieldType ==
                                                                                                  "ddma" &&
                                                                                                group?.group_id <
                                                                                                  16 &&
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .score !=
                                                                                                  100 &&
                                                                                                showInteriorFaults(
                                                                                                  {
                                                                                                    images:
                                                                                                      destructuredFields[
                                                                                                        key
                                                                                                      ]
                                                                                                        .images,
                                                                                                    score:
                                                                                                      destructuredFields[
                                                                                                        key
                                                                                                      ]
                                                                                                        .score,
                                                                                                    issue:
                                                                                                      destructuredFields[
                                                                                                        key
                                                                                                      ]
                                                                                                        .option_title,
                                                                                                    detailedFaultsWithImages:
                                                                                                      destructuredFields[
                                                                                                        key
                                                                                                      ]
                                                                                                        .detailedFaultsWithImages,
                                                                                                  }
                                                                                                );
                                                                                            {
                                                                                              console.log(
                                                                                                "FFFFFFFFf"
                                                                                              );
                                                                                            }
                                                                                            (group?.group_id ==
                                                                                              16 ||
                                                                                              group?.group_id ==
                                                                                                21) &&
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .extended_answers
                                                                                                .length >
                                                                                                0 &&
                                                                                              setshowfaults(
                                                                                                !showfaults
                                                                                              );
                                                                                            changeFaults(
                                                                                              destructuredFields,
                                                                                              // fieldIndex
                                                                                              key
                                                                                            );
                                                                                          }}
                                                                                          style={
                                                                                            Object.keys(
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers
                                                                                            ) ==
                                                                                              "No Notes" ||
                                                                                            Object.keys(
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers
                                                                                            ) ==
                                                                                              "لا ملاحظات"
                                                                                              ? {
                                                                                                  color:
                                                                                                    "#636363",
                                                                                                }
                                                                                              : destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  ?.field_tag ==
                                                                                                  "Equipment" &&
                                                                                                Object.keys(
                                                                                                  destructuredFields[
                                                                                                    key
                                                                                                  ]
                                                                                                    .answers
                                                                                                )[0] !=
                                                                                                  "Not Present" &&
                                                                                                Object.keys(
                                                                                                  destructuredFields[
                                                                                                    key
                                                                                                  ]
                                                                                                    .answers
                                                                                                )[0] !=
                                                                                                  "No Keys" &&
                                                                                                Object.keys(
                                                                                                  destructuredFields[
                                                                                                    key
                                                                                                  ]
                                                                                                    .answers
                                                                                                )[0] !=
                                                                                                  "لا مفاتيح" &&
                                                                                                Object.keys(
                                                                                                  destructuredFields[
                                                                                                    key
                                                                                                  ]
                                                                                                    .answers
                                                                                                )[0] !=
                                                                                                  "لا يوجد"
                                                                                              ? {
                                                                                                  color:
                                                                                                    "#00bdff",
                                                                                                  textDecoration:
                                                                                                    "underline",
                                                                                                  cursor:
                                                                                                    "pointer",
                                                                                                }
                                                                                              : key ==
                                                                                                  945 ||
                                                                                                key ==
                                                                                                  990 ||
                                                                                                (key ==
                                                                                                  951 &&
                                                                                                  Object.keys(
                                                                                                    destructuredFields[951]
                                                                                                      .answers
                                                                                                  ) !=
                                                                                                    "No Notes" &&
                                                                                                  Object.keys(
                                                                                                    destructuredFields[951]
                                                                                                      .answers
                                                                                                  ) !=
                                                                                                    "لا ملاحظات") ||
                                                                                                (key ==
                                                                                                  971 &&
                                                                                                  Object.keys(
                                                                                                    destructuredFields[971]
                                                                                                      ?.answers
                                                                                                  ) !=
                                                                                                    "No Notes" &&
                                                                                                  Object.keys(
                                                                                                    destructuredFields[971]
                                                                                                      .answers
                                                                                                  ) !=
                                                                                                    "لا ملاحظات")
                                                                                              ? {
                                                                                                  color:
                                                                                                    "#00BDFF",
                                                                                                  cursor:
                                                                                                    "pointer",
                                                                                                }
                                                                                              : destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .fieldType ==
                                                                                                "ddma"
                                                                                              ? destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .score !=
                                                                                                100
                                                                                                ? {
                                                                                                    color:
                                                                                                      "#00BDFF",
                                                                                                    textDecorationLine:
                                                                                                      "underline",
                                                                                                    cursor:
                                                                                                      "pointer",
                                                                                                  }
                                                                                                : {
                                                                                                    color:
                                                                                                      "#636363",
                                                                                                  }
                                                                                              : destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .extended_answers
                                                                                                  .length >
                                                                                                0
                                                                                              ? {
                                                                                                  color:
                                                                                                    "#00BDFF",
                                                                                                  textDecoration:
                                                                                                    "underline",
                                                                                                  cursor:
                                                                                                    "pointer",
                                                                                                }
                                                                                              : {
                                                                                                  paddingRight:
                                                                                                    localStorage.getItem(
                                                                                                      "language"
                                                                                                    ) ===
                                                                                                    "en"
                                                                                                      ? "8px"
                                                                                                      : "0",
                                                                                                  paddingLeft:
                                                                                                    localStorage.getItem(
                                                                                                      "language"
                                                                                                    ) ===
                                                                                                    "en"
                                                                                                      ? "0"
                                                                                                      : "8px",
                                                                                                  fontSize:
                                                                                                    window
                                                                                                      .screen
                                                                                                      .width >=
                                                                                                    960
                                                                                                      ? "14px"
                                                                                                      : "12px",
                                                                                                  fontWeight:
                                                                                                    "400",
                                                                                                  color:
                                                                                                    "#636363",
                                                                                                }
                                                                                          }
                                                                                        >
                                                                                          {/* {here} */}
                                                                                          {key ==
                                                                                            945 ||
                                                                                          key ==
                                                                                            990 ? (
                                                                                            t(
                                                                                              "Notes"
                                                                                            ) +
                                                                                            " | " +
                                                                                            Object.keys(
                                                                                              destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers
                                                                                            )
                                                                                              .length
                                                                                          ) : key ==
                                                                                            951 ? (
                                                                                            Object.keys(
                                                                                              destructuredFields[951]
                                                                                                .answers
                                                                                            ) ==
                                                                                              "No Notes" ||
                                                                                            Object.keys(
                                                                                              destructuredFields[951]
                                                                                                .answers
                                                                                            ) ==
                                                                                              "لا ملاحظات" ? (
                                                                                              t(
                                                                                                "No Notes"
                                                                                              )
                                                                                            ) : (
                                                                                              t(
                                                                                                "Notes"
                                                                                              ) +
                                                                                              " | " +
                                                                                              Object.keys(
                                                                                                destructuredFields[951]
                                                                                                  .answers
                                                                                              )
                                                                                                .length
                                                                                            )
                                                                                          ) : key ==
                                                                                            971 ? (
                                                                                            Object.keys(
                                                                                              destructuredFields[971]
                                                                                                .answers
                                                                                            ) ==
                                                                                              "No Notes" ||
                                                                                            Object.keys(
                                                                                              destructuredFields[971]
                                                                                                .answers
                                                                                            ) ==
                                                                                              "لا ملاحظات" ? (
                                                                                              t(
                                                                                                "No Notes"
                                                                                              )
                                                                                            ) : (
                                                                                              t(
                                                                                                "Notes"
                                                                                              ) +
                                                                                              " | " +
                                                                                              Object.keys(
                                                                                                destructuredFields[971]
                                                                                                  .answers
                                                                                              )
                                                                                                .length
                                                                                            )
                                                                                          ) : destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .fieldType ==
                                                                                            "ddma" ? (
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              .score ==
                                                                                            100 ? (
                                                                                              t(
                                                                                                "Excellent"
                                                                                              )
                                                                                            ) : (
                                                                                              t(
                                                                                                "Issues"
                                                                                              ) +
                                                                                              " | " +
                                                                                              Object.values(
                                                                                                destructuredFields[
                                                                                                  key
                                                                                                ]
                                                                                                  .answers
                                                                                              ).reduce(
                                                                                                (
                                                                                                  a,
                                                                                                  b
                                                                                                ) =>
                                                                                                  a +
                                                                                                  b
                                                                                              )
                                                                                            )
                                                                                          ) : group?.group_id ==
                                                                                              9 &&
                                                                                            mmRegex.test(
                                                                                              answer
                                                                                            ) &&
                                                                                            localStorage.getItem(
                                                                                              "language"
                                                                                            ) ==
                                                                                              "ar" ? (
                                                                                            <span>
                                                                                              {answer.match(
                                                                                                /\d+/
                                                                                              )}{" "}
                                                                                              ملم
                                                                                            </span>
                                                                                          ) : group?.group_id !=
                                                                                              16 &&
                                                                                            group?.group_id !=
                                                                                              21 ? (
                                                                                            <span>
                                                                                              {destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .answers[
                                                                                                answer
                                                                                              ] >
                                                                                              1
                                                                                                ? `${destructuredFields[key].answers[answer]}X `
                                                                                                : ""}
                                                                                              {tireAgeIds.includes(
                                                                                                // to change tireage text format
                                                                                                key
                                                                                              )
                                                                                                ? tireAgeAnswer(
                                                                                                    // turns yr.mo to "yr yrs. mo mos"
                                                                                                    answer
                                                                                                  )
                                                                                                : answer}
                                                                                            </span>
                                                                                          ) : (
                                                                                            <>
                                                                                              {destructuredFields[
                                                                                                key
                                                                                              ]
                                                                                                .extended_answers
                                                                                                .length ==
                                                                                              0
                                                                                                ? key ==
                                                                                                    950 ||
                                                                                                  key ==
                                                                                                    985
                                                                                                  ? Object.keys(
                                                                                                      destructuredFields[
                                                                                                        key
                                                                                                      ]
                                                                                                        .answers
                                                                                                    )[0]
                                                                                                  : t(
                                                                                                      "No Faults"
                                                                                                    )
                                                                                                : t(
                                                                                                    "Faults | "
                                                                                                  ) +
                                                                                                  destructuredFields[
                                                                                                    key
                                                                                                  ]
                                                                                                    .extended_answers
                                                                                                    .length}
                                                                                            </>
                                                                                          )}
                                                                                        </Typography>
                                                                                      ))}
                                                                                    {/* // to here // */}

                                                                                    <Typography
                                                                                      className={
                                                                                        classes.clickableAnswer
                                                                                      }
                                                                                      onClick={() => {
                                                                                        handleAnswerClick(
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .clickableAnswers
                                                                                            .image,
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .field
                                                                                        );
                                                                                      }}
                                                                                    >
                                                                                      {destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        .clickableAnswers
                                                                                        .title &&
                                                                                        t(
                                                                                          "View Image"
                                                                                        )}
                                                                                    </Typography>
                                                                                    <div
                                                                                      style={{
                                                                                        display:
                                                                                          "table-cell",
                                                                                        display:
                                                                                          "flex",
                                                                                        alignSelf:
                                                                                          "center",
                                                                                      }}
                                                                                      className={
                                                                                        classes.thirditem
                                                                                      }
                                                                                    >
                                                                                      {destructuredFields[
                                                                                        key
                                                                                      ]
                                                                                        ?.field_tag ==
                                                                                      "Equipment" ? (
                                                                                        Object.keys(
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            ?.answers
                                                                                        )[0] ==
                                                                                          "Not Present" ||
                                                                                        Object.keys(
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            ?.answers
                                                                                        )[0] ==
                                                                                          "لا يوجد" ||
                                                                                        Object.keys(
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            ?.answers
                                                                                        )[0] ==
                                                                                          "No Keys" ||
                                                                                        Object.keys(
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            ?.answers
                                                                                        )[0] ==
                                                                                          "لا مفاتيح" ? (
                                                                                          <img
                                                                                            src={
                                                                                              FaultyAnswer
                                                                                            }
                                                                                            alt="condition"
                                                                                          />
                                                                                        ) : Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              ?.answers
                                                                                          )[0] ==
                                                                                            "Present" ||
                                                                                          Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              ?.answers
                                                                                          )[0] ==
                                                                                            "يوجد" ||
                                                                                          Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              ?.answers
                                                                                          )[0] ==
                                                                                            "مفتاحين" ||
                                                                                          Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              ?.answers
                                                                                          )[0] ==
                                                                                            "Two Keys" ? (
                                                                                          <img
                                                                                            src={
                                                                                              UnFaultyAnswer
                                                                                            }
                                                                                            alt="condition"
                                                                                          />
                                                                                        ) : Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              ?.answers
                                                                                          )[0] ==
                                                                                            "One Key" ||
                                                                                          Object.keys(
                                                                                            destructuredFields[
                                                                                              key
                                                                                            ]
                                                                                              ?.answers
                                                                                          )[0] ==
                                                                                            "مفتاح واحد" ? (
                                                                                          <img
                                                                                            src={
                                                                                              InformativeAnswer
                                                                                            }
                                                                                            alt="condition"
                                                                                          />
                                                                                        ) : (
                                                                                          ""
                                                                                        )
                                                                                      ) : key ==
                                                                                        987 ? (
                                                                                        <img
                                                                                          src={
                                                                                            NotIncluded
                                                                                          }
                                                                                          alt="not included"
                                                                                          width="22px"
                                                                                        />
                                                                                      ) : key !=
                                                                                          950 && // distance since data reset dtc
                                                                                        key !=
                                                                                          985 && // battery type hybrid lithium
                                                                                        key !=
                                                                                          945 && // inspection notes hybrid nickle
                                                                                        key !=
                                                                                          990 && // inspection notes hybrid lithium
                                                                                        key !=
                                                                                          951 && // inspection notes dtc
                                                                                        key !=
                                                                                          953 && // recorded codes dtc & hybrid lithium
                                                                                        (group?.group_id ==
                                                                                          16 ||
                                                                                          group?.group_id ==
                                                                                            21) ? (
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          ?.extended_answers
                                                                                          ?.length ==
                                                                                        0 ? (
                                                                                          <img
                                                                                            src={
                                                                                              UnFaultyAnswer
                                                                                            }
                                                                                            alt="condition"
                                                                                          />
                                                                                        ) : (
                                                                                          <img
                                                                                            src={
                                                                                              FaultyAnswer
                                                                                            }
                                                                                            alt="condition"
                                                                                          />
                                                                                        )
                                                                                      ) : destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .score ===
                                                                                        "N/A" ? null : destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .score <=
                                                                                        50 ? (
                                                                                        <img
                                                                                          src={
                                                                                            FaultyAnswer
                                                                                          }
                                                                                          alt="condition"
                                                                                        />
                                                                                      ) : (destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .field_tag ===
                                                                                          "Cosmetic Test" ||
                                                                                          destructuredFields[
                                                                                            key
                                                                                          ]
                                                                                            .field_tag ===
                                                                                            "Tire Test" ||
                                                                                          key ==
                                                                                            194 ||
                                                                                          key ==
                                                                                            983) &&
                                                                                        destructuredFields[
                                                                                          key
                                                                                        ]
                                                                                          .score <=
                                                                                          99 ? (
                                                                                        <img
                                                                                          style={{
                                                                                            width:
                                                                                              "22px",
                                                                                            height:
                                                                                              "22px",
                                                                                          }}
                                                                                          src={
                                                                                            InformativeAnswer ///////
                                                                                          }
                                                                                          alt="condition"
                                                                                        />
                                                                                      ) : (
                                                                                        <img
                                                                                          src={
                                                                                            UnFaultyAnswer
                                                                                          }
                                                                                          alt="condition"
                                                                                        />
                                                                                      )}
                                                                                    </div>
                                                                                  </div>

                                                                                  {/* here */}
                                                                                </div>
                                                                              </div>
                                                                            );
                                                                          }
                                                                        }
                                                                      )}
                                                                    </div>
                                                                    {Object.keys(
                                                                      destructuredFields
                                                                    ).length -
                                                                      1 !==
                                                                    fieldIndex ? (
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "table-row",
                                                                        }}
                                                                      >
                                                                        <td
                                                                          colSpan="2"
                                                                          style={{
                                                                            padding: 0,
                                                                            backgroundColor:
                                                                              "#fff",
                                                                          }}
                                                                        >
                                                                          <hr
                                                                            className={
                                                                              classes.rowHr
                                                                            }
                                                                          />
                                                                        </td>
                                                                      </div>
                                                                    ) : (
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "table-row",
                                                                          height:
                                                                            "8px",
                                                                        }}
                                                                      >
                                                                        <td
                                                                          colSpan="2"
                                                                          style={{
                                                                            padding: 0,
                                                                            backgroundColor:
                                                                              "#fff",
                                                                          }}
                                                                        ></td>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                );
                                                            }
                                                          )}
                                                      </Paper>
                                                    </div>
                                                  )}
                                                </div>
                                                <Hidden smDown>
                                                  <div
                                                    style={
                                                      group?.group_id == 17
                                                        ? {
                                                            height: `${ref?.current?.clientHeight}px`,
                                                          }
                                                        : {}
                                                    }
                                                  >
                                                    {}
                                                    {group?.group_id == 17 ? (
                                                      <BodyPaintResult
                                                        fields={
                                                          group?.sub_groups[0]
                                                            .fields
                                                        }
                                                      >
                                                        {console.log(
                                                          "rrrrrrrrf",
                                                          ref?.current
                                                            ?.clientHeight
                                                        )}
                                                      </BodyPaintResult>
                                                    ) : null}
                                                  </div>
                                                </Hidden>
                                              </div>
                                            );
                                          }
                                        )
                                      ) : (
                                        <div>
                                          {skelStructure.map((table) => (
                                            <div>
                                              <Skeleton
                                                variant="rect"
                                                width="100%"
                                                height="34px"
                                                style={{
                                                  margin: "20px 0 5px",
                                                }}
                                              ></Skeleton>

                                              {Object.keys(table).map((row) => (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Skeleton
                                                    variant="rect"
                                                    width="50%"
                                                    height="32px"
                                                    style={{
                                                      margin: "0 2px 5px",
                                                    }}
                                                  ></Skeleton>
                                                  <Skeleton
                                                    variant="rect"
                                                    width="50%"
                                                    height="32px"
                                                    style={{
                                                      margin: "0 2px 5px",
                                                    }}
                                                  ></Skeleton>
                                                  <div
                                                    style={{
                                                      height: "12px",
                                                    }}
                                                  >
                                                    <Skeleton
                                                      variant="rect"
                                                      width="100%"
                                                      height="2px"
                                                      style={{
                                                        margin: "0 0 14px",
                                                      }}
                                                      className={classes.rowHr}
                                                    />
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </ExpansionPanelDetails>
                                  </div>
                                  <Typography className={classes.groupNote}>
                                    {group != undefined &&
                                    DetInspRepDiscription[group["group_id"]]
                                      ? DetInspRepDiscription[group["group_id"]]
                                          .note
                                      : null}
                                  </Typography>
                                  <Typography
                                    className={clsx({
                                      [classes.groupNote]: 1,
                                      [classes.secondNote]: 1,
                                    })}
                                  >
                                    {group?.sub_groups?.find(
                                      (o) => o.id == 66
                                    ) && (
                                      <Typography
                                        style={{
                                          color: "#636363",
                                          fontSize: "12px",
                                          marginTop: "16px",
                                        }}
                                      >
                                        {t(
                                          "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report."
                                        )}
                                      </Typography>
                                    )}

                                    {group != undefined &&
                                    DetInspRepDiscription[group["group_id"]]
                                      ? DetInspRepDiscription[group["group_id"]]
                                          .secondNote
                                      : null}
                                  </Typography>
                                </ExpansionPanel>
                              ) : null;
                          })}
                        </div>
                      ) : (
                        [1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                          <Skeleton
                            style={{
                              height: "52px",
                              transform: "scale(1,0.95)",
                            }}
                            className={classes.expansionPanelSummaryRoot}
                          />
                        ))
                      )}
                    </div>
                  </Paper>
                  <div className={classes.disclaimerDiv}>
                    <Typography
                      style={
                        localStorage.getItem("language") === "ar"
                          ? {
                              fontWeight: "700",
                              fontSize: "14px",
                              color: "#535353",
                              marginBottom: "5px",
                            }
                          : {
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#535353",
                              marginBottom: "5px",
                            }
                      }
                    >
                      {t("Disclaimer")}:
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        // padding: "5px 11px 32px 11px",
                        color: "#535353",
                      }}
                    >
                      {localStorage.getItem("language") === "en" ? (
                        <>
                          <p>
                            * Markabte condition report is based on the
                            condition of the car during the time of inspection
                            only and for the points included in the report only.
                          </p>
                          <p>
                            * Markabte LLC can not be held responsible in any
                            way due to the use of this report.
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            * يعتمد تقرير مركبتي على حالة المركبة خلال وقت الفحص
                            فقط وللنقاط المشمولة بالتقرير فقط ولا يمكن تحميل
                            شركة مركبتي المسؤولية بأي شكل من الأشكال بسبب اسخدام
                            هذا التقرير.
                          </p>
                          <p>
                            * يعتمد تقرير مركبتي على قراءة عداد المسافات خلال
                            وقت الفحص وليست مسؤولة عن التحقق من صحتها.
                          </p>
                        </>
                      )}
                    </Typography>
                  </div>
                </Paper>
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Hidden>
          <InspectionReportMobile
            classes={classes}
            t={t}
            switchState={switchState}
            handleSwitchChange={handleSwitchChange}
            groups={groups}
            openTooltip={openTooltip}
            changeFaults={changeFaults}
            pointCount={pointCount}
            mmRegex={mmRegex}
            DetInspRepDiscription={DetInspRepDiscription}
            checkGroupFilter={checkGroupFilter}
            expanded={expanded}
            handleChange={handleChange}
            checkSubgroupFilter={checkSubgroupFilter}
            faultscnt={faultscnt}
            setframeFaultopen={setframeFaultopen}
            setfaultOpen={setfaultOpen}
            tooltipButtonId={tooltipButtonId}
            skelStructure={skelStructure}
            handleAnswerClick={handleAnswerClick}
            tireAgeAnswer={tireAgeAnswer}
            DetInspRepIcons={DetInspRepIcons}
            checkFieldFilter={checkFieldFilter}
            infoSpecialCase={infoSpecialCase}
            handleTooltipClose={handleTooltipClose}
            setshowfaults={setshowfaults}
            tireAgeIds={tireAgeIds}
            showfaults={showfaults}
            props={props}
            handleTooltipOpen={handleTooltipOpen}
            setframeFault={setframeFault}
            showInteriorFaults={showInteriorFaults}
            setExpanded={setExpanded}
            carDetailsPopup={carDetailsPopup}
            setCarDetailsPopup={setCarDetailsPopup}
            handleInspectionNotesDialog={handleInspectionNotesDialog}
          />
        </Grid>
      </Grid>{" "}
      {showfaults && (
        <FaultyDetails
          open={showfaults}
          setOpen={setshowfaults}
          system={faults?.field}
          faultcnt={`${faults?.extended_answers?.length} ${t("faults")}`}
        >
          <CardIterator fields={faults} carInfo={carInfo} />
        </FaultyDetails>
      )}
      <MarkabtePopup
        dir={"rtl"}
        open={frameFaultopen}
        setOpen={setframeFaultopen}
        title={frameFault.title}
        action={`${frameFault?.data?.issues?.length} ${t("Frame Issues")}`}
        headColor={"#FEFEFE"}
        cardColor={"#F9FAFF"}
        // headShadow={'0px 7.75168px 12.9195px rgba(130, 148, 154, 0.4)'}
        withdivider={true}
      >
        <FrameFaultsLis data={frameFault?.data?.issues} />
      </MarkabtePopup>
      <FaultsStepper
        open={faultOpen}
        setOpen={setfaultOpen}
        {...interiorFaults}
      />
      <ClickableAnswerDialog
        open={openAnswer}
        setOpen={setOpenAnswer}
        {...imageDialogProps}
      />
      <InspectionNotesDialog
        open={openInspection}
        setOpenInspection={setOpenInspection}
        {...inspectionNotes}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  carList: state.car.cars,
  carId: state.car.car.id, //remove?
  date: state.car.car.created_at,
  enteredBy: state.car.car.entered_by_fullname,
  points: state.forms.points,
});

const mapDispatchToProps = (dispatch) => ({
  getCarGroups(payload) {
    // gets the groups including the comments
    dispatch(getCarGroups(payload));
  },
  getCarFaults(payload) {
    dispatch(getCarFaults(payload));
  },
  getCarVisualization(payload) {
    dispatch(getCarVisualization(payload));
  },
  updateCarSale(payload) {
    dispatch(updateCarSale(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InspectionReport)
);
