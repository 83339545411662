import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";

import {
  IconButton,
  Typography,
  useMediaQuery,
  Button,
} from "@material-ui/core";

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import theme from "../../theme";
import backArrow from "../../icons/back_arrow.svg";
import useWindowSize from "../../helpers/useWindowSize";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "391px",
    [theme.breakpoints.down("sm")]: {
      // width: "100vw",
      width: "calc(100vw - 32px)",
      margin: "0 16px",
    },
  },
  titleDiv: {
    // fontWeight:''
    display: "flex",
  },
  titleDivIconButton: {
    marginLeft: "-20px",
  },
  titleDivArrow: {
    color: "#07303E",
    fontSize: "2rem",
    transform: theme.direction == "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    // marginLeft: "-8px",
  },
  titleDivTypography: {
    margin: "auto",
    paddingRight: "24px",
    fontSize: "22px",
    fontWeight: "500",
    color: "#07303E",
  },
  outputDisplay: {
    margin: "0 -24px",
    padding: "0 24px",
    backgroundColor: "#BFD6DE",
  },
  outputDisplayTypography: {
    color: "##07303E",
    fontSize: "17px",
  },
  datePicker: {
    margin: "0 -24px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 -16px",
    },
  },
  prevMonArrow: {
    position: "absolute",
    top: "18px",
    lineHeight: "0.78",
    borderRadius: "3px",
    left: "22px",
  },
  nextMonArrow: {
    position: "absolute",
    top: "18px",
    lineHeight: "0.78",
    borderRadius: "3px",
    right: "22px",
  },
  buttonDiv: {},
  button: {
    textTransform: "none",
  },
}));

const DateFilter = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    setFirst,
    dateRange,
    reportPurchaseUsers,
    setReportPurchaseUsers,
    constReportPurchaseUsers,
    setWithoutHeader,
  } = props;

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const size = useWindowSize();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [localReportPurchaseUsers, setLocalReportPurchaseUsers] =
    useState(reportPurchaseUsers);
  const [focusedInput, setFocusedInput] = useState("startDate");
  const formatString = "ddd, MMM D, YYYY";
  const { minDate, maxDate } = dateRange;

  useEffect(() => {
    setWithoutHeader(true);
    return function cleanup() {
      setWithoutHeader(false);
    };
  }, []);

  useEffect(() => {
    let tempUsers = [...constReportPurchaseUsers];
    let filteredUsers = tempUsers.filter((el) => {
      return el.dates.filter((date) => {
        return (
          moment(date)?.format("YYYY-MM-DD") >=
            (startDate?.format("YYYY-MM-DD") ??
              moment(minDate).format("YYYY-MM-DD")) &&
          moment(date)?.format("YYYY-MM-DD") <=
            (endDate?.format("YYYY-MM-DD") ??
              moment(maxDate).format("YYYY-MM-DD"))
        );
      }).length;
    });
    setLocalReportPurchaseUsers(filteredUsers);
  }, [startDate, endDate]);

  return (
    <div className={classes.root}>
      <div container className={classes.titleDiv}>
        <IconButton
          className={classes.titleDivIconButton}
          onClick={() => {
            setFirst(true);
          }}
        >
          <KeyboardArrowLeft className={classes.titleDivArrow} />
        </IconButton>
        <Typography className={classes.titleDivTypography}>
          {t("Filter on time")}
        </Typography>
      </div>
      <div className={classes.outputDisplay}>
        <Typography className={classes.outputDisplayTypography}>{`${
          startDate?.format(formatString) ??
          moment(minDate).format(formatString)
        } - ${
          endDate?.format(formatString) ?? moment(maxDate).format(formatString)
        }`}</Typography>
      </div>
      <div className={classes.datePicker}>
        <DateRangePicker
          isRTL={theme.direction == "rtl"}
          startDate={startDate}
          startDateId="start-date"
          endDate={endDate}
          endDateId="end-date"
          numberOfMonths={1}
          navPrev={
            <IconButton className={classes.prevMonArrow}>
              <img
                src={backArrow}
                alt="back_arrow_icon"
                style={
                  theme.direction === "rtl"
                    ? { transform: "rotate(180deg)" }
                    : {}
                }
              />
            </IconButton>
          }
          navNext={
            <IconButton className={classes.nextMonArrow}>
              <img
                src={backArrow}
                alt="back_arrow_icon"
                style={
                  theme.direction === "ltr"
                    ? { transform: "rotate(180deg)" }
                    : {}
                }
              />
            </IconButton>
          }
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => {
            if (!focusedInput) return; // doesn't update the focusedInput if it is trying to close the DRP
            setFocusedInput(focusedInput);
          }}
          daySize={!isSmall ? 56 : size.width * 0.13055}
          noBorder
          hideKeyboardShortcutsPanel
          withPortal={false}
          isOutsideRange={(day) => {
            if (
              day.format("YYYY-MM-DD") >=
                moment(dateRange.minDate).format("YYYY-MM-DD") &&
              day.format("YYYY-MM-DD") <=
                moment(dateRange.maxDate).format("YYYY-MM-DD")
            ) {
              return false;
            }
            return true;
          }}
          initialVisibleMonth={() => moment(dateRange.maxDate)}
        />
      </div>
      <div className={classes.buttonDiv}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => {
            setReportPurchaseUsers(localReportPurchaseUsers);
            setFirst(true);
          }}
        >
          {t("Show") +
            " " +
            localReportPurchaseUsers.length +
            " " +
            t("Results")}
        </Button>
      </div>
    </div>
  );
};

export default DateFilter;
