import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from "react-i18next";
import LoudSpeakerIcon from "./icons/LoudSpeaker";
import Odometer from "./icons/Odometer";
import theme from "../theme";
import Skeleton from "@material-ui/lab/Skeleton";
/////////////////////////////////////////////////// import ReactGa from "react-ga";
import { distanceWithCommas, numberWithCommas } from "../helpers/functions";
import ReportSaleComponent from "./ReportSale/ReportSaleComponent";
import { connect } from "react-redux";
import Stats from "../icons/Stats.svg";

const useStyles = makeStyles((theme) => ({
  actionBarRelative: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      transformOrigin: "bottom left",
      zIndex: 10,
      width: "100%",
      left: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
      height: "100%",
      position: "relative",
    },
  },
  actionBarPaper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      boxShadow: "0px -2px 4px rgba(24, 105, 133, 0.25)",
      borderRadius: 0,
      padding: "10px 24px",
    },
  },
  stickyDiv: {
    position: "sticky",
    top: "45px",
    minWidth: "290px",
    height: "max-content",
    zIndex: 10,
    [theme.breakpoints.up("md")]: {
      width: "290px",
      margin: "119px 21px 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "119px auto 0 0",
    },
    [theme.breakpoints.up("xl")]: {
      width: "374px",
    },
  },
  ActionBarPaper: {
    boxShadow: "0px 2.87561px 4.60098px rgba(10, 47, 60, 0.2)",
    backgroundColor: "#F9FAFD",
    padding: "9.33px 15.55px 16.33px",
    [theme.breakpoints.up("md")]: {
      display: "grid",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "12px 20px 21px",
    },
  },
  loudSpeakerDiv: {
    display: "flex",
    justifySelf: "center",
    marginBottom: "4.66px",
    [theme.breakpoints.up("xl")]: {
      marginBottom: "6px",
    },
  },
  loudSpeakerIcon: {
    marginRight: "7.78px",
    [theme.breakpoints.up("xl")]: {
      marginRight: "10px",
    },
  },
  actionBarHeader: {
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.up("md")]: {
      fontSize: "15.55px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
    },
  },
  actionBarSubHeader: {
    fontSize: "10.8847px",
    fontWeight: "500",
    color: "#07303E",
    justifySelf: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "10.8847px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  },
  hr: {
    width: "100%",
    margin: "7.77px 0 18.66px",
    height: "1px",
    border: "none",
    backgroundColor: "rgb(177, 177, 177)",
    [theme.breakpoints.up("xl")]: {
      margin: "10px 0 24px",
    },
  },
  featuredImageDiv: {
    //div added around image to hide image and image broken borders
    overflow: "hidden",
    width: "calc(56.76px - 2px)",
    height: "calc(43.54px - 2px)",
    borderRadius: "3.8874px",
    flexBasis: "calc(22% - 2px)",
    minWidth: "calc(22% - 2px)",
    [theme.breakpoints.up("md")]: {
      width: "calc(56.76px - 2px)",
      height: "calc(43.54px - 2px)",
      borderRadius: "3.8874px",
      flexBasis: "calc(22% - 2px)",
      minWidth: "calc(22% - 2px)",
    },
    [theme.breakpoints.up("xl")]: {
      width: "calc(73px - 2px)",
      height: "calc(56px - 2px)",
      borderRadius: "3.8874px",
      flexBasis: "calc(22% - 2px)",
      minWidth: "calc(22% - 2px)",
    },
  },
  featuredImage: {
    margin: "-1px",
    width: "56.76px",
    height: "43.54px",
    borderRadius: "3.8874px",
    flexBasis: "22%",
    minWidth: "22%",
    objectFit: "cover",
    [theme.breakpoints.up("md")]: {
      width: "56.76px",
      height: "43.54px",
      borderRadius: "3.8874px",
      flexBasis: "22%",
      minWidth: "22%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "73px",
      height: "56px",
      borderRadius: "3.8874px",
      flexBasis: "22%",
      minWidth: "22%",
    },
  },
  detailDiv: {
    display: "flex",
    flexBasis: "74.17%",
    minWidth: "74.17%",
    flexWrap: "wrap",
    marginBottom: "11.67px",
    justifyContent: "space-between",
    [theme.breakpoints.up("xl")]: {
      marginBottom: "15.68px",
    },
  },
  carNameTypography: {
    fontSize: "12.4397px",
    fontWeight: "500",
    color: "#07303E",
    // flexBasis: "100%",
    // minWidth: "100%",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      fontSize: "12.4397px",
      marginBottom: "3.11px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
      marginBottom: "4px",
    },
  },
  priceTypography: {
    fontSize: "14.1601px",
    fontWeight: "500",
    color: "#F15A29",
    [theme.breakpoints.up("md")]: {
      fontSize: "14.1601px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18.21px",
    },
  },
  odometerDiv: {
    display: "flex",
    alignItems: "center",
  },
  odometerTypography: {
    fontSize: "12.3901px",
    fontWeight: "400",
    color: "#909090",
    [theme.breakpoints.up("md")]: {
      marginLeft: "3.54px",

      fontSize: "12.3901px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "15.94px",
      marginLeft: "4.55px",
    },
  },
  valuePropesitionPaper: {
    boxShadow: "0px 2.87561px 4.60098px rgba(10, 47, 60, 0.2)",
    backgroundColor: "#F9FAFD",
    width: "100%",
    height: "118.95px",
    marginTop: "18.66px",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "0",
    [theme.breakpoints.up("xl")]: {
      height: "153px",
      marginTop: "24px",
    },
  },
  vPInnerPaper: {
    flexBasis: "29.769%",
    minWidth: "29.769%",
    borderRadius: "0",
    border: "1px solid #e1e1e1",
    boxShadow: "none",
    height: "95.63px",
    alignSelf: "center",
    [theme.breakpoints.up("xl")]: {
      height: "123px",
    },
  },
  // vpImages: {
  //   display: "block",
  //   margin: "8px auto 0",
  //   width: "66.94px",
  //   [theme.breakpoints.up("xl")]: {
  //     width: "86.1px",
  //     margin: "11px auto 0",
  //   },
  // },
  // vpTypography: {
  //   display: "block",
  //   fontSize: "10px",
  //   fontWeight: "500",
  //   textAlign: "center",
  //   [theme.breakpoints.up("xl")]: {
  //     margin: "0 auto 11px",
  //     fontSize: "13px",
  //     width: "103px",
  //   },
  // },
  buttons: {
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.5",
    // padding: "10px 48px",
    padding: "8px 48px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      padding: "8.94px 34.21px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
      padding: "11.5px 43px",
    },
  },
  firstButton: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "18px",
    },
  },
  actionBarDiv: {
    height: "51px",
    display: "flex",
    justifyContent: "space-between",
    marginInline: "16px",
    placeItems: "center",
  },
  typoTitle: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#07303E",
  },
  statNumber: {
    fontWeight: 700,
    fontSize: "18px",
    color: "#07303E",
  },
  statsDiv: {
    height: "51px",
    display: "flex",
    justifyContent: "space-between",
    marginInline: "16px",
    placeItems: "center",
  },
  totalTypo: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#07303E",
  },
  statsImg: {
    width: "36px",
    height: "30px",
    marginLeft: "16px",
    marginRight: "7px",
  },
  statsMainDiv: {
    backgroundColor: "#F9FAFD",
    border: "1px solid #DADADA",
    height: "263px",
  },
  statsSubDiv: {
    display: "flex",
    height: "59px",
    alignItems: "center",
  },
}));

const ActionBarButtons = (props) => {
  const {
    handleClickOpen,
    xLargeUp,
    smDown,
    role,
    reservation,
    history,
    carId,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Button
        className={clsx({ [classes.buttons]: 1, [classes.firstButton]: 1 })}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleClickOpen}
      >
        {t("Report Sale")}
      </Button>
      {role === "dealer owner" && !reservation.reserved && (
        <Button
          className={classes.buttons}
          variant="outlined"
          color="primary"
          fullWidth
          // disabled={true}
          onClick={() => {
            history.push({
              pathname: "/car/" + carId + "/edit-price",
              state: { carId: carId },
            });
          }}
        >
          {t("Edit Price")}
        </Button>
      )}
    </>
  );
};

const ActionBar = (props) => {
  const { t } = useTranslation();
  const {
    carId,
    updateCarSale,
    role,
    carData,
    carCard,
    carImages,
    currentcar,
    msgs,
  } = props;
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const featuredImageRef = useRef();
  const [featuredImageLoaded, setFeaturedImageLoaded] = useState(false);
  const [miniImageLoaded, setMiniImageLoaded] = useState(true);
  const [open, setOpen] = useState(false);

  const [featuredImage, setFeaturedImage] = useState();
  const xLargeUp = useMediaQuery(theme.breakpoints.up("xl"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [reservation, setReservation] = useState({
    reservedTo: null,
    reserved: false,
    id: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
    document.getElementById("root").style.filter = "blur(16px)"; // adds blur to root element
    // ReactGa.event({
    //   category: "Appointment Booking",
    //   action: "Clicked Book Free Appointment",
    //   label: "Book Appointment Action Bar",
    // });
  };

  useEffect(() => {
    setReservation(
      carData?.reservation ?? {
        reservedTo: null,
        reserved: false,
        id: null,
      }
    );
  }, [carData]);

  useEffect(() => {
    // setFeaturedImage(carImages.filter((image) => image.includes("Featured"))); // causes problems when images are intered in arabic
    setFeaturedImage(carImages[0]); // if car was entered correctly first image is featured else it would have first exterior image
  }, [carImages]);

  useEffect(() => {
    setFeaturedImageLoaded(true);
  }, [miniImageLoaded]);

  return (
    <div className={classes.stickyDiv}>
      <Hidden smDown>
        <div square>
          {window.location.href.includes("maintenance-report") ? (
            <Paper square className={classes.ActionBarPaper}>
              <div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "6px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#07303E",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    {t("Maintenance Report")}
                  </Typography>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#6B8495",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    {t(
                      "Inspection notes are available for 24hr from date of inspection."
                    )}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <hr className={classes.hr} style={{ margin: "0px" }} />
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginBottom: "16px",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#07303E",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {t("Looking to buy a car?")}
                    </Typography>
                  </div>
                  <Button
                    className={clsx({
                      [classes.buttons]: 1,
                      [classes.firstButton]: 1,
                    })}
                    style={{
                      marginBottom: "7px",
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      window.open("https://markabte.com/signup", "_blank");
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {t("Sign Up")}
                    </Typography>
                  </Button>
                </div>
              </div>
            </Paper>
          ) : // <Paper square className={classes.ActionBarPaper}>
          //   <div>
          //     <div className={classes.loudSpeakerDiv}>
          //       <LoudSpeakerIcon
          //         size={xLargeUp ? 36 : 27.25}
          //         fill={"#07303E"}
          //         className={classes.loudSpeakerIcon}
          //         style={
          //           localStorage.getItem("language") === "en"
          //             ? {}
          //             : { transform: "rotate(180deg) scaleY(-1)" }
          //         }
          //       />
          //       <Typography className={classes.actionBarHeader}>
          //         {t("Update Car Status!")}
          //       </Typography>
          //     </div>
          //     <Typography className={classes.actionBarSubHeader}>
          //       {t("Update your inventory and remove the car!")}
          //     </Typography>
          //     <hr className={classes.hr} />
          //     <div
          //       style={{
          //         display: "flex",
          //         flexWrap: "wrap",
          //         justifyContent: "space-between",
          //         position: "relative",
          //       }}
          //     >
          //       <div className={classes.featuredImageDiv}>
          //         <img
          //           loading="lazy"
          //           ref={featuredImageRef}
          //           src={featuredImage}
          //           slt="featured_image"
          //           className={classes.featuredImage}
          //         />
          //       </div>

          //       <Skeleton
          //         className={classes.featuredImage}
          //         style={{
          //           transform: "none",
          //           position: "absolute",
          //           top: "0",
          //         }}
          //       />

          //       <div className={classes.detailDiv}>
          //         {carCard?.year ? (
          //           <>
          //             <div
          //               style={{
          //                 display: "flex",
          //                 minWidth: "100%",
          //                 flexBasis: "100%",
          //                 overflow: "hidden",
          //                 flexWrap: "wrap",
          //               }}
          //             >
          //               <Typography
          //                 className={classes.carNameTypography}
          //               >{`${carCard?.year}`}</Typography>
          //               {"\u00A0"}
          //               <Typography
          //                 className={classes.carNameTypography}
          //               >{`${carCard?.make}`}</Typography>
          //               {"\u00A0"}
          //               <Typography
          //                 className={classes.carNameTypography}
          //               >{`${carCard?.model}`}</Typography>
          //               {"\u00A0"}
          //               <Typography
          //                 className={classes.carNameTypography}
          //               >{`${carCard?.trim}`}</Typography>
          //             </div>
          //             <Typography className={classes.priceTypography}>
          //               {localStorage.getItem("language") === "ar"
          //                 ? `${numberWithCommas(carCard?.price)} ${t("JD")}`
          //                 : `${t("JD")} ${numberWithCommas(carCard?.price)}`}
          //             </Typography>
          //             <div className={classes.odometerDiv}>
          //               <Odometer
          //                 size={xLargeUp ? 16 : 12.5}
          //                 fill={"#909090"}
          //                 style={{ alignSelf: "center" }}
          //               />
          //               <Typography className={classes.odometerTypography}>
          //                 {distanceWithCommas(carCard?.milage)}
          //               </Typography>
          //             </div>
          //           </>
          //         ) : (
          //           <>
          //             <Skeleton
          //               className={classes.carNameTypography}
          //               style={{
          //                 width: "50%",
          //                 height: "12.5px",
          //                 minWidth: 0,
          //                 flexBasis: "auto",
          //               }}
          //             />
          //             <Skeleton
          //               className={classes.priceTypography}
          //               style={{ width: "55%", height: "14.16px" }}
          //             />
          //             <Skeleton
          //               className={classes.odometerTypography}
          //               style={{ width: "30%", height: "12.39px" }}
          //             />
          //           </>
          //         )}
          //       </div>

          //       <hr className={classes.hr} />
          //       <ActionBarButtons
          //         xLargeUp={xLargeUp}
          //         smDown={smDown}
          //         handleClickOpen={handleClickOpen}
          //         role={role}
          //         reservation={reservation}
          //         history={props.history}
          //         carId={carId}
          //       />
          //     </div>
          //   </div>
          // </Paper>
          null}
          {/* <Hidden smDown>
            <Paper square className={classes.valuePropesitionPaper}>
              {VPObj.map((vpItem) => (
                <Paper className={classes.vPInnerPaper}>
                  {vpItem.icon}
                  <Typography className={classes.vpTypography}>
                    {vpItem.text}
                  </Typography>
                </Paper>
              ))}
            </Paper>
          </Hidden> */}
        </div>
      </Hidden>
      {!window.location.href.includes("car-maintenance-report") && (
        <Hidden mdUp>
          <div className={classes.actionBarRelative}>
            <Paper className={classes.actionBarPaper}>
              <ActionBarButtons
                xLargeUp={xLargeUp}
                handleClickOpen={handleClickOpen}
                role={role}
                reservation={reservation}
                history={props.history}
                carId={carId}
              />
            </Paper>
          </div>
        </Hidden>
      )}
      <ReportSaleComponent
        setOpen={setOpen}
        open={open}
        carId={carId}
        initialTab={!reservation.reserved ? 1 : 2}
        firstFlow={!reservation.reserved}
        reservedTo={reservation.reservedTo}
        reservationId={reservation.id}
      />
      <Hidden smDown>
        <div className={classes.statsMainDiv}>
          <div className={classes.statsSubDiv}>
            <img className={classes.statsImg} src={Stats} />
            <Typography className={classes.totalTypo}>
              {t("Total Car Stats")}
            </Typography>
          </div>
          <div className={classes.statsDiv}>
            <Typography className={classes.typoTitle}>
              • {t("Search views")}
            </Typography>
            <Typography className={classes.statNumber}>
              {numberWithCommas(currentcar.search_views)}
            </Typography>
          </div>
          <hr className={classes.hr} style={{ margin: "0px" }} />
          <div className={classes.actionBarDiv}>
            <Typography className={classes.typoTitle}>
              • {t("Detailed views")}
            </Typography>
            <Typography className={classes.statNumber}>
              {numberWithCommas(currentcar.detailed_views)}
            </Typography>
          </div>
          <hr className={classes.hr} style={{ margin: "0px" }} />
          <div className={classes.statsDiv}>
            <Typography className={classes.typoTitle}>
              • {t("Calls")}
            </Typography>
            <Typography className={classes.statNumber}>
              {numberWithCommas(currentcar.calls_number)}
            </Typography>
          </div>
          <hr className={classes.hr} style={{ margin: "0px" }} />
          <div className={classes.statsDiv}>
            <Typography className={classes.typoTitle}>
              • {t("Messages")}
            </Typography>
            <Typography className={classes.statNumber}>
              {numberWithCommas(msgs)}
            </Typography>
          </div>
        </div>
      </Hidden>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentcar: state.car.car,
  msgs: state.dealership.messages_info?.filter(
    (word) => word.history == true && word.received == true
  ).length,
});

export default withRouter(connect(mapStateToProps, null)(ActionBar));
