import { get } from "js-cookie";
import moment from "moment";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const ArColors = {
  black: "أسود",
  white: "أبيض",
  silver: "فضي",
  grey: "رمادي",
  red: "أحمر",
  blue: "أزرق",
  green: "أخضر",
  brown: "بني",
  beige: "بيج",
  burgundy: "خمري",
  gold: "ذهبي",
  yellow: "أصفر",
  orange: "برتقالي",
  purple: "بنفسجي",
};

const HexaColors = {
  black: "#000000",
  white: "#FFFFFF",
  silver: "#c0c0c0",
  grey: "#808080",
  red: "#ff0000",
  blue: "#0000ff",
  green: "#008000",
  brown: "#a52a2a",
  beige: "#f5f5dc",
  burgundy: "#800020",
  gold: "#ffd700",
  yellow: "#ffff00",
  orange: "#ffa500",
  purple: "#800080",
};

export default function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function isEnabledColor(arr, color, activeFilter) {
  if (activeFilter === "filter_exterior_color") return true;

  if (arr === undefined) return true;

  return arr.includes(color);
}
export function isEnabledMake(options, item, activeFilter) {
  return !options?.includes(item);
}

export function ArClrtoEn(i) {
  // convert arabic colors to english
  return Object.keys(ArColors).find((key) => ArColors[key] === i);
}

export function validate(fieldValidate, form, errors, setErrors, page) {
  if (typeof fieldValidate !== "object") {
    fieldValidate = form;
  }

  let temp = { ...errors };
  if ("firstName" in fieldValidate)
    temp.firstName = fieldValidate.firstName ? "" : "this field is required";
  if ("lastName" in fieldValidate)
    temp.lastName = fieldValidate.lastName ? "" : "this field is required";
  if ("phone" in fieldValidate) {
    temp.phone =
      fieldValidate.phone.length >= 13 ? "" : "invalid mobile number"; //"phone should contain 9 numbers or more";
  }
  if ("current" in fieldValidate) {
    temp.current =
      /^(?=.*[0-9])(?=.*[!@#$%^&."'<>*+=_-])[a-zA-Z0-9!@#$%^&."'<>*+=_-]{8,}$/.test(
        fieldValidate.current
      )
        ? ""
        : page == "login"
        ? "incorrect password"
        : "Password must be at least 8 characters and contain at least one number and upper case letter"; //"password should contains 8 characters 1 symbol & 1 number";
  }
  if ("password" in fieldValidate) {
    temp.password =
      /^(?=.*[0-9])(?=.*[!@#$%^&."'<>*+=_-])[a-zA-Z0-9!@#$%^&."'<>*+=_-]{8,}$/.test(
        fieldValidate.password
      )
        ? ""
        : page == "login"
        ? "incorrect password"
        : "Password must be at least 8 characters and contain at least one number and upper case letter"; //"password should contains 8 characters 1 symbol & 1 number";

    if ("confirm" in form)
      temp.confirm =
        fieldValidate.password == form.confirm
          ? ""
          : "Those passwords didn't match. Try again."; //"password does not match confirmation";
  }
  if ("confirm" in fieldValidate)
    temp.confirm =
      fieldValidate.confirm == form.password
        ? ""
        : "Those passwords didn't match. Try again."; //"password does not match confirmation";
  // if ("gender" in fieldValidate)
  //   temp.gender = fieldValidate.gender ? "" : "this field is required";
  setErrors({
    ...temp,
  });
  return Object.values(temp).every((x) => x == "");
}

export function removePhoneZero(phone) {
  var z = "";
  var x = phone.split("");
  if (phone[4] == "0") {
    x.splice(4, 1);
    for (let i = 0; i < x.length; i++) {
      z += x[i];
    }
  }
  return z;
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? i18n.t("PM") : i18n.t("AM");
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function numberWithCommas(x) {
  if (x !== undefined) {
    return x
      .toString()
      .replace(/[^0-9.]/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return null;
  }
}

export function distanceWithCommas(distanceString) {
  distanceString = distanceString ?? "";
  if (distanceString) {
    return (
      numberWithCommas(distanceString) +
      " " +
      i18n.t(distanceString.replace(/[^a-zA-Z]+/, ""))
    );
  } else {
    return distanceString;
  }
}

export function distanceToNumber(distanceString) {
  distanceString = distanceString ?? "";
  if (distanceString) {
    if (distanceString.includes(" Mile")) {
      return Number(distanceString.replace(" Mile", "")) * 1.609;
    } else {
      return Number(distanceString.replace(" Km", ""));
    }
  } else {
    return 0; // not supposed to return 0 but not not supposed to go to else, used 0 to not produce NAN erro bug
  }
}

export function getDay(day) {
  var x = "";
  switch (day) {
    case 0:
      x = "Sunday";
      break;
    case 1:
      x = "Monday";
      break;
    case 2:
      x = "Tuesday";
      break;
    case 3:
      x = "Wednesday";
      break;
    case 4:
      x = "Thursday";
      break;
    case 5:
      x = "Friday";
      break;
    case 6:
      x = "Saturday";
  }

  if (day - new Date().getDay() == 0) {
    return "Today";
  }
  if (day - new Date().getDay() == 1) {
    return "Tomorrow";
  }
  return x;
}

export function getDayName(date) {
  var myDate = date.split("-");
  var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
  return getDay(newDate.getDay());
}

export function DAT(date) {
  var dt = new Date(date);
  var month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dt);
  return `${month} ${dt.getDate()}`;
}
export function timesList(start, end) {
  return "10:30:00";
}
export function getTimeOptions(start, end) {
  if (start && end) {
    var times = [];
    var traker = start;
    while (
      moment(traker, "hh:mm:ss").format("HH:mm:ss") <=
      moment(end, "hh:mm:ss").subtract(30, "minutes").format("HH:mm:ss")
    ) {
      times.push(traker);
      traker = moment(traker, "hh:mm:ss")
        .locale("en")
        .add(30, "minutes")
        .format("HH:mm:ss");
    }
  }
  return times;
}

export function orderByDayNumber(arr) {
  //this function reorder appointments by the day number
  //it solve the problem of cross between weekend and weekbegin

  var number_array = [];
  for (var i of arr) {
    var obj = {};
    if (i.component_data.day == "Today") {
      obj = i;
      number_array[0] = obj;
    } else if (i.component_data.day == "Tomorrow") {
      obj = i;
      number_array[1] = obj;
    } else {
      obj = i;
      number_array[2] = obj;
    }
  }
  return [...number_array];
}

export function getAppts(arr) {
  var res = orderByDayNumber(arr);
  var firstSelected = undefined;
  var firstSelectedId = 0;
  if (!res[0]) {
    let x = moment().locale("en");
    var obj = {
      id: 0,
      component_data: {
        date: DAT(x.format("YYYY-MM-DD")),
        day: "Today",
        day_number: new Date().getDate(),
        time_from: "",
        time_to: "",
      },
    };

    res[0] = obj;
  } else {
    firstSelected = res[0];
    firstSelectedId = 1;
  }
  if (!res[1]) {
    let y = moment().add(1, "days").locale("en");
    var obj = {
      id: 0,
      component_data: {
        date: DAT(y.format("YYYY-MM-DD")),
        day: getDay(
          moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY")
            .add(1, "days")
            .day()
        ),
        day_number: moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY")
          .add(1, "days")
          .day(),
        time_from: "",
        time_to: "",
      },
    };

    res[1] = obj;
  } else {
    console.log("nnnnnnnnnnn", firstSelected);
    if (!firstSelected) {
      firstSelected = res[1];
      firstSelectedId = 2;
    }
  }

  if (!res[2]) {
    let y = moment().add(2, "days").locale("en");
    var obj = {
      id: 0,
      component_data: {
        date: DAT(y.format("YYYY-MM-DD")),
        day: getDay(
          moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY")
            .add(2, "days")
            .day()
        ),
        day_number: moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY")
          .add(2, "days")
          .day(),
        time_from: "",
        time_to: "",
      },
    };
    res[2] = obj;
  } else {
    if (!firstSelected) {
      firstSelected = res[2];
      firstSelectedId = 3;
    }
  }
  console.log("nnnnnnnnnnnn", firstSelected);
  return {
    res: res,
    firstSelected: firstSelected,
    firstSelectedId: firstSelectedId,
  };
}
export function formatDate(date, oldf, newf) {
  return moment(date, oldf, true).locale("en").format(newf);
}

export function workEnded(end, to) {
  if (!end) return true;
  if (moment(end).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
    return (
      moment(`${end} ${to}`).format("HH:mm:ss") < moment().format("HH:mm:ss")
    );
  } else {
    return false;
  }
}

export function timerSinceDisplay(startDate, short = true) {
  var difference = new Date() - new Date(startDate); // in milli_sec
  var toSeconds = 1000;
  var toMinutes = toSeconds * 60;
  var toHours = toMinutes * 60;
  var toDays = toHours * 24; //milli_sec sec min hour
  // var since = Math.floor(difference / toDays); //to limit the difference to days and not hours,...
  var seconds = Math.floor(difference / toSeconds); //to limit the difference to days and not hours,...
  var minutes = Math.floor(difference / toMinutes); //to limit the difference to days and not hours,...
  var hours = Math.floor(difference / toHours); //to limit the difference to days and not hours,...
  var days = Math.floor(difference / toDays);

  var since = short
    ? days
      ? { time: days, unit: "days" }
      : hours
      ? { time: hours, unit: "hours" }
      : minutes
      ? { time: minutes, unit: "minutes" }
      : seconds
      ? { time: seconds, unit: "seconds" }
      : { time: difference, unit: "milli seconds" }
    : { days, hours, minutes, seconds };
  return since;
}

export const convertPhone = (name, value) => ({
  target: {
    name,
    value,
  },
});

const CryptoJS = require("crypto-js");

export const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export const bodyPaintColorSelector = (score) => {
  if (score == 0) {
    return "#DD4B4B";
  }
  if (score == 100) {
    return "#37C534";
  }
  return "white"; // "N/A"
};
