import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Buyer from "../../icons/Buyer.svg";
import IndependentBuyer from "../../icons/IndependentBuyer.svg";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "16px",
    //    minHeight:'500px'
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
  },
  blockD: {
    borderRadius: "5px",
    border: "1px solid #186985",
    boxShadow: "0px 1.32929px 1.99394px rgba(15, 71, 90, 0.4)",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  selectionBox: {
    cursor: "pointer",
    fontSize: "14px",
    padding: "15px 5px",
    margin: "5px",
    color: "#186985",
    fontWeight: "500",
    textAlign: "center",
    borderRadius: "3px",
    fontFamily: "Poppins",
  },
  selectedBox: {
    backgroundColor: "#CCE0E7",
  },
  inline: {
    width: "100%",
    display: "inline-flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: "3px",
    fontSize: "16px",
    fontWeight: "medium",
    margin: "24px 0",
  },
  hr: {
    width: "100%",
    background: "#CDCDCD",
    height: "1px",
    border: "0px",
    "flex-shrink": 0,
    margin: "12px 0",
  },
  nextButton: {
    display: "flex",
    alignItems: "flex-end",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "clamp(12px,1vw,14px)",
    // position:'absolute',
    bottom: "20px",
    width: "86%",
    // margin: "5px auto",
    margin: "auto",
    left: "50%",
    marginLeft: "-43%",
    padding: "10px 0",
    [theme.breakpoints.up("md")]: {
      //   margin: "5px 32px",
      //   padding:'12px 45px',
      //   fontSize:'18px',
      //   float:'right',
      //   right:'20px',
      //   maxWidth: "193px",
      marginTop: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      //   postion:'relative',
      //   margin: "5px auto",
      //   width:'94%',
      marginTop: "70px",
    },
  },
}));

function ReportSelection(props) {
  const { setData, data, handleNext } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [buyer, setBuyer] = useState("Deposit");
  const [buyerType, setBuyerType] = useState("Markabte Buyer");
  //card single
  function radioCard(title, action, icon) {
    return (
      <div
        className={classes.inline}
        onClick={() => setData({ ...data, buyerType: title })}
      >
        <div style={{ display: "inline-flex", alignItems: "flex-start" }}>
          <FormControlLabel
            value={title}
            style={{ fontWeight: "bold" }}
            control={<Radio color={"primary"} />}
          />
          <div>
            <Typography style={{ fontWeight: "600" }}>{t(title)}</Typography>
            <Typography>{t(action)}</Typography>
          </div>
        </div>
        <div>
          <img src={icon} />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.mainContainer}>
      <div className={classes.flexContainer}>
        <div className={classes.blockD}>
          <div
            style={{ width: "35%" }}
            className={`${classes.selectionBox} ${
              data.buyer == "Deposit" && classes.selectedBox
            }`}
            onClick={() => setData({ ...data, buyer: "Deposit" })}
          >
            {t("Deposit")}
          </div>
          <div
            style={{ width: "65%" }}
            className={`${classes.selectionBox}  ${
              data.buyer == "Transfer of Ownership" && classes.selectedBox
            }`}
            onClick={() => setData({ ...data, buyer: "Transfer of Ownership" })}
          >
            {t("Transfer of Ownership")}
          </div>
        </div>
        <div>
          <Typography style={{ marginTop: "27px", marginBottom: "14px" }}>
            {t("Select buyer type")}:
          </Typography>
        </div>
        <div>
          <FormControl component="fieldset" className={classes.radiotext}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={data.buyerType}
            >
              {radioCard(
                "Markabte Buyer",
                "A buyer who was send to your dealership through Markabte.",
                Buyer
              )}
              <Divider className={classes.hr} />
              {radioCard(
                "Independent Buyer",
                "A buyer who has no relation with Markabte.",
                IndependentBuyer
              )}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <Button
        className={classes.nextButton}
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => handleNext()}
      >
        {t("Next")}
      </Button>
    </div>
  );
}

export default ReportSelection;
