import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Score from "./ScorePrototype";
import CircularProg from "./CircularProg";

import DTC from "../icons/DTC.svg";
import Frame from "../icons/Frame.svg";
import HybridBattery from "../icons/HybridBattery.svg";

import theme from "../theme";

import Officer from "../icons/Officer.svg"; // turn into component
// import Calendar from "../icons/calendar.svg"; // turn into component
import Skeleton from "@material-ui/lab/Skeleton";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ReportSummaryCalendar from "../icons/ReportSummaryCalendar.svg";

const useStyles = makeStyles((theme) => ({
  smPapers: {
    borderRadius: "0",
    boxShadow: "unset",
    margin: "24px 012",
    [theme.breakpoints.up("md")]: {
      all: "initial",
      width: "100%",
    },
  },
  header: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "16px 0 16px",
      color: "#186985",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#186985",
      padding: "0",
      margin: "34px 0",
    },
  },
  reportIcons: {
    height: "15px",
    width: "15px",
    margin: "0 5px 0 0",
    [theme.breakpoints.up("md")]: {
      height: "clamp(15px,2vw,24px)",
      width: "clamp(15px,2vw,24px)",
    },
  },
  inspDetText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#636363",
    fontSize: "10px",
    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(10px,1vw,16px)",
    },
  },
  inspDetSubText: {
    color: "#07303E",
    fontSize: "10px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(10px,1vw,16px)",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "21px",
    },
  },
  noOfPoints: {
    fontSize: "12px",
    fontWeight: "500",
    color: "white",
    marginRight: "3px",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
  },
  conditionReportHr: {
    height: "1px",
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, #0c6885 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.up("md")]: {
      margin: "32px 0 30px",
      backgroundImage:
        "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    },
  },
  titleDiv: {
    margin: "0 -15px",
    padding: "0 15px",
    [theme.breakpoints.up("md")]: {
      borderBottom: "none",
    },
  },
  borderedDiv: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: "15px",
    },
  },
  reportSummaryDiv: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {},
  },
  reportSummaryText: {
    color: "white",
    fontSize: "14px",
    fontWeight: "500",
    paddingLeft: "18px",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      alignSelf: "center",
      paddingLeft: "20px",
    },
  },
  visualScoreGrid: {
    padding: "0 10px 20px",
    [theme.breakpoints.up("md")]: {
      padding: "15px 0px 20px",
      borderBottom: "1px solid #CDCDCD",
    },
  },
  rep: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#00BDFF",
    display: "flex",
    justifyContent: "center",
  },
  arrow: {
    height: "15px",
  },
  groupScore: {
    color: "#07303E",
    fontSize: "12px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  },
  groupTitle: {
    color: "#186985",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  },
  Divider: {
    height: 1,
    backgroundColor: "#D2D2D2",
    marginLeft: "24px",
  },
  headerWrap: {
    margin: "13px 0",
    [theme.breakpoints.up("md")]: {
      margin: "34px 0",
    },
  },
  reportPaper: {
    background: "#07303E",
    borderRadius: "8.3px",
    margin: "0",
    [theme.breakpoints.up("md")]: {
      background: "#07303E",
      borderRadius: "16px",
      // margin: "34px 0", ///////////////this
    },
  },
  reportSummarySubInfo: {
    padding: "20px",
    borderBottom: "2px solid #CDCDCD",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    background: "#F9FAFD",
    [theme.breakpoints.up("md")]: {
      marginBottom: "-1px",
      whiteSpace: "nowrap",
      justifyContent: "space-between",
      background: "#F9FAFD",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px 12px 12px 0px",
    },
  },
  scoreGridItem: {
    padding: "5.5px",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: "5.5px 20px",
      justifyContent: "space-between",
    },
  },
  markabteScoreDiv: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px",
      paddingTop: "7.5px",
    },
  },
  circularProgDiv: {
    margin: "10px 15px 10px auto",
  },
  firstTableCell: {
    display: "flex",
  },
  secondTableCell: {
    display: "flex",
  },
  newGroupTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: "500",
    },
    whiteSpace: "nowrap",
  },
  newSubGroupTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    fontSize: "12px",
    fontWeight: "400",
    color: "#07303E",
    whiteSpace: "nowrap",
    margin: "auto",
  },

  groupIcon: {
    width: "20px",
    height: "28px",
    marginRight: "7px",
  },
  showFullReportButton: {
    padding: "10px 18px",
    textTransform: "none",
    justifyContent: "flex-end",
    alignContent: "center",
  },
  computerAndFrame: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  tableCellDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      display: "list-item",
      marginLeft: "15px",
    },
  },
  groupsDiv: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15.5px",
    },
  },
  outerGroupDiv: {
    width: "50%",
    borderBottom: "1px solid #c4c4c4",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  groupDiv: {
    display: "flex",
    borderRight: "1px solid #c4c4c4",
    margin: "16px 0",
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      borderRight: "none",
      justifyContent: "space-between",
      margin: "6px 0",
    },
  },
  dtcGroupDiv: {},
  hybridGroupDiv: {
    display: "none",
  },
  frameGroupOuterDiv: {
    width: "100%",
  },
  frameGroupDiv: {
    width: "100%",
    borderBottom: "none",
  },
  withotBorderRight: {
    borderRight: "none",
  },
  pointDiv: {
    display: "flex",
    placeSelf: "center",
  },
  pointFirstTypography: {
    color: "#07303E",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "4px",
      fontSize: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "4.7px",
      fontSize: "27px",
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: "5.6px",
      fontSize: "32px",
    },
  },
  pointSecondTypography: {
    color: "#626465",
    fontSize: "10px",
    fontWeight: 400,
    maxWidth: "48px",
    whiteSpace: "normal",
    alignSelf: "center",
    minWidth: "min-content",
    [theme.breakpoints.up("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "13px",
    },
  },
  calendarIcon: {
    marginRight: "6px",
    alignSelf: "baseline",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      width: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "18px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "21.34px",
    },
  },
}));

const nth = (day) => {
  if (day > 3 && day < 21) return day + "th";
  switch (day % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
};

const ConditionReport = (props) => {
  const { t, i18n } = useTranslation();
  const mediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();
  const {
    noOfPoints,
    enteredBy,
    dateObject,
    groups,
    carId,
    history,
    score,
    scroll,
    withoutLink,
  } = props;
  const [totalPointsCount, setTotalPointsCount] = useState(0);
  useEffect(() => {
    if (groups != undefined) {
      setTotalPointsCount(0);
      groups.groups.map((group, i) => {
        if (group.group_id !== 15) {
          setTotalPointsCount((prev) => prev + group.group_points);
        }
      });
    }
  }, [groups]);
  let computerScanGroup = groups?.groups?.filter((el) => el?.group_id == 16)[0];
  let frameGroup = groups?.groups?.filter((el) => el?.group_id == 15)[0];
  let hybridLithiumGroup = groups?.groups?.filter(
    (el) => el?.group_id == 21
  )[0];
  let is_inspection = window.location.href.includes("inspection-report");
  return (
    <Paper
      style={
        is_inspection
          ? { padding: "0 16px 32px" }
          : { padding: "0 0 16px", width: "100%" }
      }
      className={classes.smPapers}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        justify="space-between"
        classes={{ root: classes.headerwrap }}
      >
        <Grid item xs zeroMinWidth style={{ maxWidth: "min-content" }}>
          <Typography
            style={
              localStorage.getItem("language") === "ar"
                ? { fontWeight: "700" }
                : { fontWeight: "500" }
            }
            className={classes.header}
            // noWrap
          >
            {t("Inspection Report Summary")}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs>
            <Divider component="div" classes={{ root: classes.Divider }} />
          </Grid>
        </Hidden>
      </Grid>
      {/* score is just visual now will be added after scoring sprint */}
      <Paper classes={{ root: classes.reportPaper }}>
        <div className={classes.reportSummaryDiv}>
          <Grid container style={{ alignItems: "center", height: "59px" }}>
            <div>
              <Typography
                style={
                  localStorage.getItem("language") === "ar"
                    ? {
                        fontWeight: "600",
                      }
                    : {}
                }
                className={classes.reportSummaryText}
              >
                {t("Report Summary")}
              </Typography>
            </div>
          </Grid>
        </div>
        <Grid container classes={{ root: classes.reportSummarySubInfo }}>
          <div>
            <div style={{ display: "flex" }}>
              <img
                src={ReportSummaryCalendar}
                className={classes.calendarIcon}
              />
              <Typography
                style={
                  localStorage.getItem("language") === "ar"
                    ? {
                        fontWeight: "600",
                        color: "#626465",
                        fontSize: "15.53px",
                      }
                    : {
                        fontWeight: "400",
                        color: "#626465",
                        fontSize: "15.53px",
                      }
                }
                className={classes.inspDetSubText}
              >
                {t("Inspected On")}
                {": "}
              </Typography>
            </div>

            <Typography
              style={
                localStorage.getItem("language") === "ar"
                  ? {
                      fontWeight: "600",
                      color: "#07303E",
                      fontSize: "15.53px",
                    }
                  : {
                      fontWeight: "400",
                      color: "#07303E",
                      fontSize: "15.53px",
                    }
              }
              className={classes.inspDetSubText}
            >
              {" "}
              {localStorage.getItem("language") === "en"
                ? nth(dateObject.getDate())
                : dateObject.getDate()}{" "}
              {t(
                dateObject.toLocaleString("default", {
                  month: "long",
                })
              )}{" "}
              {dateObject.getFullYear()}
            </Typography>
          </div>
          <div className={classes.pointDiv}>
            <Typography className={classes.pointFirstTypography}>
              {totalPointsCount}
              {"\u00A0"}
            </Typography>
            <Typography className={classes.pointSecondTypography}>
              {t("Inspected Point")}
            </Typography>
          </div>
        </Grid>

        <Grid
          container
          className={classes.visualScoreGrid}
          // style={{ background: "#f3f3f3" }}
          style={{ background: "white" }} //////// check this and the rest
        >
          {groups !== undefined
            ? groups.groups.map(
                (group, i) =>
                  group.group_id !== 15 &&
                  group.group_id !== 16 &&
                  group.group_id !== 21 && (
                    <Grid
                      container
                      xs={12}
                      lg={6}
                      classes={{ root: classes.scoreGridItem }}
                    >
                      <Grid item>
                        <Typography
                          style={
                            localStorage.getItem("language") === "ar"
                              ? { fontWeight: "600" }
                              : { fontWeight: "400" }
                          }
                          className={classes.groupTitle}
                        >
                          {group.title}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Typography
                          style={
                            localStorage.getItem("language") === "ar"
                              ? { fontWeight: "600" }
                              : { fontWeight: "500" }
                          }
                          className={classes.groupScore}
                        >
                          {!group.group_score || group.group_points == 0
                            ? "Not Available"
                            : !Number(group.group_score?.fields?.group_score) &&
                              group.group_score?.fields?.group_score !== "0.00"
                            ? t(group.group_score?.fields?.group_score)
                            : Math.floor(
                                group.group_score?.fields?.group_score
                              ) + "%"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Score
                          value={
                            !group.group_score ||
                            !Number(group.group_score?.fields?.group_score)
                              ? 0
                              : Math.floor(
                                  group.group_score?.fields?.group_score
                                )
                          }
                          unAvailable={
                            !!!Number(group.group_score?.fields?.group_score)
                          }
                        />
                      </Grid>
                    </Grid>
                  )
              )
            : [1, 2, 3, 4, 5, 6, 7].map(() => {
                return (
                  <Grid
                    container
                    xs={12}
                    lg={6}
                    classes={{ root: classes.scoreGridItem }}
                  >
                    <Grid item>
                      <Skeleton
                        style={{ width: "140px", height: "21px" }}
                        className={classes.groupTitle}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <Skeleton
                        style={{ width: "34px", height: "21px" }}
                        className={classes.groupScore}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton
                        style={{
                          width: "100%",
                          height: "6px",
                          marginTop: "7px",
                          transform: "none",
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
        </Grid>
        <Table style={{ backgroundColor: "white" }}>
          <div className={classes.groupsDiv}>
            <div
              className={clsx({
                [classes.outerGroupDiv]: 1,
                [classes.hybridGroupDiv]: !hybridLithiumGroup?.title,
              })}
            >
              <div
                className={clsx({
                  [classes.groupDiv]: 1,
                  [classes.hybridGroupDiv]: !hybridLithiumGroup?.title,
                })}
              >
                {groups !== undefined && hybridLithiumGroup?.title ? (
                  <>
                    {" "}
                    <div className={classes.tableCellDiv}>
                      <Hidden smDown>
                        <img
                          className={classes.groupIcon}
                          src={HybridBattery}
                        />
                      </Hidden>
                      <div style={{ display: "flex" }}>
                        <Typography className={classes.newGroupTitle}>
                          {t("Hybrid Battery")}
                        </Typography>
                        <Typography className={classes.newSubGroupTitle}>
                          {"\u00A0"}
                          {t("(Lithium)")}:
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography
                        style={
                          typeof hybridLithiumGroup?.answers !== "string"
                            ? { fontSize: "23px" }
                            : { fontSize: "14px", lineHeight: "34.5px" }
                        }
                        className={classes.newGroupTitle}
                      >
                        {"\u00A0"}
                        {t(hybridLithiumGroup?.answers)}
                      </Typography>
                      {typeof hybridLithiumGroup?.answers !== "string" && (
                        <Typography
                          style={{ fontSize: "12px", color: "#9A9A9A" }}
                        >
                          {"\u00A0"}
                          {t("Faults")}
                        </Typography>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className={classes.outerGroupDiv}>
              <div
                className={clsx({
                  [classes.groupDiv]: 1,
                  [classes.dtcGroupDiv]: !hybridLithiumGroup?.title,
                  [classes.withotBorderRight]: hybridLithiumGroup?.title,
                })}
              >
                {groups !== undefined ? (
                  <>
                    <div className={classes.tableCellDiv}>
                      <Hidden smDown>
                        <img className={classes.groupIcon} src={DTC} />
                      </Hidden>
                      <div style={{ display: "flex" }}>
                        <Typography className={classes.newGroupTitle}>
                          {t(computerScanGroup?.title)}
                        </Typography>
                        <Typography className={classes.newSubGroupTitle}>
                          {"\u00A0"}
                          {t("(Markabte)")} :
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography
                        style={
                          typeof computerScanGroup?.answers !== "string"
                            ? { fontSize: "23px" }
                            : { fontSize: "14px", lineHeight: "34.5px" }
                        }
                        className={classes.newGroupTitle}
                      >
                        {"\u00A0"}
                        {t(computerScanGroup?.answers)}
                      </Typography>
                      {typeof computerScanGroup?.answers !== "string" && (
                        <Typography
                          style={{ fontSize: "12px", color: "#9A9A9A" }}
                        >
                          {"\u00A0"}
                          {t("Faults")}
                        </Typography>
                      )}
                    </div>
                  </>
                ) : (
                  <Skeleton
                    style={{
                      width: "50%",
                      height: "50px",
                      marginInline: "20px",
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={clsx({
                [classes.outerGroupDiv]: 1,
                [classes.frameGroupOuterDiv]: hybridLithiumGroup?.title,
              })}
            >
              <div
                className={clsx({
                  [classes.groupDiv]: 1,
                  [classes.frameGroupDiv]: hybridLithiumGroup?.title,
                  [classes.withotBorderRight]: 1,
                })}
              >
                {groups !== undefined ? (
                  <>
                    <div className={classes.tableCellDiv}>
                      <Hidden smDown>
                        <img className={classes.groupIcon} src={Frame} />
                      </Hidden>
                      <Hidden mdUp>
                        <div style={{ display: "flex" }}>
                          <Typography className={classes.newGroupTitle}>
                            {t(frameGroup?.title)}
                          </Typography>
                          <Typography
                            style={
                              localStorage.getItem("language") === "ar"
                                ? {
                                    marginLeft: "38px",
                                  }
                                : {}
                            }
                            className={classes.newSubGroupTitle}
                          >
                            {"\u00A0"}
                            {t("(3rd Party)")} :
                          </Typography>
                        </div>
                      </Hidden>
                      <Hidden smDown>
                        <Typography className={classes.newGroupTitle}>
                          {t(frameGroup?.title)}
                        </Typography>
                        <Typography className={classes.newSubGroupTitle}>
                          {"\u00A0"}
                          {t("(3rd Party)")} :
                        </Typography>
                      </Hidden>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography
                        style={
                          typeof frameGroup?.answers !== "string"
                            ? { fontSize: "23px" }
                            : { fontSize: "14px", lineHeight: "34.5px" }
                        }
                        className={classes.newGroupTitle}
                      >
                        {"\u00A0"}
                        {t(frameGroup?.answers)}
                      </Typography>
                      {typeof frameGroup?.answers !== "string" && (
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#9A9A9A",
                          }}
                        >
                          {"\u00A0"}
                          {t("Good")}
                        </Typography>
                      )}
                    </div>
                  </>
                ) : (
                  <Skeleton
                    style={{
                      width: "50%",
                      height: "50px",
                      marginInline: "20px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Table>
        <Hidden smDown>
          <Link style={{ color: "#00BDFF" }}>
            <Grid container>
              <Button
                className={classes.showFullReportButton}
                fullWidth
                onClick={() => {
                  scroll();
                }}
              >
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "flex",
                      color: "#00BDFF",
                    }}
                  >
                    {t("Show full report")}
                  </Typography>
                </Grid>
                <Grid item>
                  <ExpandMoreIcon
                    className={classes.arrow}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      color: "#00BDFF",
                      height: "100%",
                    }}
                  />
                </Grid>
              </Button>
            </Grid>
          </Link>
        </Hidden>
        <Hidden mdUp>
          <div style={{ color: "#00BDFF", height: "44px" }}>
            {!withoutLink && (
              <Hidden mdUp>
                <Grid
                  container
                  className={classes.borderedDiv}
                  style={{ borderTop: " none", justifyContent: "center" }}
                >
                  <Grid item sm={12} className={classes.rep}>
                    <Link style={{ color: "#00BDFF" }}>
                      <Grid container>
                        <Button
                          style={{
                            padding: "13px 0 11px",
                            textTransform: "none",
                          }}
                          fullWidth
                          onClick={() => {
                            scroll();
                          }}
                        >
                          <Grid item>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                color: "#45A5C7",
                              }}
                            >
                              {t("Show full report")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ArrowBackIosIcon
                              className={classes.arrow}
                              style={{
                                transform: "rotate(270deg)",
                                flexDirection: "column",
                                justifyContent: "center",
                                color: "#00BDFF",
                              }}
                            />
                          </Grid>
                        </Button>
                      </Grid>
                    </Link>
                  </Grid>
                </Grid>
              </Hidden>
            )}
          </div>
        </Hidden>
      </Paper>
    </Paper>
  );
};

export default withRouter(ConditionReport);
