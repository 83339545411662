import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const RadioButtonInput = (props) => {
  const { onChange, value, options, formTitle, disabled, field } = props;

  const handleChange = (event) => {
    let option = options.find((el) => el.id.toString() === event.target.value);
    onChange(event, { id: event.target.value, field: field, option: option });
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="label" color={"secondary"}>
        {formTitle}
      </FormLabel>
      <RadioGroup value={value.toString()} onChange={handleChange}>
        {options.map((option, i) => (
          <FormControlLabel
            disabled={disabled}
            value={option.id.toString()}
            control={<Radio color={"primary"} />}
            label={option.title}
            key={`field_radio_button_${i}_${option.id}`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonInput;
