import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Button,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Calendar from "../icons/ReportSummaryCalendar.svg";

const useStyles = makeStyles((theme) => ({
  smPapers: {
    borderRadius: "0",
    boxShadow: "unset",
    margin: "24px 012",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      all: "initial",
      width: "100%",
    },
  },
  headerWrap: {
    margin: "13px 0",
    [theme.breakpoints.up("md")]: {
      margin: "34px 0",
    },
  },
  header: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "16px 0 16px",
      color: "#186985",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#186985",
      padding: "0",
      margin: "34px 0",
    },
  },
  Divider: {
    height: 1,
    backgroundColor: "#D2D2D2",
    marginLeft: "24px",
  },
  inspectionTimeMainDiv: {
    backgroundColor: "#F9FAFD",
    border: "1px solid #E0E0E0",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 11px",
      marginBottom: "20px",
      padding: "20px 20px 0",
    },
  },
  inspectionTimeDiv: {
    display: "flex",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "12px",
    },
  },
  calendarIcon: {
    height: "21px",
    marginRight: "7.5px",
    [theme.breakpoints.down("sm")]: {
      height: "17px",
    },
  },
  inspectedOnTypography: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#07303E",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  inspectionDateTypography: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: 500,
    },
  },
  inspectionTimeExpiryDiv: {
    display: "flex",
  },
  expiryFirstTypography: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#636363",
    marginRight: "5px",
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11.5px",
    },
  },
  expirySecondTypography: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#636363",
    display: "contents",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11.5px",
    },
  },
  viewInspectionNotesDiv: {
    borderTop: "1px solid #c4c4c4",
    marginTop: "12px",
  },
  arrow: {
    height: "15px",
  },
}));
const nth = (day) => {
  if (day > 3 && day < 21) return day + "th";
  switch (day % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
};

const InspectionTimeDiv = (props) => {
  const { dateObject, setCarDetailsPopup, scroll, Expired } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.smPapers}>
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        justify="space-between"
        classes={{ root: classes.headerwrap }}
      >
        <Grid item xs zeroMinWidth style={{ maxWidth: "min-content" }}>
          <Typography
            style={
              localStorage.getItem("language") === "ar"
                ? { fontWeight: "700" }
                : { fontWeight: "500" }
            }
            className={classes.header}
          >
            {t("Maintenance Report Summary")}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs>
            <Divider component="div" classes={{ root: classes.Divider }} />
          </Grid>
        </Hidden>
      </Grid>
      <div className={classes.inspectionTimeMainDiv}>
        <div className={classes.inspectionTimeDiv}>
          <img src={Calendar} className={classes.calendarIcon} />
          <Typography className={classes.inspectedOnTypography}>
            {t("Inspection Date")}
          </Typography>
          <Typography className={classes.inspectionDateTypography}>
            {localStorage.getItem("language") === "en"
              ? nth(dateObject.getDate())
              : dateObject.getDate()}{" "}
            {t(
              dateObject.toLocaleString("default", {
                month: "long",
              })
            )}{" "}
            {dateObject.getFullYear()}
          </Typography>
        </div>
        <div className={classes.inspectionTimeExpiryDiv}>
          <Typography className={classes.expiryFirstTypography}>
            {`* ${t("Inspection notes are available for")}`}{" "}
            <Typography className={classes.expirySecondTypography}>
              {t("24hr from date of inspection.")}
            </Typography>
          </Typography>
        </div>
        <Hidden mdUp>
          <div className={classes.viewInspectionNotesDiv}>
            <Button
              style={{
                padding: "16px 0",
                textTransform: "none",
              }}
              disabled={Expired}
              fullWidth
              onClick={() => {
                setCarDetailsPopup(true);
                scroll();
              }}
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: Expired ? "#aaa" : "#45A5C7",
                  }}
                >
                  {t("View Insepction Notes")}
                </Typography>
              </Grid>
              <Grid item>
                <ArrowBackIosIcon
                  className={classes.arrow}
                  style={{
                    transform:
                      localStorage.getItem("language") != "ar" &&
                      "rotate(180deg) translate(0px, -3px)",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: Expired ? "#aaa" : "#45A5C7",
                  }}
                />
              </Grid>
            </Button>
          </div>
        </Hidden>
      </div>
    </Paper>
  );
};

export default InspectionTimeDiv;
