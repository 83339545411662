import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { Grid, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Typography from "@material-ui/core/Typography";
import PositionSwitch from "./PositionSwitch";

import theme from "../theme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  fullland: {
    // styles for image in landscape orientation
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
  },
  fullport: {
    // styles for image in portrait orientation
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(90deg)",
    width: "auto",
    transition: "none",
  },
  grid: {
    // styles for the grid that contains everything
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    height: "100%",
    transition: "none",
    backgroundColor: "black",
  },
  portexit: {
    // styles for X in portrait orientation
    position: "fixed",
    top: "95%",
    left: "95%",
    transform: "translate(-50%, -50%) rotate(90deg)",
    zIndex: "2",
    borderRadius: "50%",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    display: "flex",
  },
  landexit: {
    // styles for X in landscape orientation
    position: "fixed",
    top: "5%",
    left: "95%",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    display: "flex",
    zIndex: 2,
  },
  portdiv: {
    // styles for div that contains the arrows in portrait orientation
    transform: "translate(-50%,-50%) rotate(90deg)",
    transformOrigin: "50% 50%",
    top: "50%",
    left: "50%",
  },
  imageButtonLeft: {
    position: "fixed",
    top: "50%",
    transform: "translate(0,-50%)",
    height: "80px",
    width: "50px",
    minWidth: "50px",
    padding: "0",
    display: "flex",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    borderRadius: 0,
  },
  imageButtonRight: {
    position: "fixed",
    top: "50%",
    transform: "translate(0,-50%)",
    height: "80px",
    width: "50px",
    minWidth: "50px",
    padding: "0",
    display: "flex",
    right: 0,
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    borderRadius: 0,
  },
  imageStepCountLand: {
    position: "absolute",
    top: "calc(100% - 40px)",
    transform: "translate(0,0)",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
  },
  imageStepCountPort: {
    position: "absolute",
    left: "40px",
    top: 0,
    transform: "translate(0,0) rotate(90deg)",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    transformOrigin: "top left",
  },
  portSwitch: {
    transform: "rotate(90deg)",
  },
  landSwitch: {},
  // switch: {
  //   position: "relative",
  //   display: "inline-block",
  //   width: "158px",
  //   height: "24px",
  // },
  // switchInput: {
  //   opacity: 0,
  //   width: 0,
  //   height: 0,
  // },
  // sliderExterior: {
  //   position: "absolute",
  //   cursor: "pointer",
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  //   backgroundColor: "#0c6885",
  //   "-webkit-transition": ".4s",
  //   transition: ".4s",
  //   borderRadius: "15px",

  //   "&::before": {
  //     position: "absolute",
  //     content: '" "',
  //     height: "24px",
  //     width: "84px",
  //     left: "0px",
  //     bottom: "0px",
  //     backgroundColor: "white",
  //     "-webkit-transition": ".4s",
  //     transition: ".4s",
  //     borderRadius: "15px",
  //   },
  // },
  // sliderInterior: {
  //   position: "absolute",
  //   cursor: "pointer",
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  //   backgroundColor: "#0c6885",
  //   "-webkit-transition": ".4s",
  //   transition: ".4s",
  //   borderRadius: "15px",

  //   "&::before": {
  //     position: "absolute",
  //     content: '" "',
  //     height: "24px",
  //     width: "72px", // smaller white slider because of smaller text
  //     left: "12px", // to compensate for smaller white slider (width)
  //     bottom: "0px",
  //     backgroundColor: "white",
  //     "-webkit-transition": ".4s",
  //     transition: ".4s",
  //     borderRadius: "15px",
  //     transform: "translateX(74px)",
  //   },
  // },
  // selectedText: {
  //   width: "79px",
  //   paddingLeft: "24px",
  //   paddingRight: "20px",
  //   fontSize: "10px",
  //   fontWeight: "500",
  //   zIndex: "2",
  //   position: "relative",
  //   color: "#07303E",
  //   "-webkit-transition": ".4s",
  //   transition: ".4s",
  //   "&:hover": {
  //     cursor: "pointer",
  //   },
  // },
  // unselectedText: {
  //   width: "79px",
  //   paddingLeft: "24px",
  //   paddingRight: "20px",
  //   fontSize: "10px",
  //   fontWeight: "400",
  //   zIndex: "2",
  //   position: "relative",
  //   color: "white",
  //   "-webkit-transition": ".4s",
  //   transition: ".4s",
  //   "&:hover": {
  //     cursor: "pointer",
  //   },
  // },
  landPositionSwitch: {
    position: "absolute",
    top: "10%",
    left: "50%",
    transform: "translate(-50%,-100%)",
    height: "24px",
    zIndex: "2",
  },
  portPositionSwitch: {
    position: "absolute",
    left: "90%",
    top: "50%",
    transform: "translate(-42%,0) rotate(90deg)", // 42?????
    height: "24px",
    zIndex: "2",
  },
  arabicRotate: {
    transform: "rotate(180deg)",
  },
});

function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

// const PositionSwitch = (props) => {
//   const { t, i18n } = useTranslation();
//   const { className, onChange } = props;
//   const classes = useStyles();
//   const [position, setPosition] = useState("exterior");
//   const handleInputChange = (event) => {
//     position === "exterior" ? setPosition("interior") : setPosition("exterior");
//     onChange(event);
//   };
//   let sliderClass = classes.sliderExterior;
//   position === "exterior"
//     ? (sliderClass = classes.sliderExterior)
//     : (sliderClass = classes.sliderInterior);

//   return (
//     <div className={className}>
//       <label className={classes.switch}>
//         <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
//           <Typography
//             className={
//               position === "exterior"
//                 ? classes.selectedText
//                 : classes.unselectedText
//             }
//           >
//             {t("Exterior")}
//           </Typography>
//           <Typography
//             className={
//               position === "exterior"
//                 ? classes.unselectedText
//                 : classes.selectedText
//             }
//           >
//             {t("Interior")}
//           </Typography>
//         </div>
//         <input
//           type="checkbox"
//           className={classes.switchInput}
//           checked={position === "exterior" ? true : false}
//           onChange={handleInputChange}
//         />
//         <span className={sliderClass}></span>
//       </label>
//     </div>
//   );
// };

const ViewImg = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [switchState, setSwitchState] = useState(true); //switchState will later be used to render interior or exterior images

  const { showAppBar } = props;
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const imageRef = useRef();
  const [imageData, setImageData] = useState({});
  const [enteredFullScreen, setEnteredFullScreen] = useState(false);
  if (props.location.images) {
    localStorage.setItem("images", JSON.stringify(props.location.images)); // saves the images to local storage so the website doesnot crash on reload i didnot save them to store because they are only needed here i might change that later if we need to
    localStorage.setItem("carId", JSON.stringify(props.location.carId)); // saves the carId to local storage so the website doesnot crash on reload i didnot save them to store because they are only needed here i might change that later if we need to
  }
  const storedImages = JSON.parse(localStorage.getItem("images"));
  const carId = JSON.parse(localStorage.getItem("carId"));
  const exitPath = props.location.prevPath;
  const [activeStep, setActiveStep] = React.useState(props.location.imageId);
  const imageObj = props.location.imageObj;
  let exteriorImages = [];
  let interiorImages = [];
  // exteriorImages = storedImages.filter(
  //   (image) => image.includes("Exterior") || image.includes("Featured")
  // );
  // console.log("fffffff0", { exteriorImages });
  // interiorImages = storedImages.filter((image) => image.includes("Interior"));
  if (imageObj) {
    for (let i = 0; i < imageObj.length; i++) {
      if (imageObj[i].position === "ext") {
        exteriorImages = [...exteriorImages, ...imageObj[i].answer.images];
      } else {
        interiorImages = [...interiorImages, ...imageObj[i].answer.images];
      }
    }
  }

  const [positionImages, setPositionImages] = useState(exteriorImages);
  const maxSteps = positionImages.length;

  // initial values for each style object
  let divclasspick = classes.portdiv;
  let exitclasspick = classes.portexit;
  let picclasspick = classes.fullport;
  let countclasspick = classes.imageStepCountPort;
  let switchclasspick = classes.portSwitch;

  // the function that chooses style depending on device orientation
  const pick = () => {
    if (isPortrait) {
      picclasspick = classes.fullport;
      exitclasspick = classes.portexit;
      divclasspick = classes.portdiv;
      countclasspick = classes.imageStepCountPort;
      switchclasspick = classes.portPositionSwitch;
    } else {
      picclasspick = classes.fullland;
      exitclasspick = classes.landexit;
      divclasspick = {};
      countclasspick = classes.imageStepCountLand;
      switchclasspick = classes.landPositionSwitch;
    }
  };

  useEffect(() => {
    document.addEventListener("touchstart", handleTouchStart, false);
    document.addEventListener("touchmove", handleTouchMove, false);

    let xDown = null;
    let yDown = null;

    const getTouches = (evt) => {
      return evt.touches || evt.originalEvent.touches;
    };

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }
      let xUp = evt.touches[0].clientX;
      let yUp = evt.touches[0].clientY;
      let xDiff = xDown - xUp;
      let yDiff = yDown - yUp;
      if (isPortrait) {
        if (Math.abs(xDiff) < Math.abs(yDiff)) {
          if (yDiff > 0) {
            /* up swipe (next image) */
            setActiveStep((prev) =>
              prev != positionImages.length - 1 ? prev + 1 : 0
            );
          } else {
            /* down swipe (previous image) */
            setActiveStep((prev) =>
              prev != 0 ? prev - 1 : positionImages.length - 1
            );
          }
        }
      } else {
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          if (xDiff < 0) {
            /* right swipe (previous image) */
            setActiveStep((prev) =>
              prev != 0 ? prev - 1 : positionImages.length - 1
            );
          } else {
            /* left swipe (next image) */
            setActiveStep((prev) =>
              prev != positionImages.length - 1 ? prev + 1 : 0
            );
          }
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }
    return (_) => {
      document.removeEventListener("touchstart", handleTouchStart, false);
      document.removeEventListener("touchmove", handleTouchMove, false);
    };
  }, [isPortrait, positionImages]);
  useEffect(() => {
    //to get the size and position of the image, used for positioning the arrow containig div
    function handleResize() {
      if (imageRef.current) {
        let boundingRect = imageRef.current.getBoundingClientRect();
        setImageData({
          ...boundingRect,
          width: boundingRect.width,
          height: boundingRect.height,
          top: boundingRect.top,
          bottom: boundingRect.bottom,
          left: boundingRect.left,
          right: boundingRect.right,
        });
      }
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return (_) => {
      //clean up
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [imageRef]);

  useEffect(() => {
    if (switchState) {
      setPositionImages(exteriorImages);
    } else {
      setPositionImages(interiorImages);
    }
  }, [switchState]);

  // arrow containing position styles for portrait and landscape, this depends on the images size and position
  let divwidth = isPortrait ? imageData.height : imageData.width;
  let divheight = isPortrait ? imageData.width : imageData.height;
  let divright = isPortrait ? "50%" : imageData.right;
  let divbottom = isPortrait ? "50%" : imageData.bottom;
  let divleft = isPortrait ? "50%" : imageData.left;
  let divtop = isPortrait
    ? "50%"
    : imageData.top + (0.5 * imageData.height * 20) / 100;

  // stepper functions
  const handleNext = () => {
    activeStep < maxSteps - 1
      ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
      : setActiveStep(() => 0);
  };

  const handleBack = () => {
    activeStep >= 1
      ? setActiveStep((prevActiveStep) => prevActiveStep - 1)
      : setActiveStep(() => maxSteps - 1);
  };

  // exiting fullscreen upon leaving this page and clearing storage since the images are not needed anymore
  const exitFullscreen = (event) => {
    localStorage.removeItem("carId");
    localStorage.removeItem("images");

    showAppBar();
    //document.exitFullscreen();
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => console.log("Document Exited form Full screen mode"))
        .catch((err) => console.error(err));
    }
  };

  // keeps track if fullscreen is entered
  useEffect(() => {
    if (document.fullscreen) {
      setEnteredFullScreen(true);
    }
  }, [document.fullscreen]);
  useEffect(() => {
    if (props.location.imageId >= exteriorImages.length) {
      setActiveStep(props.location.imageId - exteriorImages.length);
      setSwitchState(false);
    } else setSwitchState(true);
  }, []);
  //Comment this out to test in computer responsive mode{
  if (enteredFullScreen && !document.fullscreen) {
    showAppBar();
    // only works if the device entered full screen at the beginning if not it won't automatically redirect on entrance the back button will automatically take him to previous screen no exit fullscreen required
    return <Redirect to={props.location.prevPath} />; //To return to previous page instead of exiting full screen only when the back button is clicked in mobile
  }

  const handleSwitchChange = (event) => {
    // swich change function the value of switchState will later be used to render interior or exterior images
    setSwitchState(!switchState);
    setActiveStep(0);
  };

  return (
    <div>
      <Grid
        container
        className={classes.grid}
        style={
          isIOS() ? (isPortrait ? {} : { height: window.innerHeight }) : {}
        }
      >
        <Grid item xs={12}>
          {pick()}
          <div
            className={classes.grid}
            style={
              isIOS()
                ? isPortrait
                  ? {}
                  : { height: window.innerHeight, overflow: "hidden" }
                : {}
            }
          >
            <img
              ref={imageRef}
              id="image"
              className={picclasspick}
              onLoad={(event) => {
                event.persist();
                const boundingRect = event.target.getBoundingClientRect();
                setImageData({
                  ...boundingRect,
                  naturalHeight: event.target.naturalHeight,
                  naturalWidth: event.target.naturalWidth,
                  width: boundingRect.width,
                  height: boundingRect.height,
                  top: boundingRect.top,
                  bottom: boundingRect.bottom,
                  left: boundingRect.left,
                  right: boundingRect.right,
                });
              }}
              src={positionImages[activeStep]}
              alt=""
              style={
                isIOS()
                  ? isPortrait
                    ? { width: "auto", height: "100vw" }
                    : { width: "134vh", height: "auto" }
                  : isPortrait
                  ? { width: "auto", height: "100vw" }
                  : { width: "134vh", height: "auto" }
              }
            ></img>
            <Link
              onClick={exitFullscreen}
              className={exitclasspick}
              to={exitPath}
              style={
                isIOS()
                  ? isPortrait
                    ? localStorage.getItem("language") === "ar"
                      ? {
                          left: "5%",
                          top: "5%",
                          // (window.innerWidth -
                          //   document.getElementById("image")?.width) /
                          // 2,
                        }
                      : {}
                    : {
                        right: "5%",
                        top: "5%",
                        // (window.innerWidth -
                        //   document.getElementById("image")?.width) /
                        // 2,
                      }
                  : isPortrait
                  ? localStorage.getItem("language") === "ar"
                    ? {
                        top: "5%",
                        left: "5%",
                      }
                    : {}
                  : {
                      right: "5%",
                      top: "5%",
                    }
              }
            >
              <CloseIcon style={{ fill: "white", fontSize: "28px" }} />
            </Link>

            <PositionSwitch
              className={switchclasspick}
              onChange={handleSwitchChange}
              setPositionExternally={switchState}
            />
            <div
              className={divclasspick}
              style={
                isIOS()
                  ? {
                      position: "fixed",
                      width: window.innerHeight,
                      height: window.innerWidth,
                      display: "flex",
                      justifyContent: "space-between",
                    }
                  : theme.direction === "ltr"
                  ? {
                      position: "fixed",
                      top: "50%",
                      width: divwidth,
                      height: divheight,
                      display: "flex",
                      justifyContent: "space-between",
                    }
                  : {
                      position: "fixed",
                      width: divwidth,
                      height: divheight,
                      display: "flex",
                      justifyContent: "space-between",
                    }
              }
            >
              <Button
                className={`${classes.imageButtonLeft} ${
                  localStorage.getItem("language") === "ar"
                    ? classes.arabicRotate
                    : ""
                }`}
                onClick={handleBack}
                style={
                  isIOS()
                    ? isPortrait
                      ? {
                          left:
                            (window.innerHeight -
                              document.getElementById("image")?.width) /
                            2,
                        }
                      : {
                          left:
                            (window.innerWidth -
                              document.getElementById("image")?.width) /
                            2,
                        }
                    : isPortrait
                    ? {
                        left: 0,
                        // (window.innerWidth -
                        //   document.getElementById("image")?.width) /
                        // 2,
                      }
                    : {
                        left:
                          (window.innerWidth -
                            document.getElementById("image")?.width) /
                          2,
                      }
                }
              >
                {theme.direction === "ltr" ? (
                  <KeyboardArrowLeft
                    style={{ fill: "white", fontSize: "5rem" }}
                  />
                ) : (
                  <KeyboardArrowRight
                    style={{ fill: "white", fontSize: "5rem" }}
                  />
                )}
              </Button>
              <Button
                className={`${classes.imageButtonRight} ${
                  localStorage.getItem("language") === "ar"
                    ? classes.arabicRotate
                    : ""
                }`}
                onClick={handleNext}
                style={
                  isIOS()
                    ? isPortrait
                      ? {
                          right:
                            (window.innerHeight -
                              document.getElementById("image")?.width) /
                            2,
                        }
                      : {
                          right:
                            (window.innerWidth -
                              document.getElementById("image")?.width) /
                            2,
                        }
                    : isPortrait
                    ? {
                        right: 0,
                        // (window.innerHeight -
                        //   document.getElementById("image")?.width) /
                        // 2,
                      }
                    : {
                        right:
                          (window.innerWidth -
                            document.getElementById("image")?.width) /
                          2,
                      }
                }
              >
                {theme.direction === "ltr" ? (
                  <KeyboardArrowRight
                    style={{ fill: "white", fontSize: "5rem" }}
                  />
                ) : (
                  <KeyboardArrowLeft
                    style={{ fill: "white", fontSize: "5rem" }}
                  />
                )}
              </Button>
            </div>
            <div
              className={countclasspick}
              style={
                isIOS()
                  ? isPortrait
                    ? {
                        top:
                          (window.innerHeight -
                            document.getElementById("image")?.width) /
                          2,
                      }
                    : {
                        left:
                          (window.innerWidth -
                            document.getElementById("image")?.width) /
                          2,
                      }
                  : isPortrait
                  ? {
                      top:
                        (window.innerHeight -
                          document.getElementById("image")?.width) /
                        2,
                    }
                  : {
                      left:
                        (window.innerWidth -
                          document.getElementById("image")?.width) /
                        2,
                    }
              }
            >
              <Typography
                style={{
                  color: "white",
                  padding: "8px 12px",
                  height: "40px",
                  fontSize: "12px",
                }}
              >
                {activeStep + 1} {t("of")} {positionImages.length}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(ViewImg);
