// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";
import {
  CLEAR_NEW_CARS,
  CLEAR_REMOVED_CARS,
  ADD_CAR,
  REMOVE_CAR,
  FETCH_PAGE_TIME_STAMP_SUCCEED,
  FETCH_UNSEEN_NOTIFICATIONS_SUCCEED,
} from "../constants";

const initialState = {
  data: {},
  removedRequests: [],
  removedCars: [],
  incReqStamp: "",
  carListStamp: "",
  unseenCarListings: 0,
  working: false,
};

export default createReducer(initialState, {
  [ADD_CAR]: (state, payload) => {
    return Object.assign({}, state, {
      data: payload,
      unseenCarListings: state.unseenCarListings + 1,
    });
  },
  [REMOVE_CAR]: (state, payload) => {
    let removed_cars = state.removedCars;
    removed_cars.push(payload.instance);
    let removed = state.unseenCarListings - 1;

    return Object.assign({}, state, {
      data: payload,
      unseenCarListings: removed,
      removedCars: removed_cars,
    });
  },
  [CLEAR_NEW_CARS]: (state) => {
    return Object.assign({}, state, {
      unseenCarListings: 0,
    });
  },
  [CLEAR_REMOVED_CARS]: (state) => {
    return Object.assign({}, state, {
      removedCars: [],
    });
  },
  [FETCH_PAGE_TIME_STAMP_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      //  new state for stamps
      incReqStamp: payload["inc_req_stamp"],
      carListStamp: payload["car_list_stamp"],
    });
  },
  [FETCH_UNSEEN_NOTIFICATIONS_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      unseenIncRequests: payload.unseen_incoming_listings,
      unseenCarListings: payload.unseen_cars,
    });
  },
});
