import React from "react";

const OwnerInfoEdit = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3924 0.563505C11.6397 -0.187835 10.4209 -0.187835 9.66823 0.563505L8.98631 1.24916L1.72734 8.50427L1.71191 8.51982C1.70818 8.52356 1.70818 8.52753 1.7042 8.52753C1.69649 8.5391 1.68492 8.55055 1.67732 8.56212C1.67732 8.56598 1.67335 8.56598 1.67335 8.56984C1.66563 8.58141 1.6619 8.58912 1.65406 8.60069C1.65033 8.60455 1.65033 8.60828 1.64647 8.61226C1.64261 8.62383 1.63876 8.63154 1.63478 8.64311C1.63478 8.64685 1.63104 8.64685 1.63104 8.65083L0.0204958 13.4939C-0.0267491 13.6318 0.00916667 13.7845 0.112937 13.8868C0.185853 13.9588 0.2842 13.999 0.386524 13.9987C0.428345 13.9979 0.469805 13.9914 0.509819 13.9794L5.34917 12.365C5.35291 12.365 5.35291 12.365 5.35689 12.3612C5.36906 12.3576 5.38075 12.3524 5.39148 12.3457C5.39449 12.3453 5.39714 12.344 5.39931 12.342C5.41076 12.3342 5.42619 12.3264 5.43776 12.3187C5.44921 12.3111 5.4609 12.2995 5.47247 12.2918C5.47632 12.2878 5.48006 12.2878 5.48006 12.2841C5.48404 12.2803 5.49175 12.2765 5.49561 12.2687L13.4365 4.32779C14.1878 3.57513 14.1878 2.35628 13.4365 1.60374L12.3924 0.563505ZM5.22588 11.4595L2.54424 8.77798L9.25604 2.06618L11.9377 4.7477L5.22588 11.4595ZM2.16653 9.49075L4.50925 11.8334L0.991428 13.0046L2.16653 9.49075ZM12.8933 3.78833L12.4848 4.20064L9.80309 1.51889L10.2155 1.1067C10.6666 0.655946 11.3979 0.655946 11.8491 1.1067L12.897 2.15465C13.3448 2.60781 13.3431 3.33734 12.8933 3.78833Z"
        fill="#186985"
      />
    </svg>
  );
};

export default OwnerInfoEdit;
