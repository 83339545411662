import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MarkabtePopup from "../MarkabtePopup";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";

import OwnerInfoEdit from "../icons/OwnerInfoEdit";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import useGeneralStyle from "../GeneralStyles";
import { convertPhone } from "../../helpers/functions";
import GPS from "../icons/gps";
import { setOwnerInfo } from "../../store/actions/dealership";
import MarkabtePhone from "../MarkabtePhone";

function InformationForm(props) {
  const {
    title,
    fields,
    classes,
    t,
    theme,
    infoFields,
    setInfoFields,
    editable,
  } = props;

  const FieldSelector = (props) => {
    return (
      <>
        <FormControl
          fullWidth
          style={{
            marginBottom: "3.555vh",

            minWidth: "335.55px", ///check
          }}
          classes={{
            root: props.field != "AdditionalInfo" && classes.formControlRoot, //remove styles for textarea
          }}
        >
          {/* select field type depending on field name phone or regular text */}
          {props.field == "PhoneNumber" ? (
            <>
              <MarkabtePhone
                label={t(`${props.label}`)}
                disabled={editable}
                value={infoFields[props.field]}
                onChange={(e) => {
                  setInfoFields({
                    ...infoFields,
                    [props.field]: convertPhone("phone", e).target.value,
                  });
                }}
              />
            </>
          ) : (
            <TextField
              InputProps={{
                classes: {
                  root:
                    props.field != "AdditionalInfo" && classes.inputBaseRoot, //remove styles for textarea
                },
                endAdornment: (
                  <InputAdornment position="end">{props.icon}</InputAdornment>
                ),
              }}
              multiline={props.field == "AdditionalInfo"} //text area if field is additional info
              rows={5}
              maxRows={5}
              disabled={editable}
              type={"text"}
              value={infoFields[props.field]}
              onChange={(event) => {
                setInfoFields({
                  ...infoFields,
                  [props.field]: event.target.value,
                });
              }}
              fullWidth
              label={t(`${props.label}`)}
              variant="outlined"
              InputLabelProps={{
                classes: {
                  outlined: clsx({
                    [classes.inputLableOutlined]:
                      theme.direction === "ltr" ||
                      localStorage.getItem("language") === "en",
                    [classes.inputLableOutlinedArabic]:
                      theme.direction === "rtl" ||
                      localStorage.getItem("language") === "ar",
                  }),
                },
              }}
            />
          )}
        </FormControl>
      </>
    );
  };

  return (
    <>
      {/* form title */}
      <Typography component="div">
        <Box sx={{ fontSize: "h6.fontSize" }} style={{ padding: "15px 0" }}>
          {title}
        </Box>
      </Typography>
      {/* form title */}
      {/* form fields */}
      {fields?.map((element) => (
        <div>{FieldSelector(element)}</div>
      ))}
      {/* form field */}
    </>
  );
}

// main

const OwnerInfo = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useGeneralStyle();
  const {
    open,
    setOpen,
    setOwnerInfo,
    dealership_details,
    listing_request_id,
    carId,
  } = props;
  const [editable, setEditable] = useState(true); //flage does files editable. stars with false
  const [infoFields, setInfoFields] = useState({
    FullName: "",
    PhoneNumber: "",
    Day: "",
    TimeSlot: "",
    Location: "",
    FirstOwner: "",
    PriceRange: "",
    AvailableforTestDrive: "",
    AdditionalInfo: ``,
  });

  useEffect(() => {
    setInfoFields({
      ...infoFields,
      FullName: dealership_details?.owner_name,
      PhoneNumber: dealership_details?.phone_number,
      Day: dealership_details?.owner_info?.day,
      TimeSlot: dealership_details?.owner_info?.time_slot,
      Location: dealership_details?.owner_info?.location,
      FirstOwner: dealership_details?.owner_info?.first_owner,
      PriceRange: dealership_details?.owner_info?.price_range,
      AvailableforTestDrive:
        dealership_details?.owner_info?.available_for_test_drive,
      AdditionalInfo: dealership_details?.owner_info?.additional_info,
    });
  }, [dealership_details]);

  const handleEdit = () => {
    setEditable(!editable);
  };

  const handleSubmit = (carId) => {
    setOwnerInfo({
      ...infoFields,
      listing_details: listing_request_id,
      carid: carId,
      callback: (response) => {
        if (response.status == "success") {
          setEditable(true);
        }
      },
    });
  };

  const InformationFormTemplate = [
    {
      title: t("Owner Information"),
      data: [
        { label: t("Full Name"), field: "FullName" },
        { label: t("Phone Number"), field: "PhoneNumber" },
      ],
    },
    {
      title: t("Appointment Availability"),
      data: [
        { label: t("Day"), field: "Day", icon: <ExpandMoreIcon /> },
        {
          label: t("Time Slot"),
          field: "TimeSlot",
          icon: <ExpandMoreIcon />,
        },
        {
          label: t("Location"),
          field: "Location",
          icon: (
            <GPS style={{ alignSelf: "center" }} size="17.94" fill="#676767" />
          ),
        },
      ],
    },
    {
      title: t("Car Related Information"),
      data: [
        { label: t("First Owner"), field: "FirstOwner" },
        { label: t("Price Range"), field: "PriceRange" },
        {
          label: t("Available for Test Drive"),
          field: "AvailableforTestDrive",
        },
      ],
    },
    {
      title: t("Additional Info"),
      data: [{ label: t("Additional Info"), field: "AdditionalInfo" }],
    },
  ];

  return (
    <MarkabtePopup
      height="max-content"
      title={t("Owner Information")}
      setOpen={setOpen}
      open={open}
      withdivider={true}
      dir={localStorage.getItem("language") == "ar" ? "rtl" : "ltr"}
      onExited={() => {
        document.getElementById("root").style.filter = "none"; // removes blur from root element
      }}
    >
      <div style={{ padding: "0 10px" }}>
        {/* edit info button */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="primary"
            onClick={() => {
              handleEdit();
            }}
          >
            <OwnerInfoEdit />
            <div style={{ margin: "6px 2px" }}>{t("Edit Information")}</div>
          </Button>
        </div>
        {/* information block */}
        {InformationFormTemplate.map((infoBlock) => (
          <InformationForm
            classes={classes}
            t={t}
            theme={theme}
            infoFields={infoFields}
            setInfoFields={setInfoFields}
            title={infoBlock.title}
            editable={editable}
            fields={infoBlock.data}
          />
        ))}
        {/* information block */}
        {/* submit button */}
        {!editable && (
          <Button
            className={classes.innerCardButton}
            style={{
              justifyContent: "center",
              width: "100%",
              marginBottom: "20px",
            }}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(carId)}
            fullWidth
          >
            {t("Save Changes")}
          </Button>
        )}
        {/* submit button */}
      </div>
    </MarkabtePopup>
  );
};

const mapStateToProps = (state) => ({
  // // appointments: state.appointment.appointments,
  // // user: state.auth.user,
  // dealershipId: state.auth.user.dealership_id,
  dealerId: state.auth.user.user_id,
});
const mapDispatchToProps = (dispatch) => ({
  setOwnerInfo(payload) {
    dispatch(setOwnerInfo(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OwnerInfo)
);
