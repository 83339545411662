import { makeStyles } from "@material-ui/core";

const useGeneralStyle = makeStyles((theme) => ({
  formControlRoot: {
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      maxHeight: "40px",
    },
  },
  inputBaseRoot: {
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      maxHeight: "40px",
      backgroundColor: "white",
    },
  },
  inputLableOutlined: {
    transform: "translate(20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  inputLableOutlinedArabic: {
    transform: "translate(-20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(-16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
}));

export default useGeneralStyle;
