import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import MarkabtePopup from "./MarkabtePopup";
import { generateSellerCar } from "../store/actions/car";
import MarkabtePhone from "./MarkabtePhone";
import { removePhoneZero } from "../helpers/functions";
import { Button, Typography } from "@material-ui/core";
import { checkUniquePhone } from "../store/actions/auth";

const useStyles = makeStyles((theme) => ({
  description: {
    margin: "20px 0",
  },
  button: {
    marginBottom: "30px",
    textTransform: "none",
  },
}));

const SellerCarGenerate = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { open, setOpen, carId, generateSellerCar, checkUniquePhone } = props;

  const [phone, setPhone] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (phone?.[4] == "0") {
      let phoneNumber = removePhoneZero(phone);
      setPhone(phoneNumber);
    }
    if (phone?.length < 4 || phone == undefined) {
      setPhone("+962");
    }
  }, [phone]);

  useEffect(() => {
    if (phone?.length === 13) {
      checkUniquePhone({
        phone,
        callback: (response) => {
          if (response.status) {
            setDisabled(true);
            setError(true);
          } else {
            setDisabled(false);
            setError(false);
          }
        },
      });
    } else {
      setDisabled(true);
      setError(false);
    }
  }, [phone]);

  return (
    <MarkabtePopup
      height="max-content"
      title={t("Send Inspection Report Link")}
      setOpen={setOpen}
      open={open}
      withdivider={true}
      dir={localStorage.getItem("language") == "ar" ? "rtl" : "ltr"}
      onExited={() => {
        document.getElementById("root").style.filter = "none"; // removes blur from root element
      }}
    >
      <div style={{ padding: "0 10px" }}>
        <Typography className={classes.description}>
          {t("Please enter car owner's phone")}
        </Typography>
        <MarkabtePhone
          value={phone}
          onChange={(e) => {
            setPhone(e);
          }}
          errors={
            error ? t("This phone number is not a registered user.") : null
          }
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={disabled}
          className={classes.button}
          onClick={() => {
            generateSellerCar({
              car: carId,
              phone: phone,
              callback: (response) => {
                window.open(
                  `https://wa.me/${phone}/?text=مرحباً سيد/ة ${response.user_name}, يمكنك مشاهدة تقرير الفحص الخاص بسيارتك من الرابط الآتي: %0a https://markabte.com/inspection-seller-report/car/${carId}`
                );
                setOpen(false);
              },
            });
          }}
        >
          {t("Send Link")}
        </Button>
      </div>
    </MarkabtePopup>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateSellerCar(payload) {
    dispatch(generateSellerCar(payload));
  },
  checkUniquePhone(payload) {
    dispatch(checkUniquePhone(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerCarGenerate);
