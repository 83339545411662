import AWS from "aws-sdk";
import Compressor from "compressorjs";

AWS.config.region = "eu-central-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "eu-central-1:176dded5-bfe6-4b8d-8187-bccb40c5174f",
});

let s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "markabte-static" },
});

const uploadImage = async (file, fileName, callback) => {
  let photoKey = fileName;
  new Compressor(file, {
    quality: 0.6,
    maxWidth: 1920,
    maxHeight: 1080,
    async success(result) {
      await s3.upload(
        {
          Key: photoKey,
          Body: result,
          ACL: "public-read",
        },
        async function (err, data) {
          if (err) {
            return alert(
              "There was an error uploading your photo: ",
              err.message
            );
          }
          callback(data);
          return data;
        }
      );
    },
    error(err) {
      console.log(err.message);
    },
  });
};

export default uploadImage;
