import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ConfirmReset from "./ConfirmReset";
import AccountActivation from "./AccountActivation";
import NotificationWrapper from "../wrappers/Notifications";
import Dashboard from "./Dashboard";
import ErrorBoundary from "../components/ErrorBoundary";
import CarDetails from "./CarListings/DetailsPages/CarDetails";
function App() {
  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === null) {
      localStorage.setItem("language", "ar");
    }
    var htmlTag = document.getElementsByTagName("html");
    htmlTag[0].dir = localStorage.getItem("language") === "en" ? "ltr" : "rtl";
  }, []);
  return (
    <Router>
      {/* // <NotificationWrapper />
      // <Switch>
      //   <Route exact path="/login">
      //     <Login />
      //   </Route>
      //   <Route exact path="/account_activation/">
      //     <AccountActivation />
      //   </Route>
      //   <Route exact path="/forgot_password/">
      //     <ForgotPassword />
      //   </Route>
      //   <Route exact path="/request_reset/:code/">
      //     <ConfirmReset />
      //   </Route>
      //   <Route
      //     exact
      //     path="/redirect-operations"
      //     component={() => {
      //       window.location.href = "https://operations.markabte.com/";
      //       return null;
      //     }}
      //   />
      //   {/* route to other apps  */}
      {/* <Route */}
      <ErrorBoundary>
        <NotificationWrapper />
        <Switch>
          <Route path={"/car-maintenance-report/:id"}>
            <CarDetails
              hideAppBar={() => {}}
              showAppBar={() => {}}
              mainRef={{ current: null }}
            />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/account_activation/">
            <AccountActivation />
          </Route>
          <Route exact path="/forgot_password/">
            <ForgotPassword />
          </Route>
          <Route exact path="/request_reset/:code/:phone">
            <ConfirmReset />
          </Route>

          {/* route to operations app  */}
          <Route
            exact
            path="/redirect-operations"
            component={() => {
              window.location.href = "https://operations.markabte.com/";
              return null;
            }}
          />
          {/* route to other apps  */}
          {/* <Route
          exact
          path="/redirect-user"
          component={() => {
            window.location.href = "https://google.com/";
            return null;
          }}
        /> */}
          <Route path={"/"}>
            <div>
              <Dashboard />
            </div>
          </Route>
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
