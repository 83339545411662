// const API_ROOT = process.env.REACT_APP_HOST_URL || "https://localhost:8000/api";
const API_ROOT = "https://api.markabte.com/api";
// const API_ROOT = "http://localhost:8000/api";
// const API_ROOT = "http://192.168.1.120:8000/api";
// const API_ROOT = "http://192.168.1.108:8000/api";
// const API_ROOT = "http://192.168.8.101:8000/api";
// const API_ROOT = "http://192.168.100.107:8000/api";
// const API_ROOT = "http://192.168.8.105:8000/api";
// const API_ROOT = "http://192.168.1.99:8000/api";

const API_URLS = (extraData) => ({
  ROOT: API_ROOT,
  AUTH: {
    OBTAIN_TOKEN: API_ROOT + "/token-auth/",
    OBTAIN_REFRESH_TOKEN: API_ROOT + "/token-refresh/",
    ACTIVATE_ACCOUNT: API_ROOT + "/account_activation/",
    CONFIRM_PASSWORD: API_ROOT + "/reset_confirm/",
    SEND_OTP: API_ROOT + "/send-otp/",
    VALIDATE_OTP: API_ROOT + "/validate-otp/",
    CHECK_PHONE: API_ROOT + "/unique-phone/",
  },
  LISTINGS: {
    REQUEST:
      API_ROOT +
      "/dealership/listing-requests/" +
      extraData +
      "/request_listings/", // action name in BE viewset
  },
  CAR_FORM: {
    FORM_FIELDS:
      API_ROOT + "/car-forms/fields/?form=" + extraData + "&is_feature=false",
    OPTIONS: API_ROOT + "/car-forms/fields/?id=" + extraData,
  },
  CAR: {
    UPDATE_FORM:
      API_ROOT + "/car/details/" + extraData + "/update_car_form_info/",
    LIST: API_ROOT + "/car/cards/" + extraData,
    CAR_SALE: API_ROOT + "/car/cards/" + extraData + "/update_car_sale/",
    DETAILS: API_ROOT + "/car/details/" + extraData + "/",
    PAGE: API_ROOT + "/car/page/" + extraData + "/",
    CAR_FIELDS: API_ROOT + "/car/fields/" + extraData,
    CAR_FORMS: API_ROOT + "/car/forms/" + extraData,
    CAR_FEATURES: API_ROOT + "/car/fields/?is_feature=true&" + extraData,
    CAR_FAULTS: API_ROOT + "/car/faulty-condition/?" + extraData,
    CAR_VISUALIZATION: API_ROOT + "/car/visualization/?" + extraData,
    CAR_GROUPED_FIELDS: API_ROOT + "/car/grouped-fields-dealer/" + extraData,
    REPORT_GROUPED_FIELDS: API_ROOT + "/car/grouped-fields/" + extraData,
    CAR_GROUPED_SCORING_FIELDS:
      API_ROOT + "/car/scored-grouped-fields/" + extraData,
    RESERVE_CAR: API_ROOT + "/car/reserve-car/",
    RESERVED_CAR_BOUGHT_UPDATE:
      API_ROOT + "/car/reserve-car/" + extraData + "/",
    UPLOAD_LICENSE_IMAGES: API_ROOT + "/car/license-images/",
    SELLER_CAR_GENERATE: API_ROOT + "/car/seller_temp_unlock/",
    SET_CAR_STATUS: API_ROOT + "/car/car-status/",
    GET_CAR_STATUS: API_ROOT + "/car/get-car-status/?car_id=" + extraData,
  },
  NOTIFICATIONS: {
    UPDATE_STAMP: API_ROOT + "/live/page-stamp/" + extraData + "/page_stamp/",
    GET_STAMPS: API_ROOT + "/live/page-stamp/" + extraData + "/",
    GET_UNSEEN_NOTIFICATIONS:
      API_ROOT + "/live/page-stamp/" + extraData + "/unseen_notifications/",
  },
  // APPOINTMENTS: {
  //   DEALER_ATTENDED: API_ROOT + "/appointments/appointments/" + extraData + "/",
  //   GET_APPOINTMENTS: API_ROOT + "/appointments/appointments/",
  //   GET_DISABLEDAPPTS: API_ROOT + "/appointments/reserved-appointments/",
  //   GET_REPORT_PURCHASE_APPOINTMENTS:
  //     API_ROOT +
  //     "/appointments/appointments/null/report_purchase_appointments/" +
  //     extraData,
  //   CONFIRM_APPOINTMENTS:
  //     API_ROOT + "/appointments/confirmappointment/" + extraData + "/",
  // },
  DEALERSHIP: {
    // GET_APPOINTMENT_INTERVAL:
    //   API_ROOT + "/dealership/opening-hours/" + extraData,
    GET_DEALER_CREDITS: API_ROOT + "/dealership/dealership-info/" + extraData,
    SET_OWNER_INFO: API_ROOT + "/dealership/owner-info/" + extraData + "/",
    GET_CALLS_INFO:
      API_ROOT + "/dealership/callback-listing/?dealership_id=" + extraData,
    MOVE_TO_HISTORY: API_ROOT + "/dealership/move-to-history/",
    GET_MESSAGES_INFO:
      API_ROOT + "/dealership/messages-listing/?dealership_id=" + extraData,
    RECEIVED_OR_HISTORY: API_ROOT + "/dealership/received-or-history/",
  },
});

export default API_URLS;
