import React from "react";

const BackArrow = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size*0.641}
      height={size} viewBox="0 0 25 39" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M0.678783 17.9782L19.6158 0.623044C20.0538 0.221315 20.6384 0 21.2619 0C21.8853 0 22.47 0.221315 22.908 0.623044L24.3025 1.90084C25.21 2.73347 25.21 4.08672 24.3025 4.91808L8.40068 19.4919L24.3202 34.0819C24.7582 34.4836 25 35.0192 25 35.5902C25 36.1619 24.7582 36.6974 24.3202 37.0995L22.9256 38.377C22.4873 38.7787 21.9029 39 21.2795 39C20.6561 39 20.0714 38.7787 19.6334 38.377L0.678783 21.0059C0.239756 20.6029 -0.00137711 20.0649 5.72205e-06 19.4929C-0.00137711 18.9187 0.239756 18.3809 0.678783 17.9782Z" fill={fill}/>
</svg>
  );
};

export default BackArrow;
