import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import theme from "../theme";
import { withStyles } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NextClickableImage from "./icons/NextClickableImage";
import PrevClickableImage from "./icons/PrevClickableImage";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#07303E",
  },
});

const useStyles = makeStyles((theme) => ({
  paperWidthMd: {
    maxWidth: "540",
  },
  image: {
    height: "100%",
    left: "50%",
    position: "relative",
    objectFit: "contain",
    transform: "translate(-50%)",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  contentRoot: {
    padding: "0",
    overflow: "hidden",
  },
  title: {
    color: "#07303E",
    fontSize: "24px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  roundedPaper: {
    height: "100%",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      borderRadius: "8px",
      width: "540px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0",
    },
  },
  flag: {
    background: "#000000aa",
    position: "absolute",
    bottom: "0",
    left: "0",
    color: "#fff",
    width: "90px",
    height: "36.89px",
    textAlign: "center",
    lineHeight: "37px",
    borderTopRightRadius: "10px",
  },
  back: {
    position: "absolute",
    width: "92%",
    display: "flex",
    justifyContent: "space-between",
    top: "50%",
    left: "50%",
    marginLeft: "-46%",
  },
  dialogRoot: {
    zIndex: "11000!important",
  },
}));

const DialogTitleClose = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const ClickableAnswerDialog = (props) => {
  const { open, setOpen, image, title } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [imgcnt, setimgcnt] = useState(0);
  const switchImages = (images) => {
    return images[imgcnt];
  };
  const onClose = () => {
    setOpen(false);
    document.getElementById("root").style.filter = "none";
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      classes={{ paperWidthMd: classes.paperWidthMd, root: classes.dialogRoot }}
      // PaperProps={{ classes: { rounded: classes.roundedPaper } }}
      PaperProps={{ className: classes.roundedPaper }}
    >
      <DialogTitleClose
        id="customized-dialog-title"
        onClose={onClose}
        classes={classes}
      >
        {title}
      </DialogTitleClose>
      <DialogContent className={classes.contentRoot}>
        {console.log(image, "iiiiiiiiiiiiimmm")}
        <img
          src={image?.length > 1 ? switchImages(image) : image?.[0]}
          className={classes.image}
        />
        {Array.isArray(image) && image?.length > 1 && (
          <div className={classes.flag}>
            {imgcnt + 1} of {image.length}
          </div>
        )}
        {Array.isArray(image) && image?.length > 1 && (
          <div className={classes.back}>
            <span
              onClick={() => {
                imgcnt == image.length - 1
                  ? setimgcnt(0)
                  : setimgcnt(imgcnt + 1);
              }}
            >
              <NextClickableImage />
            </span>
            <span
              onClick={() => {
                imgcnt == 0
                  ? setimgcnt(image.length - 1)
                  : setimgcnt(imgcnt - 1);
              }}
            >
              <PrevClickableImage />
            </span>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ClickableAnswerDialog;
