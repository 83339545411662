import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  reserveCar,
  uploadLicenseImages,
  reservedCarPurchaseStatus,
} from "../../store/actions/car";

import MarkabtePopup from "../MarkabtePopup";
import ReportSelection from "./ReportSelection";
import PurchaseState from "./PurchaseState";
import ReportImageUpload from "./ReportImageUpload";
import SelectUser from "./SelectUser";

const ReportSaleComponent = (props) => {
  const { t } = useTranslation();
  const {
    open,
    setOpen,
    carId,
    initialTab,
    firstFlow,
    reserveCar,
    uploadLicenseImages,
    reservedCarPurchaseStatus,
    dealerId,
    reservedTo,
    reservationId,
    history,
  } = props;

  const [tabs, setTabs] = useState(initialTab); // 1 Select payment type: 2 Select purchase state: 3 Upload Car License Images: 4 Select User

  const [reportData, setReportData] = useState({
    buyer: "Deposit",
    buyerType: "Markabte Buyer",
    carState: "Car is sold",
  });

  const [userId, setUserId] = useState(reservedTo); // the id of the user who made a deposite on the car
  const [userData, setUserData] = useState(reservedTo); // the id of the user who made a deposite on the car
  const [licenseImages, setLicenseImages] = useState({ front: "", rear: "" });
  const [withoutHeader, setWithoutHeader] = useState(false);

  useEffect(() => {
    setTabs(initialTab);
  }, [initialTab]);

  const action = () => {
    if (tabs == 1) {
      return "Select payment type";
    } else if (tabs == 2) {
      return "Select purchase state";
      // } else if (tabs == 3) {
      //   return "Upload both sides of the car license below";
    } else if (tabs == 4) {
      return "Please specify which buyer wants to buy the car below";
    }
  };

  const handleNext = () => {
    let currentTab = tabs,
      configFlow = [],
      nextTab;
    if (firstFlow) {
      if (
        reportData.buyer == "Deposit" &&
        reportData.buyerType == "Markabte Buyer"
      ) {
        configFlow = [1, 4];
        if (configFlow.indexOf(currentTab) === configFlow.length - 1) {
          reserveCar({
            car: carId,
            bought: null,
            dealer: dealerId,
            user: userData.users.length == 1 ? userData.users[0] : null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            phone_number: userData.phone_number,
          });
        }
      } else if (
        reportData.buyer == "Deposit" &&
        reportData.buyerType == "Independent Buyer"
      ) {
        reserveCar({
          car: carId,
          dealer: dealerId,
          user: null,
          bought: null,
          first_name: null,
          last_name: null,
          phone_number: null,
        });
        setOpen(false);
        return;
      } else if (
        reportData.buyer == "Transfer of Ownership" &&
        reportData.buyerType == "Markabte Buyer"
      ) {
        configFlow = [1, 4];
        if (configFlow.indexOf(currentTab) === configFlow.length - 1) {
          uploadLicenseImages({
            // front_image: licenseImages.front,
            // back_image: licenseImages.rear,
            car: carId,
            dealer: dealerId,
            user: userData.users.length == 1 ? userData.users[0] : null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            phone_number: userData.phone_number,
            callback: () => {
              if (history.location.pathname.includes("/car/")) {
                history.push("/");
              }
            },
          });
        }
      } else {
        configFlow = [1];
        if (configFlow.indexOf(currentTab) === configFlow.length - 1) {
          uploadLicenseImages({
            // front_image: licenseImages.front,
            // back_image: licenseImages.rear,
            car: carId,
            dealer: dealerId,
            user: null,
            first_name: null,
            last_name: null,
            phone_number: null,
            callback: () => {
              if (history.location.pathname.includes("/car/")) {
                history.push("/");
              }
            },
          });
        }
      }
    } else {
      if (reportData.carState == "Car is sold") {
        configFlow = [2];
      } else {
        configFlow = [2];
      }
      if (configFlow.indexOf(currentTab) === configFlow.length - 1) {
        if (reportData.carState == "Car is sold") {
          uploadLicenseImages({
            // front_image: licenseImages.front,
            // back_image: licenseImages.rear,
            car: carId,
            dealer: dealerId, // id of the user that uploaded the images
            user: userData?.users.length == 1 ? userData.users[0] : null,
            first_name: userData?.first_name ?? null,
            last_name: userData?.last_name ?? null,
            phone_number: userData?.phone_number ?? null,
            callback: () => {
              if (history.location.pathname.includes("/car/")) {
                history.push("/");
              }
            },
          });
          reservedCarPurchaseStatus({ bought: true, reservationId }); // probably should be added to callback
        } else {
          reservedCarPurchaseStatus({ bought: false, reservationId });
        }
      }
    }
    nextTab = configFlow.indexOf(currentTab) + 1;

    if (configFlow[nextTab]) {
      setTabs(configFlow[nextTab]);
    } else {
      // end of configFlow
      // setTabs(initialTab) is handled by dialog's onExited
      setOpen(false);
    }
  };

  useEffect(() => {
    if (licenseImages.front !== "" && licenseImages.rear !== "") {
      handleNext();
    }
  }, [licenseImages]);

  return (
    <MarkabtePopup
      height="max-content"
      title={t("Report Purchase")}
      action={`${t(action())} :`}
      setOpen={setOpen}
      // withHeader={!withoutHeader}
      // withHeader={false}
      withoutHeader={withoutHeader}
      open={open}
      onExited={() => {
        document.getElementById("root").style.filter = "none"; // removes blur from root element
        setTabs(initialTab);
      }}
    >
      {tabs == 1 ? (
        <ReportSelection
          setData={setReportData}
          data={reportData}
          handleNext={handleNext}
        />
      ) : tabs == 2 ? (
        <PurchaseState
          setData={setReportData}
          data={reportData}
          handleNext={handleNext}
        />
      ) : // ) : tabs == 3 ? (
      //   <ReportImageUpload
      //     setLicenseImages={setLicenseImages}
      //     handleNext={handleNext}
      //   />
      tabs == 4 ? (
        <SelectUser
          handleNext={handleNext}
          setUserData={setUserData}
          setWithoutHeader={setWithoutHeader}
        />
      ) : null}
    </MarkabtePopup>
  );
};

const mapStateToProps = (state) => ({
  // // appointments: state.appointment.appointments,
  // // user: state.auth.user,
  // dealershipId: state.auth.user.dealership_id,
  dealerId: state.auth.user?.user_id, // CHECK DEALER
});
const mapDispatchToProps = (dispatch) => ({
  reserveCar(payload) {
    dispatch(reserveCar(payload));
  },
  uploadLicenseImages(payload) {
    dispatch(uploadLicenseImages(payload));
  },
  reservedCarPurchaseStatus(payload) {
    dispatch(reservedCarPurchaseStatus(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportSaleComponent)
);
