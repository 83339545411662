import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import { withRouter } from "react-router";
import { activateAccount, logout } from "../store/actions/auth";
import Markabte_English_Logo from "../icons/Markabte_English_Logo.svg";
import ActivateAccount_SVG from "../icons/reset-password.svg";
import LOGO from "../icons/MOB_LOGIN_Logo.svg";
import LogInImage from "../images/loginImg.png";

import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const useStyle = makeStyles((theme) => ({
  bottomImg: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  circleDiv: {
    height: "130vh",
    borderRadius: "0% 100rem 100rem 0%", // problematic at high heights and low widths
    // borderRadius: "0% 50% 50% 0%",  // not as circular
    backgroundColor: "white",
    position: "relative",
    transform: "translate(0, -15vh)",
    maxWidth: "46.11%",
    flexBasis: "46.11%",
    padding: "15vh 0 15vh 7.013vw",
  },
  formControlRoot: {
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      maxHeight: "40px",
    },
  },
  inputBaseRoot: {
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      maxHeight: "40px",
      backgroundColor: "white",
    },
  },
  inputLableOutlined: {
    transform: "translate(20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  inputLableOutlinedArabic: {
    transform: "translate(-20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(-16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  logo: {
    marginLeft: "-17px",
  },
  sendLinkButton: {
    fontSize: "clamp(14px,1.46vw,18px)",
    fontWeight: "500",
    textTransform: "none",
    padding: "9px clamp(25px,3.13vw,45px)",
    borderRadius: "5px",
    lineHeight: "1.5",
    boxShadow: "0px 2px 5px rgba(9, 38, 48, 0.4)",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(12px,3.89vw,14px)",
      padding: "8px 25px",
      marginTop: "0",
      width: "auto",
    },
  },
  toSignInButton: {
    fontSize: "clamp(14px,1.46vw,18px)",
    fontWeight: "500",
    textTransform: "none",
    borderRadius: "5px",
    lineHeight: "1.5",
    marginTop: "6.82vh",
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(12px,3.89vw,14px)",
      padding: "0",
      marginTop: "0",
    },
  },
  mainContentDiv: {
    paddingTop: "5.7vh",
    justifyContent: "left",
  },
}));

const ConfirmReset = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const [verifiedPassword, setVerifiedPassword] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const { history, activateAccount, logout, phone } = props;

  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === null) {
      localStorage.setItem("language", "ar");
    }
    var htmlTag = document.getElementsByTagName("html");
    htmlTag[0].dir = localStorage.getItem("language") === "en" ? "ltr" : "rtl";
    var bodyTag = document.getElementsByTagName("body");
    bodyTag[0].style.overflow = "hidden";
    window.scroll(0, 0);
  }, []);

  const handleActivateAccount = (event) => {
    event.preventDefault();
    activateAccount({
      password,
      verifiedPassword,
      phone,
      callback: () => {
        history.push("/login");
        logout();
      },
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <Hidden smDown>
        <Grid
          container
          style={{
            minHeight: "100%",
            backgroundColor: "#efefef",
          }}
        >
          <Grid item className={classes.circleDiv}>
            <Grid
              container
              justify={"center"}
              className={classes.mainContentDiv}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid item xs={12}>
                    <img
                      src={Markabte_English_Logo}
                      alt={Markabte_English_Logo}
                      className={classes.logo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant={"h3"}
                      style={{
                        fontWeight: "700",
                        fontSize: "24px",
                        color: "#07303E",
                        margin: "8.44vh 0px 4px",
                        lineHeight: "1.5",
                      }}
                    >
                      {t("Reset Your Password")}
                    </Typography>
                    <Typography
                      style={{
                        marginBottom: "4.74vh",
                      }}
                    >
                      {t("Please enter your new password.")}
                    </Typography>
                    <form onSubmit={handleActivateAccount}>
                      <FormControl
                        fullWidth
                        style={{
                          boxShadow:
                            "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4)",
                          marginBottom: "3.555vh",
                          width: "28.542vw",
                          minWidth: "335.55px", ///check
                        }}
                        classes={{ root: classes.formControlRoot }}
                      >
                        <TextField
                          InputProps={{
                            classes: { root: classes.inputBaseRoot },
                          }}
                          type={"password"}
                          value={verifiedPassword}
                          onChange={(event) => {
                            setVerifiedPassword(event.target.value);
                          }}
                          fullWidth
                          label={t("New Password")}
                          variant="outlined"
                          InputLabelProps={{
                            classes: {
                              outlined: clsx({
                                [classes.inputLableOutlined]:
                                  theme.direction === "ltr" ||
                                  localStorage.getItem("language") === "en",
                                [classes.inputLableOutlinedArabic]:
                                  theme.direction === "rtl" ||
                                  localStorage.getItem("language") === "ar",
                              }),
                            },
                          }}
                        />
                      </FormControl>
                      <FormControl
                        fullWidth
                        style={{
                          boxShadow:
                            "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4)",
                          width: "28.542vw",
                          minWidth: "335.55px", ///check
                          marginBottom: "5.93vh",
                        }}
                        classes={{ root: classes.formControlRoot }}
                      >
                        <TextField
                          InputProps={{
                            classes: { root: classes.inputBaseRoot },
                          }}
                          value={password}
                          type={"password"}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          fullWidth
                          label={t("Confirm Password")}
                          variant="outlined"
                          InputLabelProps={{
                            classes: {
                              outlined: clsx({
                                [classes.inputLableOutlined]:
                                  theme.direction === "ltr" ||
                                  localStorage.getItem("language") === "en",
                                [classes.inputLableOutlinedArabic]:
                                  theme.direction === "rtl" ||
                                  localStorage.getItem("language") === "ar",
                              }),
                            },
                          }}
                        />
                      </FormControl>

                      <FormControl
                        fullWidth
                        style={{
                          width: "28.542vw",
                          minWidth: "335.55px",
                        }}
                      >
                        <Button
                          className={classes.sendLinkButton}
                          type="submit"
                          children={t("Reset Password")}
                          variant={"contained"}
                          color={"primary"}
                          onClick={handleActivateAccount}
                        />
                      </FormControl>
                    </form>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            lg={6}
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "53.89%",
              flexBasis: "53.89%",
            }}
          >
            <img
              src={ActivateAccount_SVG}
              alt={ActivateAccount_SVG}
              style={{ width: "clamp(472px,42.1vw,606px)" }}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <div
          style={{
            backgroundColor: "white",
            minHeight: "100%",
            minWidht: "100%",
          }}
        >
          <img
            src={LogInImage}
            alt={LogInImage}
            style={{
              position: "fixed",
              filter: "blur(1.3px)",
              transform: "translate(-50%,-50%)",
              top: "50%",
              left: "50%",
            }}
          />
          <div
            style={{
              height: "100vh",
              width: "100vw",
              position: "absolute",
              background: "rgba(255, 255, 255, 0.3)",
            }}
          />
          <div
            style={{
              height: "100vh",
              width: "100vw",
              position: "absolute",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 99.51%)",
              transform: "rotate(-180deg)",
            }}
          />
          <Grid container>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "9.722vw",
                height: "max-content",
              }}
            >
              <img
                src={LOGO}
                alt={LOGO}
                style={{
                  zIndex: "2",
                  width: "123px",
                  height: "auto",
                  margin: "6.107vh 0 15.616vh",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                margin: "0 9.722vw",
                position: "relative",
                zIndex: 10,
              }}
            >
              <Typography
                variant={"h3"}
                style={{
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "1.5",
                }}
              >
                {t("Reset Password")}
              </Typography>
              <Typography
                style={{
                  marginBottom: "4.96vh",
                  fontSize: "14px",
                }}
              >
                {t("Please enter your new password.")}
              </Typography>
              <form onSubmit={handleActivateAccount}>
                <FormControl
                  fullWidth
                  style={{
                    boxShadow:
                      "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4)",
                    marginBottom: "3.05vh",
                  }}
                  classes={{ root: classes.formControlRoot }}
                >
                  <TextField
                    InputProps={{
                      classes: { root: classes.inputBaseRoot },
                    }}
                    type={"password"}
                    value={verifiedPassword}
                    onChange={(event) => {
                      setVerifiedPassword(event.target.value);
                    }}
                    fullWidth
                    label={t("New Password")}
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        outlined: clsx({
                          [classes.inputLableOutlined]:
                            theme.direction === "ltr" ||
                            localStorage.getItem("language") === "en",
                          [classes.inputLableOutlinedArabic]:
                            theme.direction === "rtl" ||
                            localStorage.getItem("language") === "ar",
                        }),
                      },
                    }}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  style={{
                    boxShadow:
                      "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4)",
                    marginBottom: "4.96vh",
                  }}
                  classes={{ root: classes.formControlRoot }}
                >
                  <TextField
                    InputProps={{
                      classes: { root: classes.inputBaseRoot },
                    }}
                    value={password}
                    type={"password"}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    fullWidth
                    label={t("Confirm Password")}
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        outlined: clsx({
                          [classes.inputLableOutlined]:
                            theme.direction === "ltr" ||
                            localStorage.getItem("language") === "en",
                          [classes.inputLableOutlinedArabic]:
                            theme.direction === "rtl" ||
                            localStorage.getItem("language") === "ar",
                        }),
                      },
                    }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  style={{
                    boxShadow:
                      "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4) inline",
                    marginBottom: "3em",
                    display: "flex",
                    flexDirection: "row-reverse", ///check
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    className={classes.sendLinkButton}
                    type="submit"
                    children={t("Reset Password")}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleActivateAccount}
                  />
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </div>
      </Hidden>
    </div>
  );
};

const mapStateToProps = (state) => ({
  phone: state?.auth?.user?.phone_number,
});

const mapDispatchToProps = (dispatch) => ({
  activateAccount(payload) {
    dispatch(activateAccount(payload));
  },
  logout() {
    dispatch(logout());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmReset)
);
