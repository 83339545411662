import { makeStyles, Typography } from "@material-ui/core";
import React, { Children } from "react";
const useStyles = makeStyles((theme) => ({
  container: {
    background: "#F9FAFD",
    border: "1px solid #CBC6C6",
    maxWidth: "328px",
    padding: "12px",
    margin: "15px",
  },
  inline: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  bold: {
    fontWeight: "500",
  },
  fontsize: {
    fontSize: "16px",
  },
  fontsizeSm: {
    fontSize: "14px",
  },
  icon: {
    margin: "0  0 0 15px",
  },
}));
function CallReuestCard(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.inline}>
        <div className={classes.title}>
          <Typography className={`${classes.bold} ${classes.fontsize}`}>
            <span>#{props.id}</span> | {props.car}
          </Typography>
        </div>
        <div className={classes.icon}>{props.icon}</div>
      </div>
      <div>
        <Typography className={`${classes.bold} ${classes.fontsize}`}>
          {props.username} | {props.phone}
        </Typography>
      </div>
      <div>
        <Typography className={classes.fontsizeSm}>
          {new Date(props.date).toDateString()}{" "}
          {new Date(props.date).getHours()}:{new Date(props.date).getMinutes()}
        </Typography>
      </div>
      <div>{props.children}</div>
    </div>
  );
}

export default CallReuestCard;
