import React from "react";

const Close = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.73293 4.00682L7.84797 0.89168C8.0507 0.689053 8.0507 0.361433 7.84797 0.158806C7.64535 -0.0438208 7.31773 -0.0438208 7.1151 0.158806L3.99996 3.27395L0.884915 0.158806C0.682193 -0.0438208 0.354668 -0.0438208 0.152041 0.158806C-0.0506805 0.361433 -0.0506805 0.689053 0.152041 0.89168L3.26709 4.00682L0.152041 7.12196C-0.0506805 7.32459 -0.0506805 7.65221 0.152041 7.85484C0.253023 7.95591 0.385798 8.00669 0.518478 8.00669C0.651159 8.00669 0.783839 7.95591 0.884915 7.85484L3.99996 4.73969L7.1151 7.85484C7.21618 7.95591 7.34886 8.00669 7.48154 8.00669C7.61422 8.00669 7.7469 7.95591 7.84797 7.85484C8.0507 7.65221 8.0507 7.32459 7.84797 7.12196L4.73293 4.00682Z"
        fill="#A7A7A7"
      />
    </svg>
  );
};
export default Close;
