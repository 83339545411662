import React, { useState, useEffect, useRef } from "react";
import { withRouter, Redirect } from "react-router";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import {
  socketConnect,
  getPageTimeStamp,
  getUnseenNotifications,
} from "../store/actions/websockets";
import { Switch, Route } from "react-router-dom";
import cookies from "js-cookie";

import CarListings from "./CarListings/CarListings";
import EditPrice from "../wrappers/EditPrice";

import Hidden from "@material-ui/core/Hidden";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import classNames from "classnames";
import Logo from "../Markabte_English_Logo 2.svg";

import CarDetails from "./CarListings/DetailsPages/CarDetails";

import { useTranslation } from "react-i18next";

import ViewImg from "../components/imageviewProto";
import Visualization from "../wrappers/CosmeticConditionProto";
import InspectionReport from "../wrappers/InspectionReportProto";

import UserPlaceholder from "../icons/user_placeholder.svg";

import CarListingsIcon from "../components/icons/CarListing";
import LanguageNewIcon from "../components/icons/Language";
import SignOutIcon from "../components/icons/Signout";
import MarkabteLogoEn from "../components/icons/MarkabteLogoEn";
import MarkabteLogoAr from "../components/icons/MarkabteLogoAr";
// import AppointmentIcon from "../components/icons/AppointmentIcon";
// import Appointmentslisting from "../components/Appointmentslisting";
import Handle404 from "../components/Handle404";
import NavBar from "../components/NavBar";
import Tour from "../wrappers/Tour";
import CallBackRequests from "../components/CallRequest/CallBackRequests";
import RequestsHistory from "../components/CallRequest/RequestsHistory";
import WhatsappMessages from "../components/CallRequest/WhatsappMessages";
import { getCallsInfo, getMessagesInfo } from "../store/actions/dealership";
import CallBackRequestsIcon from "../components/icons/CallBackRequests";
import RequestsHistoryIcon from "../components/icons/RequestsHistory";
import DetailsReport from "./CarListings/DetailsPages/DetailsReport/DetailsReport";
const drawerWidthDesktop = 254;
const drawerWidthMobile = 231;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer + 1,
    },
    background: "white",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      // display: "none", //// drawer remove
      width: drawerWidthDesktop,
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    flexShrink: 0,
  },
  drawerPaper: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthDesktop,
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    background: "white",
    boxShadow: "5px 0px 14px rgba(7, 48, 62, 0.3)",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidthDesktop,
      minHeight: "90px",
    },
  },
  contentWithDrawer: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    // backgroundColor: "white",
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidthDesktop,
      // marginLeft: 0, //// drawer remove
    },
  },
  contentNoDrawer: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    // backgroundColor: "white",
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      // marginLeft: 0, //// drawer remove
    },
  },
  selected: {
    background: "rgba(196,196,196,0.5)",
    color: "#fff",
  },
  logoListItemRoot: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  menuuButtonRoot: {
    padding: 0,
  },
  listItemGutters: {
    paddingLeft: "25px",
  },
  listItemIconRoot: {
    width: "28px",
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
    paddingRight: "11.5px",
  },
  userListItemGutters: {
    padding: "25px 14px 0",
  },
  userListItemGuttersMdUp: {
    padding: "20px 0 0",
  },
  backdropRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  toolbarGutters: {
    paddingRight: "8px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      maxHeight: "54px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "68px",
    },
  },
  ListItemRoot: {
    padding: "14px 16px 14px",
  },
  bigScreenFullName: {
    color: "#07303E",
    fontSize: "20px",
    lineHeight: "21px",
    marginLeft: "14px",
    padding: "0 0 20px ",
  },
  mobFullName: {
    color: "#07303E",
    fontSize: "14px",
    lineHeight: "21px",
    marginLeft: "14px",
  },
}));

const Dashboard = (props) => {
  if (props.user !== null) {
    var {
      user: { dealership_id, role, full_name_en, full_name_ar, user_id },
    } = props;
  }
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isVisualization, setIsVisualization] = useState(false);
  const [fullName, setFullName] = useState("");
  const {
    history,
    logout,
    socketConnect,
    getPageTimeStamp,
    getUnseenNotifications,
    unseenCarListings,
    getCallsInfo,
    getMessagesInfo,
    calls_info,
  } = props;

  const mainRef = useRef();

  useEffect(() => {
    if (!cookies.get("token")) {
      history.push("/login");
    }
  }, [history]);

  let offset = 0;
  if (history.location.pathname.includes("/requests-history")) {
    offset = 48;
  } else if (
    history.location.pathname === "/" ||
    history.location.pathname.includes("/car/")
  ) {
    offset = props?.user?.runner_account ? 96 : 0;
  }

  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === null) {
      localStorage.setItem("language", "ar");
    }
    var htmlTag = document.getElementsByTagName("html");
    htmlTag[0].dir = localStorage.getItem("language") === "en" ? "ltr" : "rtl";
    lang === "ar" ? setFullName(full_name_ar) : setFullName(full_name_en);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.user) {
      socketConnect({
        role:
          role === "dealer owner"
            ? "dealer_owner"
            : role === "dealer staff"
            ? "dealer_staff"
            : "",
        dealer: dealership_id,
      });
      getPageTimeStamp({ user_id: user_id });
      getUnseenNotifications({ user_id: user_id });
      getCallsInfo({ dealership_id: dealership_id });
      getMessagesInfo({ dealership_id: dealership_id });
    }
    // eslint-disable-next-line
  }, []);

  const handleListItemClick = (path) => {
    history.push(path);
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const renderDrawerList = () => {
    const { location } = props;

    let selected = "cars";
    if (location.pathname.includes("earnings")) {
      selected = "earnings";
    } else if (location.pathname.includes("listing-requests")) {
      selected = "requests";
    } else if (location.pathname.includes("call-back-requests")) {
      selected = "call-back-requests";
    } else if (location.pathname.includes("requests-history")) {
      selected = "requests-history";
    } else if (location.pathname.includes("whatsapp-messages")) {
      selected = "whatsapp-messages";
    }
    // else if (location.pathname.includes("appointments")) {
    //   selected = "appointments";
    // }

    return (
      <div>
        {/* redirects to another app if not operations account */}
        {role !== "dealer owner" && role !== "dealer staff" ? (
          role === "dispatcher" || role === "officer" ? (
            <Redirect to="/redirect-operations" />
          ) : (
            <Redirect to="/redirect-user" />
          )
        ) : null}
        <Tabs
          orientation="vertical"
          TabIndicatorProps={{
            // style: { height: "48px", top: tabIndicatorTop, width: "3px" },
            style: {
              height: "48px",
              top: offset,
              width: "3px",
              display: "none",
            },
          }}
        >
          <List disablePadding>
            {/* <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "appointments"}
              id={4}
              onClick={handleListItemClick.bind(this, "/appointments")}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <AppointmentIcon size="28" fill="#07303E" />
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Appointments")} */}
            {/* </ListItemText> */}
            {/* {unseenCarListings == 2 ? (
                <div
                  style={{
                    backgroundColor: "red",
                    borderRadius: "50%",
                    color: "white",
                    height: "24px",
                    width: "24px",
                  }}>
                  <Typography align='center'>{unseenCarListings}</Typography>
                </div>
              ) : null} */}
            {/* </ListItem> */}
            {props?.user?.runner_account && (
              <ListItem
                classes={{
                  selected: classes.selected,
                  gutters: classes.listItemGutters,
                }}
                button
                selected={selected === "call-back-requests"}
                id={4}
                onClick={handleListItemClick.bind(this, "/call-back-requests")}
              >
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <CallBackRequestsIcon size="22" fill="#07303E" />
                </ListItemIcon>
                <ListItemText style={{ color: "#07303E" }}>
                  {t("Call Back Requests")}
                </ListItemText>
                {calls_info?.filter((call) => !call.calledback).length > 0 ? (
                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      color: "white",
                      height: "24px",
                      width: "24px",
                    }}
                  >
                    <Typography align="center">
                      {calls_info?.filter((call) => !call.calledback).length}
                    </Typography>
                  </div>
                ) : null}
              </ListItem>
            )}
            {props?.user?.runner_account && (
              <ListItem
                classes={{
                  selected: classes.selected,
                  gutters: classes.listItemGutters,
                }}
                button
                selected={selected === "requests-history"}
                id={5}
                onClick={handleListItemClick.bind(this, "/requests-history")}
              >
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <RequestsHistoryIcon size="22" fill="#07303E" />
                </ListItemIcon>
                <ListItemText style={{ color: "#07303E" }}>
                  {t("Requests History")}
                </ListItemText>
              </ListItem>
            )}
            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "cars"}
              id={1}
              onClick={handleListItemClick.bind(this, "/")}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <CarListingsIcon size="28" fill="#07303E" />
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Car Listings")}
              </ListItemText>
              {unseenCarListings > 0 ? (
                <div
                  style={{
                    backgroundColor: "red",
                    borderRadius: "50%",
                    color: "white",
                    height: "24px",
                    width: "24px",
                  }}
                >
                  <Typography align="center">{unseenCarListings}</Typography>
                </div>
              ) : null}
            </ListItem>
            {props?.user?.runner_account && (
              <ListItem
                classes={{
                  selected: classes.selected,
                  gutters: classes.listItemGutters,
                }}
                button
                selected={selected === "whatsapp-messages"}
                id={6}
                onClick={handleListItemClick.bind(this, "/whatsapp-messages")}
              >
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <RequestsHistoryIcon size="22" fill="#07303E" />
                </ListItemIcon>
                <ListItemText style={{ color: "#07303E" }}>
                  {t()}
                  {localStorage.getItem("language") === "en"
                    ? "WhatsApp Messages"
                    : "رسائل واتساب"}
                </ListItemText>
              </ListItem>
            )}
            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "language"}
              id={2}
              onClick={() => {
                localStorage.getItem("language") === "ar"
                  ? localStorage.setItem("language", "en")
                  : localStorage.setItem("language", "ar");
                window.location.reload();
              }}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <LanguageNewIcon size="24" fill="#07303E" />
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Language")}
              </ListItemText>
            </ListItem>{" "}
            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "Logout"}
              id={3}
              onClick={() => {
                logout();
                handleListItemClick.bind(this, "/login");
                history.push("/login");
              }}
            >
              <ListItemIcon>
                <SignOutIcon size="24" fill="#07303E" />
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Sign out")}
              </ListItemText>
            </ListItem>
          </List>
        </Tabs>
      </div>
    );
  };

  const renderMdUpDrawer = () => {
    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          BackdropProps: { classes: { root: classes.backdropRoot } },
        }}
        anchor="left"
        open={
          window.location.pathname == "/" ||
          window.location.pathname == "/requests-history" ||
          window.location.pathname == "/call-back-requests" ||
          window.location.pathname == "/whatsapp-messages"
        }
      >
        <div>
          <ListItem classes={{ root: classes.ListItemRoot }}>
            <ListItemIcon
              classes={{
                selected: classes.selected,
                root: classes.logoListItemRoot,
              }}
              style={{ margin: "auto" }}
              onClick={() => {
                props.history.push("/");
              }}
            >
              {localStorage.getItem("language") === "ar" ? (
                <MarkabteLogoAr style={{ width: "145px" }} />
              ) : (
                <MarkabteLogoEn style={{ width: "155px" }} />
              )}
            </ListItemIcon>
          </ListItem>
          <hr
            style={{
              height: "1px",
              border: "0px",
              "background-image":
                "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.15) 100%)",
              margin: "0 0 10px 0px",
            }}
          ></hr>
        </div>
        {renderDrawerList()}
      </Drawer>
    );
  };

  const renderMdDownDrawer = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          BackdropProps: { classes: { root: classes.backdropRoot } },
        }}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <div className={classes.toolbar} style={{ backgroundColor: "white" }}>
          <ListItem classes={{ gutters: classes.userListItemGutters }}>
            <ListItemIcon
              children={<img src={UserPlaceholder} alt="user_placeholder" />}
              classes={{ selected: classes.selected }}
            ></ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.mobFullName,
              }}
            >
              {fullName}
              <div>{role}</div>
            </ListItemText>
          </ListItem>

          <hr
            style={{
              height: "1px",
              border: "0px",
              "background-image":
                "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.15) 100%)",
              margin: "20px 0px",
            }}
          ></hr>
        </div>
        {renderDrawerList()}
      </Drawer>
    );
  };

  const hideAppBar = () => {
    setIsVisualization(true);
  };
  const showAppBar = () => {
    setIsVisualization(false);
  };

  return (
    <div>
      {/* {!isVisualization && <NavBar />} */}
      {!isVisualization &&
        (window.location.pathname == "/" ||
        window.location.pathname == "/requests-history" ||
        window.location.pathname == "/call-back-requests" ||
        window.location.pathname == "/whatsapp-messages" ? (
          <AppBar
            position="static"
            elevation={1}
            className={classNames(classes.appBar)}
          >
            <Toolbar
              className={classNames(classes.toolbar)}
              classes={{ gutters: classes.toolbarGutters }}
              style={{
                justifyContent: "space-between",
              }}
            >
              <Hidden mdUp>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    /* remove if fails menue card results*/ marginLeft: "-3px",
                  }}
                >
                  <IconButton
                    classes={{ root: classes.menuuButtonRoot }}
                    onClick={handleOpenDrawer}
                  >
                    <MenuIcon style={{ fill: "#0c6885" }} />
                  </IconButton>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                  onClick={() => {
                    props.history.push("/");
                  }}
                >
                  {localStorage.getItem("language") === "ar" ? (
                    <MarkabteLogoAr style={{ width: "125px" }} />
                  ) : (
                    <MarkabteLogoEn style={{ width: "140px" }} />
                  )}
                </div>
              </Hidden>
              <Hidden smDown>
                <ListItem
                  classes={{ gutters: classes.userListItemGuttersMdUp }}
                >
                  <ListItemIcon
                    children={
                      <img
                        src={UserPlaceholder}
                        alt="user_placeholder"
                        style={{ padding: "0 0 20px 0" }}
                      />
                    }
                    classes={{ selected: classes.selected }}
                  ></ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.bigScreenFullName,
                    }}
                  >
                    {fullName}
                    <div>
                      <text
                        style={{
                          color: "#626465",
                          fontSize: "16px",
                          lineHeight: "18px",
                        }}
                      >
                        {t(role)}
                      </text>
                    </div>
                  </ListItemText>
                </ListItem>
              </Hidden>
            </Toolbar>
            <hr
              style={{
                height: "1px",
                border: "0px",
                backgroundImage:
                  "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%)",
                margin: "-3px 0 0",
              }}
            ></hr>
          </AppBar>
        ) : (
          <NavBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        ))}
      <Hidden smDown>{renderMdUpDrawer()}</Hidden>
      <Hidden mdUp>{renderMdDownDrawer()}</Hidden>

      {/* <main className={classNames(classes.content)}> */}
      <main
        className={clsx({
          [classes.contentWithDrawer]: window.location.pathname == "/",
          [classes.contentNoDrawer]: window.location.pathname != "/",
        })}
      >
        <Switch>
          <Route path={"/car/:id/edit-price"}>
            <EditPrice />
          </Route>
          <Route path={"/car/:id/inspection-report"}>
            <InspectionReport hideAppBar={hideAppBar} showAppBar={showAppBar} />
          </Route>
          <Route path={"/car/:id"}>
            <CarDetails
              hideAppBar={hideAppBar}
              showAppBar={showAppBar}
              mainRef={mainRef}
            />
          </Route>
          <Route path={"/car-details/:id"}>
            {props?.user?.runner_account && (
              <DetailsReport
                hideAppBar={hideAppBar}
                showAppBar={showAppBar}
                mainRef={mainRef}
              />
            )}
          </Route>
          <Route path="/imageview">
            <ViewImg showAppBar={showAppBar} />
          </Route>
          <Route path="/visualization">
            <Tour showAppBar={showAppBar} />
          </Route>
          <Route path="/call-back-requests">
            <CallBackRequests />
          </Route>
          <Route path="/requests-history">
            <RequestsHistory />
          </Route>
          <Route path="/whatsapp-messages">
            <WhatsappMessages />
          </Route>
          {/* <Route exact path={"/appointments"}>
            <Appointmentslisting />
          </Route> */}
          <Route path={"/something-went-wrong"}>
            <Handle404 />
          </Route>
          <Route path={"/404"}>
            <Handle404 />
          </Route>
          <Route exact path={"/"}>
            <CarListings />
          </Route>
          {/* <Route path={"*"}>
            <Handle404 />
          </Route> */}
        </Switch>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  unseenCarListings: state.websockets.unseenCarListings,
  calls_info: state.dealership.calls_info,
});

const mapDispatchToProps = (dispatch) => ({
  logout() {
    dispatch(logout());
  },
  socketConnect(payload) {
    dispatch(socketConnect(payload));
  },
  getPageTimeStamp(payload) {
    dispatch(getPageTimeStamp(payload));
  },
  getUnseenNotifications(payload) {
    dispatch(getUnseenNotifications(payload));
  },
  getCallsInfo(payload) {
    dispatch(getCallsInfo(payload));
  },
  getMessagesInfo(payload) {
    dispatch(getMessagesInfo(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
