import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Badge } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LanguageNewIcon from "../components/icons/Language";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import MarkabteLogoEn from "./icons/MarkabteLogoEn";
import MarkabteLogoAr from "./icons/MarkabteLogoAr";
import SignOutIcon from "./icons/Signout";
import { logout } from "../store/actions/auth";

const drawerWidthDesktop = 254;
const drawerWidthMobile = 267;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer + 1,
    },
    background: "white",
    // maxHeight: "72px",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthDesktop,
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    flexShrink: 0,
  },
  drawerPaper: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthDesktop,
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    background: "white",
    boxShadow: "5px 0px 14px rgba(7, 48, 62, 0.3)",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingRight: "20px",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      // paddingLeft: "8px",
      paddingLeft: "20px",
    },
    [theme.breakpoints.up("md")]: {
      // "min-height": "64.6px",
      "min-height": "48px",
    },
    [theme.breakpoints.up("lg")]: {
      // "min-height": "71.7px",
      "min-height": "53.36px",
    },
    [theme.breakpoints.up("xl")]: {
      // "min-height": "86px",
      "min-height": "64px",
    },
  },

  content: {
    flexGrow: 1,
  },
  selected: {
    background: "rgba(196,196,196,0.5)",
    color: "#fff",
  },
  logoListItemRoot: {
    "&:hover": {
      cursor: "pointer",
    },
    imgLogo: {
      width: "182.2px",
    },
  },

  menuuButtonRoot: {
    padding: 0,
  },
  listItemGutters: {
    paddingLeft: "25px",
  },
  listItemIconRoot: {
    width: "28px",
    display: "flex",
    justifyContent: "center",
    minWidth: 4,
    paddingRight: 0,
  },
  userListItemGutters: {
    padding: "25px 14px 0",
  },
  userListItemGuttersMdUp: {
    padding: "20px 0 0",
  },
  backdropRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  toolbarGutters: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "68px",
      paddingRight: "68px",
    },
  },
  ListItemRoot: {
    padding: "14px 10px 14px",
  },
  bigScreenFullName: {
    color: "#07303E",
    fontSize: "20px",
    lineHeight: "21px",
    marginLeft: "14px",
    padding: "0 0 20px ",
  },
  mobFullName: {
    color: "#07303E",
    fontSize: "14px",
    lineHeight: "21px",
    marginLeft: "14px",
  },
  ButtonBaseRoot: {
    minWidth: "fit-content",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 11,
      paddingRight: 11,
      ButtonText: {
        fontSize: 15,
      },
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 12,
      paddingRight: 12,
      ButtonText: {
        fontSize: 16.8,
      },
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 14,
      paddingRight: 14,
      TypographyBody1: {
        fontSize: 20,
      },
    },
  },
  ButtonBaseRootRight: {
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 10,
      paddingRight: 10,
      ButtonText: {
        fontSize: 16,
      },
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "160px",
    },
    [theme.breakpoints.up("md")]: {
      // width: "136.7px",
      width: "124.6px",
    },
    [theme.breakpoints.up("lg")]: {
      // width: "153.7px",
      width: "140.2px",
    },
    [theme.breakpoints.up("xl")]: {
      // width: "182.2px",
      width: "155px",
    },
  },
  body1: {
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
      menuItem: {
        fontSize: 11,
      },
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 16.8,
      menuItem: {
        fontSize: 12,
      },
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: 20,
      menuItem: {
        fontSize: 16,
      },
    },
  },

  body2: {
    [theme.breakpoints.up("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 13.5,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: 16,
    },
  },
  NavBarIcons: {
    paddingRight: "15px",
    color: "#000",
  },
  closed: { fontSize: 26, color: "#07303E" },
  opened: { fontSize: 26, color: "#07303E", transform: "rotate(180deg)" },
  //////////////////////////////////////////////////////////////
  listItemTypography: {
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  },
  listItems: {
    padding: "0 12px",
    width: "max-content",
    // height: "50px",
  },
  //////////////////////////////////////////////////////////////
}));

const NavBar = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  // const [openDrawer, setOpenDrawer] = useState(false);
  const {
    history,
    isAuthenticated,
    first_name,
    last_name,
    logout,
    // schedualedcnt,
    // bookedSeen,
    openDrawer,
    setOpenDrawer,
    user,
  } = props;

  const handleListItemClick = (path) => {
    history.push(path);
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const [openMore, setOpenMore] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const anchorRefMore = useRef(null);
  const anchorRefAccount = useRef(null);

  const handleToggleAccount = () => {
    setOpenAccount((prevOpenAccount) => !prevOpenAccount);
  };

  const handleCloseAccount = (event) => {
    if (
      anchorRefAccount.current &&
      anchorRefAccount.current.contains(event.target)
    ) {
      return;
    }
    setOpenAccount(false);
  };

  function handleAccountListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenAccount(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpenMore = useRef(openMore);
  const prevOpenAccount = useRef(openAccount);

  useEffect(() => {
    if (prevOpenMore.current === true && openMore === false) {
      anchorRefMore.current.focus();
    }

    prevOpenMore.current = openMore;
  }, [openMore]);

  useEffect(() => {
    if (prevOpenAccount.current === true && openAccount === false) {
      anchorRefAccount.current.focus();
    }

    prevOpenAccount.current = openAccount;
  }, [openAccount]);

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* <Hidden smDown> */}
      <AppBar
        position="relative"
        elevation={1}
        className={classNames(classes.appBar)}
      >
        <Toolbar
          className={classNames(classes.toolbar)}
          style={{
            justifyContent: "space-between",
            zIndex: 1,
          }}
        >
          <Hidden mdUp>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                props.history.push("/");
              }}
            >
              {localStorage.getItem("language") === "ar" ? (
                <MarkabteLogoAr style={{ width: "124px" }} />
              ) : (
                <MarkabteLogoEn className={classes.img} />
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Badge
                style={{
                  display: "block",
                  // position: "absolute",
                }}
                // badgeContent={schedualedcnt}
                fontSize="small"
                color="secondary"
                className={classes.badeg}
                // onClick={() => bookedSeen()}
              >
                <IconButton
                  classes={{ root: classes.menuuButtonRoot }}
                  onClick={handleOpenDrawer}
                >
                  <MenuIcon style={{ fill: "#0c6885", fontSize: "2rem" }} />
                </IconButton>
              </Badge>
            </div>
          </Hidden>
          <Hidden smDown>
            <ListItem
              style={{
                alignItems: "flex-start",
                // paddingRight: "0px",
                // paddingLeft: "0px",
                padding: "0",
              }}
            >
              <div style={{ /*margin: "auto 0",*/ margin: "auto 12px" }}>
                <ListItem
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    // height: "70.5px",
                  }}
                >
                  <ListItemIcon
                    classes={{
                      selected: classes.selected,
                      root: classes.logoListItemRoot,
                    }}
                    // style={{ "margin-bottom": "15px" }}
                    onClick={() => {
                      props.history.push("/");
                    }}
                  >
                    {localStorage.getItem("language") === "ar" ? (
                      <MarkabteLogoAr
                        style={{ width: "123px" }}
                        className={classes.img}
                      />
                    ) : (
                      <MarkabteLogoEn className={classes.img} />
                    )}
                  </ListItemIcon>
                </ListItem>
              </div>

              <ListItem
                style={{
                  width: "max-content",
                  padding: 0,
                  margin: "auto 0",
                  // height: "50px",
                  // paddingTop: "39px",
                  // paddingBottom: "20px",
                  // paddingLeft: 0,
                }}
              >
                <ListItem
                  classes={{ root: classes.ButtonBaseRoot }}
                  button
                  onClick={() => {
                    // logout();
                    handleListItemClick.bind(this, "/");
                    history.push("/");
                  }}
                  className={classes.listItems}
                >
                  <ListItemText style={{ color: "#07303E" }}>
                    {t("Car Listings")}
                  </ListItemText>
                </ListItem>
                {user?.runner_account && (
                  <>
                    <ListItem
                      classes={{ root: classes.ButtonBaseRoot }}
                      button
                      onClick={() => {
                        history.push("/call-back-requests");
                      }}
                      className={classes.listItems}
                    >
                      <ListItemText style={{ color: "#07303E" }}>
                        {t("Call Back Requests")}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      classes={{ root: classes.ButtonBaseRoot }}
                      button
                      onClick={() => {
                        history.push("/requests-history");
                      }}
                      className={classes.listItems}
                    >
                      <ListItemText style={{ color: "#07303E" }}>
                        {t("Requests History")}
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      classes={{ root: classes.ButtonBaseRoot }}
                      button
                      onClick={() => {
                        history.push("/whatsapp-messages");
                      }}
                      className={classes.listItems}
                    >
                      <ListItemText style={{ color: "#07303E" }}>
                        {t("Whatsapp Messages")}
                      </ListItemText>
                    </ListItem>
                  </>
                )}
                {/* <ListItem
                  classes={{
                    selected: classes.selected,
                    gutters: classes.listItemGutters,
                  }}
                  button
                  id={4}
                  onClick={handleListItemClick.bind(this, "/appointments")}
                >
                  <ListItemText style={{ color: "#07303E" }}>
                    {t("Appointments")}
                  </ListItemText>
                </ListItem> */}
              </ListItem>
            </ListItem>
            <div
              style={{
                alignItems: "flex-start",
                // paddingRight: 0,
                padding: "0",
                display: "flex",
                marginRight: "18px",
              }}
            >
              <ListItem
                style={{
                  width: "max-content",
                  padding: 0,
                  // height: "50px",
                  // paddingTop: "28px",
                  // paddingBottom: "20px",
                }}
              >
                <ListItem
                  classes={{ root: classes.ButtonBaseRoot }}
                  button
                  onClick={() => {
                    localStorage.getItem("language") === "ar"
                      ? localStorage.setItem("language", "en")
                      : localStorage.setItem("language", "ar");
                    window.location.reload();
                  }}
                  className={classes.listItems}
                  // style={{
                  //   width: "max-content",
                  //   height: "50px",
                  // }}
                >
                  <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                    <LanguageNewIcon size="24" fill="#07303E" />
                  </ListItemIcon>
                  <ListItemText style={{ color: "#07303E" }}>
                    <Typography
                      // className={classes.body2}
                      className={classes.listItemTypography}
                      style={{
                        fontFamily:
                          localStorage.getItem("language") === "en"
                            ? "'Cairo', sans-serif"
                            : "'Poppins', sans-serif",
                      }}
                    >
                      {t("Language")}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem
                  style={{
                    width: "max-content",
                    height: "40px",
                    padding: 0,
                  }}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      width: 1,
                      "background-color": "black",
                      margin: 0,
                    }}
                    variant="middle"
                  />
                </ListItem>
                <ListItem
                  classes={{
                    selected: classes.selected,
                    gutters: classes.listItemGutters,
                  }}
                  button
                  id={3}
                  onClick={() => {
                    logout();
                    handleListItemClick.bind(this, "/login");
                    history.push("/login");
                  }}
                >
                  <ListItemIcon>
                    <SignOutIcon size="24" fill="#07303E" />
                  </ListItemIcon>
                  <ListItemText style={{ color: "#07303E" }}>
                    {t("Sign out")}
                  </ListItemText>
                </ListItem>
              </ListItem>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      {/* </Hidden> */}
      {/* <Hidden mdUp>{renderMdDownDrawer()}</Hidden> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  // schedualedcnt: state.appointment?.schedualedcnt,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout() {
    dispatch(logout());
  },
  // bookedSeen(payload) {
  //   dispatch(bookedSeen(payload));
  // },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
