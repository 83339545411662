import { put, takeLatest, takeEvery } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  // GET_APPOINTMENT_INTERVAL,
  // GET_APPOINTMENT_INTERVAL_SUCCESS,
  // GET_APPOINTMENT_INTERVAL_FAILURE,
  GET_DEALER_CREDITS,
  GET_DEALER_CREDITS_SUCCESS,
  GET_DEALER_CREDITS_FAILURE,
  SET_OWNER_INFO,
  SET_OWNER_INFO_FAILURE,
  SET_OWNER_INFO_SUCCEED,
  TOGGLE_NOTIFICATION_BAR,
  GET_CALLS_INFO_FAILURE,
  GET_CALLS_INFO,
  GET_CALLS_INFO_SUCCEED,
  MOVE_TO_HISTORY_SUCCEED,
  MOVE_TO_HISTORY_FAILURE,
  MOVE_TO_HISTORY,
  GET_MESSAGES_INFO_FAILURE,
  GET_MESSAGES_INFO,
  GET_MESSAGES_INFO_SUCCEED,
  RECEIVED_OR_HISTORY_SUCCEED,
  RECEIVED_OR_HISTORY_FAILURE,
  RECEIVED_OR_HISTORY,
  SET_CAR_STATUS,
  SET_CAR_STATUS_SUCCEED,
  SET_CAR_STATUS_FAILURE,
  GET_CAR_STATUS,
  GET_CAR_STATUS_SUCCEED,
  GET_CAR_STATUS_FAILURE,
} from "../constants";

import API_URLS from "../../api";

// export function* getApptInterval(action) {
//   try {
//     let data = {
//       url: API_URLS(action.payload.dealershipId).DEALERSHIP
//         .GET_APPOINTMENT_INTERVAL,
//       method: "GET",
//     };
//     const response = yield requestAPI(data);
//     // yield put({ type: GET_APPOINTMENT_INTERVAL_SUCCESS, payload: response });
//     action.payload.callback(response);
//   } catch (e) {
//     yield put({ type: GET_APPOINTMENT_INTERVAL_FAILURE, error: new Error(e) });
//   }
// }

export function* getDealerCredits(action) {
  try {
    let data = {
      url: API_URLS(action.payload.dealershipId).DEALERSHIP.GET_DEALER_CREDITS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: GET_DEALER_CREDITS_SUCCESS, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: GET_DEALER_CREDITS_FAILURE, error: new Error(e) });
  }
}

// export function* getApptIntervalWatcher() {
//   yield takeEvery(GET_APPOINTMENT_INTERVAL, getApptInterval);
// }
export function* getDealerCreditsWatcher() {
  yield takeEvery(GET_DEALER_CREDITS, getDealerCredits);
}

export function* setOwnerInfo(action) {
  try {
    let data = {
      url: API_URLS(action.payload.listing_details).DEALERSHIP.SET_OWNER_INFO,
      method: "POST",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    console.log("xxxxxxxxxxxxxxx", response);
    if (response.status == "success") {
      yield put({
        type: SET_OWNER_INFO_SUCCEED,
        payload: { data: response.data, carId: action.payload.carid },
      });
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          //to display a notification
          message: "data saved successfuly",
          variant: "success",
          open: true,
        },
      });
    }
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: SET_OWNER_INFO_FAILURE, error: new Error(e) });
  }
}

// export function* getApptIntervalWatcher() {
//   yield takeEvery(GET_APPOINTMENT_INTERVAL, getApptInterval);
// }
export function* setOwnerInfoWatcher() {
  yield takeEvery(SET_OWNER_INFO, setOwnerInfo);
}

export function* getCallsInfo(action) {
  try {
    let data = {
      url: API_URLS(action.payload.dealership_id).DEALERSHIP.GET_CALLS_INFO,
      method: "get",
    };
    const response = yield requestAPI(data);
    console.log("xxxxxxxxxxxxxxx", response.results);
    yield put({
      type: GET_CALLS_INFO_SUCCEED,
      payload: { data: response.results },
    });
    // if (response.status == "success") {
    // }
    // action.payload.callback(response);
  } catch (e) {
    console.log("xxxxxxxxxxxxxxx", e);
    yield put({ type: GET_CALLS_INFO_FAILURE, error: new Error(e) });
  }
}

// export function* getApptIntervalWatcher() {
//   yield takeEvery(GET_APPOINTMENT_INTERVAL, getApptInterval);
// }
export function* getCallsInfoWatcher() {
  yield takeLatest(GET_CALLS_INFO, getCallsInfo);
}

export function* getMessagesInfo(action) {
  try {
    let data = {
      url: API_URLS(action.payload.dealership_id).DEALERSHIP.GET_MESSAGES_INFO,
      method: "get",
    };
    const response = yield requestAPI(data);
    yield put({
      type: GET_MESSAGES_INFO_SUCCEED,
      payload: { data: response.results },
    });
  } catch (e) {
    yield put({ type: GET_MESSAGES_INFO_FAILURE, error: new Error(e) });
  }
}

export function* getMessagesInfoWatcher() {
  yield takeLatest(GET_MESSAGES_INFO, getMessagesInfo);
}

export function* moveToHistory(action) {
  try {
    let data = {
      url: API_URLS().DEALERSHIP.MOVE_TO_HISTORY,
      method: "post",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: MOVE_TO_HISTORY_SUCCEED,
      payload: { data: response.results },
    });
    if (response.status == "success") {
      console.log("vvvvvvvvvvvvvvv", response);
      yield put({
        type: MOVE_TO_HISTORY_SUCCEED,
        payload: { calls_info: response.data },
      });
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          //to display a notification
          message: "data saved successfuly",
          variant: "success",
          open: true,
        },
      });
    }
  } catch (e) {
    console.log("xxxxxxxxxxxxxxx", e);
    yield put({ type: MOVE_TO_HISTORY_FAILURE, error: new Error(e) });
  }
}

// export function* getApptIntervalWatcher() {
//   yield takeEvery(GET_APPOINTMENT_INTERVAL, getApptInterval);
// }
export function* moveToHistoryWatcher() {
  yield takeLatest(MOVE_TO_HISTORY, moveToHistory);
}

export function* receivedOrHistory(action) {
  try {
    let data = {
      url: API_URLS().DEALERSHIP.RECEIVED_OR_HISTORY,
      method: "post",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: RECEIVED_OR_HISTORY_SUCCEED,
      payload: { data: response.results },
    });
    if (response.status == "success") {
      yield put({
        type: RECEIVED_OR_HISTORY_SUCCEED,
        payload: response.data,
      });
      action.payload.callback(response);
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          message: "data saved successfuly",
          variant: "success",
          open: true,
        },
      });
    }
  } catch (e) {
    yield put({ type: RECEIVED_OR_HISTORY_FAILURE, error: new Error(e) });
  }
}

export function* receivedOrHistoryWatcher() {
  yield takeLatest(RECEIVED_OR_HISTORY, receivedOrHistory);
}

export function* setCarStatus(action) {
  try {
    let data = {
      url: API_URLS(action.payload.listing_details).CAR.SET_CAR_STATUS,
      method: "POST",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    if (response.status == "success") {
      yield put({
        type: SET_CAR_STATUS_SUCCEED,
        payload: { data: response.data, carId: action.payload.carid },
      });
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          message: "data saved successfuly",
          variant: "success",
          open: true,
        },
      });
    }
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: SET_CAR_STATUS_FAILURE, error: new Error(e) });
  }
}

export function* setCarStatusWatcher() {
  yield takeEvery(SET_CAR_STATUS, setCarStatus);
}

export function* getCarStatus(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).CAR.GET_CAR_STATUS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: GET_CAR_STATUS_SUCCEED, payload: response });
  } catch (e) {
    yield put({ type: GET_CAR_STATUS_FAILURE, error: new Error(e) });
  }
}

export function* getCarStatusWatcher() {
  yield takeEvery(GET_CAR_STATUS, getCarStatus);
}
