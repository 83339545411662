import { DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogContentText } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Alert } from "@material-ui/lab";
import { Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import BackArrow from "./icons/BackArrow";
import { useHistory, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  endIcon: {
    margin: 0,
  },
  card: {
    marginBottom: " 1px",
    borderRadius: 0,
    height: "100%",
    display: "grid",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  cardContent: {
    padding: "21px 31px 0 17px",
    marginBottom: "-24px", // 24px is to over ride .MuiCardContent-root:last-child's 24px padding just a quick fix
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0 0 16px", // 24px is over ridden in .MuiCardContent-root:last-child just included it to explain the -9 margin just a quick fix
      margin: "0 0 -9px",
    },
  },
  cardActions: {
    padding: "22px 31px 29px",
    marginTop: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 16px 16px",
      marginTop: "0",
      width: "auto",
    },
  },
  dialogPaper: {
    maxWidth: "440px",
    position: "fixed",
    zIndex: 10000,
    overflowX: "hidden",
    [theme.breakpoints.up("md")]: {
      // marginTop: "100px",/////comb
      minWidth: "440px",
      // minHeight: "647px",
      maxHeight: "90vh",
      minHeight: "none",
      borderRadius: "8px",
      padding: "15px 35px 2px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100%",
      maxHeight: "none",
      maxWidth: "none",
      margin: "0",
      borderRadius: "0px",
      // paddingTop: "101px",
    },
  },
  dialogRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  dialogContentText: {
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      flex: "none",
    },
    "&.MuiDialogContent": {
      overflow: "visible",
    },
  },
  ".MuiTableCell-root	": {
    display: "inline",
  },
  listingnumberTypo: { fontSize: "14px", fontWeight: "500", color: "#07303E" },
  continuelistingTypo: {
    textDecoration: "underline",
    fontSize: "12px",
    fontWeight: "600",
    color: "#186985",
    verticalAlign: "top",
    cursor: "pointer",
  },
  continuelistingTypotwo: {
    color: "#186985",
    textDecoration: "underline",
    fontSize: "12px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  listvehicleTypo: {
    cursor: "pointer",
    color: "#186985",
  },
  listvehicleTypotwo: {
    color: "#186985",
    fontSize: "12px",
    fontWeight: "600",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  cardDiv: { display: "flex", width: "100%", justifyContent: "space-between" },
  cardDivtwo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  dialogtitle: {
    float: "left",
    "&.MuiDialogTitle": {
      width: "94%",
      padding: "16px 14px",
      display: "inline-flex",
      justifyContent: "space-between",
    },
  },
  claimRequestTypo: {
    fontSize: "26px",
    fontWeight: 500,
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  dialogActions: {
    padding: 0,
    display: "block",
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      justifyContent: "center",
    },
  },
  cardBase: {
    zIndex: 100000,
    postion: "fixed",
  },
  hr: {
    width: "100%",
    background:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    // "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    height: "1px",
    border: "0px",
    "flex-shrink": 0,
  },
  paddingCard: {
    [theme.breakpoints.up("md")]: {
      padding: "36px",
    },
  },
  backPannel: {
    backgroundColor: "#186985",
    color: "#fff",
    padding: "10px",
    display: "inline-flex",
    alignItems: "center",
  },
}));

const BackPannel = (props) => {
  const { classes, title, history, handleBack } = props;
  return (
    <div className={classes.backPannel}>
      <IconButton
        onClick={() => {
          handleBack();
        }}
      >
        <BackArrow
          size="15"
          fill="white"
          style={
            localStorage.getItem("language") == "en"
              ? {}
              : { transform: "rotate(180deg)" }
          }
        ></BackArrow>
      </IconButton>
      <div>{title}</div>
    </div>
  );
};

function MarkabtePopup(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    open, //open dialog
    setOpen, //dialog orenning setter
    children, //chiled injected in popup as chiled
    title, //heading of popup
    action, //secondary heading whith light dark color in regular
    cardColor, //card back ground
    withdivider, //with divider under header part to split the popup parts
    headColor, //header part background to make header color different color from popup background
    headShadow, //shadow under header
    titleColor, //setting title color text
    actionColor, //action or secoundary header text color
    dir, //direction for popup content | ltr or rtl
    width, //width of popup
    height, //height of popup
    withoutHeader, //to make popup without header , popup with just chiled or content
    closeAtt, //close action
    withUpperBackPannel,
    handleBack,
    padding, //padding for action (chiled container)
  } = props;
  const history = useHistory();
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          document.getElementById("root").style.filter = "none";
          setOpen(false);
          closeAtt();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialogPaper,
          root: classes.dialogRoot,
          paddingCard: classes.paddingCard,
        }}
        style={{ backgroundColor: cardColor || "" }}
        onExited={props?.onExited}
      >
        {withUpperBackPannel && (
          <BackPannel
            title={t(title)}
            classes={classes}
            history={history}
            handleBack={handleBack}
          />
        )}
        {!withoutHeader && (
          <div
            style={{
              background: headColor ? headColor : "#ffffff",
              direction: dir || "",
              position: "relative",
              top: 0,
              left: 0,
              right: 0,
              boxShadow: headShadow || "",
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              disableTypography={true}
              className={classes.dialogTitle}
              style={{
                padding: "16px 14px",
                display: "inline-flex",
                justifyContent: "space-between",
                width: "94%",
              }}
            >
              <Typography
                className={classes.claimRequestTypo}
                style={{ color: titleColor || "" }}
              >
                {title}
              </Typography>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  document.getElementById("root").style.filter = "none";
                  setOpen(false);
                  closeAtt();
                }}
              />
            </DialogTitle>
            <DialogContent
              classes={{ root: classes.dialogContentText }}
              style={{
                postion: !action && "absolute",
                width: !action && "1px",
                display: "contents",
              }}
            >
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogContentTextTwo}
              >
                <Typography
                  style={{ margin: "0 18px", color: actionColor || "" }}
                >
                  {action}
                </Typography>
              </DialogContentText>
            </DialogContent>
            {withdivider && <Divider className={classes.hr} />}
          </div>
        )}
        <DialogActions
          className={classes.dialogActions}
          style={{ direction: dir || "", padding: padding }}
        >
          {children}
        </DialogActions>
      </Dialog>
    </div>
  );
}

MarkabtePopup.defaultProps = {
  closeAtt: () => {},
  setOpen: () => {},
};

MarkabtePopup.propTypes = {
  closeAtt: PropTypes.func,
  setOpen: PropTypes.func,
};

export default withRouter(MarkabtePopup);
