// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";
import {
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_REQUEST_SUCCEED,
  FETCH_TOKEN_REQUEST_FAILURE,
  TOKEN_REMOVE_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCEED,
  FETCH_REFRESH_TOKEN_REQUEST, ///
  FETCH_REFRESH_TOKEN_REQUEST_SUCCEED, ///
  FETCH_REFRESH_TOKEN_REQUEST_FAILURE, ///
  UPDATE_KEEP_ME_LOGGED_IN, ///
} from "../constants";
import cookies from "js-cookie";
import cookie from "js-cookie";
import jwtDecode from "jwt-decode"; ///

const initialState = {
  token: null,
  user: null,
  isAuthenticating: false,
  isAuthenticated: false,
  keepMeLoggedIn: true,
};

export default createReducer(initialState, {
  [FETCH_TOKEN_REQUEST]: (state) => {
    cookies.remove("token"); ///
    // cookies.remove("refresh-token"); ///
    return Object.assign({}, state, {
      token: null, ///
      user: null, ///
      isAuthenticating: true,
    });
  },
  [FETCH_TOKEN_REQUEST_SUCCEED]: (state, payload) => {
    let token = payload.access;
    let user = jwtDecode(token);
    cookies.set("token", token, {
      expires: 2110,
    });
    if (state.keepMeLoggedIn === true) {
      cookies.set("refresh-token", payload.refresh, {
        expires: 2110,
      });
    }
    return Object.assign({}, state, {
      token: token, //payload.token,///
      user: user, //payload.user,///
      isAuthenticating: false,
      isAuthenticated: true,
    });
  },
  [FETCH_TOKEN_REQUEST_FAILURE]: (state) => {
    cookies.remove("token");
    return Object.assign({}, state, {
      isAuthenticating: false,
    });
  },
  /////////////////////////////////////////////////////////////////////

  [FETCH_REFRESH_TOKEN_REQUEST]: (state) => {
    return Object.assign({}, state, {
      isAuthenticating: true,
    });
  },
  [FETCH_REFRESH_TOKEN_REQUEST_SUCCEED]: (state, payload) => {
    cookies.set("token", payload.access, {
      expires: 2110,
    });
    cookie.set("token", payload.access, {
      expires: 2110,
    });
    return Object.assign({}, state, {
      token: payload.access,
      isAuthenticating: false,
      isAuthenticated: true,
    });
  },
  //double check the action below, adjustments might be required later.
  [FETCH_REFRESH_TOKEN_REQUEST_FAILURE]: (state) => {
    cookies.remove("token");
    return Object.assign({}, state, {
      isAuthenticating: false,
      isAuthenticated: false,
    });
  },

  [UPDATE_KEEP_ME_LOGGED_IN]: (state, payload) => {
    return Object.assign({}, state, {
      keepMeLoggedIn: payload,
    });
  },
  /////////////////////////////////////////////////////////////////////

  [TOKEN_REMOVE_REQUEST]: () => {
    cookies.remove("token");
    cookies.remove("refresh-token");
    return Object.assign({}, initialState);
  },
  [FORGOT_PASSWORD_REQUEST]: () => {
    return Object.assign({}, initialState);
  },
  [FORGOT_PASSWORD_REQUEST_SUCCEED]: () => {
    return Object.assign({}, initialState);
  },
});
