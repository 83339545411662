import React from "react";

const MaintainIcon = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size ?? "32"}
      height={size ?? "37"}
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8313 20.7254V21.896H3.36009C2.30099 21.8867 1.44629 21.32 1.44629 20.6232C1.44629 19.6571 3.07209 19.0253 4.38202 19.527C4.47492 19.5642 4.53995 19.6571 4.53995 19.7593V20.4932C4.53995 20.6232 4.64215 20.7254 4.77221 20.7254H5.8313Z"
        fill="#7A9BA7"
      />
      <path
        d="M31.1655 20.6232C31.1655 21.4408 30.0971 21.896 29.261 21.896H26.7898V20.7254H27.8396C27.9697 20.7254 28.0719 20.6232 28.0719 20.5025V19.7592C28.0719 19.657 28.1369 19.5641 28.2298 19.5363C29.5118 19.0346 31.1655 19.6385 31.1655 20.6232Z"
        fill="#7A9BA7"
      />
      <path
        d="M5.94351 29.3376V35.0512C5.94351 35.59 5.50686 36.0174 4.97732 36.0174H1.89295C1.3634 36.0174 0.926758 35.59 0.926758 35.0512V29.3376H5.94351Z"
        fill="#5B5B5B"
      />
      <path
        d="M26.6782 29.3376V35.0512C26.6782 35.59 27.1149 36.0174 27.6444 36.0174H30.7288C31.2583 36.0174 31.695 35.59 31.695 35.0512V29.3376H26.6782Z"
        fill="#5B5B5B"
      />
      <path
        d="M28.0351 21.8962L26.3907 17.0002C25.8333 15.7646 24.6162 14.9564 23.2691 14.9192C18.6333 14.7706 13.9882 14.7706 9.35231 14.9192C7.99593 14.9657 6.78819 15.7739 6.23078 17.0002L4.5864 21.8962C2.36602 22.4815 0.749512 24.4975 0.749512 26.8851V30.6941C0.749512 32.4964 2.1988 33.955 3.99182 33.9736H28.6296C30.432 33.955 31.8719 32.4871 31.8719 30.6941V26.8758C31.8719 24.4882 30.2647 22.4815 28.0351 21.8962V21.8962Z"
        fill="#BDD9E3"
      />
      <path
        opacity="0.08"
        d="M31.8722 26.8666V30.6849C31.8722 32.4873 30.4229 33.9458 28.6299 33.9644H26.2702C28.0725 33.9458 29.5125 32.478 29.5125 30.6849V26.8666C29.5125 25.4917 28.9736 24.2282 28.0818 23.2992C27.4314 22.6303 26.6139 22.1379 25.6756 21.887L24.0312 16.9911C23.4738 15.7555 22.2568 14.9472 20.9097 14.9008H23.2694C24.6165 14.9472 25.8335 15.7555 26.3909 16.9911L28.0353 21.887C30.2836 22.4816 31.8722 24.5069 31.8722 26.8666V26.8666Z"
        fill="black"
      />
      <path
        d="M22.711 27.3591L21.3732 31.0566C21.271 31.484 20.8808 31.7905 20.4349 31.7905H12.1851C11.7392 31.7905 11.349 31.484 11.2468 31.0566L9.909 27.3591C9.76036 26.7552 10.2249 26.1699 10.8473 26.1699H21.7727C22.3951 26.1699 22.8596 26.7552 22.711 27.3591Z"
        fill="#949494"
      />
      <path
        opacity="0.08"
        d="M22.712 27.3591L21.3649 31.0659C21.2627 31.4932 20.8725 31.7998 20.4266 31.7998H18.6057C19.0516 31.7998 19.4325 31.4932 19.5347 31.0659L20.8818 27.3591C21.0305 26.7552 20.5753 26.1699 19.9528 26.1699H21.7737C22.3961 26.1699 22.8607 26.7552 22.712 27.3591Z"
        fill="black"
      />
      <path
        d="M8.61876 27.721C7.27167 28.9102 5.26497 29.2354 2.7473 28.8545C2.54292 28.8266 2.40356 28.6501 2.40356 28.4457V26.5783C2.40356 26.3461 2.60795 26.151 2.84021 26.1696C5.01413 26.2996 7.03941 26.504 8.49799 27.0243C8.78599 27.1265 8.86031 27.5074 8.61876 27.721Z"
        fill="white"
      />
      <path
        d="M5.14323 27.5819C5.14323 28.1486 4.688 28.6038 4.1213 28.6038C3.55459 28.6038 3.09937 28.1486 3.09937 27.5819C3.09937 27.0152 3.55459 26.5599 4.1213 26.5599C4.688 26.5599 5.14323 27.0245 5.14323 27.5819Z"
        fill="#F2F0F0"
      />
      <path
        d="M6.99266 27.5817C6.99266 27.9626 6.68608 28.2784 6.29589 28.2784C5.9057 28.2784 5.59912 27.9719 5.59912 27.5817C5.59912 27.2008 5.9057 26.8849 6.29589 26.8849C6.68608 26.8942 6.99266 27.2008 6.99266 27.5817Z"
        fill="#F2F0F0"
      />
      <path
        d="M24.0028 27.721C25.3499 28.9102 27.3566 29.2354 29.8742 28.8545C30.0786 28.8266 30.218 28.6501 30.218 28.4457V26.5783C30.218 26.3461 30.0136 26.151 29.7813 26.1696C27.6074 26.2996 25.5821 26.504 24.1235 27.0243C23.8355 27.1265 23.7612 27.5074 24.0028 27.721Z"
        fill="white"
      />
      <path
        d="M27.478 27.5819C27.478 28.1486 27.9333 28.6038 28.5 28.6038C29.0667 28.6038 29.5219 28.1486 29.5219 27.5819C29.5219 27.0152 29.0667 26.5599 28.5 26.5599C27.9333 26.5599 27.478 27.0245 27.478 27.5819Z"
        fill="#F2F0F0"
      />
      <path
        d="M25.6292 27.5817C25.6292 27.9626 25.9357 28.2784 26.3259 28.2784C26.7068 28.2784 27.0227 27.9719 27.0227 27.5817C27.0227 27.2008 26.7161 26.8849 26.3259 26.8849C25.9357 26.8942 25.6292 27.2008 25.6292 27.5817Z"
        fill="#F2F0F0"
      />
      <path
        d="M26.9848 21.7196C26.9662 21.7103 26.9569 21.7103 26.9383 21.7103C17.1464 21.3016 5.8587 21.6081 5.64502 21.7196L7.11288 17.3346C7.53095 16.452 8.40423 15.8853 9.389 15.8482C13.4767 15.7088 18.4656 15.6902 23.2408 15.8482C23.7146 15.8667 24.1513 16.0061 24.5322 16.2384C24.9502 16.4985 25.3033 16.8701 25.5169 17.3346L26.9848 21.7196Z"
        fill="#EDEDED"
      />
      <path
        opacity="0.4"
        d="M8.06171 32.2918C8.06171 32.6728 7.75513 32.9793 7.37423 32.9793H5.1074C4.7265 32.9793 4.41992 32.6728 4.41992 32.2918C4.41992 31.9109 4.7265 31.6044 5.1074 31.6044H7.37423C7.75513 31.6044 8.06171 31.9109 8.06171 32.2918Z"
        fill="white"
      />
      <path
        d="M13.4989 10.5521C12.7611 11.5355 11.7493 12.279 10.5903 12.6892C9.43142 13.0994 8.17714 13.1581 6.98501 12.8578C6.11399 12.6393 5.30118 12.2333 4.6034 11.668C3.90561 11.1028 3.3397 10.3919 2.94519 9.58524C2.89685 9.48933 2.87408 9.38256 2.8791 9.27527C2.88412 9.16798 2.91676 9.0638 2.97385 8.97283C3.03095 8.88185 3.11057 8.80716 3.205 8.75598C3.29943 8.7048 3.40547 8.67887 3.51286 8.68071H6.39863C6.61763 8.6807 6.83277 8.62305 7.02242 8.51355C7.21208 8.40405 7.36957 8.24655 7.47907 8.05689L7.83839 7.43308C7.94789 7.24341 8.00554 7.02827 8.00554 6.80926C8.00554 6.59026 7.94789 6.37511 7.83839 6.18545L7.47907 5.56164C7.36957 5.37198 7.21208 5.21448 7.02242 5.10498C6.83277 4.99548 6.61763 4.93783 6.39863 4.93782H3.51286C3.40598 4.93932 3.30051 4.91333 3.20657 4.86234C3.11262 4.81136 3.03335 4.73709 2.97636 4.64666C2.91937 4.55623 2.88657 4.45267 2.88112 4.34592C2.87566 4.23917 2.89772 4.1328 2.94519 4.03703C3.33885 3.22933 3.90444 2.51753 4.60232 1.95156C5.30019 1.38559 6.11343 0.979156 6.98501 0.760762C8.17714 0.460453 9.43142 0.519082 10.5903 0.929286C11.7493 1.33949 12.7611 2.08298 13.4989 3.06638H25.8504C26.734 3.05058 27.596 3.33971 28.2914 3.88509C28.9867 4.43047 29.473 5.19879 29.6681 6.06069C29.778 6.60442 29.7658 7.16576 29.6324 7.70422C29.4991 8.24267 29.2479 8.74483 28.8971 9.17448C28.5462 9.60413 28.1043 9.95056 27.6034 10.1888C27.1024 10.427 26.5548 10.5511 26.0001 10.5521H13.4976"
        fill="#EAEAEA"
      />
    </svg>
  );
};
export default MaintainIcon;
