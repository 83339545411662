import React from "react";

const FilterIcon = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9035 4.39314H7.9792C7.73385 3.66442 7.04459 3.13794 6.2342 3.13794C5.42381 3.13794 4.73455 3.66442 4.4892 4.39314H3.09619C2.7726 4.39314 2.51025 4.65548 2.51025 4.97907C2.51025 5.30267 2.7726 5.56501 3.09619 5.56501H4.48924C4.73459 6.29372 5.42385 6.82021 6.23424 6.82021C7.04463 6.82021 7.73389 6.29372 7.97924 5.56501H16.9035C17.2271 5.56501 17.4894 5.30267 17.4894 4.97907C17.4894 4.65548 17.2271 4.39314 16.9035 4.39314ZM6.2342 5.64833C5.86518 5.64833 5.56494 5.3481 5.56494 4.97907C5.56494 4.61005 5.86518 4.30981 6.2342 4.30981C6.60322 4.30981 6.90346 4.61005 6.90346 4.97907C6.90346 5.3481 6.60322 5.64833 6.2342 5.64833Z"
        fill={fill}
      />
      <path
        d="M16.9035 9.41389H15.5104C15.2651 8.68518 14.5758 8.15869 13.7654 8.15869C12.9551 8.15869 12.2658 8.68518 12.0204 9.41389H3.09619C2.7726 9.41389 2.51025 9.67623 2.51025 9.99982C2.51025 10.3234 2.7726 10.5858 3.09619 10.5858H12.0204C12.2658 11.3145 12.9551 11.841 13.7654 11.841C14.5758 11.841 15.2651 11.3145 15.5104 10.5858H16.9035C17.2271 10.5858 17.4894 10.3234 17.4894 9.99982C17.4894 9.67623 17.2271 9.41389 16.9035 9.41389ZM13.7654 10.6691C13.3964 10.6691 13.0962 10.3688 13.0962 9.99982C13.0962 9.6308 13.3964 9.33057 13.7654 9.33057C14.1345 9.33057 14.4347 9.6308 14.4347 9.99982C14.4347 10.3688 14.1345 10.6691 13.7654 10.6691Z"
        fill={fill}
      />
      <path
        d="M16.9035 14.4349H10.4896C10.2443 13.7062 9.55502 13.1797 8.74463 13.1797C7.93424 13.1797 7.24498 13.7062 6.99963 14.4349H3.09619C2.7726 14.4349 2.51025 14.6972 2.51025 15.0208C2.51025 15.3444 2.7726 15.6068 3.09619 15.6068H6.99963C7.24498 16.3355 7.93424 16.8619 8.74463 16.8619C9.55502 16.8619 10.2443 16.3355 10.4896 15.6068H16.9035C17.2271 15.6068 17.4894 15.3444 17.4894 15.0208C17.4894 14.6972 17.2271 14.4349 16.9035 14.4349ZM8.74463 15.6901C8.37561 15.6901 8.07537 15.3899 8.07537 15.0209C8.07537 14.6518 8.37561 14.3516 8.74463 14.3516C9.11365 14.3516 9.41389 14.6518 9.41389 15.0208C9.41389 15.3898 9.11365 15.6901 8.74463 15.6901Z"
        fill={fill}
      />
    </svg>
  );
};

export default FilterIcon;
