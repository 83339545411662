import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SoldCar from "../../icons/SoldCar.svg";
import CanceledPurchas from "../../icons/CanceledPurchas.svg";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "16px",
    //    minHeight:'500px'
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
  },
  blockD: {
    borderRadius: "5px",
    border: "1px solid #186985",
    boxShadow: "0px 1.32929px 1.99394px rgba(15, 71, 90, 0.4)",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  selectionBox: {
    fontSize: "14px",
    padding: "15px 5px",
    margin: "5px",
    color: "#186985",
    fontWeight: "500",
    textAlign: "center",
    borderRadius: "3px",
    fontFamily: "Poppins",
  },
  selectedBox: {
    backgroundColor: "#CCE0E7",
  },
  inline: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: "3px",
    fontSize: "16px",
    fontWeight: "medium",
    margin: "24px 0",
  },
  hr: {
    width: "100%",
    background: "#CDCDCD",
    height: "1px",
    border: "0px",
    "flex-shrink": 0,
    margin: "12px 0",
  },
  nextButton: {
    display: "flex",
    alignItems: "flex-end",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "clamp(12px,1vw,14px)",
    // position:'absolute',
    width: "86%",
    margin: "auto",
    left: "50%",
    marginLeft: "-43%",
    padding: "10px 0",
    [theme.breakpoints.up("md")]: {
      //   margin: "5px 32px",
      //   padding:'12px 45px',
      //   fontSize:'18px',
      //   float:'right',
      //   right:'20px',
      //   maxWidth: "193px",
      marginTop: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      //   postion:'relative',
      //   margin: "5px auto",
      //   width:'94%',
      marginTop: "150px",
    },
  },
}));

function PurchaseState(props) {
  const { data, setData, handleNext } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  //card single
  function radioCard(title, action, icon) {
    return (
      <div
        className={classes.inline}
        onClick={() => setData({ ...data, carState: title })}
      >
        <div style={{ display: "inline-flex", alignItems: "flex-start" }}>
          <FormControlLabel
            value={title}
            style={{ fontWeight: "bold" }}
            control={<Radio color={"primary"} />}
          />
          <div>
            <Typography style={{ fontWeight: "600" }}>{t(title)}</Typography>
            <Typography>{t(action)}</Typography>
          </div>
        </div>
        <div>
          <img src={icon} />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.mainContainer}>
      <div className={classes.flexContainer}>
        <div style={{ display: "grid" }}>
          <FormControl component="fieldset" className={classes.radiotext}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={data.carState}
            >
              {radioCard(
                "Car is sold",
                "Car ownership has been transfered to the new car owner.",
                SoldCar
              )}
              <Divider className={classes.hr} />
              {radioCard(
                "Deal is cancelled",
                "The buyer is no longer interested in buying the car.",
                CanceledPurchas
              )}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <Button
        className={classes.nextButton}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleNext}
      >
        {t("Submit")}
      </Button>
    </div>
  );
}

export default PurchaseState;
