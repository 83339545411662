import { Hidden, IconButton } from "@material-ui/core";
import MarkabtePopup from "./MarkabtePopup";
import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import FaultyAnswer from "../icons/faultyPoint.svg";
import UnFaultyAnswer from "../icons/unFaultyPoint.svg";
import InformativeAnswer from "../icons/infoPoint.svg";
import Information from "../icons/Information.svg";
import NotIncluded from "../icons/NotIncluded.svg";
import InfoIcon from "@material-ui/icons/Info";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
function InspectionReportMobile(Props) {
  var pointCount = 0;
  const {
    classes,
    t,
    // IOSSwitch,
    switchState,
    handleSwitchChange,
    groups,
    openTooltip,
    changeFaults,
    mmRegex,
    DetInspRepDiscription,
    checkGroupFilter,
    expanded,
    handleChange,
    // checkSubgroupFilter,
    faultscnt,
    setframeFaultopen,
    setfaultOpen,
    tooltipButtonId,
    skelStructure,
    handleAnswerClick,
    tireAgeAnswer,
    DetInspRepIcons,
    checkFieldFilter,
    infoSpecialCase,
    handleTooltipClose,
    setshowfaults,
    tireAgeIds,
    showfaults,
    handleTooltipOpen,
    setframeFault,
    showInteriorFaults,
    setExpanded,
    carDetailsPopup,
    setCarDetailsPopup,
    handleInspectionNotesDialog,
  } = Props;

  const [subPopup, setsubPopup] = useState(false);
  const [subGroupsSelected, setsubGroupsSelected] = useState([]);
  const [allGroups, setallGroups] = useState([]);
  const [groupIndex, setgroupIndex] = useState(0);
  const [subGroupExpanded, setsubGroupExpanded] = useState(null);

  const handleSubgroupChange = (panel) => (event, isExpanded) => {
    setsubGroupExpanded(isExpanded ? panel : false);
  };

  const innerCloser = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenDetails = () => {
    setsubPopup(true);
  };

  useEffect(() => {
    if (groups) {
      setsubGroupsSelected(groups.groups?.[groupIndex]?.sub_groups);
      setallGroups(groups.groups?.[groupIndex]);
    }
  }, [groups]);

  const spreadSubGroups = (groups, subGroups, groupIndex) => {
    setsubPopup(true);
    setsubGroupsSelected(subGroups);
    setallGroups(groups);
    setgroupIndex(groupIndex);
  };

  function checkSubgroupFilter(list) {
    if (switchState) {
      return list.filter((element) => {
        return (
          element?.score?.fields?.subgroup_score < 100 ||
          element?.score?.fields?.subgroup_score === undefined
        );
      });
    } else {
      return list;
    }
  }

  return (
    <div>
      <Hidden mdUp>
        <MarkabtePopup
          withoutHeader={true}
          withUpperBackPannel={true}
          open={carDetailsPopup}
          handleBack={() => {
            setCarDetailsPopup(false);
          }}
          title={"Car Details"}
        >
          <Paper className={classes.headerVisualizationPaper}>
            <div>
              <div style={{ display: "flex" }}>
                <Typography
                  style={
                    localStorage.getItem("language") === "ar"
                      ? { fontWeight: "700" }
                      : { fontWeight: "500" }
                  }
                  className={classes.headerVisualization}
                >
                  {t("Car Inspection Notes")}
                </Typography>
                <Hidden smDown>
                  <hr
                    style={{
                      height: "1px",
                      width: "100%",
                      border: "none",
                      background: "#D2D2D2",
                      margin: "auto",
                    }}
                  />
                </Hidden>
              </div>
              <div
                container
                style={{
                  display: "inline-flex",
                  padding: "10px",
                  backgroundColor: "#D9F2FB",
                  margin: "10px 0px",
                  width: "calc(100% - 20px)",
                }}
              >
                <InfoOutlinedIcon />
                <Typography>
                  {t(
                    "Inspection notes are available for 24hr from date of inspection."
                  )}
                </Typography>
              </div>
              {groups !== undefined ? (
                // to not  crash before data is fetched from api
                <div>
                  {checkGroupFilter(groups.groups).map((group, i) => {
                    const subGroups = group?.sub_groups;
                    if (group)
                      return group?.title !== "comments" &&
                        group?.group_score.fields?.group_score != 100 ? ( // removes comment from groups expansion panel
                        <ExpansionPanel
                          style={{
                            background: "#F4F5F7",
                            margin: "-1px",
                            border: "1px solid #B5B5B5",
                          }}
                          expanded={expanded === `group_${i}`}
                          onClick={() => {
                            spreadSubGroups(group, subGroups, i);
                          }}
                          key={`group_${i}`}
                        >
                          <ExpansionPanelSummary
                            classes={{
                              content: classes.expansionPanelSummaryContent,
                              root: classes.expansionPanelSummaryRoot,
                            }}
                            expandIcon={
                              <ExpandMoreIcon style={{ fill: "#000000" }} />
                            }
                          >
                            <img
                              src={
                                group != undefined
                                  ? DetInspRepIcons[group["group_id"]]
                                  : null
                              }
                              className={
                                group != undefined
                                  ? group["group_id"] === 7
                                    ? classes.exteriorGroupIcon
                                    : classes.groupIcon
                                  : null
                              }
                              alt="DetInspRepIcons"
                            />
                            <Typography
                              style={
                                localStorage.getItem("language") === "ar"
                                  ? { fontWeight: "600" }
                                  : { fontWeight: "400" }
                              }
                              className={classes.groupTitle}
                            >
                              {group?.title}
                            </Typography>
                          </ExpansionPanelSummary>
                          <div className={classes.tableInfoDiv}>
                            <Typography className={classes.groupDisclaimer}>
                              {group != undefined &&
                              DetInspRepDiscription[group["group_id"]]
                                ? DetInspRepDiscription[group["group_id"]].text
                                : null}
                            </Typography>
                            <Grid
                              container
                              justify="space-between"
                              className={classes.discriptionGridContainer}
                            >
                              {group != undefined &&
                              DetInspRepDiscription[group["group_id"]]
                                ? DetInspRepDiscription[
                                    group["group_id"]
                                  ]?.iconDisc.map((item) => (
                                    <Grid
                                      item
                                      style={{
                                        display: "flex",
                                        marginBottom: "14px",
                                      }}
                                    >
                                      <img
                                        src={item.icon}
                                        alt=""
                                        className={classes.discriptionIcon}
                                      />
                                      <Typography
                                        className={classes.discriptionText}
                                      >
                                        {item.represents}
                                      </Typography>
                                    </Grid>
                                  ))
                                : null}
                            </Grid>
                          </div>
                          <div
                            className={classes.column}
                            style={{ columnCount: 1 }}
                          >
                            <ExpansionPanelDetails
                              className={classes.expansionPanelDetails}
                            >
                              {subGroups !== undefined ? ( // to not crash before end of slow fields fetch
                                checkSubgroupFilter(subGroups).map(
                                  (subGroup, index) => {
                                    const fields = subGroup.fields;
                                    let destructuredFields = {};
                                    {
                                      var i = 0;
                                      checkFieldFilter(fields).map((option) => {
                                        let x = fields.filter((el) => {
                                          return (
                                            el.selected_option ===
                                              option.selected_option &&
                                            option.selected_option !== null &&
                                            option.selected_option !== 3263 && // original paint
                                            option.selected_option !== 3264 && // repainted paint
                                            option.selected_option !== 3265 // in applicable paint
                                          );
                                        });
                                        destructuredFields = Object.assign(
                                          destructuredFields,
                                          {
                                            [option.field_id]: {
                                              field_tag:
                                                option?.field_tag?.title,
                                              field_id: option?.field_tag?.id,
                                              field: option?.field_title,
                                              field_tree_id:
                                                option?.field_tree_id,
                                              score: option?.field_score,
                                              img: {
                                                [option?.field_title]: [
                                                  ...(destructuredFields[
                                                    option?.field_id
                                                  ]?.img[option?.field_title] ??
                                                    []),
                                                  ...(option?.images ?? []),
                                                ],
                                              },
                                              images: [
                                                ...(destructuredFields[
                                                  option?.field_id
                                                ]?.images ?? []),
                                                {
                                                  [option?.field_title]:
                                                    option?.with_image == false
                                                      ? localStorage.getItem(
                                                          "language"
                                                        ) == "en"
                                                        ? [
                                                            [
                                                              "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png",
                                                            ],
                                                            option?.field_score,
                                                          ]
                                                        : [
                                                            [
                                                              "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png",
                                                            ],
                                                            option?.field_score,
                                                          ]
                                                      : [
                                                          option?.images,
                                                          option?.field_score,
                                                        ],
                                                },
                                              ],
                                              answers: Object.assign(
                                                destructuredFields[
                                                  option.field_id
                                                ]
                                                  ? destructuredFields[
                                                      option?.field_id
                                                    ].answers
                                                  : {},
                                                {
                                                  [option.option_title ||
                                                  option.text]: x.length,
                                                }
                                              ),
                                              clickableAnswers: {
                                                title:
                                                  option?.field_tag?.id === 24
                                                    ? option.field_id === 335
                                                      ? t("Report Image")
                                                      : option.text
                                                    : null,
                                                image: option?.images,
                                              },
                                              fieldType: option.field_type,
                                              detailedFaultsWithImages: [
                                                ...(destructuredFields[
                                                  option?.field_id
                                                ]?.detailedFaultsWithImages ??
                                                  []),
                                                {
                                                  [option?.field_tree_id]:
                                                    option?.with_image == false
                                                      ? localStorage.getItem(
                                                          "language"
                                                        ) == "en"
                                                        ? [
                                                            [
                                                              "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png",
                                                            ],
                                                            option?.field_score,
                                                            option?.option_title,
                                                          ]
                                                        : [
                                                            [
                                                              "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png",
                                                            ],
                                                            option?.field_score,
                                                            option?.option_title,
                                                          ]
                                                      : [
                                                          option?.images,
                                                          option?.field_score,
                                                          option?.option_title,
                                                        ],
                                                },
                                              ],
                                              extended_answers:
                                                option?.extended_answers,
                                            },
                                          }
                                        );
                                      });
                                    }
                                    pointCount =
                                      pointCount +
                                      Object.keys(destructuredFields).length;
                                    return (
                                      Math.floor(
                                        subGroup?.score?.fields?.subgroup_score
                                      ) != 100 && (
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "100%",
                                          }}
                                        >
                                          <Paper className={classes.table}>
                                            <header
                                              style={{
                                                display: "table-row",
                                              }}
                                              className={classes.tablehead}
                                            >
                                              <div
                                                style={{
                                                  display: "table-cell",
                                                }}
                                              >
                                                <Typography
                                                  className={
                                                    classes.subGroupText
                                                  }
                                                >
                                                  {/* here */}
                                                  {subGroup.title}
                                                </Typography>
                                              </div>
                                              <div
                                                style={{
                                                  display: "table-cell",
                                                }}
                                              >
                                                <Typography
                                                  className={
                                                    classes.subGroupScoreText
                                                  }
                                                  style={
                                                    group?.group_id == 20 &&
                                                    group?.sub_groups?.[0]
                                                      ?.fields?.[0]?.field_id ==
                                                      916
                                                      ? {
                                                          color: "#929693",
                                                        }
                                                      : group?.group_id != 15 &&
                                                        group?.group_id != 16 &&
                                                        group?.group_id != 21 &&
                                                        !(
                                                          group?.group_id ==
                                                            19 &&
                                                          group?.sub_groups?.[0]
                                                            ?.fields?.[0]
                                                            ?.field_id == 902
                                                        )
                                                      ? //here
                                                        {
                                                          color:
                                                            "rgb(60, 182, 86)",
                                                          // color: !subGroup.score
                                                          //   ? "#929693"
                                                          //   : subGroup.score
                                                          //       .fields
                                                          //       .subgroup_score <=
                                                          //     50
                                                          //   ? "rgb(235, 50, 35)"
                                                          //   : subGroup.score
                                                          //       .fields
                                                          //       .subgroup_score <=
                                                          //     70
                                                          //   ? "rgb(236, 222, 0)"
                                                          //   : "rgb(60, 182, 86)",
                                                        }
                                                      : {
                                                          color: "#8A8A8A",
                                                        }
                                                  }
                                                >
                                                  {group?.group_id == 20 &&
                                                  group?.sub_groups?.[0]
                                                    ?.fields?.[0]?.field_id ==
                                                    916
                                                    ? ""
                                                    : group?.group_id != 15
                                                    ? group?.group_id != 16 &&
                                                      group?.group_id != 21
                                                      ? !subGroup.score
                                                        ? "N/A"
                                                        : group?.group_id ==
                                                            19 &&
                                                          group?.sub_groups?.[0]
                                                            ?.fields?.[0]
                                                            ?.field_id == 902
                                                        ? t("Not Available")
                                                        : "+" +
                                                          (100 -
                                                            Math.floor(
                                                              subGroup?.score
                                                                ?.fields
                                                                ?.subgroup_score
                                                            ))
                                                      : group?.sub_groups?.[0]
                                                          ?.fields?.length == 1
                                                      ? t("Not Available")
                                                      : faultscnt(subGroup)
                                                    : ""}{" "}
                                                  {group?.group_id == 20 &&
                                                  group?.sub_groups?.[0]
                                                    ?.fields?.[0]?.field_id ==
                                                    916
                                                    ? t("Not Available")
                                                    : group?.group_id != 15
                                                    ? group?.group_id != 16 &&
                                                      group?.group_id != 21
                                                      ? group?.group_id == 19 &&
                                                        group?.sub_groups?.[0]
                                                          ?.fields?.[0]
                                                          ?.field_id == 902
                                                        ? null
                                                        : "%"
                                                      : group?.sub_groups?.[0]
                                                          ?.fields?.length == 1
                                                      ? null
                                                      : t("Faults")
                                                    : ""}
                                                </Typography>
                                              </div>
                                            </header>
                                            {Object.keys(destructuredFields)
                                              .sort((a, b) => {
                                                return (
                                                  destructuredFields[a]
                                                    .field_tree_id -
                                                  destructuredFields[b]
                                                    .field_tree_id
                                                );
                                              })
                                              .map((key, fieldIndex) => {
                                                return key == 950 &&
                                                  Object.keys(
                                                    destructuredFields[key]
                                                      .answers
                                                  )[0] == "null" ? null : (
                                                  <div
                                                    style={{
                                                      display:
                                                        "table-row-group",
                                                    }}
                                                  >
                                                    {fieldIndex === 0 ? (
                                                      <div
                                                        style={{
                                                          display: "table-row",
                                                        }}
                                                      >
                                                        <td
                                                          colSpan="2"
                                                          style={{
                                                            padding: 0,
                                                            backgroundColor:
                                                              "#fff",
                                                          }}
                                                        >
                                                          <hr
                                                            style={{
                                                              height: "2px",
                                                              border: "none",
                                                              margin: "6px 0",
                                                            }}
                                                          />
                                                        </td>
                                                      </div>
                                                    ) : (
                                                      <Hidden smDown>
                                                        <div
                                                          style={{
                                                            display:
                                                              "table-row",
                                                            height: "8px",
                                                          }}
                                                        >
                                                          <td
                                                            colSpan="2"
                                                            style={{
                                                              padding: 0,
                                                              backgroundColor:
                                                                "#fff",
                                                            }}
                                                          ></td>
                                                        </div>
                                                      </Hidden>
                                                    )}
                                                    <div
                                                      style={{
                                                        display: "table-row",
                                                        backgroundColor: "#fff",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "table-cell",
                                                        }}
                                                        className={
                                                          classes.firstitem
                                                        }
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <Typography
                                                            style={
                                                              localStorage.getItem(
                                                                "language"
                                                              ) === "ar"
                                                                ? {
                                                                    fontWeight:
                                                                      "600",
                                                                  }
                                                                : {
                                                                    fontWeight:
                                                                      "500",
                                                                  }
                                                            }
                                                            className={
                                                              classes.firstItemTypography
                                                            }
                                                          >
                                                            {
                                                              destructuredFields[
                                                                key
                                                              ].field
                                                            }
                                                          </Typography>
                                                          <Hidden mdUp>
                                                            {infoSpecialCase(
                                                              key
                                                            ) && (
                                                              <Tooltip
                                                                title={t(
                                                                  "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking."
                                                                )}
                                                                arrow
                                                                placement="top-start"
                                                                open={
                                                                  openTooltip &&
                                                                  tooltipButtonId ===
                                                                    key
                                                                }
                                                                onClose={
                                                                  handleTooltipClose
                                                                }
                                                                classes={{
                                                                  tooltip:
                                                                    classes.tooltip,
                                                                  arrow:
                                                                    classes.tooltipArrow,
                                                                }}
                                                                enterTouchDelay={
                                                                  0
                                                                }
                                                                leaveTouchDelay={
                                                                  6000
                                                                }
                                                              >
                                                                <Button
                                                                  id={key}
                                                                  onClick={
                                                                    handleTooltipOpen
                                                                  }
                                                                  style={{
                                                                    padding:
                                                                      "0",
                                                                    minWidth:
                                                                      "0px",
                                                                  }}
                                                                >
                                                                  <img
                                                                    src={
                                                                      Information
                                                                    }
                                                                    alt="infoIcon"
                                                                    className={
                                                                      classes.infoIcon
                                                                    }
                                                                  />
                                                                </Button>
                                                              </Tooltip>
                                                            )}
                                                          </Hidden>
                                                        </div>
                                                      </div>
                                                      {Object.keys(
                                                        destructuredFields[key]
                                                          .answers
                                                      ).map((answer, index) => {
                                                        if (index == 0) {
                                                          return (
                                                            <div>
                                                              <div
                                                                className={
                                                                  classes.seconditem
                                                                }
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                  }}
                                                                >
                                                                  {/* // from here // */}
                                                                  {destructuredFields[
                                                                    key
                                                                  ]
                                                                    .clickableAnswers
                                                                    .title ===
                                                                    null &&
                                                                    (group?.group_id ==
                                                                      15 &&
                                                                    destructuredFields[
                                                                      key
                                                                    ]
                                                                      .field_id ==
                                                                      25 ? (
                                                                      //
                                                                      <Typography
                                                                        className={
                                                                          classes.answerText
                                                                        }
                                                                        onClick={() => {
                                                                          setframeFaultopen(
                                                                            true
                                                                          );
                                                                          setframeFault(
                                                                            {
                                                                              title:
                                                                                destructuredFields[
                                                                                  key
                                                                                ]
                                                                                  .field,
                                                                              data: {
                                                                                issues:
                                                                                  destructuredFields[
                                                                                    key
                                                                                  ].extended_answers
                                                                                    .filter(
                                                                                      (
                                                                                        el
                                                                                      ) =>
                                                                                        el.score !==
                                                                                        100
                                                                                    )
                                                                                    .map(
                                                                                      (
                                                                                        el
                                                                                      ) =>
                                                                                        el.text
                                                                                    ),
                                                                              },
                                                                            }
                                                                          );
                                                                        }}
                                                                      >
                                                                        {destructuredFields[
                                                                          key
                                                                        ]
                                                                          .score !==
                                                                          0 &&
                                                                          `${
                                                                            //   t(
                                                                            //   "good"
                                                                            // )
                                                                            t(
                                                                              destructuredFields[
                                                                                key
                                                                              ].extended_answers.filter(
                                                                                (
                                                                                  el
                                                                                ) =>
                                                                                  el.score ===
                                                                                  100
                                                                              )[0]
                                                                                ?.english_text
                                                                            )
                                                                          }`}
                                                                        {destructuredFields[
                                                                          key
                                                                        ].extended_answers.filter(
                                                                          (
                                                                            el
                                                                          ) =>
                                                                            el.score !==
                                                                            100.0
                                                                        )
                                                                          .length !==
                                                                          0 &&
                                                                          destructuredFields[
                                                                            key
                                                                          ]
                                                                            .score !==
                                                                            0 && (
                                                                            <span>
                                                                              {
                                                                                " + "
                                                                              }
                                                                            </span>
                                                                          )}
                                                                        {destructuredFields[
                                                                          key
                                                                        ].extended_answers.filter(
                                                                          (
                                                                            el
                                                                          ) =>
                                                                            el.score !==
                                                                            100.0
                                                                        )
                                                                          .length !==
                                                                          0 && (
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "#00BDFF",
                                                                              textDecoration:
                                                                                "underline",
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                          >
                                                                            {t(
                                                                              "Issues"
                                                                            )}{" "}
                                                                            |{" "}
                                                                            {
                                                                              destructuredFields[
                                                                                key
                                                                              ].extended_answers.filter(
                                                                                (
                                                                                  el
                                                                                ) =>
                                                                                  el.score !==
                                                                                  100.0
                                                                              )
                                                                                .length
                                                                            }
                                                                          </span>
                                                                        )}
                                                                      </Typography>
                                                                    ) : (
                                                                      <Typography
                                                                        className={
                                                                          classes.answerText
                                                                        }
                                                                        onClick={() => {
                                                                          key ==
                                                                            945 ||
                                                                          key ==
                                                                            990
                                                                            ? handleInspectionNotesDialog(
                                                                                Object.keys(
                                                                                  destructuredFields[
                                                                                    key
                                                                                  ]
                                                                                    .answers
                                                                                )
                                                                              )
                                                                            : key ==
                                                                                951 &&
                                                                              Object.keys(
                                                                                destructuredFields[951]
                                                                                  .answers
                                                                              ) !=
                                                                                "No Notes" &&
                                                                              Object.keys(
                                                                                destructuredFields[951]
                                                                                  .answers
                                                                              ) !=
                                                                                "لا ملاحظات"
                                                                            ? handleInspectionNotesDialog(
                                                                                Object.keys(
                                                                                  destructuredFields[951]
                                                                                    .answers
                                                                                )
                                                                              )
                                                                            : key ==
                                                                                971 &&
                                                                              Object.keys(
                                                                                destructuredFields[971]
                                                                                  .answers
                                                                              ) !=
                                                                                "No Notes" &&
                                                                              Object.keys(
                                                                                destructuredFields[971]
                                                                                  .answers
                                                                              ) !=
                                                                                "لا ملاحظات"
                                                                            ? handleInspectionNotesDialog(
                                                                                Object.keys(
                                                                                  destructuredFields[971]
                                                                                    .answers
                                                                                )
                                                                              )
                                                                            : destructuredFields[
                                                                                key
                                                                              ]
                                                                                .fieldType ==
                                                                                "ddma" &&
                                                                              group?.group_id <
                                                                                16 &&
                                                                              destructuredFields[
                                                                                key
                                                                              ]
                                                                                .score !=
                                                                                100 &&
                                                                              showInteriorFaults(
                                                                                {
                                                                                  images:
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .images,
                                                                                  score:
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .score,
                                                                                  issue:
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .option_title,
                                                                                  detailedFaultsWithImages:
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .detailedFaultsWithImages,
                                                                                }
                                                                              );
                                                                          {
                                                                            console.log(
                                                                              "FFFFFFFFf"
                                                                            );
                                                                          }
                                                                          (group?.group_id ==
                                                                            16 ||
                                                                            group?.group_id ==
                                                                              21) &&
                                                                            destructuredFields[
                                                                              key
                                                                            ]
                                                                              .extended_answers
                                                                              .length >
                                                                              0 &&
                                                                            setshowfaults(
                                                                              !showfaults
                                                                            );
                                                                          changeFaults(
                                                                            destructuredFields,
                                                                            // fieldIndex
                                                                            key
                                                                          );
                                                                        }}
                                                                        style={
                                                                          destructuredFields[
                                                                            key
                                                                          ]
                                                                            .fieldType ==
                                                                          "ddma"
                                                                            ? destructuredFields[
                                                                                key
                                                                              ]
                                                                                .score !=
                                                                              100
                                                                              ? {
                                                                                  color:
                                                                                    "#00BDFF",
                                                                                  textDecorationLine:
                                                                                    "underline",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }
                                                                              : {
                                                                                  color:
                                                                                    "#636363",
                                                                                }
                                                                            : destructuredFields[
                                                                                key
                                                                              ]
                                                                                .extended_answers
                                                                                .length >
                                                                              0
                                                                            ? {
                                                                                color:
                                                                                  "#00BDFF",
                                                                                textDecoration:
                                                                                  "underline",
                                                                                cursor:
                                                                                  "pointer",
                                                                              }
                                                                            : {
                                                                                paddingRight:
                                                                                  localStorage.getItem(
                                                                                    "language"
                                                                                  ) ===
                                                                                  "en"
                                                                                    ? "8px"
                                                                                    : "0",
                                                                                paddingLeft:
                                                                                  localStorage.getItem(
                                                                                    "language"
                                                                                  ) ===
                                                                                  "en"
                                                                                    ? "0"
                                                                                    : "8px",
                                                                                fontSize:
                                                                                  window
                                                                                    .screen
                                                                                    .width >=
                                                                                  960
                                                                                    ? "14px"
                                                                                    : "12px",
                                                                                fontWeight:
                                                                                  "400",
                                                                                color:
                                                                                  "#636363",
                                                                              }
                                                                        }
                                                                      >
                                                                        {/* {here} */}

                                                                        {key ==
                                                                          945 ||
                                                                        key ==
                                                                          990 ? (
                                                                          t(
                                                                            "Notes"
                                                                          ) +
                                                                          " | " +
                                                                          Object.keys(
                                                                            destructuredFields[
                                                                              key
                                                                            ]
                                                                              .answers
                                                                          )
                                                                            .length
                                                                        ) : key ==
                                                                          951 ? (
                                                                          Object.keys(
                                                                            destructuredFields[951]
                                                                              .answers
                                                                          ) ==
                                                                            "No Notes" ||
                                                                          Object.keys(
                                                                            destructuredFields[951]
                                                                              .answers
                                                                          ) ==
                                                                            "لا ملاحظات" ? (
                                                                            t(
                                                                              "No Notes"
                                                                            )
                                                                          ) : (
                                                                            t(
                                                                              "Notes"
                                                                            ) +
                                                                            " | " +
                                                                            Object.keys(
                                                                              destructuredFields[951]
                                                                                .answers
                                                                            )
                                                                              .length
                                                                          )
                                                                        ) : key ==
                                                                          971 ? (
                                                                          Object.keys(
                                                                            destructuredFields[971]
                                                                              .answers
                                                                          ) ==
                                                                            "No Notes" ||
                                                                          Object.keys(
                                                                            destructuredFields[971]
                                                                              .answers
                                                                          ) ==
                                                                            "لا ملاحظات" ? (
                                                                            t(
                                                                              "No Notes"
                                                                            )
                                                                          ) : (
                                                                            t(
                                                                              "Notes"
                                                                            ) +
                                                                            " | " +
                                                                            Object.keys(
                                                                              destructuredFields[971]
                                                                                .answers
                                                                            )
                                                                              .length
                                                                          )
                                                                        ) : destructuredFields[
                                                                            key
                                                                          ]
                                                                            .fieldType ==
                                                                          "ddma" ? (
                                                                          destructuredFields[
                                                                            key
                                                                          ]
                                                                            .score ==
                                                                          100 ? (
                                                                            t(
                                                                              "Excellent"
                                                                            )
                                                                          ) : (
                                                                            t(
                                                                              "Issues"
                                                                            ) +
                                                                            " | " +
                                                                            Object.values(
                                                                              destructuredFields[
                                                                                key
                                                                              ]
                                                                                .answers
                                                                            ).reduce(
                                                                              (
                                                                                a,
                                                                                b
                                                                              ) =>
                                                                                a +
                                                                                b
                                                                            )
                                                                          )
                                                                        ) : group?.group_id ==
                                                                            9 &&
                                                                          mmRegex.test(
                                                                            answer
                                                                          ) &&
                                                                          localStorage.getItem(
                                                                            "language"
                                                                          ) ==
                                                                            "ar" ? (
                                                                          <span>
                                                                            {answer.match(
                                                                              /\d+/
                                                                            )}{" "}
                                                                            ملم
                                                                          </span>
                                                                        ) : group?.group_id !=
                                                                            16 &&
                                                                          group?.group_id !=
                                                                            21 ? (
                                                                          <span>
                                                                            {destructuredFields[
                                                                              key
                                                                            ]
                                                                              .answers[
                                                                              answer
                                                                            ] >
                                                                            1
                                                                              ? `${destructuredFields[key].answers[answer]}X `
                                                                              : ""}
                                                                            {tireAgeIds.includes(
                                                                              // to change tireage text format
                                                                              key
                                                                            )
                                                                              ? tireAgeAnswer(
                                                                                  // turns yr.mo to "yr yrs. mo mos"
                                                                                  answer
                                                                                )
                                                                              : answer}
                                                                          </span>
                                                                        ) : (
                                                                          <>
                                                                            {destructuredFields[
                                                                              key
                                                                            ]
                                                                              .extended_answers
                                                                              .length ==
                                                                            0
                                                                              ? key ==
                                                                                  950 ||
                                                                                key ==
                                                                                  985
                                                                                ? Object.keys(
                                                                                    destructuredFields[
                                                                                      key
                                                                                    ]
                                                                                      .answers
                                                                                  )[0]
                                                                                : t(
                                                                                    "No Faults"
                                                                                  )
                                                                              : t(
                                                                                  "Faults | "
                                                                                ) +
                                                                                destructuredFields[
                                                                                  key
                                                                                ]
                                                                                  .extended_answers
                                                                                  .length}
                                                                          </>
                                                                        )}
                                                                      </Typography>
                                                                    ))}
                                                                  {/* // to here // */}

                                                                  <Typography
                                                                    className={
                                                                      classes.clickableAnswer
                                                                    }
                                                                    onClick={() => {
                                                                      handleAnswerClick(
                                                                        destructuredFields[
                                                                          key
                                                                        ]
                                                                          .clickableAnswers
                                                                          .image,
                                                                        destructuredFields[
                                                                          key
                                                                        ].field
                                                                      );
                                                                    }}
                                                                  >
                                                                    {destructuredFields[
                                                                      key
                                                                    ]
                                                                      .clickableAnswers
                                                                      .title &&
                                                                      t(
                                                                        "View Image"
                                                                      )}
                                                                  </Typography>
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "table-cell",
                                                                      display:
                                                                        "flex",
                                                                      alignSelf:
                                                                        "center",
                                                                    }}
                                                                    className={
                                                                      classes.thirditem
                                                                    }
                                                                  >
                                                                    {key ==
                                                                    987 ? (
                                                                      <img
                                                                        src={
                                                                          NotIncluded
                                                                        }
                                                                        alt="not included"
                                                                        width="22px"
                                                                      />
                                                                    ) : key !=
                                                                        950 && // distance since data reset dtc
                                                                      key !=
                                                                        985 && // battery type hybrid lithium
                                                                      key !=
                                                                        945 && // inspection notes hybrid nickle
                                                                      key !=
                                                                        990 && // inspection notes hybrid lithium
                                                                      key !=
                                                                        951 && // inspection notes dtc
                                                                      key !=
                                                                        953 && // recorded codes dtc & hybrid lithium
                                                                      (group?.group_id ==
                                                                        16 ||
                                                                        group?.group_id ==
                                                                          21) ? (
                                                                      destructuredFields[
                                                                        key
                                                                      ]
                                                                        .extended_answers
                                                                        .length ==
                                                                      0 ? (
                                                                        <img
                                                                          src={
                                                                            UnFaultyAnswer
                                                                          }
                                                                          alt="condition"
                                                                        />
                                                                      ) : (
                                                                        <img
                                                                          src={
                                                                            FaultyAnswer
                                                                          }
                                                                          alt="condition"
                                                                        />
                                                                      )
                                                                    ) : destructuredFields[
                                                                        key
                                                                      ]
                                                                        .score ===
                                                                      "N/A" ? null : destructuredFields[
                                                                        key
                                                                      ].score <=
                                                                      50 ? (
                                                                      <img
                                                                        src={
                                                                          FaultyAnswer
                                                                        }
                                                                        alt="condition"
                                                                      />
                                                                    ) : (destructuredFields[
                                                                        key
                                                                      ]
                                                                        .field_tag ===
                                                                        "Cosmetic Test" ||
                                                                        destructuredFields[
                                                                          key
                                                                        ]
                                                                          .field_tag ===
                                                                          "Tire Test" ||
                                                                        key ==
                                                                          983) &&
                                                                      destructuredFields[
                                                                        key
                                                                      ].score <=
                                                                        99 ? (
                                                                      <img
                                                                        style={{
                                                                          width:
                                                                            "22px",
                                                                          height:
                                                                            "22px",
                                                                        }}
                                                                        src={
                                                                          InformativeAnswer ///////
                                                                        }
                                                                        alt="condition"
                                                                      />
                                                                    ) : (
                                                                      <img
                                                                        src={
                                                                          UnFaultyAnswer
                                                                        }
                                                                        alt="condition"
                                                                      />
                                                                    )}
                                                                  </div>
                                                                </div>

                                                                {/* here */}
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      })}
                                                    </div>
                                                    {Object.keys(
                                                      destructuredFields
                                                    ).length -
                                                      1 !==
                                                    fieldIndex ? (
                                                      <div
                                                        style={{
                                                          display: "table-row",
                                                        }}
                                                      >
                                                        <td
                                                          colSpan="2"
                                                          style={{
                                                            padding: 0,
                                                            backgroundColor:
                                                              "#fff",
                                                          }}
                                                        >
                                                          <hr
                                                            className={
                                                              classes.rowHr
                                                            }
                                                          />
                                                        </td>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        style={{
                                                          display: "table-row",
                                                          height: "8px",
                                                        }}
                                                      >
                                                        <td
                                                          colSpan="2"
                                                          style={{
                                                            padding: 0,
                                                            backgroundColor:
                                                              "#fff",
                                                          }}
                                                        ></td>
                                                      </div>
                                                    )}
                                                  </div>
                                                );
                                              })}
                                          </Paper>
                                        </div>
                                      )
                                    );
                                  }
                                )
                              ) : (
                                <div>
                                  {skelStructure.map((table) => (
                                    <div>
                                      <Skeleton
                                        variant="rect"
                                        width="100%"
                                        height="34px"
                                        style={{ margin: "20px 0 5px" }}
                                      ></Skeleton>

                                      {Object.keys(table).map((row) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                          }}
                                        >
                                          <Skeleton
                                            variant="rect"
                                            width="50%"
                                            height="32px"
                                            style={{
                                              margin: "0 2px 5px",
                                            }}
                                          ></Skeleton>
                                          <Skeleton
                                            variant="rect"
                                            width="50%"
                                            height="32px"
                                            style={{
                                              margin: "0 2px 5px",
                                            }}
                                          ></Skeleton>
                                          <div style={{ height: "12px" }}>
                                            <Skeleton
                                              variant="rect"
                                              width="100%"
                                              height="2px"
                                              style={{
                                                margin: "0 0 14px",
                                              }}
                                              className={classes.rowHr}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </ExpansionPanelDetails>
                          </div>
                          <Typography className={classes.groupNote}>
                            {group != undefined &&
                            DetInspRepDiscription[group["group_id"]]
                              ? DetInspRepDiscription[group["group_id"]].note
                              : null}
                          </Typography>
                        </ExpansionPanel>
                      ) : null;
                  })}
                </div>
              ) : (
                [1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                  <Skeleton
                    style={{
                      height: "52px",
                      transform: "scale(1,0.95)",
                    }}
                    className={classes.expansionPanelSummaryRoot}
                  />
                ))
              )}
            </div>
          </Paper>
        </MarkabtePopup>
        <MarkabtePopup
          withoutHeader={true}
          withUpperBackPannel={true}
          open={subPopup}
          handleBack={() => {
            setsubPopup(false);
            setExpanded(false);
          }}
          title={"Faults Details"}
        >
          <div className={classes.column} style={{ columnCount: 1 }}>
            <Grid style={{ display: "flex", margin: "10px 0px" }}>
              <img
                src={
                  allGroups != undefined
                    ? // ? DetInspRepIcons?.[allGroups?.["group_id"]]
                      DetInspRepIcons[allGroups["group_id"]]
                    : null
                }
                style={{ width: "30px", margin: "auto 12px" }}
                alt="DetInspRepIcons"
              />
              {localStorage.getItem("language") == "en"
                ? `${allGroups?.title} ${t("Inspection Notes")}`
                : `${t("Inspection Notes")} ${allGroups?.title}`}
            </Grid>
            {subGroupsSelected !== undefined ? ( // to not crash before end of slow fields fetch
              checkSubgroupFilter(subGroupsSelected).map((subGroup, index) => {
                const fields = subGroup.fields;
                let destructuredFields = {};
                {
                  var i = 0;
                  checkFieldFilter(fields).map((option) => {
                    let x = fields.filter((el) => {
                      return (
                        el.selected_option === option.selected_option &&
                        option.selected_option !== null &&
                        option.selected_option !== 3263 && // original paint
                        option.selected_option !== 3264 && // repainted paint
                        option.selected_option !== 3265 // in applicable paint
                      );
                    });
                    destructuredFields = Object.assign(destructuredFields, {
                      [option.field_id]: {
                        field_tag: option?.field_tag?.title,
                        field_id: option?.field_tag?.id,
                        field: option?.field_title,
                        field_tree_id: option?.field_tree_id,
                        score: option?.field_score,
                        img: {
                          [option?.field_title]: [
                            ...(destructuredFields[option?.field_id]?.img[
                              option?.field_title
                            ] ?? []),
                            ...(option?.images ?? []),
                          ],
                        },
                        images: [
                          ...(destructuredFields[option?.field_id]?.images ??
                            []),
                          {
                            [option?.field_title]:
                              option?.with_image == false
                                ? localStorage.getItem("language") == "en"
                                  ? [
                                      [
                                        "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png",
                                      ],
                                      option?.field_score,
                                    ]
                                  : [
                                      [
                                        "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png",
                                      ],
                                      option?.field_score,
                                    ]
                                : [option?.images, option?.field_score],
                          },
                        ],
                        answers: Object.assign(
                          destructuredFields[option.field_id]
                            ? destructuredFields[option?.field_id].answers
                            : {},
                          {
                            [option.option_title || option.text]: x.length,
                          }
                        ),
                        clickableAnswers: {
                          title:
                            option?.field_tag?.id === 24
                              ? option.field_id === 335
                                ? t("Report Image")
                                : option.text
                              : null,
                          image: option?.images,
                        },
                        fieldType: option.field_type,
                        detailedFaultsWithImages: [
                          ...(destructuredFields[option?.field_id]
                            ?.detailedFaultsWithImages ?? []),
                          {
                            [option?.field_tree_id]:
                              option?.with_image == false
                                ? localStorage.getItem("language") == "en"
                                  ? [
                                      [
                                        "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png",
                                      ],
                                      option?.field_score,
                                      option?.option_title,
                                    ]
                                  : [
                                      [
                                        "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png",
                                      ],
                                      option?.field_score,
                                      option?.option_title,
                                    ]
                                : [
                                    option?.images,
                                    option?.field_score,
                                    option?.option_title,
                                  ],
                          },
                        ],
                        extended_answers: option?.extended_answers,
                      },
                    });
                  });
                }
                pointCount =
                  pointCount + Object.keys(destructuredFields).length;
                // counterIsDone = counterDone(
                //   i,
                //   groups.groups.length,
                //   index,
                //   subGroups.length
                // );
                return (
                  Math.floor(subGroup?.score?.fields?.subgroup_score) !=
                    100 && (
                    <>
                      <ExpansionPanel
                        style={{
                          background: "#F4F5F7",
                          margin: "-1px",
                          border: "1px solid #B5B5B5",
                        }}
                        expanded={subGroupExpanded === `panel${index}`}
                        onChange={handleSubgroupChange(`panel${index}`)}
                      >
                        <ExpansionPanelSummary
                          classes={{
                            content: classes.expansionPanelSummaryContent,
                            //   root: classes.expansionPanelSummaryRoot,
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <div className={classes.subGroupExpantionPanel}>
                            <Typography className={classes.heading}>
                              {subGroup.title}
                            </Typography>
                            {/* <Typography className={classes.secondaryHeading}>
                              <div
                                style={{
                                  display: "table-cell",
                                }}
                              >
                                <Typography
                                  className={classes.subGroupScoreText}
                                  style={
                                    allGroups?.group_id == 20 &&
                                    allGroups?.sub_groups?.[0]?.fields?.[0]
                                      ?.field_id == 916
                                      ? {
                                          color: "#929693",
                                        }
                                      : allGroups?.group_id != 15 &&
                                        allGroups?.group_id != 16 &&
                                        allGroups?.group_id != 21 &&
                                        !(
                                          allGroups?.group_id == 19 &&
                                          allGroups?.sub_groups?.[0]
                                            ?.fields?.[0]?.field_id == 902
                                        )
                                      ? //here
                                        {
                                          color: "rgb(60, 182, 86)",
                                          // color: !subGroup.score
                                          //   ? "#929693"
                                          //   : subGroup.score
                                          //       .fields
                                          //       .subgroup_score <=
                                          //     50
                                          //   ? "rgb(235, 50, 35)"
                                          //   : subGroup.score
                                          //       .fields
                                          //       .subgroup_score <=
                                          //     70
                                          //   ? "rgb(236, 222, 0)"
                                          //   : "rgb(60, 182, 86)",
                                        }
                                      : {
                                          color: "#8A8A8A",
                                        }
                                  }
                                >
                                  {allGroups?.group_id == 20 &&
                                  allGroups?.sub_groups?.[0]?.fields?.[0]
                                    ?.field_id == 916
                                    ? ""
                                    : allGroups?.group_id != 15
                                    ? allGroups?.group_id != 16 &&
                                      allGroups?.group_id != 21
                                      ? !subGroup.score
                                        ? "N/A"
                                        : allGroups?.group_id == 19 &&
                                          allGroups?.sub_groups?.[0]
                                            ?.fields?.[0]?.field_id == 902
                                        ? t("Not Available")
                                        : subGroup?.fixed_score &&
                                          " + " +
                                            (Number(
                                              subGroup?.fixed_score.toFixed(2)
                                            ) > 0.01
                                              ? Number(
                                                  subGroup?.fixed_score.toFixed(
                                                    2
                                                  )
                                                )
                                              : "< 0.01")
                                      : allGroups?.sub_groups?.[0]?.fields
                                          ?.length == 1
                                      ? t("Not Available")
                                      : faultscnt(subGroup)
                                    : ""}{" "}
                                  {allGroups?.group_id == 20 &&
                                  allGroups?.sub_groups?.[0]?.fields?.[0]
                                    ?.field_id == 916
                                    ? t("Not Available")
                                    : allGroups?.group_id != 15
                                    ? allGroups?.group_id != 16 &&
                                      allGroups?.group_id != 21
                                      ? allGroups?.group_id == 19 &&
                                        allGroups?.sub_groups?.[0]?.fields?.[0]
                                          ?.field_id == 902
                                        ? null
                                        : "%"
                                      : allGroups?.sub_groups?.[0]?.fields
                                          ?.length == 1
                                      ? null
                                      : t("Faults")
                                    : ""}
                                </Typography>
                              </div>
                            </Typography> */}
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          className={classes.expansionPanelDetails}
                        >
                          <Paper className={classes.table}>
                            <header
                              style={{
                                display: "table-row",
                              }}
                              className={classes.tablehead}
                            ></header>
                            {Object.keys(destructuredFields)
                              .sort((a, b) => {
                                return (
                                  destructuredFields[a].field_tree_id -
                                  destructuredFields[b].field_tree_id
                                );
                              })
                              .map((key, fieldIndex) => {
                                if (destructuredFields[key].score != 100)
                                  return key == 950 &&
                                    Object.keys(
                                      destructuredFields[key].answers
                                    )[0] == "null" ? null : (
                                    <div
                                      style={{
                                        display: "table-row-group",
                                      }}
                                    >
                                      {fieldIndex === 0 ? (
                                        <div
                                          style={{
                                            display: "table-row",
                                          }}
                                        >
                                          <td
                                            colSpan="2"
                                            style={{
                                              padding: 0,
                                              backgroundColor: "#fff",
                                            }}
                                          >
                                            <hr
                                              style={{
                                                height: "2px",
                                                border: "none",
                                                margin: "6px 0",
                                              }}
                                            />
                                          </td>
                                        </div>
                                      ) : (
                                        <Hidden smDown>
                                          <div
                                            style={{
                                              display: "table-row",
                                              height: "8px",
                                            }}
                                          >
                                            <td
                                              colSpan="2"
                                              style={{
                                                padding: 0,
                                                backgroundColor: "#fff",
                                              }}
                                            ></td>
                                          </div>
                                        </Hidden>
                                      )}
                                      <div
                                        style={{
                                          display: "table-row",
                                          backgroundColor: "#fff",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "table-cell",
                                          }}
                                          className={classes.firstitem}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                            }}
                                          >
                                            <Typography
                                              style={
                                                localStorage.getItem(
                                                  "language"
                                                ) === "ar"
                                                  ? {
                                                      fontWeight: "600",
                                                    }
                                                  : {
                                                      fontWeight: "500",
                                                    }
                                              }
                                              className={
                                                classes.firstItemTypography
                                              }
                                            >
                                              {destructuredFields[key].field}
                                            </Typography>
                                            <Hidden smDown>
                                              {infoSpecialCase(key) && (
                                                <Tooltip
                                                  title={t(
                                                    "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking."
                                                  )}
                                                  arrow
                                                  placement="top-start"
                                                  classes={{
                                                    tooltip: classes.tooltip,
                                                    arrow: classes.tooltipArrow,
                                                  }}
                                                >
                                                  <img
                                                    src={Information}
                                                    alt="infoIcon"
                                                    className={classes.infoIcon}
                                                  />
                                                </Tooltip>
                                              )}
                                            </Hidden>
                                            <Hidden mdUp>
                                              {infoSpecialCase(key) && (
                                                <Tooltip
                                                  title={t(
                                                    "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking."
                                                  )}
                                                  arrow
                                                  placement="top-start"
                                                  open={
                                                    openTooltip &&
                                                    tooltipButtonId === key
                                                  }
                                                  onClose={handleTooltipClose}
                                                  classes={{
                                                    tooltip: classes.tooltip,
                                                    arrow: classes.tooltipArrow,
                                                  }}
                                                  enterTouchDelay={0}
                                                  leaveTouchDelay={6000}
                                                >
                                                  <Button
                                                    id={key}
                                                    onClick={handleTooltipOpen}
                                                    style={{
                                                      padding: "0",
                                                      minWidth: "0px",
                                                    }}
                                                  >
                                                    <img
                                                      src={Information}
                                                      alt="infoIcon"
                                                      className={
                                                        classes.infoIcon
                                                      }
                                                    />
                                                  </Button>
                                                </Tooltip>
                                              )}
                                            </Hidden>
                                          </div>
                                        </div>
                                        {Object.keys(
                                          destructuredFields[key].answers
                                        ).map((answer, index) => {
                                          if (index == 0) {
                                            return (
                                              <div>
                                                <div
                                                  className={classes.seconditem}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    {/* // from here // */}
                                                    {destructuredFields[key]
                                                      .clickableAnswers
                                                      .title === null &&
                                                      (allGroups?.group_id ==
                                                        15 &&
                                                      destructuredFields[key]
                                                        .field_id == 25 ? (
                                                        //
                                                        <Typography
                                                          className={
                                                            classes.answerText
                                                          }
                                                          onClick={() => {
                                                            setframeFaultopen(
                                                              true
                                                            );
                                                            setframeFault({
                                                              title:
                                                                destructuredFields[
                                                                  key
                                                                ].field,
                                                              data: {
                                                                issues:
                                                                  destructuredFields[
                                                                    key
                                                                  ].extended_answers
                                                                    .filter(
                                                                      (el) =>
                                                                        el.score !==
                                                                        100
                                                                    )
                                                                    .map(
                                                                      (el) =>
                                                                        el.text
                                                                    ),
                                                              },
                                                            });
                                                          }}
                                                        >
                                                          {destructuredFields[
                                                            key
                                                          ].score !== 0 &&
                                                            `${
                                                              //   t(
                                                              //   "good"
                                                              // )
                                                              t(
                                                                destructuredFields[
                                                                  key
                                                                ].extended_answers.filter(
                                                                  (el) =>
                                                                    el.score ===
                                                                    100
                                                                )[0]
                                                                  ?.english_text
                                                              )
                                                            }`}
                                                          {destructuredFields[
                                                            key
                                                          ].extended_answers.filter(
                                                            (el) =>
                                                              el.score !== 100.0
                                                          ).length !== 0 &&
                                                            destructuredFields[
                                                              key
                                                            ].score !== 0 && (
                                                              <span>
                                                                {" + "}
                                                              </span>
                                                            )}
                                                          {destructuredFields[
                                                            key
                                                          ].extended_answers.filter(
                                                            (el) =>
                                                              el.score !== 100.0
                                                          ).length !== 0 && (
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#00BDFF",
                                                                textDecoration:
                                                                  "underline",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              {t("Issues")} |{" "}
                                                              {
                                                                destructuredFields[
                                                                  key
                                                                ].extended_answers.filter(
                                                                  (el) =>
                                                                    el.score !==
                                                                    100.0
                                                                ).length
                                                              }
                                                            </span>
                                                          )}
                                                        </Typography>
                                                      ) : (
                                                        <Typography
                                                          className={
                                                            classes.answerText
                                                          }
                                                          onClick={() => {
                                                            key == 945 ||
                                                            key == 990
                                                              ? handleInspectionNotesDialog(
                                                                  Object.keys(
                                                                    destructuredFields[
                                                                      key
                                                                    ].answers
                                                                  )
                                                                )
                                                              : key == 951 &&
                                                                Object.keys(
                                                                  destructuredFields[951]
                                                                    .answers
                                                                ) !=
                                                                  "No Notes" &&
                                                                Object.keys(
                                                                  destructuredFields[951]
                                                                    .answers
                                                                ) !=
                                                                  "لا ملاحظات"
                                                              ? handleInspectionNotesDialog(
                                                                  Object.keys(
                                                                    destructuredFields[951]
                                                                      .answers
                                                                  )
                                                                )
                                                              : key == 971 &&
                                                                Object.keys(
                                                                  destructuredFields[971]
                                                                    .answers
                                                                ) !=
                                                                  "No Notes" &&
                                                                Object.keys(
                                                                  destructuredFields[971]
                                                                    .answers
                                                                ) !=
                                                                  "لا ملاحظات"
                                                              ? handleInspectionNotesDialog(
                                                                  Object.keys(
                                                                    destructuredFields[971]
                                                                      .answers
                                                                  )
                                                                )
                                                              : destructuredFields[
                                                                  key
                                                                ].fieldType ==
                                                                  "ddma" &&
                                                                allGroups?.group_id <
                                                                  16 &&
                                                                destructuredFields[
                                                                  key
                                                                ].score !=
                                                                  100 &&
                                                                showInteriorFaults(
                                                                  {
                                                                    images:
                                                                      destructuredFields[
                                                                        key
                                                                      ].images,
                                                                    score:
                                                                      destructuredFields[
                                                                        key
                                                                      ].score,
                                                                    issue:
                                                                      destructuredFields[
                                                                        key
                                                                      ]
                                                                        .option_title,
                                                                    detailedFaultsWithImages:
                                                                      destructuredFields[
                                                                        key
                                                                      ]
                                                                        .detailedFaultsWithImages,
                                                                  }
                                                                );
                                                            {
                                                              console.log(
                                                                "FFFFFFFFf"
                                                              );
                                                            }
                                                            (allGroups?.group_id ==
                                                              16 ||
                                                              allGroups?.group_id ==
                                                                21) &&
                                                              destructuredFields[
                                                                key
                                                              ].extended_answers
                                                                .length > 0 &&
                                                              setshowfaults(
                                                                !showfaults
                                                              );
                                                            changeFaults(
                                                              destructuredFields,
                                                              // fieldIndex
                                                              key
                                                            );
                                                          }}
                                                          style={
                                                            destructuredFields[
                                                              key
                                                            ].fieldType ==
                                                            "ddma"
                                                              ? destructuredFields[
                                                                  key
                                                                ].score != 100
                                                                ? {
                                                                    color:
                                                                      "#00BDFF",
                                                                    textDecorationLine:
                                                                      "underline",
                                                                    cursor:
                                                                      "pointer",
                                                                  }
                                                                : {
                                                                    color:
                                                                      "#636363",
                                                                  }
                                                              : destructuredFields[
                                                                  key
                                                                ]
                                                                  .extended_answers
                                                                  .length > 0
                                                              ? {
                                                                  color:
                                                                    "#00BDFF",
                                                                  textDecoration:
                                                                    "underline",
                                                                  cursor:
                                                                    "pointer",
                                                                }
                                                              : {
                                                                  paddingRight:
                                                                    localStorage.getItem(
                                                                      "language"
                                                                    ) === "en"
                                                                      ? "8px"
                                                                      : "0",
                                                                  paddingLeft:
                                                                    localStorage.getItem(
                                                                      "language"
                                                                    ) === "en"
                                                                      ? "0"
                                                                      : "8px",
                                                                  fontSize:
                                                                    window
                                                                      .screen
                                                                      .width >=
                                                                    960
                                                                      ? "14px"
                                                                      : "12px",
                                                                  fontWeight:
                                                                    "400",
                                                                  color:
                                                                    "#636363",
                                                                }
                                                          }
                                                        >
                                                          {/* {here} */}

                                                          {key == 945 ||
                                                          key == 990 ? (
                                                            t("Notes") +
                                                            " | " +
                                                            Object.keys(
                                                              destructuredFields[
                                                                key
                                                              ].answers
                                                            ).length
                                                          ) : key == 951 ? (
                                                            Object.keys(
                                                              destructuredFields[951]
                                                                .answers
                                                            ) == "No Notes" ||
                                                            Object.keys(
                                                              destructuredFields[951]
                                                                .answers
                                                            ) ==
                                                              "لا ملاحظات" ? (
                                                              t("No Notes")
                                                            ) : (
                                                              t("Notes") +
                                                              " | " +
                                                              Object.keys(
                                                                destructuredFields[951]
                                                                  .answers
                                                              ).length
                                                            )
                                                          ) : key == 971 ? (
                                                            Object.keys(
                                                              destructuredFields[971]
                                                                .answers
                                                            ) == "No Notes" ||
                                                            Object.keys(
                                                              destructuredFields[971]
                                                                .answers
                                                            ) ==
                                                              "لا ملاحظات" ? (
                                                              t("No Notes")
                                                            ) : (
                                                              t("Notes") +
                                                              " | " +
                                                              Object.keys(
                                                                destructuredFields[971]
                                                                  .answers
                                                              ).length
                                                            )
                                                          ) : destructuredFields[
                                                              key
                                                            ].fieldType ==
                                                            "ddma" ? (
                                                            destructuredFields[
                                                              key
                                                            ].score == 100 ? (
                                                              t("Excellent")
                                                            ) : (
                                                              t("Issues") +
                                                              " | " +
                                                              Object.values(
                                                                destructuredFields[
                                                                  key
                                                                ].answers
                                                              ).reduce(
                                                                (a, b) => a + b
                                                              )
                                                            )
                                                          ) : allGroups?.group_id ==
                                                              9 &&
                                                            mmRegex.test(
                                                              answer
                                                            ) &&
                                                            localStorage.getItem(
                                                              "language"
                                                            ) == "ar" ? (
                                                            <span>
                                                              {answer.match(
                                                                /\d+/
                                                              )}{" "}
                                                              ملم
                                                            </span>
                                                          ) : allGroups?.group_id !=
                                                              16 &&
                                                            allGroups?.group_id !=
                                                              21 ? (
                                                            <span>
                                                              {destructuredFields[
                                                                key
                                                              ].answers[
                                                                answer
                                                              ] > 1
                                                                ? `${destructuredFields[key].answers[answer]}X `
                                                                : ""}
                                                              {tireAgeIds.includes(
                                                                // to change tireage text format
                                                                key
                                                              )
                                                                ? tireAgeAnswer(
                                                                    // turns yr.mo to "yr yrs. mo mos"
                                                                    answer
                                                                  )
                                                                : answer}
                                                            </span>
                                                          ) : (
                                                            <>
                                                              {destructuredFields[
                                                                key
                                                              ].extended_answers
                                                                .length == 0
                                                                ? key == 950 ||
                                                                  key == 985
                                                                  ? Object.keys(
                                                                      destructuredFields[
                                                                        key
                                                                      ].answers
                                                                    )[0]
                                                                  : t(
                                                                      "No Faults"
                                                                    )
                                                                : t(
                                                                    "Faults | "
                                                                  ) +
                                                                  destructuredFields[
                                                                    key
                                                                  ]
                                                                    .extended_answers
                                                                    .length}
                                                            </>
                                                          )}
                                                        </Typography>
                                                      ))}
                                                    {/* // to here // */}

                                                    <Typography
                                                      className={
                                                        classes.clickableAnswer
                                                      }
                                                      onClick={() => {
                                                        handleAnswerClick(
                                                          destructuredFields[
                                                            key
                                                          ].clickableAnswers
                                                            .image,
                                                          destructuredFields[
                                                            key
                                                          ].field
                                                        );
                                                      }}
                                                    >
                                                      {destructuredFields[key]
                                                        .clickableAnswers
                                                        .title &&
                                                        t("View Image")}
                                                    </Typography>
                                                    <div
                                                      style={{
                                                        display: "table-cell",
                                                        display: "flex",
                                                        alignSelf: "center",
                                                      }}
                                                      className={
                                                        classes.thirditem
                                                      }
                                                    >
                                                      {key == 987 ? (
                                                        <img
                                                          src={NotIncluded}
                                                          alt="not included"
                                                          width="22px"
                                                        />
                                                      ) : key != 950 && // distance since data reset dtc
                                                        key != 985 && // battery type hybrid lithium
                                                        key != 945 && // inspection notes hybrid nickle
                                                        key != 990 && // inspection notes hybrid lithium
                                                        key != 951 && // inspection notes dtc
                                                        key != 953 && // recorded codes dtc & hybrid lithium
                                                        (allGroups?.group_id ==
                                                          16 ||
                                                          allGroups?.group_id ==
                                                            21) ? (
                                                        destructuredFields[key]
                                                          .extended_answers
                                                          .length == 0 ? (
                                                          <img
                                                            src={UnFaultyAnswer}
                                                            alt="condition"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={FaultyAnswer}
                                                            alt="condition"
                                                          />
                                                        )
                                                      ) : destructuredFields[
                                                          key
                                                        ].score ===
                                                        "N/A" ? null : destructuredFields[
                                                          key
                                                        ].score <= 50 ? (
                                                        <img
                                                          src={FaultyAnswer}
                                                          alt="condition"
                                                        />
                                                      ) : (destructuredFields[
                                                          key
                                                        ].field_tag ===
                                                          "Cosmetic Test" ||
                                                          destructuredFields[
                                                            key
                                                          ].field_tag ===
                                                            "Tire Test" ||
                                                          key == 983) &&
                                                        destructuredFields[key]
                                                          .score <= 99 ? (
                                                        <img
                                                          style={{
                                                            width: "22px",
                                                            height: "22px",
                                                          }}
                                                          src={
                                                            InformativeAnswer ///////
                                                          }
                                                          alt="condition"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={UnFaultyAnswer}
                                                          alt="condition"
                                                        />
                                                      )}
                                                    </div>
                                                  </div>

                                                  {/* here */}
                                                </div>
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                      {Object.keys(destructuredFields).length -
                                        1 !==
                                      fieldIndex ? (
                                        <div
                                          style={{
                                            display: "table-row",
                                          }}
                                        >
                                          <td
                                            colSpan="2"
                                            style={{
                                              padding: 0,
                                              backgroundColor: "#fff",
                                            }}
                                          >
                                            <hr className={classes.rowHr} />
                                          </td>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "table-row",
                                            height: "8px",
                                          }}
                                        >
                                          <td
                                            colSpan="2"
                                            style={{
                                              padding: 0,
                                              backgroundColor: "#fff",
                                            }}
                                          ></td>
                                        </div>
                                      )}
                                    </div>
                                  );
                              })}
                          </Paper>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </>
                  )
                );
              })
            ) : (
              <div>
                {skelStructure.map((table) => (
                  <div>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height="34px"
                      style={{ margin: "20px 0 5px" }}
                    ></Skeleton>

                    {Object.keys(table).map((row) => (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Skeleton
                          variant="rect"
                          width="50%"
                          height="32px"
                          style={{
                            margin: "0 2px 5px",
                          }}
                        ></Skeleton>
                        <Skeleton
                          variant="rect"
                          width="50%"
                          height="32px"
                          style={{
                            margin: "0 2px 5px",
                          }}
                        ></Skeleton>
                        <div style={{ height: "12px" }}>
                          <Skeleton
                            variant="rect"
                            width="100%"
                            height="2px"
                            style={{
                              margin: "0 0 14px",
                            }}
                            className={classes.rowHr}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </MarkabtePopup>
      </Hidden>
    </div>
  );
}

export default InspectionReportMobile;
