import React from "react";

const RequestsHistoryIcon = (props) => {
  const { size, className, style, fill } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8184 12.9565C13.5749 12.9565 13.351 13.0465 13.1879 13.2093L10.9331 15.4638C8.12046 14.6461 6.99787 12.367 6.62945 11.3562L9.06664 8.919C9.35615 8.62949 9.39636 8.22513 9.18601 7.71724C8.70149 6.54765 5.55058 2.12109 3.77106 2.12109C3.4529 2.12109 3.15812 2.24072 2.94049 2.45835L0.568129 4.83071C-0.932688 6.33177 0.572653 13.2096 4.69713 17.3338C7.97345 20.6101 12.4201 22.1022 15.1652 22.1022C16.1478 22.1019 16.8469 21.9014 17.243 21.505L19.6153 19.1329C19.7857 18.9626 20.1469 18.4816 19.8219 17.6972C19.167 16.1167 14.9593 12.9565 13.8184 12.9565V12.9565ZM15.1652 20.5423C13.1346 20.5943 8.96335 19.3943 5.80013 16.2308C1.8302 12.2609 1.20419 6.66275 1.68243 5.92214L3.87535 3.72922C4.21688 3.90488 6.63875 5.84273 7.65579 8.12411L5.32666 10.453C5.03565 10.7443 4.93085 11.1697 5.05349 11.5635C5.37391 12.5909 6.73525 16.0222 10.8007 17.0436C10.8879 17.0654 11.2251 17.3715 11.8504 16.752L13.9933 14.6094C15.0636 15.1258 17.9044 17.3007 18.3445 18.1973C18.3447 18.1973 16.3141 20.5129 15.1652 20.5423V20.5423Z"
        fill={fill}
      />
      <path
        d="M19.9309 0H11.6646C10.5465 0 9.68579 0.89667 9.68579 2.01449V5.44586C9.68579 5.87661 10.009 6.22567 10.4397 6.22567C10.8705 6.22567 11.1936 5.87661 11.1936 5.44586V2.01449C11.1936 1.75665 11.4065 1.50785 11.6646 1.50785H19.9309C20.189 1.50785 20.4921 1.75665 20.4921 2.01449V15.4279C20.4921 15.8586 20.8153 16.2077 21.246 16.2077C21.6767 16.2077 21.9999 15.8586 21.9999 15.4279V2.01449C21.9999 0.89667 21.049 0 19.9309 0V0Z"
        fill={fill}
      />
      <path
        d="M13.6922 4.77479H17.7473C18.178 4.77479 18.5271 4.32595 18.5271 3.89521C18.5271 3.46446 18.178 3.01562 17.7473 3.01562H13.6922C13.2614 3.01562 12.9124 3.46446 12.9124 3.89521C12.9124 4.32595 13.2614 4.77479 13.6922 4.77479V4.77479Z"
        fill={fill}
      />
      <path
        d="M13.6922 8.04203H17.7473C18.178 8.04203 18.5271 7.71885 18.5271 7.28811C18.5271 6.85736 18.178 6.53418 17.7473 6.53418H13.6922C13.2614 6.53418 12.9124 6.85736 12.9124 7.28811C12.9124 7.71885 13.2614 8.04203 13.6922 8.04203V8.04203Z"
        fill={fill}
      />
      <path
        d="M13.6922 11.5601H17.7473C18.178 11.5601 18.5271 11.2369 18.5271 10.8062C18.5271 10.3754 18.178 10.0522 17.7473 10.0522H13.6922C13.2614 10.0522 12.9124 10.3754 12.9124 10.8062C12.9124 11.2369 13.2614 11.5601 13.6922 11.5601V11.5601Z"
        fill={fill}
      />
    </svg>
  );
};

export default RequestsHistoryIcon;
