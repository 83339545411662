import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { toggleNotification } from "../store/actions/notification";
// import classes from "*.module.css";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    // backgroundColor: "#51B583",
    backgroundColor: "#E1F7E1",

    color: "#37C534",
    // green[600],
  },
  error: {
    backgroundColor: "#FAE4E4",
    color: "#DD4B4B",
    // theme.palette.error.dark,
  },
  info: {
    backgroundColor: "#CBEBFB",
    color: "#3AA7D1",
    // theme.palette.primary.main,
  },
  warning: {
    backgroundColor: "#FFF1B6",
    color: "#F5A623",
    //  amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  snackbarAnchor: {
    left: "270px",
    right: "8px",
    transform: "translateX(0)",
    [theme.breakpoints.down("sm")]: {
      left: "8px",
    },
  },
  snackbarContentRoot: {
    flexGrow: 1,
    borderRadius: 0,
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  const { t } = useTranslation();

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {t(message)}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
};

const NotificationsWrapper = (props) => {
  const { message, variant, open, toggleNotification, history } = props;
  const classes = useStyles1();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    toggleNotification({ open: false });
  };
  const [isLogin, setIsLogin] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [threshold, setThreshold] = useState(
    window.screen.width >= 960 ? 70 : 50
  );
  useEffect(() => {
    let last_known_scroll_position = 0;
    let ticking = false;

    const handleResize = () => {
      window.screen.width >= 960 ? setThreshold(70) : setThreshold(50);
    };

    window.addEventListener("resize", handleResize);

    window.addEventListener("scroll", function (e) {
      last_known_scroll_position = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          setScrollPos(last_known_scroll_position);
          ticking = false;
        });

        ticking = true;
      }
    });

    return (_) => {
      window.removeEventListener("scroll", function (e) {
        last_known_scroll_position = window.scrollY;

        if (!ticking) {
          window.requestAnimationFrame(function () {
            setScrollPos(last_known_scroll_position);
            ticking = false;
          });

          ticking = true;
        }
      });
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      history.location.pathname.includes("/login") ||
      history.location.pathname.includes("/account_activation/") ||
      history.location.pathname.includes("/forgot_password/") ||
      history.location.pathname.includes("/request_reset/")
    ) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    } // add condition for visualization and imageview
  }, [history.location.pathname]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ anchorOriginTopCenter: classes.snackbarAnchor }}
        style={{
          transform:
            scrollPos <= threshold
              ? `translateX(0) translateY(${threshold - scrollPos}px)`
              : "translateX(0) translateY(0)",
          // right: isAuthenticated ? "" : "8px",
          // top: isAuthenticated ? "" : "-54px",
          right: !isLogin ? "" : "8px",
          top: !isLogin ? "" : "-54px",
        }}
        open={open}
        // open={true}
        autoHideDuration={6000}
        onClose={handleClose}
        key={message} //When displaying multiple consecutive Snackbars from a parent rendering a single <Snackbar/>, add the key prop to ensure independent treatment of each message. otherwise, the message may update-in-place and features such as autoHideDuration may be canceled.
      >
        <MySnackbarContentWrapper
          classes={{ root: classes.snackbarContentRoot }}
          onClose={handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  message: state.notifications.message,
  variant: state.notifications.variant,
  open: state.notifications.open,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsWrapper)
);
