import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    borderRadius: "20px",
  },
  progbar: {
    position: "relative",
    height: "6px",
    width: "100%",
    borderRadius: "25px",
    backgroundColor: "gainsboro",
  },
  filler: {
    height: "100%",
    borderRadius: "20px",
  },
  per: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "7px",
    marginTop: "7px",
  },
});

const Score = (props) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const [activeStep, setActiveStep] = React.useState(0);
  // const [color, setColor] = React.useState("");
  let col = "";

  if (Number(props.value) <= 20) {
    col = "#D40000";
  } else if (Number(props.value) <= 30) {
    col = "#EA3800";
  } else if (Number(props.value) <= 40) {
    col = "#EA5400";
  } else if (Number(props.value) <= 50) {
    col = "#FF7A00";
  } else if (Number(props.value) <= 60) {
    col = "#FFA800";
  } else if (Number(props.value) <= 70) {
    col = "#FFD600";
  } else if (Number(props.value) <= 80) {
    col = "#C5E500";
  } else if (Number(props.value) <= 90) {
    col = "#5BCE00";
  } else if (Number(props.value) <= 100) {
    col = "#00A900";
  }
  return (
    <div>
      <div
        className={classes.progbar}
        style={props.unAvailable ? { backgroundColor: "#BFD6DE" } : {}}
      >
        <div
          className={classes.filler}
          style={{ width: `${props.value}%`, background: col }}
        >
          <p className={classes.per} style={{ display: "flex" }}></p>
        </div>
      </div>
    </div>
  );
};

export default Score;
