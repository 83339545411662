import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateCarForm, getCarForms } from "../store/actions/car";
import { getFields, updateformFields } from "../store/actions/forms";

import {
  updateAnswers,
  updateHidden,
  getOptionsFields,
} from "../store/actions/forms";

import TextInput from "../components/TextInput";
import RadioButtonInput from "../components/RadioButtonInput";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "../components/Button";
import IconButton from "@material-ui/core/IconButton";

import HideIcon from "@material-ui/icons/VisibilityOff";
import UnhideIcon from "@material-ui/icons/Visibility";

import { useTranslation } from "react-i18next";

const FormFields = (props) => {
  const {
    field,
    answers,
    hidden,
    updateAnswers,
    updateHidden,
    setInvokerId,
    formFields,
    updateformFields,
  } = props;
  const { type, required } = field;
  const [hide, setHide] = useState(false);
  const createCurrentAnswersWithDefaultValues = () => {
    const { options } = field;
    let defaultValue = options.find((el) => el.default);
    return defaultValue ? defaultValue.id.toString() : undefined;
  };

  let defaultValue = createCurrentAnswersWithDefaultValues();
  const getCurrentValues = () => {
    if (answers[field.id]) {
      if (Array.isArray(answers[field.id])) {
        return answers[field.id].map((el) => el.id || el.text);
      } else {
        return answers[field.id].id || answers[field.id]?.text;
      }
    }
    return undefined;
  };

  const handleChange = (event, value) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    let reducedAnswers = answers;
    let reducedHidden = hidden;
    if (!Array.isArray(value)) {
      // remove and add code for ddma (value is an array of objects) if necessary
      if (
        answers[value?.field.id] !== undefined &&
        answers[value?.field.id].option !== null &&
        answers[value?.field.id].option !== undefined
      ) {
        // answers undefined is for fields that havenot been answered yet and null is for dropdown fieds (that dont have a default answer or dropdown that is saved in text not option im not sure) and option undefined is for fieds that do not contain an option (text)
        let newFormFields = formFields.filter((element) => {
          let counter = 0;
          for (let i = 0; i < element.invokers.length; i++) {
            if (
              element.invokers[i] !== value.id &&
              element.invokers[i].toString() === answers[value?.field.id].id
            ) {
              counter++;
            }
          }
          return counter > 0 ? false : true;
        });

        let removedFieldsIds = formFields.filter(
          (element) =>
            element.invokers.includes(answers[value.field.id].option.id) &&
            !element.invokers.includes(value.option.id)
        );
        let newAnswers = [];
        let newHidden = [];
        for (let i = 0; i < removedFieldsIds.length; i++) {
          newAnswers = Object.keys(reducedAnswers).filter(
            (element) => (removedFieldsIds[i]?.id).toString() !== element
          );
          reducedAnswers = newAnswers.reduce((obj, key) => {
            obj[key] = answers[key];
            return obj;
          }, {});
          newHidden = Object.keys(reducedHidden).filter(
            (element) => (removedFieldsIds[i]?.id).toString() !== element
          );
          reducedHidden = newHidden.reduce((obj, key) => {
            obj[key] = hidden[key];
            return obj;
          }, {});
        }
        updateformFields(newFormFields);
      }
    }

    if (value.option?.invoker_option) {
      setInvokerId(value.option.id);
    } else {
      setInvokerId();
    }
    updateAnswers(
      Object.assign({}, reducedAnswers, {
        [field.id]: value,
      })
    );
    // updateHidden(
    //   Object.assign({}, reducedHidden, { // hidden
    //     [field.id]: false,
    //   })
    // );
  };
  const handleHide = (value) => {
    setHide(value);
    delete answers[field.id];
    hidden[field.id] = value;
    updateHidden(Object.assign({}, hidden));
    updateAnswers(Object.assign({}, answers));
  };

  const renderHideButton = () =>
    required ? null : (
      <IconButton
        onClick={() => {
          handleHide(!hidden[field.id]);
        }}
      >
        {hide || hidden[field.id] ? (
          <HideIcon fontSize={"small"} color={"secondary"} />
        ) : (
          <UnhideIcon fontSize={"small"} color={"secondary"} />
        )}
      </IconButton>
    );

  if (type === "text") {
    return (
      <div style={{ display: "flex" }}>
        <TextInput
          disabled={hidden[field.id]}
          value={getCurrentValues() || ""}
          field={field}
          onChange={handleChange}
          label={field.title}
          answerObject={answers[field.id] || {}}
        />
        {renderHideButton()}
      </div>
    );
  }

  if (type === "num") {
    return (
      <div style={{ display: "flex" }}>
        <TextInput
          disabled={hidden[field.id]}
          value={getCurrentValues("number") || ""}
          inputProps={{ pattern: "\\d*" }}
          field={field}
          type={"number"}
          answerObject={answers[field.id] || {}}
          onChange={handleChange}
          label={field.title}
        />
        {renderHideButton()}
      </div>
    );
  }

  if (type === "rb") {
    return (
      <div style={{ display: "flex" }}>
        <RadioButtonInput
          disabled={hidden[field.id]}
          value={getCurrentValues() || defaultValue || ""}
          field={field}
          answerObject={answers[field.id] || {}}
          onChange={handleChange}
          options={field.options}
          formTitle={field.title}
        />
        {renderHideButton()}
      </div>
    );
  }
  if (type === "stat") {
    return null;
  }
  return (
    <div>
      <Typography>
        This field type is not supported please contact Markabte
      </Typography>
    </div>
  );
};

const EditPrice = (props) => {
  const {
    updateCarForm,
    getCarForms,
    getFields,
    formFields,
    answers,
    hidden,
    getOptionsFields,
  } = props;
  const { t } = useTranslation();
  const [invoker_id, setInvokerId] = useState();
  let carId = props.location.state.carId;
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    getFields({
      formId: 9,
      car_id: carId,
      callback: () => {
        getCarForms({
          formId: 9,
          carId: carId, //change
        });
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (invoker_id !== undefined) {
      getOptionsFields({
        formId: 9,
        invoker_id: invoker_id,
      });
    }
    // eslint-disable-next-line
  }, [invoker_id]);

  const handleSubmitting = () => {
    setSubmitting(true);
    updateCarForm({
      id: carId,
      body: { answers, form: 9, hidden },
      // callback: () => {
      //   fetchData();
      // },
      callback: () => {
        props.history.push("/");
      },
    });

    //set redirect state to vehicle detail page if sumbit and exit was selected
    // if (event.currentTarget.value === "Submit & Exit") {
    // setRedirect("/car/" + carId);
    // }
  };

  let formFieldsSorted = formFields.sort((a, b) => a.tree_id - b.tree_id);

  return (
    <Paper style={{ margin: "2rem", padding: "2rem" }}>
      <Grid container style={{ justifyContent: "center" }}>
        <Typography variant="h3" style={{ marginBottom: "2rem" }}>
          {t("Edit car price")}
        </Typography>
      </Grid>
      {formFieldsSorted.map((field, i) => (
        <div key={`field_${field.type}_${i}`} style={{ marginBottom: "1em" }}>
          <FormFields
            field={field}
            answers={answers}
            hidden={hidden}
            updateAnswers={props.updateAnswers}
            updateHidden={props.updateHidden}
            setInvokerId={setInvokerId}
            formFields={props.formFields}
            updateformFields={props.updateformFields}
          />
        </div>
      ))}
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleSubmitting}
            loading={submitting}
          >
            {t("Submit")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  formFields: state.forms.formFields,
  answers: state.forms.answers,
  hidden: state.forms.hidden,
});

const mapDispatchToProps = (dispatch) => ({
  updateCarForm(payload) {
    dispatch(updateCarForm(payload));
  },
  updateformFields(payload) {
    dispatch(updateformFields(payload));
  },
  getCarForms(payload) {
    dispatch(getCarForms(payload));
  },
  getFields(payload) {
    dispatch(getFields(payload));
  },
  updateAnswers(payload) {
    dispatch(updateAnswers(payload));
  },
  updateHidden(payload) {
    dispatch(updateHidden(payload));
  },
  getOptionsFields(payload) {
    dispatch(getOptionsFields(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPrice)
);
