import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ImageUpload from "../ImageUpload";
import uploadImage from "../../helpers/aws";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  searchButton: {
    textTransform: "none",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      width: "100%",
    },
  },
  searchIcon: {
    transform: theme.direction === "ltr" ? "scaleX(1)" : "scaleX(-1)",
  },
  danger: {
    backgroundColor: "#D11A2A",
    color: "#fff",
  },
  imagespacing: {
    margin: "12px auto",
  },
}));
function ReportImageUpload(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedCar, userId, setLicenseImages, handleNext } = props;
  const [loading, setLoading] = useState({});
  const [imageValidation, setImageValidation] = useState(false);
  const [awsimg, setawsimg] = useState({ front: "", rear: "" });
  var loaded = {};
  var license = {};
  const uploadImg = () => {
    var liceFace = { front: frontgetimage, rear: reargetimage };
    Object.keys(liceFace).map((side) => {
      //car id note
      var name = `car ${selectedCar} license ${side}.png`;
      uploadImage(liceFace[side].selectedFile, name, (response) => {
        loaded[side] = false;
        license[side] = response.Location;
        if (side == "front")
          setawsimg((prev) => {
            return { ...prev, front: response.Location };
          });
        if (side == "rear")
          setawsimg((prev) => {
            return { ...prev, rear: response.Location };
          });
      });
      var Loading = {};
      setLoading({ ...loaded });
      // setLicense({ ...license });
    });
  };

  useEffect(() => {
    if (awsimg.front !== "" && awsimg.rear !== "") {
      setLicenseImages({ ...awsimg });
    }
  }, [awsimg]);

  const [imageGetter, setimageGetter] = useState({});
  var getterobj = { front: "", rear: "" };

  const [frontgetimage, setfrontgetimage] = useState({
    mainState: "initial",
    imageUploaded: 0,
    selectedFile: null,
  });

  const [reargetimage, setreargetimage] = useState({
    mainState: "initial",
    imageUploaded: 0,
    selectedFile: null,
  });

  useEffect(() => {
    if (!!frontgetimage.selectedFile && !!reargetimage.selectedFile) {
      setImageValidation(true);
    }
  }, [frontgetimage, reargetimage]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "0",
        padding: "0",
        width: "100%",
      }}
    >
      <div className={classes.imagespacing}>
        <ImageUpload
          getterobj={getterobj}
          status={"front"}
          getImg={setimageGetter}
          oldState={imageGetter}
          cardName="Input Image"
          message={t("Upload Front Side Image")}
          setstate={setfrontgetimage}
          state={frontgetimage}
        />
      </div>
      <div className={classes.imagespacing}>
        <ImageUpload
          getterobj={getterobj}
          status={"rear"}
          getImg={setimageGetter}
          oldState={imageGetter}
          cardName="Input Image"
          message={t("Upload Back Side Image")}
          setstate={setreargetimage}
          state={reargetimage}
        />
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {imageValidation ? (
          <Button
            fullWidth
            className={classes.imagespacing}
            type="submit"
            children={t("Submit")}
            variant={"contained"}
            color={"primary"}
            style={{
              width: "333px",
              display: "flex",
              margin: "auto",
            }}
            onClick={() => {
              uploadImg();
            }}
          />
        ) : (
          <Button
            fullWidth
            className={classes.imagespacing}
            type="submit"
            children={t("Submit")}
            variant={"contained"}
            color={"inherit"}
            style={{
              background: "#C4C4C4",
              width: "333px",
              display: "flex",
              margin: "auto",
            }}
            onClick={() => {
              console.log(getterobj, !!getterobj.front, "jjjjjjjjjjj");
            }}
          />
        )}
      </div>
    </div>
  );
}

// export default ReportImageUpload

const mapStateToProps = (state) => ({
  // carID: state.car.car.id,
  userId: state.auth.user?.user_id,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportImageUpload)
);
