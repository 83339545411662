import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
  useMediaQuery,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { ExpandMore } from "@material-ui/icons";
import PhoneIcon from "../../icons/phone.svg";
import FilterIcon from "../icons/FilterIcon.js";
// import { getReportPurchaseAppointments } from "../../store/actions/appointments";
import theme from "../../theme";
import DateFilter from "./DateFilter";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 24px 0", ///0
    maxWidth: "391px",
    width: "391px", //// after working on date filter check
    minHeight: "calc(100% - 128px)",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
      minHeight: "auto",
      maxWidth: "initial",
      width: "auto",
    },
  },
  dialogActions: {
    position: "absolute", //// /////comb
    bottom: 0, ////
    left: 0, ////
    width: "397px", //// 439 from design - (21 + 21) padding

    boxShadow: "0px -2px 4px rgba(15, 71, 90, 0.2)", ////
    backgroundColor: "white",
    zIndex: "1400",
    justifyContent: "center",
    padding: "16px 21px", ////
    justifyContent: "space-between",
    width: "calc(100% - 42px)", //// comb
    margin: "0!important", //// comb
    [theme.breakpoints.down("sm")]: {
      position: "fixed", ////
      padding: "10px", ////
      width: "calc(100vw - 20px)", // 100vw mobile width - (10 + 10) padding
    },
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    marginLeft: "8px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0",
      marginTop: "8px",
    },
    "&:first-child": {
      marginLeft: "0",
      marginTop: "0",
    },
  },
  dialogRoot: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  dialogContentText: {
    padding: "0",
  },
  dialogContent: {
    padding: 0,
    minHeight: "485px",
    margin: "0 16px",
  },
  mobPaper: {
    boxShadow: "unset",
  },
  mobPopper: {
    transform: "translate3d(0px, 0px, 0px) !important", /// wrong depends on scroll
    marginTop: "11px",
    position: "unset", //// comb
    width: "auto!important", //// comb
    // marginBottom: "calc(74.5px - 35px)", //// comb
    marginBottom: "calc(74.5px)", //// comb
  },
  mobOption: {
    padding: "0",
    minHeight: 0,
  },
  formControl: {
    margin: "0px 12px 24px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0px 9px 18px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0px 10.125px 20.25px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0px 12px 24px",
    },
  },
  formControlListOpen: {
    margin: "0px",
  },
  outlinedInputRootFocused: {
    borderRadius: "5px 5px 0 0",
  },
  outlinedInputRoot: {
    borderRadius: "8px",
    backgroundColor: "white",
    padding: "3px 32px 3px 14px!important",
    height: "45px",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "0!important", // had to seperate them because arabic turns "0 32px 0 9.09px!important" to "0 9.09px!important 0 32px" which is invalid value
      paddingRight: "32px!important",
      paddingBottom: "0!important",
      paddingLeft: "9.09px!important",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "0!important",
      paddingRight: "32px!important",
      paddingBottom: "0!important",
      paddingLeft: "10.12px!important",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "0!important",
      paddingRight: "32px!important",
      paddingBottom: "0!important",
      paddingLeft: "12px!important",
    },
  },
  autocompleteInput: {
    [theme.breakpoints.down("sm")]: {
      padding: "0!important",
      fontSize: "14px",
      color: "#07303E",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "7.375px!important",
      paddingRight: "4px!important",
      paddingBottom: "7.375px!important",
      paddingLeft: "6px!important",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "9.5px!important",
      paddingRight: "4px!important",
      paddingBottom: "9.5px!important",
      paddingLeft: "6px!important",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "13px!important",
      paddingRight: "4px!important",
      paddingBottom: "13px!important",
      paddingLeft: "6px!important",
    },
  },
  shrink: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(30px, 5px) scale(0.75)!important",
    },
  },
  shrinkListOpen: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(22px, 7px) scale(0.75)!important",
    },
  },
  inputLableOutlined: {
    color: "#07303E",
    transform: "translate(14px, 13.5px) scale(1)",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      transform: "translate(22px, 22px) scale(1)",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
      transform: "translate(14px, 11.5px) scale(1)",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13.5px",
      transform: "translate(14px, 12.5px) scale(1)",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
      transform: "translate(14px, 13.5px) scale(1)",
    },
  },
  inputLableOutlinedlistOpen: {
    position: "relative",
    transform: "translate(22px, 25px) scale(1)",
  },
  optionHr: {
    margin: "15px 0 0",
    height: "1px",
    border: "none",
    backgroundColor: "#CDCDCD",
    [theme.breakpoints.up("md")]: {
      margin: "7.375px 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "8.355px 0 0",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px 0 0",
    },
  },
  optionHrListOpen: {
    width: "100%",
    margin: "0",
  },
  phoneTypographyDiv: {
    display: "flex",
    width: "40%",
    borderLeft: "1px solid #07303E",
    paddingLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "end",
    },
  },
  listOpenTypography: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },

  webOptionTypography: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#626465",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  mobListBox: {
    padding: "0",
    maxHeight: "calc(100vh - 370px)",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100vh - 245px)",
    },
  },
  popupTitle: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#07303E",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  contentText: {
    fontSize: "16px",
    marginBottom: "10px",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginBottom: "16px",
    },
  },
  dialogTitle: {
    padding: "0",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
}));

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.markabte_user,
});

const AutocompleteComponent = (props) => {
  const {
    options,
    label,
    variant,
    inBar,
    onChange,
    setOpen,
    setMobAutocompleteProps,
    PopperComponent,
    listOpen,
    value,
    setSelectedValues,
    field,
    setUserData,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [listOpened, setListOpened] = useState(false);

  return (
    <Autocomplete
      noOptionsText={t("No options")}
      closeIcon=""
      autoHighlight={true}
      id="combo-box-demo"
      disablePortal={true}
      options={options}
      PopperComponent={PopperComponent}
      popupIcon={listOpen ? <></> : <ExpandMore />}
      open={listOpen}
      value={value}
      getOptionLabel={(option) => option.markabte_user}
      renderOption={(option, state) => (
        <div style={{ width: "100%", padding: "1px" }}>
          <div style={{ display: "flex", margin: "16.5px 0" }}>
            <Typography
              style={{ width: "60%" }}
              className={clsx({
                [classes.listOpenTypography]: listOpen,
              })}
            >
              {option?.markabte_user}
            </Typography>
            <div className={classes.phoneTypographyDiv}>
              <img src={PhoneIcon} width="14px" />
              <Typography
                className={clsx({
                  [classes.webOptionTypography]: !inBar,
                })}
              >
                {option?.hidden_phone_number}
              </Typography>
            </div>
          </div>
          <hr
            className={clsx({
              [classes.optionHr]: 1,
              [classes.optionHrListOpen]: listOpen,
            })}
          />
        </div>
      )}
      onChange={(event, value) => {
        setUserData(value);
      }}
      onOpen={() => {
        setListOpened(true);
      }}
      onClose={() => {
        setListOpened(false);
      }}
      disabled={options.length === 1 && field === "model"}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          // {...conditionalTextProps}
          placeholder={t("Type buyer’s name")}
          variant="outlined"
          InputLabelProps={{
            classes: {
              outlined: clsx({
                [classes.inputLableOutlined]: !inBar,
                [classes.inputLableOutlinedlistOpen]: listOpen,
              }),
              shrink: clsx({
                [classes.shrink]: !inBar,
                [classes.shrinkListOpen]: listOpen,
              }),
            },
          }}
          InputProps={
            listOpen
              ? {
                  ...params.InputProps,
                  startAdornment: (
                    <SearchIcon
                      fill={"#504F4F"}
                      style={
                        localStorage.getItem("language") === "ar"
                          ? {
                              transform: "rotateY(180deg)",
                              marginLeft: "16px",
                              fill: "#504F4F",
                            }
                          : {
                              marginRight: "16px",
                              fill: "#504F4F",
                            }
                      }
                    />
                  ),
                }
              : {
                  ...params.InputProps,
                }
          }
        />
      )}
      classes={{
        input: clsx({
          [classes.autocompleteInput]: 1,
        }),
        inputRoot: clsx({
          [classes.outlinedInputRoot]: !inBar,
          [classes.outlinedInputRootFocused]: listOpened, //this is right
        }),
        root: clsx({
          [classes.formControl]: !inBar,
          [classes.formControlListOpen]: listOpen,
        }),
        listbox: clsx({
          [classes.mobListBox]: listOpen,
        }),
        paper: clsx({
          [classes.mobPaper]: listOpen,
        }),
        popper: clsx({
          [classes.mobPopper]: listOpen,
        }),
        option: clsx({
          [classes.mobOption]: listOpen,
        }),
      }}
    />
  );
};

const SelectUser = (props) => {
  const { t } = useTranslation();
  const {
    open,
    handleClose,
    handelCarSaleMarkabteUser,
    handelCarSaleIndependentUser,
    // getReportPurchaseAppointments,
    dealershipId,
    handleNext,
    setUserData,
    setWithoutHeader,
  } = props;
  const classes = useStyles(open);

  const [reportPurchaseUsers, setReportPurchaseUsers] = useState([]);
  const [constReportPurchaseUsers, setConstReportPurchaseUsers] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  const [first, setFirst] = useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // useEffect(() => {
  //   // if (open) {
  //   getReportPurchaseAppointments({
  //     dealershipId,
  //     callback: (response) => {
  //       setReportPurchaseUsers(response.response.users);
  //       setConstReportPurchaseUsers(response.response.users);
  //       setDateRange(response.response.date_range);
  //     },
  //   });
  //   // }
  // }, []);

  return (
    <>
      {first ? (
        <>
          {/* //// comb (diaAct)*/}
          <DialogContent className={classes.dialogContent}>
            <AutocompleteComponent
              options={reportPurchaseUsers}
              listOpen={true}
              inBar={false}
              value=""
              setUserData={setUserData}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions} disableSpacing>
            <Grid container justify={"space-between"}>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  // onClick={handelCarSaleMarkabteUser}
                  onClick={() => setFirst(false)}
                  color="primary"
                  variant="outlined"
                  className={classes.dialogButtons}
                  endIcon={<FilterIcon size={"20px"} fill={"#186985"} />}
                >
                  {t("Filter")}
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button
                  fullWidth
                  // onClick={handelCarSaleIndependentUser}
                  color="primary"
                  variant="contained"
                  autoFocus
                  className={classes.dialogButtons}
                  onClick={handleNext}
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      ) : (
        <DateFilter
          setFirst={setFirst}
          dateRange={dateRange}
          reportPurchaseUsers={reportPurchaseUsers}
          setReportPurchaseUsers={setReportPurchaseUsers}
          constReportPurchaseUsers={constReportPurchaseUsers}
          setWithoutHeader={setWithoutHeader}
        />
      )}
    </>
  );
};

// export default SelectUser;
//////////////move connect to parent component that thaer is working on
const mapStateToProps = (state) => ({
  // appointments: state.appointment.appointments,
  // user: state.auth.user,
  dealershipId: state.auth.user.dealership_id,
});
// const mapDispatchToProps = (dispatch) => ({
//   getReportPurchaseAppointments(payload) {
//     dispatch(getReportPurchaseAppointments(payload));
//   },
// });

export default connect(mapStateToProps, null)(SelectUser);
