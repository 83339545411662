import React from "react";

import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Score from "./ScorePrototype";
import CircularProg from "./CircularProg";

import DTC from "../icons/DTC.svg";
import Frame from "../icons/Frame.svg";
import theme from "../theme";

import Officer from "../icons/Officer.svg"; // turn into component
// import Calendar from "../icons/calendar.svg"; // turn into component
import Skeleton from "@material-ui/lab/Skeleton";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MaintainIcon from "./icons/maintainIcon";
import ExcellentIcon from "./icons/ExcellentIcon";
import InspectionNoteIcon from "./icons/InspectionNoteIcon";

const useStyles = makeStyles((theme) => ({
  smPapers: {
    borderRadius: "0",
    // boxShadow: "0px 2px 4px rgba(15, 71, 90, 0.4)",
    boxShadow: "unset",
    margin: "24px 012",
    // borderBottom:
    //   "1px solid radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.up("md")]: {
      all: "initial",
      width: "100%",
    },
  },
  header: {
    whiteSpace: "nowrap", /// check this
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "16px 0 16px",
      color: "#186985",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#186985",
      padding: "0",
      margin: "34px 0",
    },
  },
  reportIcons: {
    height: "15px",
    width: "15px",
    margin: "0 5px 0 0",
    [theme.breakpoints.up("md")]: {
      height: "clamp(15px,2vw,24px)",
      width: "clamp(15px,2vw,24px)",
    },
  },
  inspDetText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#636363",
    fontSize: "10px",
    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(10px,1vw,16px)",
    },
  },
  inspDetSubText: {
    color: "#07303E",
    fontSize: "10px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      fontSize: "clamp(10px,1vw,16px)",
    },
  },
  noOfPoints: {
    fontSize: "12px",
    fontWeight: "500",
    color: "white",
    marginRight: "3px",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      // fontSize: "clamp(10px,1.1vw,18px)",
      // alignSelf: "center",
    },
  },
  conditionReportHr: {
    height: "1px",
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, #0c6885 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.up("md")]: {
      margin: "32px 0 30px",
      backgroundImage:
        "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    },
  },
  titleDiv: {
    // borderBottom: "2px solid #0c6885",
    margin: "0 -15px",
    padding: "0 15px",
    [theme.breakpoints.up("md")]: {
      borderBottom: "none",
    },
  },
  borderedDiv: {
    [theme.breakpoints.up("md")]: {
      // border: "0.5px solid #186985",
      paddingBottom: "15px",
    },
  },
  reportSummaryDiv: {
    display: "flex",
    justifyContent: "space-between",
    // padding: "6px 0",
    [theme.breakpoints.up("md")]: {
      // borderBottom: "0.5px solid  #186985",
      // padding: "7px 32px",
      // marginBottom: "26px",
    },
  },
  reportSummaryText: {
    color: "white",
    fontSize: "14px",
    fontWeight: "500",
    paddingLeft: "18px",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      // fontSize: "clamp(12px,1.1vw,20px)",
      alignSelf: "center",
      paddingLeft: "20px",
    },
  },
  visualScoreGrid: {
    padding: "0 10px 20px",
    [theme.breakpoints.up("md")]: {
      padding: "15px 0px 20px",
      borderBottom: "1px solid #CDCDCD",
    },
  },
  rep: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#00BDFF",
    display: "flex",
    justifyContent: "center",
  },
  arrow: {
    height: "15px",
  },
  groupScore: {
    color: "#07303E",
    fontSize: "12px",
    fontWeight: "500",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  },
  groupTitle: {
    color: "#186985",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  },
  Divider: {
    height: 1,
    backgroundColor: "#D2D2D2",
    marginLeft: "24px",
  },
  headerWrap: {
    margin: "13px 0",
    [theme.breakpoints.up("md")]: {
      margin: "34px 0",
    },
  },
  reportPaper: {
    background: "#07303E",
    borderRadius: "8.3px",
    margin: "0",
    maxWidth: "725px",
    [theme.breakpoints.up("md")]: {
      background: "#07303E",
      borderRadius: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 50px", ///////////////this
    },
  },
  reportSummarySubInfo: {
    // padding: "20px 10px 5px",
    marginBottom: "-3px",
    // borderBottom: "2px solid #CDCDCD",
    // whiteSpace: "nowrap",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    background: "#F9FAFD",
    [theme.breakpoints.up("md")]: {
      marginBottom: "-1px",
      padding: "12px",
      // padding: "26px 32px 13px",
      whiteSpace: "nowrap",
      justifyContent: "space-between",
      background: "#F9FAFD",
    },
  },
  reportSummaryblock: {
    background: "#F9FAFD",
  },
  scoreGridItem: {
    padding: "5.5px",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: "5.5px 20px",
      justifyContent: "space-between",
    },
  },
  markabteScoreDiv: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
  },
  circularProgDiv: {
    // margin: "10px 32px 10px 0",
    margin: "10px 15px 10px auto",
  },
  firstTableCellScoring: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    backgroundImage:
      "linear-gradient(white 25%, #C4C4C4 25%, #C4C4C4 50%, #C4C4C4 50%, #C4C4C4 75%, white 75%)",
    backgroundSize: "1px 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0%",
    padding: "10px",
  },
  firstTableCellScore: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    borderBottom: "none",
    [theme.breakpoints.up("md")]: {
      backgroundImage:
        "linear-gradient(white 25%, #C4C4C4 25%, #C4C4C4 50%, #C4C4C4 50%, #C4C4C4 75%, white 75%)",
      backgroundSize: "1px 115%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "100% 50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      borderBottom: 0,
      padding: "0 10px",
    },
  },
  firstTableCell: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage:
        "linear-gradient(white 25%, #C4C4C4 25%, #C4C4C4 50%, #C4C4C4 50%, #C4C4C4 75%, white 75%)",
      backgroundSize: "1px 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "100% 0%",
    },
  },
  secondTableCell: {
    borderBottom: "none",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      justifyContent: "space-between",
      padding: "0 10px",
    },
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
  InspectionScoringSecondCell: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    padding: "10px",
  },
  newGroupTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: "500",
    },
    whiteSpace: "nowrap",
  },
  newSubGroupTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    fontSize: "12px",
    fontWeight: "400",
    color: "#07303E",
    whiteSpace: "nowrap",
    margin: "auto",
  },

  groupIcon: {
    width: "20px",
    height: "28px",
    marginRight: "7px",
  },
  showFullReportButton: {
    padding: "10px 18px",
    textTransform: "none",
    justifyContent: "flex-end",
    alignContent: "center",
  },
  computerAndFrame: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "13px 0 23px",
      display: "block",
    },
  },
  InspectionScoringCell: {
    display: "flex",
    alignItems: "center",
  },
  tableCellDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "baseline",
    },
  },
  hr: {
    backgroundColor: "rgb(177, 177, 177)",
    border: 0,
    width: "1px",
    [theme.breakpoints.up("xl")]: {
      margin: "10px 0 24px",
    },
  },
  inspectionPointBlock: {
    margin: "auto",
    display: "inline-flex",
  },
  reportSamaryFirstRow: {
    fontSize: "16px",
    fontWeight: 600,
    margin: "auto 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  reportSamarySecondRow: {
    fontSize: "14px",
    fontWeight: 500,
    margin: "auto 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  inspectionPointCondition: {
    color: "#636363",
  },
  inspectionPointConditionText: {
    fontSize: "16px",
    lineHeight: "1",
    fontWeight: "500",
  },
  mobViewInspRep: {
    borderTop: "1px solid #c4c4c4",
  },
}));

const nth = (day) => {
  if (day > 3 && day < 21) return day + "th";
  switch (day % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
};

const ConditionReportScoring = (props) => {
  const { t, i18n } = useTranslation();
  const mediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();
  const {
    // counterIsDone,
    noOfPoints,
    enteredBy,
    dateObject,
    groups,
    carId,
    history,
    score,
    scroll,
    withoutLink,
    inspectionPoint,
    carDetailsPopup,
    setCarDetailsPopup,
    Expired,
  } = props;
  let computerScanGroup = groups?.groups?.filter((el) => el?.group_id == 16)[0];
  let frameGroup = groups?.groups?.filter((el) => el?.group_id == 15)[0];
  let is_inspection = window.location.href.includes("inspection-report");

  return (
    <Paper
      style={
        is_inspection
          ? { padding: "0 16px 32px" }
          : { padding: "0 0 16px", width: "100%" }
      }
      className={classes.smPapers}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        justify="space-between"
        classes={{ root: classes.headerwrap }}
      >
        <Grid item xs zeroMinWidth style={{ maxWidth: "min-content" }}>
          <Typography
            style={
              localStorage.getItem("language") === "ar"
                ? { fontWeight: "700" }
                : { fontWeight: "500" }
            }
            className={classes.header}
            // noWrap
          >
            {t("Maintenance Report Summary")}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs>
            <Divider component="div" classes={{ root: classes.Divider }} />
          </Grid>
        </Hidden>
      </Grid>

      {/* score is just visual now will be added after scoring sprint */}
      <Paper classes={{ root: classes.reportPaper }}>
        <div className={classes.reportSummaryDiv}>
          <Grid container style={{ alignItems: "center", height: "59px" }}>
            <div style={{ display: "inline-flex", marginLeft: "30px" }}>
              <MaintainIcon style={{ display: "flex" }} />
              <Typography
                style={
                  localStorage.getItem("language") === "ar"
                    ? {
                        fontWeight: "600",
                      }
                    : {}
                }
                className={classes.reportSummaryText}
              >
                {t("Report Summary")}
              </Typography>
            </div>
          </Grid>
        </div>
        <Grid container classes={{ root: classes.reportSummarySubInfo }}>
          <Table>
            <TableRow className={classes.computerAndFrame}>
              <TableCell className={classes.firstTableCellScore}>
                <div className={classes.markabteScoreDiv}>
                  <Typography
                    style={{
                      color: "#07303E",
                    }}
                    className={classes.reportSamaryFirstRow}
                  >
                    {t("Car Score")}:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "30px",
                      color: "#3CB656",
                    }}
                    className={classes.reportSamaryFirstRow}
                  >
                    {score}%
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={classes.secondTableCell}>
                <div className={classes.markabteScoreDiv}>
                  <Typography
                    style={{
                      color: "#07303E",
                    }}
                    className={classes.reportSamarySecondRow}
                  >
                    {t("Inspection Date")}
                  </Typography>
                  <Typography
                    className={classes.reportSamarySecondRow}
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {localStorage.getItem("language") === "en"
                      ? nth(dateObject.getDate())
                      : dateObject.getDate()}{" "}
                    {/* {nth(dateObject.getDate())}{" "} */}
                    {t(
                      dateObject.toLocaleString("default", {
                        month: "long",
                      })
                    )}{" "}
                    {dateObject.getFullYear()}
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
            <Hidden mdUp>
              <TableRow className={classes.mobViewInspRep}>
                <Button
                  style={{
                    padding: "13px 0 11px",
                    textTransform: "none",
                  }}
                  disabled={Expired}
                  fullWidth
                  onClick={() => {
                    setCarDetailsPopup(true);
                    scroll();
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: Expired ? "#aaa" : "#45A5C7",
                      }}
                    >
                      {t("View Insepction Notes")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ArrowBackIosIcon
                      className={classes.arrow}
                      style={{
                        transform:
                          localStorage.getItem("language") != "ar" &&
                          "rotate(180deg) translate(0px, -3px)",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: Expired ? "#aaa" : "#00BDFF",
                      }}
                    />
                  </Grid>
                </Button>
              </TableRow>
            </Hidden>
          </Table>
        </Grid>
        <Hidden smDown>
          <Link style={{ color: "#00BDFF" }}>
            <Grid container style={{ height: "30px" }}>
              <Button className={classes.showFullReportButton} fullWidth>
                <Grid item>
                  {/* <ExpandMoreIcon
                    className={classes.arrow}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      color: "#00BDFF",
                      height: "100%",
                    }}
                  /> */}
                </Grid>
              </Button>
            </Grid>
          </Link>
        </Hidden>
        <Hidden mdUp>
          <div style={{ color: "#00BDFF", height: "44px" }}>
            {!withoutLink && (
              <Hidden mdUp>
                <Grid
                  container
                  className={classes.borderedDiv}
                  style={{ borderTop: " none", justifyContent: "center" }}
                >
                  <Grid item sm={12} className={classes.rep}>
                    <Link style={{ color: "#00BDFF" }}>
                      <Grid container></Grid>
                    </Link>
                  </Grid>
                </Grid>
              </Hidden>
            )}
          </div>
        </Hidden>
      </Paper>
    </Paper>
  );
};

export default withRouter(ConditionReportScoring);
