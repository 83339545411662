import { createReducer } from "../createReducerFunc";

import {
  GET_CALLS_INFO_SUCCEED,
  GET_DEALER_CREDITS_SUCCESS,
  MOVE_TO_HISTORY_SUCCEED,
  SET_DEALER_CREDITS,
  GET_MESSAGES_INFO_SUCCEED,
} from "../constants";

const initialState = {
  credits: 0,
};

export default createReducer(initialState, {
  [SET_DEALER_CREDITS]: (state, payload) => {
    return Object.assign({}, state, {
      credits: payload.credits,
    });
  },
  [GET_CALLS_INFO_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      calls_info: payload.data,
    });
  },
  [GET_MESSAGES_INFO_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      messages_info: payload.data,
    });
  },
  [GET_DEALER_CREDITS_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      credits: payload.credits,
    });
  },
  [MOVE_TO_HISTORY_SUCCEED]: (state, payload) => {
    console.log("xxxxxxxxxxxxxxxxx", payload);
    return Object.assign({}, state, {
      calls_info: payload.calls_info,
    });
  },
});
